<template>
  <div class="d-flex justify-end h-0">
    <a
      class="enable-proxy-link d-flex align-center"
      href="https://docs.coro.net/emailproxy/incoming/config/"
      target="_blank"
    >
      <v-icon icon="$help"></v-icon>
      <span class="coro-link">{{ $t("emailProxy.enableProxyLink") }}</span>
    </a>
  </div>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.HIGHLIGHTABLE_TABLE"
  >
    <beta-banner
      class="mb-4"
      :addon-or-module="SubscriptionAddon.INBOUND_GATEWAY"
      :title="$t(`subscriptions.addons.${SubscriptionAddon.INBOUND_GATEWAY}`)"
    ></beta-banner>
    <template v-if="domains.length">
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="subtitle1">
          {{ $t("emailProxy.table.title", { direction: capitalize(ProxyDirection.INBOUND) }) }}
        </div>
        <v-btn
          color="primary"
          rounded
          large
          class="mt-4 add-btn"
          @click="onActionClick({ direction: ProxyDirection.INBOUND }, EmailProxyAction.ADD_DOMAIN)"
        >
          <v-icon color="white" class="mr-2" icon="$add"></v-icon>
          <span>{{ $t("emailProxy.addDomain") }}</span>
        </v-btn>
      </div>
      <v-progress-linear v-if="loading" indeterminate height="2px" />
      <table-wrapper class="activity-logs-table">
        <v-data-table
          :items="domains"
          v-model:expanded="expanded"
          :show-expand="true"
          item-key="name"
          fixed-header
          expand-icon="icon-chevron"
        >
          <template #headers>
            <tr>
              <th />
              <th>
                {{ $t("emailProxy.table.headers.domain") }}
              </th>
              <th>
                {{ $t("emailProxy.table.headers.smpt") }}
              </th>
              <th>
                {{ $t("emailProxy.table.headers.status") }}
              </th>
              <th class="checkbox-col text-center"></th>
            </tr>
          </template>
          <template #item="{ item, toggleExpand, isExpanded, internalItem, index }">
            <tr :key="index">
              <td class="w-5">
                <v-icon
                  class="mr-2 cursor-pointer toggle-expander"
                  icon="$dropdown"
                  color="primary"
                  :class="{ rotated: isExpanded(internalItem) }"
                  @click="toggleExpand(internalItem)"
                />
              </td>
              <td class="subtitle2">
                <v-icon class="mr-4" size="40" icon="$globe"></v-icon>
                <span>{{ item.name }}</span>
              </td>
              <td>
                {{
                  $t(
                    "emailProxy.table.proxies",
                    {
                      n: item.relaySmtpUrls.length,
                    },
                    item.relaySmtpUrls.length
                  )
                }}
              </td>
              <td>
                <div :class="`${getStatusColor(item.status)}`">
                  <span>{{ $t(`emailProxy.table.statuses.${item.status}`) }}</span>
                  <span v-if="showTimestamp(item)">{{
                    ` (${moment(item.timestamp).format("L")})`
                  }}</span>
                </div>
                <div
                  v-if="item.failedReason"
                  class="caption text-indigo-medium"
                  v-html="$t(`emailProxy.table.failedReason.${item.failedReason}`)"
                />
              </td>
              <td class="text-right" @click="$event.stopPropagation()">
                <v-menu bottom left>
                  <template #activator="{ props }">
                    <v-btn v-bind="props" dark icon :data-testid="`${item.name}-actions-btn`">
                      <v-icon icon="$dots"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="action in itemActions"
                      :key="action"
                      :disabled="isActionDisabled(action, item)"
                      @click="onActionClick(item, action)"
                    >
                      <v-list-item-title>
                        {{ $t(`emailProxy.table.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template #expanded-row="{ item, columns }">
            <tr>
              <td colspan="1" />
              <td class="domain-details" :colspan="columns.length - 1">
                <div class="subtitle2">
                  {{
                    `${$t("emailProxy.table.headers.host")}:${$t("emailProxy.table.headers.port")}`
                  }}
                </div>
                <div v-for="(url, index) in item.relaySmtpUrls" :key="index">
                  {{ `${url.host}:${url.port}` }}
                </div>
                <template v-if="item.headers?.length">
                  <div class="subtitle2 mt-4">
                    {{
                      `${$t("emailProxy.table.customHeaderName")}:${$t("emailProxy.table.headerValue")}`
                    }}
                  </div>
                  <div v-for="(header, index) in item.headers" :key="index">
                    {{ `${header.name} / ${header.value}` }}
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </table-wrapper>
    </template>
    <empty-state v-else class="margin-auto mt-12">
      <template #icon>
        <v-icon icon="$accessRestrictions" :size="80"></v-icon>
      </template>
      <template #description>
        {{ $t("emailProxy.noItems.inbound.description") }}
      </template>
      <template #subtitle>
        {{ $t("emailProxy.noItems.inbound.subDescription") }}
      </template>
      <template #button>
        <v-btn
          color="primary"
          rounded
          large
          class="mt-4"
          @click="onActionClick({ direction: ProxyDirection.INBOUND }, EmailProxyAction.ADD_DOMAIN)"
        >
          <span>{{ $t("emailProxy.addDomain") }}</span>
        </v-btn>
      </template>
    </empty-state>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import BetaBanner from "@/components/BetaBanner.vue";
import {
  EmailProxyAction,
  ProxyDirection,
  ProxySecurityMode,
  ProxyStatus,
} from "@/constants/email-proxy";
import { SubscriptionAddon } from "@/constants/workplaces";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
  isWorkspaceNewAndInDemoMode,
  openMovingToTrialModal,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useEmailProxyStore } from "@/_store/email-security/email-proxy.module";
import type { EmailProxyDomain } from "@/_store/email-security/email-proxy.module";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";
import AddEmailProxyModal from "@/components/modals/email-settings/AddEmailProxyModal.vue";
import { ModalWidth } from "@/constants/modals";
import TestEmailProxyModal from "@/components/modals/email-settings/TestEmailProxyModal.vue";
import EmptyState from "@/components/EmptyState.vue";
import moment from "moment/moment";
import capitalize from "lodash/capitalize";
import { DemoWillStartModal } from "@/constants/tutorial.ts";

export default defineComponent({
  components: {
    EmptyState,
    BetaBanner,
    TableWrapper,
  },
  setup() {
    const emailProxyStore = useEmailProxyStore();
    const dialogsStore = useDialogsStore();
    const { settings, showSkeletonLoader, loading, domains } = storeToRefs(emailProxyStore);
    const expanded = ref<string[]>([]);
    const isActionDisabled = (action: EmailProxyAction, item: EmailProxyDomain) => {
      return (
        action === EmailProxyAction.TEST_DOMAIN &&
        [ProxyStatus.PASSED, ProxyStatus.PENDING].includes(item.status)
      );
    };

    const actionNotAllowed = () => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionAddon.INBOUND_GATEWAY
      );
    };

    const onActionClick = (item: Partial<EmailProxyDomain>, action: EmailProxyAction) => {
      switch (action) {
        case EmailProxyAction.ADD_DOMAIN: {
          const dialogConfig = componentDialogsConfigConstructor({
            item,
            action,
            component: AddEmailProxyModal,
            width: ModalWidth.MEDIUM,
            callback: emailProxyStore.addDomain,
            disable: actionNotAllowed(),
            scrollable: true,
          });

          const handleAction = () => {
            dialogsStore.openDialog({
              ...dialogConfig,
            });
          };

          if (isWorkspaceNewAndInDemoMode()) {
            return openMovingToTrialModal(DemoWillStartModal.PROXY, handleAction);
          }

          handleAction();

          break;
        }
        case EmailProxyAction.TEST_DOMAIN: {
          const dialogConfig = componentDialogsConfigConstructor({
            item,
            action,
            component: TestEmailProxyModal,
            width: ModalWidth.MEDIUM,
            callback: () => {},
            disable: actionNotAllowed(),
          });

          dialogsStore.openDialog({
            ...dialogConfig,
            footer: null,
          });

          break;
        }
        case EmailProxyAction.REMOVE_DOMAIN: {
          const dialogConfig = confirmationDialogsConfigConstructor({
            item,
            action: EmailProxyAction.REMOVE_DOMAIN,
            callback: () => emailProxyStore.removeDomain(item as EmailProxyDomain),
            disable: actionNotAllowed(),
          });

          dialogsStore.openDialog(dialogConfig);

          break;
        }
        case EmailProxyAction.EDIT_DOMAIN: {
          const dialogConfig = componentDialogsConfigConstructor({
            item,
            action,
            component: AddEmailProxyModal,
            width: ModalWidth.MEDIUM,
            callback: emailProxyStore.editDomain,
            disable: actionNotAllowed(),
            scrollable: true,
          });

          dialogsStore.openDialog({
            ...dialogConfig,
          });

          break;
        }
        default: {
          return;
        }
      }
    };

    const getStatusColor = (status: ProxyStatus) => {
      switch (status) {
        case ProxyStatus.PASSED:
          return "text-green-dark";
        case ProxyStatus.FAILED:
          return "text-red-dark";
        case ProxyStatus.PENDING:
          return "text-yellow-base";
        case ProxyStatus.NOT_TESTED:
          return "text-indigo-medium";
      }
    };

    const showTimestamp = (item: EmailProxyDomain) => {
      return item.timestamp && item.status !== ProxyStatus.NOT_TESTED;
    };

    onMounted(async () => {
      await emailProxyStore.init(ProxyDirection.INBOUND);
    });

    watch(domains, () => {
      expanded.value = domains.value.map(({ id }) => id);
    });

    return {
      coronetSkeletonLoaderTypes,
      TableWrapper,
      ProxyDirection,
      SubscriptionAddon,
      ProxySecurityMode,
      EmailProxyAction,
      actionNotAllowed,
      isActionDisabled,
      onActionClick,
      getStatusColor,
      showTimestamp,
      itemActions: [
        EmailProxyAction.TEST_DOMAIN,
        EmailProxyAction.EDIT_DOMAIN,
        EmailProxyAction.REMOVE_DOMAIN,
      ],
      settings,
      showSkeletonLoader,
      loading,
      domains,
      expanded,
      moment,
      capitalize,
    };
  },
});
</script>

<style scoped lang="scss">
.enable-proxy-link {
  top: -78px;
  position: relative;
  align-self: center;
  padding-left: 12px !important;
  padding-right: 24px !important;
}
:deep(*) {
  .v-table > .v-table__wrapper > table > tbody > tr > td {
    height: 92px !important;
  }

  .v-selection-control-group {
    width: 50%;
  }

  .v-data-table-footer {
    display: none;
  }

  .domain-details {
    padding: 16px 0 16px 72px !important;
  }
}
</style>
