import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";

export interface SettingsStoreState {
  demoModeEnabled: boolean;
}

const defaultSettingsState: SettingsStoreState = {
  demoModeEnabled: false,
};

export const useSettingsStore = defineStore("settings", {
  persist: false,
  state: (): SettingsStoreState => ({ ...defaultSettingsState }),
  actions: {
    async loginToMdm(openInNewTab = true) {
      try {
        const request = {
          ...api.mdmUrl,
        };
        const { data } = await axiosInstance.request(request);
        window.open(data.url, openInNewTab ? "_blank" : "_self");
      } catch (e) {
        console.error(e);
      }
    },
    async getDemoModeState() {
      const request = {
        ...api.demoMode,
      };

      try {
        const response = await axiosInstance.request(request);
        this.demoModeEnabled = response.data.enabled;
      } catch (err) {
        console.error(err);
      }
    },

    async changeDemoModeState(enabled: boolean) {
      const request = {
        ...api.demoMode,
        method: "PUT",
        data: {
          enabled,
        },
      };

      try {
        await axiosInstance.request(request);
        this.demoModeEnabled = enabled;
      } catch (err) {
        console.error(err);
      }
    },

    async resetDemoModeData() {
      const request = {
        ...api.resetDemoModeData,
      };

      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.demoMode.dataResetCompleted")
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
});
