<template>
  <v-card class="protected-devices-widget py-6 h-100" rounded="lg" elevation="0">
    <div v-if="widgetData.loading" class="px-6">
      <v-skeleton-loader
        type="list-item-avatar-two-line"
        height="100"
        class="mb-2 bg-transparent"
      />
      <v-skeleton-loader type="list-item" class="mb-2 bg-transparent" />
      <v-skeleton-loader type="list-item" class="mb-2 bg-transparent" />
      <v-skeleton-loader type="list-item" class="bg-transparent" />
    </div>

    <div v-else-if="widgetData.data">
      <div class="protected-devices-widget-grid px-6 mb-4">
        <v-icon icon="$iconDevicesWithMobileNoHex" color="primary" size="72" class="icon" />
        <div class="d-flex flex-column total-number" data-testid="protected-devices-widget-total">
          <div class="big-number">
            {{ widgetData.data.total }}
          </div>
          <div class="subtitle2">{{ $t("mspDashboard.widgets.protectedDevicesWidget.title") }}</div>
        </div>
        <router-link
          v-if="mspDevicesAccessAllowed"
          :to="{ name: RouteName.MSP_DEVICES_PAGE }"
          class="body2 coro-link view-all text-decoration-none"
        >
          <div class="view-all-btn body2 coro-link item-clickable d-flex align-center">
            <span>{{ $t("general.viewAll") }}</span>
            <v-icon icon="$chevronRight" color="link" size="16" />
          </div>
        </router-link>
      </div>
      <v-list-item
        class="px-6 py-2"
        data-testid="protected-devices-widget-isolated"
        @click="navigateToDevicesPage(null, true)"
      >
        <div class="d-flex justify-space-between">
          <div class="body2">{{ $t("mspDashboard.widgets.protectedDevicesWidget.isolated") }}</div>
          <div class="text-red-dark font-weight-bold">{{ widgetData.data.isolatedCount }}</div>
        </div>
      </v-list-item>

      <v-divider class="border-opacity-100 mx-6" color="indigo-faint" />
      <v-list-item
        class="px-6 py-2"
        data-testid="protected-devices-widget-with-issues"
        @click="navigateToDevicesPage(true)"
      >
        <div class="d-flex justify-space-between">
          <div class="body2">
            {{ $t("mspDashboard.widgets.protectedDevicesWidget.withIssues") }}
          </div>
          <div class="text-red-dark font-weight-bold">{{ widgetData.data.withIssuesCount }}</div>
        </div>
      </v-list-item>

      <v-divider class="border-opacity-100 mx-6" color="indigo-faint" />
      <v-list-item
        class="px-6 py-2"
        data-testid="protected-devices-widget-no-issues"
        @click="navigateToDevicesPage(false)"
      >
        <div class="d-flex justify-space-between">
          <div class="body2">{{ $t("mspDashboard.widgets.protectedDevicesWidget.noIssues") }}</div>
          <div class="text-green-dark">{{ widgetData.data.noIssuesCount }}</div>
        </div>
      </v-list-item>
    </div>

    <div
      v-else
      class="h-100 d-flex align-center justify-center flex-column px-6"
      data-testid="widget-error-state"
    >
      <v-icon icon="$alertTriangle" size="80"></v-icon>
      <span class="subtitle1 mb-4">
        {{ $t("mspDashboard.widgets.protectedDevicesWidget.error") }}</span
      >
      <div class="coro-link" @click="$emit('retry-fetch-data')">
        {{ $t("general.retry") }}
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { DataValue } from "@/types.ts";
import type { DevicesStats } from "@/_store/msp/msp-dashboard.module.ts";
import { RouteName } from "@/constants/routes.ts";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { useRouter } from "vue-router";
import { defaultFiltersState, FilterContext, useFiltersStore } from "@/_store/filters.module.ts";
import { DeviceStatusGroup } from "@/constants/devices.ts";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<DataValue<DevicesStats>>,
      required: true,
    },
  },
  emits: ["retry-fetch-data", "show-restricted-access-modal"],
  setup(_, { emit }) {
    const router = useRouter();
    const filtersStore = useFiltersStore();
    const mspDevicesAccessAllowed = computed(() => {
      return !isMspAccessRestricted(RolePermissionsScope.VIEWS, "devicesView");
    });

    const navigateToDevicesPage = async (
      hasVulnerabilities: boolean | null,
      isolated: boolean = false
    ) => {
      if (!mspDevicesAccessAllowed.value) {
        emit("show-restricted-access-modal");
        return;
      }

      const filters = {
        ...defaultFiltersState[FilterContext.MSP_DEVICES],
        hasVulnerabilities,
        labels: isolated ? [{ ...DeviceStatusGroup.ISOLATED, statusLabel: true }] : [],
      };
      filtersStore.setFilters(FilterContext.MSP_DEVICES, filters);
      await router.push({ name: RouteName.MSP_DEVICES_TAB });
    };

    return {
      RouteName,
      mspDevicesAccessAllowed,
      navigateToDevicesPage,
    };
  },
});
</script>
<style lang="scss">
.protected-devices-widget-grid {
  display: grid;
  grid-template-areas:
    "icon number viewAll"
    "icon number .";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  gap: 16px;
}

.icon {
  grid-area: icon;
}

.total-number {
  grid-area: number;
}

.view-all {
  grid-area: viewAll;
  justify-self: end;
  white-space: nowrap;
}
.view-all-btn {
  text-decoration: none !important;

  .icon-chevron-right {
    text-decoration: none !important;
    &:before {
      color: rgb(var(--v-theme-anchor-base)) !important;
    }
  }

  &:hover span {
    text-decoration: underline !important; /* Underline only the text */
  }
}

.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}
</style>
