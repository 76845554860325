<template>
  <div class="account-form__wrapper ma-4">
    <div
      v-if="showValidationAlert && valid === false"
      class="error-block d-flex align-center mb-2 pl-2"
    >
      <v-icon class="mr-3" icon="$warning"></v-icon>
      <span class="body2 text-red-dark">{{ $t("validations.general") }}</span>
    </div>
    <div v-else-if="showPasswordSentAlert" class="info-block d-flex align-center mb-2 pl-2">
      <span class="body2">{{ $t("myAccount.password.passwordSentMessage") }}</span>
    </div>
    <div v-else-if="showSuccessAlert" class="success-block d-flex align-center mb-2 pl-2">
      <span class="body2">{{ $t("myAccount.password.successMessage") }}</span>
    </div>
    <div
      v-else-if="!myAccount.profileData.hasUserChangedPassword"
      class="warning-block d-flex align-center mb-2 pl-2"
    >
      <v-icon class="mr-3" icon="$announcement"></v-icon>
      <span class="body2">{{ $t("myAccount.password.warningMessage") }}</span>
    </div>
    <v-card>
      <v-form ref="form" v-model="valid" class="pa-4">
        <div class="subtitle1">{{ $t("myAccount.password.title") }}</div>
        <v-text-field
          v-if="myAccount.profileData.hasUserChangedPassword"
          v-model="localValue.oldPassword"
          class="mt-8"
          variant="outlined"
          :append-inner-icon="showOldPassword ? 'icon-eye' : 'icon-eye-off'"
          :rules="oldPasswordRules"
          data-testid="my-account-page-old-password"
          :type="showOldPassword ? 'text' : 'password'"
          :label="$t('myAccount.password.oldPassword.label')"
          @click:append-inner="showOldPassword = !showOldPassword"
          @blur="onPasswordBlur()"
        />
        <v-text-field
          v-model="localValue.newPassword"
          variant="outlined"
          :type="showNewPassword ? 'text' : 'password'"
          :append-inner-icon="showNewPassword ? 'icon-eye' : 'icon-eye-off'"
          class="mt-4"
          :rules="newPasswordRules"
          :label="$t('myAccount.password.newPassword.label')"
          data-testid="my-account-page-new-password"
          @blur="onPasswordBlur()"
          @keyup.enter="onPasswordSet()"
          @click:append-inner="showNewPassword = !showNewPassword"
        />
        <password-strength-meter class="mt-2" :password="localValue.newPassword" />
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            rounded
            class="mt-7 mb-2"
            :disabled="valid === false"
            data-testid="my-account-page-set-password-btn"
            @click="onPasswordSet()"
          >
            {{ $t("myAccount.password.setPassword") }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
    <div
      v-if="myAccount.profileData.hasUserChangedPassword"
      role="button"
      class="body2 text-indigo-medium d-flex justify-end mt-4"
      @click="onForgotPasswordClick()"
    >
      {{ $t("myAccount.password.forgotPasswordBtn") }}
    </div>
  </div>
</template>

<script lang="ts">
import Patterns from "@/constants/patterns";
import { AccountErrors } from "@/constants/account";
import { computed, defineComponent, ref } from "vue";
import PasswordStrengthMeter from "@/components/PasswordStrengthMeter.vue";
import { useI18n } from "vue-i18n";
import type { VuetifyFormRef } from "@/types";
import { storeToRefs } from "pinia";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useAccountStore } from "@/_store";
import type { AxiosError } from "axios";

export default defineComponent({
  components: { PasswordStrengthMeter },
  setup() {
    const i18n = useI18n();
    const valid = ref<boolean | null>(true);
    const form = ref<VuetifyFormRef>();
    const showValidationAlert = ref(false);
    const showNewPassword = ref(false);
    const showOldPassword = ref(false);
    const showSuccessAlert = ref(false);
    const isOldPasswordIncorrect = ref(false);
    const showPasswordSentAlert = ref(false);
    const myAccountStore = useMyAccountStore();
    const accountStore = useAccountStore();
    const { account } = storeToRefs(accountStore);
    const { myAccount } = storeToRefs(myAccountStore);

    const localValue = ref<{ newPassword: string; oldPassword: string }>({
      newPassword: "",
      oldPassword: "",
    });

    const newPasswordRules = computed(() => {
      return [
        (v: string) => {
          if (showSuccessAlert.value) return true;
          if (!v) return i18n.t("validations.required");
          if (!Patterns.PASSWORD.test(v)) return i18n.t("validations.password");
          if (v === localValue.value.oldPassword) return i18n.t("validations.matchesOldPassword");

          return true;
        },
      ];
    });

    const oldPasswordRules = computed(() => {
      return [
        (v: string) => {
          if (showSuccessAlert.value || !myAccount.value.profileData.hasUserChangedPassword)
            return true;
          if (!v) return i18n.t("validations.required");
          if (isOldPasswordIncorrect.value) return i18n.t("validations.incorrectOldPassword");

          return true;
        },
      ];
    });

    const onForgotPasswordClick = async () => {
      await accountStore.forgotPassword({ email: account.value.email });
      showPasswordSentAlert.value = true;
    };

    const onPasswordBlur = () => {
      showSuccessAlert.value = false;
      if (isOldPasswordIncorrect.value) {
        isOldPasswordIncorrect.value = !isOldPasswordIncorrect.value;
      }
    };

    const onPasswordSet = async () => {
      showSuccessAlert.value = false;
      const validationResult = await form.value?.validate();

      if (!validationResult?.valid) {
        showValidationAlert.value = true;
        return;
      }

      try {
        await myAccountStore.setPassword(localValue.value);
        showSuccessAlert.value = true;
        myAccountStore.$patch((state) => {
          state.myAccount.profileData.hasUserChangedPassword = true;
        });
        localValue.value = {
          newPassword: "",
          oldPassword: "",
        };
        valid.value = true;
      } catch (error) {
        if (
          (error as AxiosError<{ errors: string }>).response?.data.errors.includes(
            AccountErrors.INCORRECT_PASSWORD
          )
        ) {
          isOldPasswordIncorrect.value = true;
          await form.value?.validate();
        }
      }
    };

    return {
      myAccount,
      localValue,
      account,
      valid,
      form,
      showValidationAlert,
      showNewPassword,
      showOldPassword,
      showSuccessAlert,
      isOldPasswordIncorrect,
      showPasswordSentAlert,
      newPasswordRules,
      oldPasswordRules,
      onForgotPasswordClick,
      onPasswordBlur,
      onPasswordSet,
    };
  },
});
</script>
