import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labs from "vuetify/labs/components";
import { h } from "vue";
import type { IconSet, IconProps, ThemeDefinition } from "vuetify";
import { paginationOptions } from "@/constants/table";
import { i18n } from "@/plugins/i18n";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { useI18n } from "vue-i18n";

const custom: IconSet = {
  component: (props: IconProps) => h(props.tag, { class: ["icon", props.icon] }),
};

const aliases: { [key: string]: string } = {
  chevron: "icon-chevron",
  blank: "icon-blank",
  bell: "icon-bell",
  groups: "icon-groups",
  search: "icon-search",
  devices: "icon-devices",
  email: "icon-list-email",
  calendar: "icon-calendar",
  user: "icon-user",
  settings: "icon-settings",
  events: "icon-events",
  expand: "icon-expand",
  minimize: "icon-minimize",
  cloud: "icon-cloud",
  check: "icon-check",
  x: "icon-x",
  dots: "icon-dots",
  v: "icon-rules-save",
  close: "icon-close",
  chevronDown: "icon-chevron",
  checkboxMarked: "icon-checkbox-marked",
  checkboxBlankOutline: "icon-checkbox-blank-outline",
  minus: "icon-minus-box",
  radio: "icon-radiobox-blank",
  radioMarked: "icon-radiobox-marked",
  empty: "icon-empty",
  closeCircle: "icon-rules-close",
  trash: "icon-trash",
  suspend: "icon-Suspend",
  export: "icon-export",
  file: "icon-file",
  info: "icon-info",
  lock: "icon-lock",
  explanation: "icon-explanation",
  copy: "icon-copy",
  smartphone: "icon-mobile",
  desktop: "icon-desltop",
  apple: "icon-apple",
  ios: "icon-ios",
  android: "icon-android",
  windows: "icon-windows",
  users: "icon-users",
  cloudApps: "icon-cloud-apps",
  locationAccess: "icon-location-access",
  add: "icon-add",
  downloadLink: "icon-download-link",
  warning: "icon-warning",
  circleCheck: "icon-check-circle",
  announcement: "icon-announcement",
  eye: "icon-eye",
  eyeOff: "icon-eye-off",
  abnormalAdminActivity: "icon-abnormal-admin-activity",
  abnormalDataManipulation: "icon-abnormal-data-manipulation",
  accessRestrictions: "icon-access-restirctions",
  accessViolation: "icon-access-violation",
  accountSecurity: "icon-account-security",
  activityLogs: "icon-activity-logs",
  adminUsers: "icon-admin-users",
  dlpContent: "icon-dlp-content",
  dlpEmail: "icon-dlp-email",
  dlpFile: "icon-dlp-file",
  dlp: "icon-dlp",
  emailPhishing: "icon-email-phishing",
  endpointVulnerability: "icon-endpoint-vulnerability",
  identityCompromise: "icon-identity-compromise",
  listAuthentication: "icon-list-authentication",
  listMalware: "icon-list-malware",
  malwareEmail: "icon-malware-email",
  malwareEndpoint: "icon-malware-endpoint",
  malwareInCloud: "icon-malware-in-cloud",
  malware: "icon-malware",
  multipleFailedLogin: "icon-multiple-failed-login",
  phishing: "icon-phishing",
  resetPassword: "icon-reset-password",
  undone: "icon-undone",
  wifiPhishing: "icon-wifi-phishing",
  emails: "icon-emails",
  data: "icon-data-copy",
  laptop: "icon-mac-laptop",
  dataLoss: "icon-data-loss",
  noAlerts: "icon-no-alerts",
  noIssues: "icon-no-issues",
  triangle: "icon-triangle",
  graphSquared: "icon-graph-squared",
  billing: "icon-billing",
  coroFlexible: "icon-coro-flexible-copy",
  usersSaySafe: "icon-users-say-safe",
  usersSayPhishing: "icon-users-say-phishing",
  manageWorkspaces: "icon-manage-workspace",
  channelWorkspace: "icon-parent-plan",
  childWorkspace: "icon-child-plan",
  regularWorkspace: "icon-regular-plan",
  noProtection: "icon-no-protection",
  allGood: "icon-all-good",
  massDownload: "icon-mass-download",
  massDelete: "icon-mass-delete",
  vulnerability: "icon-vulnerability",
  developmentMode: "icon-development-mode",
  nonGenuineWindows: "icon-non-genuine-windows",
  deviceEncryption: "icon-device-encryption",
  windowsHexagon: "icon-windows-deployment",
  sync: "icon-sync",
  macOSHexagon: "icon-apple-deployment",
  sort: "icon-sort",
  process: "icon-process",
  backOffice: "icon-back-office",
  hardDrive: "icon-hard-drive",
  fileDevice: "icon-file-device",
  help: "icon-help",
  connectors: "icon-connectors",
  globe: "icon-globe",
  wifiNew: "icon-wifi-new",
  wifiForbidden: "icon-wifi-forbidden",
  redAlert: "icon-red-alert",
  uploadFile: "icon-upload-file",
  coroMail: "icon-coro-mail",
  firewallDisabled: "icon-firewall-disabled",
  hexagonVo: "icon-vo",
  addonNetwork: "icon-privatize",
  trashOutlined: "icon-trash-outlined",
  fileEmpty: "icon-file-empty",
  folder: "icon-folder",
  endpointDataGovernance: "icon-endpoint-data-governance",
  edrHexagon: "icon-edr-hexagon",
  registryKey: "icon-registry-key",
  scheduledTask: "icon-scheduled-task",
  accountEvent: "icon-windows-event-log",
  mdm: "icon-mdm",
  folderLocked: "icon-folder-locked",
  usersNoHexagon: "icon-users-no-hexagon",
  devicesNoHexagon: "icon-devices-no-hexagon",
  devicesXs: "icon-devices-xs",
  accountXs: "icon-account-xs",
  cloudAppsNoHexagon: "icon-cloud-apps-no-hexagon",
  windowsProcess: "icon-windows-process",
  macProcess: "icon-mac-process",
  macPc: "icon-mac-pc",
  windowsPc: "icon-windows-pc",
  linuxPc: "icon-linux-pc",
  iosDevice: "icon-ios-device",
  androidDevice: "icon-android-device",
  socSettings: "icon-soc-settings",
  comments: "icon-comments",
  socXs: "icon-soc-xs",
  dropdown: "icon-dropdown",
  dataNoHexagon: "icon-data-no-hexagon",
  success: "icon-check-circle",
  alertTriangle: "icon-alert-triangle",
  firstPage: "icon-first-page",
  lastPage: "icon-last-page",
  chevronRight: "icon-chevron-right",
  chevronLeft: "icon-chevron-left",
  sortOption: "icon-sort-option",
  sortArrow: "icon-sort-arrow",
  zipFile: "icon-zip-file",
  csvFile: "icon-csv-file",
  trailingLink: "icon-trailing-link",
  secureMessagesSmall: "icon-secure-messages-small",
  dns: "icon-dns",
  soc: "icon-soc",
  sigmaMatch: "icon-sigma-match",
  inboundGatewayLarge: "icon-inbound-gateway-l",
  ztnaLarge: "icon-ztna-l",
  wifiPhishingLarge: "icon-wifi-phishing-l",
  dnsReport: "icon-dns-report",
  recentSearch: "icon-recent-search",
  fancySearch: "icon-fancy-search",
  secureMessagesAlternative: "icon-secure-messages-2",
  infoFilled: "icon-info-filled",
  forward: "icon-forward",
  confidential: "icon-confidential",
  commentsNotification: "icon-comment-template",
  dashboard: "icon-dashboard",
  coroConsole: "icon-coro-console",
  inactiveUser: "icon-inactive-user",
  emptyCalendar: "icon-empty-calendar",
  splitTunneling: "icon-split-tunneling",
  fullTunneling: "icon-full-tunneling",
  defaultVpn: "icon-default-vpn",
  includedResources: "icon-included-resources",
  thirdPartyApp: "icon-third-party-app",
  securityTraining: "icon-security-training",
  drag: "icon-drag",
  securityAwareness: "icon-security-awareness",
  securityAwarenessSmall: "icon-security-awareness-small",
  securityAwarenessNoHex: "icon-security-awareness-no-hex",
  adaptiveTraining: "icon-adaptive-training",
  label: "icon-label",
  externalLink: "icon-external-link",
  play: "icon-play",
  emptyDevices: "icon-empty-devices",
  iconDevicesWithMobileNoHex: "icon-devices-with-mobile-no-hex",
  endpointDataGovernanceXs: "icon-endpoint-data-governance-xs",
  edrXs: "icon-edr-xs",
  userDataGovernanceXs: "icon-user-data-governance-xs",
  mailUnreadXs: "icon-mail-unread-xs",
  endpointXs: "icon-endpoint-xs",
  cloudSecurityXs: "icon-cloud-security-xs",
};

const light: ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#262260",
    error: "#c90000",
    white: "#FFFFFF",
    anchor: "#006eae",
    inactive: "#BBBBBB",
    link: "#006EAE",
    border: "#262260",
    "on-surface": "#262260",
    "on-white": "#262260",

    "green-base": "#46a762",
    "green-darkest": "#145229",
    "green-dark": "#0f7e34",
    "green-light": "#69d488",
    "green-faint": "#e6f4d8",

    "indigo-darkest": "#100e2b",
    "indigo-dark": "#1f1d4a",
    "indigo-medium": "#6a6891",
    "indigo-light": "#b1b1e1",
    "indigo-pale": "#e6e6ee",
    "indigo-faint": "#EEEEF3",
    "on-indigo-faint": "#262260",
    "indigo-instructional": "#EDEDFF",

    "surface-variant": "#B1B1E1",

    "yellow-base": "#be6600",
    "yellow-light": "#f0aa59",
    "yellow-faint": "#f5efe5",

    "orange-base": "#cf4b00",
    "orange-light": "#ec6b22",

    "gray-dark": "#212121",
    "gray-neutral": "#606060",
    "gray-medium": "#bdbdbd",
    "gray-pale": "#ededed",
    "gray-faint": "#f9f9f9",
    "gray-light": "#e0e0e0",

    "red-base": "#f74b4b",
    "red-darkest": "#5e2222",
    "red-dark": "#c92626",
    "red-light": "#ff5c5c",
    "red-faint": "#f5e5e5",

    "labels-green-full": "#7bc86c",
    "labels-green-light": "#b8ddb0",
    "labels-yellow-full": "#f5dd2a",
    "labels-yellow-light": "#f5ea92",
    "labels-orange-full": "#ffaf3f",
    "labels-orange-light": "#fbd19c",
    "labels-blue-full": "#28cce5",
    "labels-blue-light": "#8fdfeb",
    "labels-purple-light": "#dfc0eb",
    "labels-purple-full": "#cd8de5",
    "labels-red-full": "#ee7564",
    "labels-red-light": "#efb3ab",
  },
};

export default createVuetify({
  directives,
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  display: {
    mobileBreakpoint: "sm",
    thresholds: {
      xs: 0,
      sm: 970,
      md: 1200,
      lg: 1600,
      xl: 1920,
    },
  },
  components: {
    ...labs,
    ...components,
  },
  icons: {
    defaultSet: "custom",
    aliases,
    sets: {
      custom,
    },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light,
    },
  },
  defaults: {
    VAutocomplete: {
      menuIcon: "icon-triangle",
    },
    VTextField: {
      variant: "outlined",
    },
    VCheckbox: {
      trueIcon: "icon-checkbox-marked",
      falseIcon: "icon-checkbox-blank-outline",
      indeterminateIcon: "icon-minus-box",
      color: "primary",
      density: "compact",
      hideDetails: true,
      ripple: false,
    },
    VCheckboxBtn: {
      trueIcon: "icon-checkbox-marked",
      falseIcon: "icon-checkbox-blank-outline",
      indeterminateIcon: "icon-minus-box",
      color: "primary",
      ripple: false,
    },
    VRadioGroup: {
      trueIcon: "icon-radiobox-marked",
      falseIcon: "icon-radiobox-blank",
      density: "compact",
    },
    VRadio: {
      density: "compact",
    },
    VCard: {
      elevation: 0,
      rounded: "lg",
    },
    VTab: {
      height: 32,
    },
    VBtn: {
      elevation: 0,
      variant: "flat",
    },
    VSelect: {
      variant: "outlined",
      menuIcon: "icon-triangle",
    },
    VDataTable: {
      itemsPerPageOptions: paginationOptions,
    },
    VDataTableServer: {
      rounded: "circle",
      activeColor: "primary",
      color: "primary",
      prevIcon: "$chevronLeft",
      nextIcon: "$chevronRight",
      firstIcon: "$firstPage",
      lastIcon: "$lastPage",
      itemsPerPageOptions: paginationOptions,
    },
    VDataTableFooter: {
      prevIcon: "$chevronLeft",
      nextIcon: "$chevronRight",
      firstIcon: "$firstPage",
      lastIcon: "$lastPage",
    },
    VPagination: {
      rounded: "circle",
      activeColor: "primary",
      color: "primary",
      prevIcon: "$chevronLeft",
      nextIcon: "$chevronRight",
      firstIcon: "$firstPage",
      lastIcon: "$lastPage",
    },
  },
});
