import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from "vue-router";
import EndpointDataGovernancePage from "@/views/settings/endpoint-data-governance/SettingsPage.vue";
import AccessControlPage from "@/views/settings/access-control/AccessControlPage.vue";
import Placeholder from "@/views/Placeholder.vue";
import PrivacySensitiveDataTab from "@/views/settings/endpoint-data-governance/PrivacySensitiveDataTab.vue";
import SensitiveDataScansTab from "@/views/settings/endpoint-data-governance/SensitiveDataScansTab.vue";
import SocContactsPage from "@/views/settings/soc/SocContactsPage.vue";
import SocRemediationPage from "@/views/settings/soc/SocRemediationPage.vue";
import SocPage from "@/views/settings/soc/SocPage.vue";
import MyAccountPage from "@/views/my-account/MyAccountPage.vue";
import NotificationsTab from "@/views/my-account/NotificationsTab.vue";
import EndpointSecurityPage from "@/views/settings/endpoint-security/EndpointSecurityPage.vue";
import EndpointAllowBlockListTab from "@/views/settings/endpoint-security/EndpointSecurityAllowBlockListTab.vue";
import ReportsTab from "@/views/settings/reports/ReportsTab.vue";
import {
  addonNavigationGuard,
  authGuard,
  backOfficeGuard,
  deviceTablePageNavigationGuard,
  globalPermissionsPageTabGuard,
  moduleNavigationGuard,
  mspPortalGuard,
  mspAccessControlPageTabGuard,
  permissionsGuard,
  permissionsPageTabGuard,
  socGuard,
  socTicketsLogGuard,
  usersTablePageNavigationGuard,
  mspPagePermissionGuard,
} from "@/_helpers/navigation-guards";
import ConnectorsPage from "@/views/settings/connectors/ConnectorsPage.vue";
import PsaTab from "@/views/settings/connectors/PsaTab.vue";
import PsaConnectorConfigurationPage from "@/views/settings/connectors/PsaConnectorConfigurationPage.vue";
import WorkspaceMappingPage from "@/views/settings/connectors/WorkspaceMappingPage.vue";
import ServiceMappingPage from "@/views/settings/connectors/ServiceMappingPage.vue";
import EdrPage from "@/views/settings/edr/EdrPage.vue";
import AllowBlockListEdrTab from "@/views/settings/edr/AllowBlockListTab.vue";
import TelemetryTab from "@/views/settings/edr/TelemetryTab.vue";
import ProcessesTab from "@/views/settings/edr/ProcessesTab.vue";
import { PsaProviders } from "@/constants/connectors";
import AdminsTab from "@/views/settings/access-control/AdminUsersTab.vue";
import RolesTab from "@/views/settings/access-control/RolesTab.vue";
import ApiCredentialsTab from "@/views/settings/connectors/ApiCredentialsTab.vue";
import ActivateMobilePage from "@/views/ActivateMobilePage.vue";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  MspRolePermissionScopes,
  ProtectionScopeModules,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import {
  SubscriptionAddon,
  SubscriptionModule,
  WorkspaceLocale,
  WorkspaceType,
} from "@/constants/workplaces";
import AdminUserDetails from "@/views/settings/access-control/AdminUserDetails.vue";
import SiemTab from "@/views/settings/connectors/SiemTab.vue";
import ActiveSessionsTab from "@/views/settings/access-control/ActiveSessionsTab.vue";
import ActivityLogsPage from "@/views/settings/activity-logs/ActivityLogsPage.vue";
import CloudSecurityPage from "@/views/settings/cloud-security/CloudSecurityPage.vue";
import UserDataGovernancePage from "@/views/settings/user-data-governance/UserDataGovernancePage.vue";
import UserDataMonitorTab from "@/views/settings/user-data-governance/UserDataMonitorTab.vue";
import DevicesSettingsPage from "@/views/settings/devices/DevicesSettingsPage.vue";
import AgentDeploymentTab from "@/views/settings/devices/AgentDeploymentTab.vue";
import DevicesSettingsTab from "@/views/settings/devices/DevicesSettingsTab.vue";
import DevicesLabelsTab from "@/views/settings/devices/DevicesLabelsTab.vue";
import UsersPage from "@/views/UsersPage.vue";
import UserDataPermissionsTab from "@/views/settings/user-data-governance/UserDataPermissionsTab.vue";
import DevicesPage from "@/views/DevicesPage.vue";
import UsersSettingsPage from "@/views/settings/users/UsersSettingsPage.vue";
import UsersTab from "@/views/settings/users/UsersTab.vue";
import ExclusionsTab from "@/views/settings/users/ExclusionsTab.vue";
import UserGroupsTab from "@/views/settings/users/UserGroupsTab.vue";
import UserDataExclusionsTab from "@/views/settings/user-data-governance/UserDataExclusionsTab.vue";
import EmailSecurityPage from "@/views/settings/email-security/EmailSecurityPage.vue";
import EmailSettingsTab from "@/views/settings/email-security/EmailSettingsTab.vue";
import MspWorkspacesOverviewPage from "@/views/msp/MspWorkspacesOverviewPage.vue";
import EmailSecurityAllowBlockTab from "@/views/settings/email-security/EmailSecurityAllowBlockTab.vue";
import { RoleType } from "@/constants/roles";
import MspRolesTab from "@/views/msp/control-panel/access-control/MspRolesTab.vue";
import MspAdminUsersTab from "@/views/msp/control-panel/access-control/MspAdminUsersTab.vue";
import EmailSecuritySecureMessagesTab from "@/views/settings/email-security/EmailSecuritySecureMessagesTab.vue";
import EmailSecurityInboundGatewayTab from "@/views/settings/email-security/EmailSecurityInboundGatewayTab.vue";
import EmailSecurityAddIns from "@/views/settings/email-security/EmailSecurityAddIns.vue";
import EndpointSecurityAddonsTab from "@/views/settings/endpoint-security/EndpointSecurityAddonsTab.vue";
import EndpointSecurityNgavTab from "@/views/settings/endpoint-security/EndpointSecurityNgavTab.vue";
import EndpointSecurityDevicePostureTab from "@/views/settings/endpoint-security/EndpointSecurityDevicePostureTab.vue";
import AgentsGradualUpdatePage from "@/views/back-office/AgentsGradualUpdatePage.vue";
import ReportsPage from "@/views/settings/reports/ReportsPage.vue";
import ExportsTab from "@/views/settings/reports/ExportsTab.vue";
import ExecutiveSummaryReportsTab from "@/views/settings/reports/ExecutiveSummaryReportsTab.vue";
import ManagedServicesSummaryTab from "@/views/settings/reports/ManagedServicesSummaryTab.vue";
import BackOfficePage from "@/views/back-office/BackOfficePage.vue";
import BackOfficeDashboardPage from "@/views/back-office/BackOfficeDashboardPage.vue";
import BackOfficePermissionsPage from "@/views/back-office/BackOfficePermissionsPage.vue";
import GlobalRolesTab from "@/views/back-office/GlobalRolesTab.vue";
import GlobalAdminUsersTab from "@/views/back-office/GlobalAdminUsersTab.vue";
import DnsSummaryTab from "@/views/settings/reports/DnsSummaryTab.vue";
import WebhooksTab from "@/views/settings/connectors/WebhooksTab.vue";
import NetworkPage from "@/views/settings/network/NetworkPage.vue";
import VirtualOfficeTab from "@/views/settings/network/VirtualOfficeTab.vue";
import SecureWebGatewayTab from "@/views/settings/network/SecureWebGatewayTab.vue";
import SecuredMessagesSummaryTab from "@/views/settings/reports/SecuredMessagesSummaryTab.vue";
import ScheduledReportsTab from "@/views/settings/reports/ScheduledReportsTab.vue";
import { RouteName } from "@/constants/routes";
import AccessPermissionsTab from "@/views/settings/cloud-security/cloud-security-tabs/AccessPermissionsTab.vue";
import ThirdPartyAppsTab from "@/views/settings/cloud-security/cloud-security-tabs/ThirdPartyAppsTab.vue";
import CloudAppsPage from "@/views/settings/cloud-apps/CloudAppsPage.vue";
import MessagesPage from "@/views/MessagesPage.vue";
import ProfileTab from "@/views/my-account/ProfileTab.vue";
import ChangePasswordTab from "@/views/my-account/ChangePasswordTab.vue";
import MdmCallbackPage from "@/views/MdmCallbackPage.vue";
import ProxyVerificationPage from "@/views/ProxyVerificationPage.vue";
import PniVerificationPage from "@/views/PniVerificationPage.vue";
import WorkspacesPage from "@/views/WorkspacesPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import ForgotPasswordPage from "@/views/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/views/ResetPasswordPage.vue";
import { useAccountStore } from "@/_store";
import { getBrowserLocale, getWorkspaceFromRedirect, setWorkspace } from "@/_helpers/utils";
import type { Service } from "@/constants/cloud-apps";
import DownloadsPage from "@/views/DownloadsPage.vue";
import InvitedPage from "@/views/InvitedPage.vue";
import MfaPage from "@/views/MfaPage.vue";
import MfaVerificationPage from "@/views/MfaVerificationPage.vue";
import MfaTab from "@/views/my-account/MfaTab.vue";
import ControlPanelPage from "@/views/ControlPanelPage.vue";
import SsoPage from "@/views/SsoPage.vue";
import CloudSecuritySettingsTab from "@/views/settings/cloud-security/cloud-security-tabs/CloudSecuritySettingsTab.vue";
import { useRedirectsStore } from "@/_store/redirects.module";
import type { WorkspaceDetails } from "@/_store/workspaces.module";
import TicketsPage from "@/views/tickets/TicketsPage.vue";
import AbnormalDataManipulationDetailsPage from "@/views/tickets/AbnormalDataManipulationDetailsPage.vue";
import DashboardPage from "@/views/DashboardPage.vue";
import SocTicketsPage from "@/views/tickets/SocTicketsPage.vue";
import MspUsersPage from "@/views/msp/MspUsersPage.vue";
import ProcessGraph from "@/views/ProcessGraph.vue";
import MspDevicesTab from "@/views/msp/MspDevicesTab.vue";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import SiteToSiteTab from "@/views/settings/network/SiteToSiteTab.vue";
import NetworkSettingsTab from "@/views/settings/network/NetworkSettingsTab.vue";
import MspGlobalControlPanelPage from "@/views/msp/control-panel/MspGlobalControlPanelPage.vue";
import MspAccessControlPage from "@/views/msp/control-panel/access-control/MspAccessControlPage.vue";
import PhishingSettingsTab from "@/views/settings/security-awareness/PhishingSettingsTab.vue";
import SecurityAwarenessPage from "@/views/settings/security-awareness/SecurityAwarenessPage.vue";
import MspTicketsPage from "@/views/msp/MspTicketsPage.vue";
import MspDevicesPage from "@/views/msp/MspDevicesPage.vue";
import MspDrivesTab from "@/views/msp/MspDrivesTab.vue";
import SecurityTrainingSettingsTab from "@/views/settings/security-awareness/SecurityTrainingSettingsTab.vue";
import MspLabelsPage from "@/views/msp/control-panel/MspLabelsPage.vue";
import { useMspStore } from "@/_store/msp/msp.module.ts";
import SecurityAwarenessSummaryTab from "@/views/settings/reports/SecurityTrainingSummaryTab.vue";
import SignUpPage from "@/views/SignUpPage.vue";
import { useTutorialStore } from "@/_store/tutorial.module.ts";
import { useGtm } from "@gtm-support/vue-gtm";
import { i18n } from "@/plugins/i18n.ts";
import MspDashboardPage from "@/views/msp/MspDashboardPage.vue";

const browserLocale: WorkspaceLocale = (() => {
  try {
    return getBrowserLocale();
  } catch {
    return WorkspaceLocale.EN_US;
  }
})();

const routes: RouteRecordRaw[] = [
  {
    name: RouteName.PORTAL,
    path: "/portal",
    redirect: { name: RouteName.DASHBOARD },
    beforeEnter: async (_, __, next) => {
      const mspStore = useMspStore();
      const accountStore = useAccountStore();
      if (accountStore.logged && accountStore.account.workplace) {
        await mspStore.checkMspAccess();
      }
      return next();
    },
    children: [
      {
        path: "settings",
        name: RouteName.SETTINGS,
        component: ControlPanelPage,
      },
      {
        path: "settings/endpoint-security",
        name: RouteName.ENDPOINT_SECURITY_SETTINGS_PAGE,
        component: EndpointSecurityPage,
        redirect: { name: RouteName.ENDPOINT_SECURITY_DEVICE_POSTURE_TAB },
        beforeEnter: [
          moduleNavigationGuard(SubscriptionModule.ENDPOINT_SECURITY),
          permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.ENDPOINT_SECURITY),
        ],
        children: [
          {
            path: "device-posture",
            name: RouteName.ENDPOINT_SECURITY_DEVICE_POSTURE_TAB,
            component: EndpointSecurityDevicePostureTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.ENDPOINT_SECURITY),
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.ENDPOINT_SECURITY
              ),
            ],
          },
          {
            path: "ngav",
            name: RouteName.ENDPOINT_SECURITY_NGAV_TAB,
            component: EndpointSecurityNgavTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.ENDPOINT_SECURITY),
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.ENDPOINT_SECURITY
              ),
            ],
          },
          {
            path: "allow-block-list",
            name: RouteName.ENDPOINT_SECURITY_ALLOW_BLOCK_LIST_TAB,
            component: EndpointAllowBlockListTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.ENDPOINT_SECURITY),
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.ENDPOINT_SECURITY
              ),
            ],
          },
          {
            path: "add-ons",
            name: RouteName.ENDPOINT_SECURITY_DEVICE_ADDONS_TAB,
            component: EndpointSecurityAddonsTab,
            beforeEnter: [
              addonNavigationGuard(SubscriptionAddon.WIFI_PHISHING),
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.ENDPOINT_SECURITY
              ),
            ],
          },
        ],
      },
      {
        path: "settings/endpoint-data-governance-settings",
        name: RouteName.ENDPOINT_DATA_GOVERNANCE_PAGE,
        component: EndpointDataGovernancePage,
        redirect: {
          name: RouteName.ENDPOINT_DATA_GOVERNANCE_PRIVACY_SENSITIVE_DATA_TAB,
        },
        beforeEnter: [
          moduleNavigationGuard(SubscriptionModule.ENDPOINT_DATA_GOVERNANCE),
          permissionsGuard(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
          ),
        ],
        children: [
          {
            path: "privacy-sensitive-data",
            name: RouteName.ENDPOINT_DATA_GOVERNANCE_PRIVACY_SENSITIVE_DATA_TAB,
            component: PrivacySensitiveDataTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.ENDPOINT_DATA_GOVERNANCE),
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
              ),
            ],
          },
          {
            path: "sensitive-data-scans",
            name: RouteName.ENDPOINT_DATA_GOVERNANCE_SENSITIVE_DATA_SCANS_TAB,
            component: SensitiveDataScansTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.ENDPOINT_DATA_GOVERNANCE),
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
              ),
            ],
          },
        ],
      },
      {
        path: "settings/devices",
        name: RouteName.DEVICES_SETTINGS_PAGE,
        meta: {
          titleKey: "appBar.title.devices",
        },
        component: DevicesSettingsPage,
        redirect: { name: RouteName.DEVICES_SETTINGS_AGENT_DEPLOYMENT_TAB }, // default child path
        children: [
          {
            path: "agent-deployment",
            name: RouteName.DEVICES_SETTINGS_AGENT_DEPLOYMENT_TAB,
            component: AgentDeploymentTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.DEVICES
              ),
            ],
          },
          {
            path: "device-settings",
            name: RouteName.DEVICES_SETTINGS_DEVICE_SETTINGS_TAB,
            component: DevicesSettingsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.DEVICES
              ),
            ],
          },
          {
            path: "labels",
            name: RouteName.DEVICES_SETTINGS_LABELS_TAB,
            component: DevicesLabelsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.DEVICES
              ),
            ],
          },
        ],
      },
      {
        path: "settings/access-control/admin-user/:id",
        name: RouteName.ACCESS_CONTROL_ADMIN_USER_DETAILS, // Update to appropriate RouteName value if available
        component: AdminUserDetails,
        props: (route) => ({ adminUserId: route.params.id }),
        beforeEnter: permissionsGuard(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.ADMIN_USERS
        ),
      },
      {
        path: "settings/connectors",
        name: RouteName.CONNECTORS_PAGE,
        component: ConnectorsPage,
        redirect: { name: RouteName.CONNECTORS_API_KEYS_TAB },
        beforeEnter: [
          permissionsGuard(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.CONNECTORS
          ),
        ],
        children: [
          {
            path: "siem",
            name: RouteName.CONNECTORS_SIEM_TAB,
            component: SiemTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "api-keys",
            name: RouteName.CONNECTORS_API_KEYS_TAB,
            component: ApiCredentialsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "webhooks",
            name: RouteName.CONNECTORS_WEBHOOKS_TAB,
            component: WebhooksTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "psa",
            name: RouteName.CONNECTORS_PSA_TAB,
            component: PsaTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
        ],
      },
      {
        path: "settings/connectors/connectwise",
        name: RouteName.CONNECTORS_CONNECTWISE,
        component: PsaConnectorConfigurationPage,
        props: {
          provider: PsaProviders.CONNECTWISE,
        },
        redirect: {
          name: RouteName.CONNECTORS_CONNECTWISE_WORKSPACE_MAPPING,
        },
        beforeEnter: [
          permissionsGuard(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.CONNECTORS
          ),
        ],
        children: [
          {
            path: "workspace-mapping",
            name: RouteName.CONNECTORS_CONNECTWISE_WORKSPACE_MAPPING,
            component: WorkspaceMappingPage,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "service-mapping",
            name: RouteName.CONNECTORS_CONNECTWISE_SERVICE_MAPPING,
            component: ServiceMappingPage,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "ticketing",
            name: RouteName.CONNECTORS_CONNECTWISE_TICKETING,
            component: Placeholder,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
        ],
      },
      {
        path: "settings/connectors/autotask",
        name: RouteName.CONNECTORS_AUTOTASK,
        component: PsaConnectorConfigurationPage,
        props: {
          provider: PsaProviders.AUTOTASK,
        },
        redirect: { name: RouteName.CONNECTORS_AUTOTASK_WORKSPACE_MAPPING },

        beforeEnter: [
          permissionsGuard(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.CONNECTORS
          ),
        ],
        children: [
          {
            path: "workspace-mapping",
            name: RouteName.CONNECTORS_AUTOTASK_WORKSPACE_MAPPING,
            component: WorkspaceMappingPage,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "service-mapping",
            name: RouteName.CONNECTORS_AUTOTASK_SERVICE_MAPPING,
            component: ServiceMappingPage,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
          {
            path: "ticketing",
            name: RouteName.CONNECTORS_AUTOTASK_TICKETING,
            component: Placeholder,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.CONNECTORS
              ),
            ],
          },
        ],
      },
      {
        path: "settings/user-data-governance-settings",
        name: RouteName.USER_DATA_GOVERNANCE_PAGE,
        component: UserDataGovernancePage,
        redirect: { name: RouteName.USER_DATA_GOVERNANCE_MONITORING_TAB },
        children: [
          {
            path: "monitoring",
            name: RouteName.USER_DATA_GOVERNANCE_MONITORING_TAB,
            component: UserDataMonitorTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.USER_DATA_GOVERNANCE
              ),
              moduleNavigationGuard(SubscriptionModule.USER_DATA_GOVERNANCE),
            ],
          },
          {
            path: "permissions",
            name: RouteName.USER_DATA_GOVERNANCE_PERMISSIONS_TAB,
            component: UserDataPermissionsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.USER_DATA_GOVERNANCE
              ),
              moduleNavigationGuard(SubscriptionModule.USER_DATA_GOVERNANCE),
            ],
          },
          {
            path: "exclusions",
            name: RouteName.USER_DATA_GOVERNANCE_EXCLUSIONS_TAB,
            component: UserDataExclusionsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.USER_DATA_GOVERNANCE
              ),
              moduleNavigationGuard(SubscriptionModule.USER_DATA_GOVERNANCE),
            ],
          },
        ],
      },
      {
        path: "settings/email",
        name: RouteName.EMAIL_SECURITY_PAGE,
        component: EmailSecurityPage,
        redirect: { name: RouteName.EMAIL_SECURITY_SETTINGS_TAB },
        children: [
          {
            path: "email-settings",
            name: RouteName.EMAIL_SECURITY_SETTINGS_TAB,
            component: EmailSettingsTab,
            beforeEnter: [moduleNavigationGuard(SubscriptionModule.EMAIL_SECURITY)],
          },
          {
            path: "allow-block-list",
            name: RouteName.EMAIL_SECURITY_ALLOW_BLOCK_LIST_TAB,
            component: EmailSecurityAllowBlockTab,
            beforeEnter: [moduleNavigationGuard(SubscriptionModule.EMAIL_SECURITY)],
          },
          {
            path: "add-ins",
            name: RouteName.EMAIL_SECURITY_ADD_INS_TAB,
            component: EmailSecurityAddIns,
            beforeEnter: [moduleNavigationGuard(SubscriptionModule.EMAIL_SECURITY)],
          },
          {
            path: "inbound-gateway",
            name: RouteName.EMAIL_SECURITY_INBOUND_GATEWAY_TAB,
            component: EmailSecurityInboundGatewayTab,
            beforeEnter: [addonNavigationGuard(SubscriptionAddon.INBOUND_GATEWAY)],
          },
          {
            path: "coro-mail",
            name: RouteName.EMAIL_SECURITY_CORO_MAIL_TAB,
            component: EmailSecuritySecureMessagesTab,
            beforeEnter: [addonNavigationGuard(SubscriptionAddon.SECURED_MESSAGES)],
          },
        ],
      },
      {
        path: "settings/cloud-security",
        name: RouteName.CLOUD_SECURITY_PAGE,
        component: CloudSecurityPage,
        redirect: { name: RouteName.CLOUD_SECURITY_PERMISSIONS_LIST_TAB },
        beforeEnter: [
          moduleNavigationGuard(SubscriptionModule.CLOUD_SECURITY),
          permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.CLOUD_SECURITY),
        ],
        children: [
          {
            path: "permissions-list",
            name: RouteName.CLOUD_SECURITY_PERMISSIONS_LIST_TAB,
            component: AccessPermissionsTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.CLOUD_SECURITY),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.CLOUD_SECURITY),
            ],
          },
          {
            path: "third-party-apps",
            name: RouteName.CLOUD_SECURITY_THIRD_PARTY_APPS_TAB,
            component: ThirdPartyAppsTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.CLOUD_SECURITY),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.CLOUD_SECURITY),
            ],
          },
          {
            path: "settings",
            name: RouteName.CLOUD_SECURITY_SETTINGS,
            component: CloudSecuritySettingsTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.CLOUD_SECURITY),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.CLOUD_SECURITY),
            ],
          },
        ],
      },
      {
        path: "settings/activity-logs",
        name: RouteName.ACTIVITY_LOGS,
        component: ActivityLogsPage,
        beforeEnter: [
          permissionsGuard(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ACTIVITY_LOGS
          ),
        ],
      },

      {
        path: "settings/soc",
        name: RouteName.SOC_PAGE,
        component: SocPage,
        redirect: { name: RouteName.SOC_CONTACTS_TAB },
        beforeEnter: [
          socGuard,
          permissionsGuard(RolePermissionsScope.PROTECTION, ProtectionScopeModules.MANAGED_SOC),
        ],
        children: [
          {
            path: "soc-contacts",
            name: RouteName.SOC_CONTACTS_TAB,
            component: SocContactsPage,
            beforeEnter: [
              socGuard,
              permissionsGuard(RolePermissionsScope.PROTECTION, ProtectionScopeModules.MANAGED_SOC),
            ],
          },
          {
            path: "soc-remediation",
            name: RouteName.SOC_REMEDIATION_TAB,
            component: SocRemediationPage,
            beforeEnter: [
              socGuard,
              permissionsGuard(RolePermissionsScope.PROTECTION, ProtectionScopeModules.MANAGED_SOC),
            ],
          },
        ],
      },
      {
        path: "settings/security-awareness",
        name: RouteName.SECURITY_AWARENESS,
        component: SecurityAwarenessPage,
        redirect: { name: RouteName.SECURITY_TRAINING },
        beforeEnter: [
          permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.SECURITY_AWARENESS),
        ],
        children: [
          {
            path: "phishing-simulation",
            name: RouteName.PHISHING_SIMULATION,
            component: PhishingSettingsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.SECURITY_AWARENESS
              ),
            ],
          },
          {
            path: "security-training",
            name: RouteName.SECURITY_TRAINING,
            component: SecurityTrainingSettingsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.SECURITY_AWARENESS
              ),
            ],
          },
        ],
      },
      {
        path: "settings/users",
        name: RouteName.USERS_SETTINGS_PAGE,
        component: UsersSettingsPage,
        redirect: { name: RouteName.USERS_SETTINGS_USERS_TAB },
        beforeEnter: [
          permissionsGuard(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.USERS
          ),
        ],
        children: [
          {
            path: "protected-users",
            name: RouteName.USERS_SETTINGS_USERS_TAB,
            component: UsersTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.USERS
              ),
            ],
          },
          {
            path: "protected-groups",
            name: RouteName.USERS_SETTINGS_GROUPS_TAB,
            component: UserGroupsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.USERS
              ),
            ],
          },
          {
            path: "exceptions",
            name: RouteName.USERS_SETTINGS_EXCEPTIONS_TAB,
            component: ExclusionsTab,
            beforeEnter: [
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.USERS
              ),
            ],
          },
        ],
      },
      {
        path: "settings/addons/network",
        name: RouteName.NETWORK_PAGE,
        component: NetworkPage,
        redirect: { name: RouteName.NETWORK_VIRTUAL_OFFICE_TAB },
        children: [
          {
            path: "virtual-office",
            name: RouteName.NETWORK_VIRTUAL_OFFICE_TAB,
            component: VirtualOfficeTab,
          },
          {
            path: "secure-web-gateway",
            name: RouteName.NETWORK_SECURE_WEB_GATEWAY_TAB,
            component: SecureWebGatewayTab,
          },
          {
            path: "settings",
            name: RouteName.NETWORK_SETTINGS_TAB,
            component: NetworkSettingsTab,
          },
          {
            path: "site-to-site",
            name: RouteName.NETWORK_SITE_TO_SITE_TAB,
            component: SiteToSiteTab,
          },
        ],
      },
      {
        path: "settings/reports",
        name: RouteName.REPORTS_PAGE,
        component: ReportsPage,
        redirect: { name: RouteName.REPORTS_SELECT_REPORTS },
        children: [
          {
            path: "select",
            name: RouteName.REPORTS_SELECT_REPORTS,
            component: ReportsTab,
            beforeEnter: permissionsGuard(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.REPORTS
            ),
          },
          {
            path: "exports-tab",
            name: RouteName.REPORTS_EXPORTS_TAB,
            component: ExportsTab,
            beforeEnter: permissionsGuard(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.REPORTS
            ),
          },
          {
            path: "scheduled-tab",
            name: RouteName.REPORTS_SCHEDULED_TAB,
            component: ScheduledReportsTab,
            beforeEnter: permissionsGuard(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.REPORTS
            ),
          },
          {
            path: "executive-summary",
            name: RouteName.REPORTS_EXECUTIVE_SUMMARY_TAB,
            component: ExecutiveSummaryReportsTab,
            beforeEnter: permissionsGuard(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.REPORTS
            ),
          },
          {
            path: "security-awareness-training",
            name: RouteName.REPORTS_SECURITY_AWARENESS_TAB,
            component: SecurityAwarenessSummaryTab,
            beforeEnter: permissionsGuard(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.SECURITY_AWARENESS
            ),
          },
          {
            path: "dns-summary",
            name: RouteName.REPORTS_DNS_SUMMARY_TAB,
            component: DnsSummaryTab,
            beforeEnter: [
              addonNavigationGuard(SubscriptionAddon.SWG),
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.REPORTS
              ),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.NETWORK),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG),
            ],
          },
          {
            path: "managed-services-summary",
            name: RouteName.REPORTS_MANAGED_SERVICES_SUMMARY_TAB,
            component: ManagedServicesSummaryTab,
            beforeEnter: socGuard,
          },
          {
            path: "secured-messages-summary",
            name: RouteName.REPORTS_SECURED_MESSAGES_SUMMARY_TAB,
            component: SecuredMessagesSummaryTab,
            beforeEnter: [
              addonNavigationGuard(SubscriptionAddon.SECURED_MESSAGES),
              permissionsGuard(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.REPORTS
              ),
            ],
          },
        ],
        beforeEnter: permissionsGuard(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.REPORTS
        ),
      },
      {
        path: "settings/cloud-apps",
        name: RouteName.CLOUD_APPS_PAGE,
        component: CloudAppsPage,
      },
      {
        path: "settings/access-control",
        name: RouteName.ACCESS_CONTROL_PAGE,
        component: AccessControlPage,
        redirect: { name: RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB },
        children: [
          {
            path: "admin-users",
            name: RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB,
            component: AdminsTab,
            beforeEnter: [permissionsPageTabGuard(WorkspaceManagementScopeSections.ADMIN_USERS)],
          },
          {
            path: "roles",
            name: RouteName.ACCESS_CONTROL_ROLES_TAB,
            component: RolesTab,
            props: {
              roleType: RoleType.WORKSPACE,
            },
            beforeEnter: [permissionsPageTabGuard(WorkspaceManagementScopeSections.ROLES)],
          },
          {
            path: "active-sessions",
            name: RouteName.ACCESS_CONTROL_ACTIVE_SESSIONS_TAB,
            component: ActiveSessionsTab,
            beforeEnter: [
              permissionsPageTabGuard(WorkspaceManagementScopeSections.ACTIVE_SESSIONS),
            ],
          },
        ],
      },
      {
        path: "edr",
        name: RouteName.EDR_PAGE,
        component: EdrPage,
        redirect: { name: RouteName.EDR_PROCESSES_TAB },
        beforeEnter: [
          moduleNavigationGuard(SubscriptionModule.EDR),
          permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
        ],
        children: [
          {
            path: "processes",
            name: RouteName.EDR_PROCESSES_TAB,
            component: ProcessesTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.EDR),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
            ],
          },
          {
            path: "telemetry",
            name: RouteName.EDR_TELEMETRY_TAB,
            component: TelemetryTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.EDR),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
            ],
          },
          {
            path: "allow-block-list",
            name: RouteName.EDR_ALLOW_BLOCK_LIST_TAB,
            component: AllowBlockListEdrTab,
            beforeEnter: [
              moduleNavigationGuard(SubscriptionModule.EDR),
              permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
            ],
          },
        ],
      },
      {
        path: "dashboard",
        name: RouteName.DASHBOARD,
        component: DashboardPage,
      },
      {
        path: "devices",
        name: RouteName.DEVICES,
        component: DevicesPage,
        beforeEnter: [
          deviceTablePageNavigationGuard,
          permissionsGuard(RolePermissionsScope.VIEWS, "devicesView"),
        ],
        props: (route) => ({
          hasVulnerabilities: route.query.hasVulnerabilities,
          search: route.query.search,
          widget: route.query.widget,
        }),
      },
      {
        path: "protected-users",
        name: RouteName.PROTECTED_USERS,
        beforeEnter: [
          usersTablePageNavigationGuard,
          permissionsGuard(RolePermissionsScope.VIEWS, "usersView"),
        ],
        component: UsersPage,
        props: (route) => ({
          hasVulnerabilities: route.query.hasVulnerabilities,
        }),
      },
      {
        path: "alerts",
        name: RouteName.ALERTS,
        component: MessagesPage,
      },
      {
        path: "process-graph",
        name: RouteName.PROCESS_GRAPH,
        component: ProcessGraph,
        props: (route) => ({
          enrollmentCode: route.query.enrollmentCode,
          processHash: route.query.processHash,
          processCreationTime: route.query.processCreationTime,
          pid: route.query.pid,
          eventId: route.query.eventId,
        }),
        beforeEnter: [
          moduleNavigationGuard(SubscriptionModule.EDR),
          permissionsGuard(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
        ],
      },
      {
        path: "tickets",
        name: RouteName.TICKETS,
        component: TicketsPage,
      },
      {
        // when /portal/event/id is matched, redirect to tickets with search by id
        path: "event/:id",
        redirect: (to) => ({
          name: RouteName.TICKETS,
          query: { search: to.params.id },
        }),
      },
      {
        path: "tickets/details/abnormal-data-manipulation",
        name: RouteName.ABNORMAL_DATA_MANIPULATION_DETAILS_PAGE,
        component: AbnormalDataManipulationDetailsPage,
        props: (route) => ({
          ticketId: route.query.ticketId,
          path: route.query.path,
          pathId: route.query.pathId,
          eventTrigger: route.query.eventTrigger,
        }),
      },
      {
        path: "my-account",
        name: RouteName.MY_ACCOUNT_PAGE,
        component: MyAccountPage,
        redirect: { name: RouteName.MY_ACCOUNT_PROFILE_TAB },
        children: [
          {
            path: "profile",
            name: RouteName.MY_ACCOUNT_PROFILE_TAB,
            component: ProfileTab,
          },
          {
            path: "change-password",
            name: RouteName.MY_ACCOUNT_CHANGE_PASSWORD_TAB,
            component: ChangePasswordTab,
          },
          {
            path: "mfa",
            name: RouteName.MY_ACCOUNT_MFA_TAB,
            component: MfaTab,
          },
          {
            path: "notifications",
            name: RouteName.MY_ACCOUNT_NOTIFICATIONS_TAB,
            component: NotificationsTab,
          },
        ],
      },
      {
        path: "workplaces",
        redirect: { name: RouteName.WORKSPACES },
      },
      {
        path: "workspaces",
        name: RouteName.WORKSPACES,
        component: WorkspacesPage,
      },
    ],
  },
  {
    name: RouteName.MSP,
    path: "/msp",
    redirect: { name: RouteName.MSP_DASHBOARD },
    beforeEnter: [
      mspPortalGuard,
      async (_, __, next) => {
        const mspStore = useMspStore();
        if (!mspStore.selectedHierarchy) {
          await mspStore.getRelevantHierarchy();
        }
        return next();
      },
    ],
    children: [
      {
        path: "dashboard",
        name: RouteName.MSP_DASHBOARD,
        component: MspDashboardPage,
      },
      {
        path: "soc-tickets",
        name: RouteName.SOC_TICKETS,
        component: SocTicketsPage,
        beforeEnter: [socTicketsLogGuard],
      },
      {
        path: "back-office",
        name: RouteName.BACK_OFFICE_PAGE,
        component: BackOfficePage,
        beforeEnter: [backOfficeGuard()],
        redirect: { name: RouteName.BACKOFFICE_DASHBOARD },
        children: [
          {
            path: "dashboard",
            name: RouteName.BACKOFFICE_DASHBOARD,
            component: BackOfficeDashboardPage,
            beforeEnter: [backOfficeGuard()],
          },
          {
            path: "permissions",
            name: RouteName.BACKOFFICE_PERMISSIONS_PAGE,
            component: BackOfficePermissionsPage,
            redirect: { name: RouteName.GLOBAL_ADMIN_USERS },
            beforeEnter: [backOfficeGuard()],
            children: [
              {
                path: "admin-users",
                name: RouteName.GLOBAL_ADMIN_USERS,
                component: GlobalAdminUsersTab,
                beforeEnter: [
                  backOfficeGuard(
                    GlobalRoleScopes.GLOBAL_SCOPE,
                    GlobalRoleScopeSection.GLOBAL_ADMIN_USERS
                  ),
                  globalPermissionsPageTabGuard(GlobalRoleScopeSection.GLOBAL_ADMIN_USERS),
                ],
              },
              {
                path: "roles",
                name: RouteName.GLOBAL_ROLES,
                component: GlobalRolesTab,
                beforeEnter: [
                  backOfficeGuard(
                    GlobalRoleScopes.GLOBAL_SCOPE,
                    GlobalRoleScopeSection.GLOBAL_ROLES
                  ),
                  globalPermissionsPageTabGuard(GlobalRoleScopeSection.GLOBAL_ROLES),
                ],
                props: {
                  roleType: RoleType.GLOBAL,
                },
              },
            ],
          },
          {
            path: "agent-gradual-update",
            name: RouteName.BACK_OFFICE_AGENT_GRADUAL_UPDATE,
            component: AgentsGradualUpdatePage,
            beforeEnter: [
              backOfficeGuard(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
                "manageEndpointAutoUpdateGroups"
              ),
            ],
          },
          // TODO: uncomment in future when needed again
          // {
          //   path: "workspace-codes",
          //   name: RouteName.BACK_OFFICE_WORKSPACE_CODES,
          //   component: WorkspaceCodesPage,
          //   beforeEnter: [
          //
          //     backOfficeGuard(
          //       GlobalRoleScopes.GLOBAL_SCOPE,
          //       GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
          //       "manageWorkspaceCodes"
          //     ),
          //   ],
          // },
        ],
      },
      {
        path: "overview",
        name: RouteName.MSP_OVERVIEW_PAGE,
        component: MspWorkspacesOverviewPage,
        props: (route) => ({
          search: route.query.search,
        }),
        beforeEnter: [
          mspPagePermissionGuard(
            MspRolePermissionScopes.MSP_PORTAL_SCOPE,
            MspPortalScopeSection.MSP_WORKSPACES
          ),
        ],
      },
      {
        name: RouteName.MSP_TICKETS_PAGE,
        path: "tickets",
        component: MspTicketsPage,
      },
      {
        name: RouteName.MSP_PROTECTED_USERS_PAGE,
        path: "protected-users",
        component: MspUsersPage,
        beforeEnter: [mspPagePermissionGuard(RolePermissionsScope.VIEWS, "usersView")],
      },
      {
        path: "global-devices",
        name: RouteName.MSP_DEVICES_PAGE,
        component: MspDevicesPage,
        redirect: { name: RouteName.MSP_DEVICES_TAB },
        beforeEnter: [mspPagePermissionGuard(RolePermissionsScope.VIEWS, "devicesView")],
        children: [
          {
            name: RouteName.MSP_DEVICES_TAB,
            path: "devices",
            component: MspDevicesTab,
            props: (route) => ({
              search: route.query.search,
            }),
          },
          {
            name: RouteName.MSP_DRIVES_TAB,
            path: "drives",
            component: MspDrivesTab,
          },
        ],
      },
      {
        name: RouteName.MSP_CONTROL_PANEL_PAGE,
        path: "settings",
        component: MspGlobalControlPanelPage,
      },
      {
        name: RouteName.MSP_LABELS,
        path: "settings/labels",
        component: MspLabelsPage,
        beforeEnter: [
          mspPagePermissionGuard(
            MspRolePermissionScopes.MSP_PORTAL_SCOPE,
            MspPortalScopeSection.MSP_WORKSPACE_LABELS
          ),
        ],
        props: (route) => ({
          search: route.query.search,
        }),
      },
      {
        path: "settings/access-control",
        name: RouteName.MSP_ACCESS_CONTROL_PAGE,
        component: MspAccessControlPage,
        redirect: { name: RouteName.MSP_ADMIN_USERS_TAB },
        children: [
          {
            path: "admin-users",
            name: RouteName.MSP_ADMIN_USERS_TAB,
            component: MspAdminUsersTab,
            beforeEnter: [mspAccessControlPageTabGuard(MspPortalScopeSection.MSP_ADMIN_USERS)],
          },
          {
            path: "roles",
            name: RouteName.MSP_ROLES_TAB,
            component: MspRolesTab,
            beforeEnter: [mspAccessControlPageTabGuard(MspPortalScopeSection.MSP_ROLES)],
            props: {
              roleType: RoleType.MSP,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: RouteName.LOGIN,
    component: LoginPage,
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/invited",
    name: RouteName.INVITED_PAGE,
    component: InvitedPage,
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/sign-up",
    name: RouteName.SIGN_UP,
    component: SignUpPage,
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/forgot-password",
    name: RouteName.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/reset-password/:id",
    name: RouteName.RESET_PASSWORD,
    props: (route) => ({ token: window.atob(route.params.id as string) }),
    component: ResetPasswordPage,
    meta: { publicPage: true, alwaysPublicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/mfa",
    name: RouteName.MFA_PAGE,
    component: MfaPage,
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/verification",
    name: RouteName.MFA_VERIFICATION,
    component: MfaVerificationPage,
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/activate-mobile",
    name: RouteName.ACTIVATE_MOBILE_PAGE,
    component: ActivateMobilePage,
    props: (route) => ({
      mobileToken: route.query.mobileToken,
    }),
    meta: { publicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/mdm/callback",
    name: RouteName.MDM_CALLBACK_PAGE,
    component: MdmCallbackPage,
    beforeEnter: moduleNavigationGuard(SubscriptionModule.MDM),
    props: (route) => ({
      error: route.query.error,
    }),
    meta: { publicPage: true },
  },
  {
    path: "/proxy-verification",
    name: RouteName.PROXY_VERIFICATION_PAGE,
    component: ProxyVerificationPage,
    meta: { publicPage: true, alwaysPublicPage: true, preferredLocale: browserLocale },
  },
  {
    path: "/pni-verification",
    name: RouteName.PNI_VERIFICATION_PAGE,
    component: PniVerificationPage,
    meta: { publicPage: true, alwaysPublicPage: true, preferredLocale: browserLocale },
    props: (route) => {
      const { allowList, token, syncToken, emailAddress } = route.query;
      return { allowListString: allowList, token, syncToken, emailAddress };
    },
  },
  {
    path: "/downloads/:id",
    name: RouteName.DOWNLOADS_PAGE,
    component: DownloadsPage,
    meta: { publicPage: true, alwaysPublicPage: true },
    props: (route) => ({ code: route.params.id }),
  },
  {
    path: "/auth/transfer",
    name: RouteName.SSO_PAGE,
    meta: { publicPage: true },
    component: SsoPage,
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: RouteName.DASHBOARD },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query.workspaceId) {
    await handleWorkspaceChangeViaQuery(to, from, next);
  }

  await saveDataFromReport(to);
  await saveDataFromSocAutomation(to);
  await saveDataToDashboard(to);
  await saveDataToControlPanel(to);

  loginWithUrlParams(to, next);
  authGuard(to, from, next);
  next();
});

// @ts-ignore
export default router;

const loginWithUrlParams = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const accountStore = useAccountStore();
  const workspacesStore = useWorkspacesStore();
  const tutorialStore = useTutorialStore();
  const { account } = accountStore;
  const storeToken = account.token;
  const {
    accessToken,
    refreshToken,
    email,
    customerId,
    customerName,
    isNextStep,
    step,
    service,
    languageCode,
    isNewUser,
  } = to.query as Record<string, string>;
  const workplace = to.path === "/portal/workplaces" ? "" : customerId;

  if (accessToken && storeToken !== accessToken) {
    accountStore.$patch({
      account: {
        socialLogin: true,
        token: accessToken,
        refreshToken,
        email,
        workplace,
        customerName,
        service: service as Service,
        hasMultipleWorkspaces: !workplace,
      },
    });

    tutorialStore.$patch({
      isNewUser: isNewUser === "true",
    });

    // sending gtm event on sign up complete from cloud service
    if (isNewUser === "true") {
      const gtm = useGtm();
      if (gtm?.enabled()) {
        window.dataLayer?.push({ event: `Signup clicked - ${i18n.global.t(service)}` });
      }
    }

    if (workplace) {
      await workspacesStore.updateWorkspaceAndPermissions(workplace);
    }

    if (isNextStep) {
      return next({
        ...to,
        query: { isNextStep, service, step, languageCode },
        replace: true,
      });
    }

    return next({
      path: to.path,
    });
  }
};

const handleWorkspaceChangeViaQuery = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  const accountStore = useAccountStore();
  const currentWorkspaceId = accountStore.account.workplace;
  const { workspaceId } = to.query;
  // If workspaceId is present and different from the current workspace
  if (workspaceId && currentWorkspaceId !== workspaceId) {
    const workspace = await getWorkspaceFromRedirect(workspaceId as string);

    // If workspace is found, set it and call next
    if (workspace) {
      await setWorkspace(workspace);
      const { workspaceId, ...restQuery } = to.query; // Destructure to remove `workspaceId`
      return next({
        ...to,
        query: restQuery, // Use the updated query without `workspaceId`
        replace: true, // Replace the current history entry to avoid duplication in the history
      });
    }
  }

  // Call next() only once after all checks
  return next();
};

async function saveDataFromReport(to: RouteLocationNormalized) {
  const {
    navigationFromReport: isNavigatedFromReport,
    workspace,
    workspaceName,
    workspaceDomain,
    headerIconUrl,
    workspaceType,
  } = to.query as Record<string, string>;
  const navigationFromReport = isNavigatedFromReport === "true";
  if (navigationFromReport) {
    const redirectsStore = useRedirectsStore();
    const newWorkspace: Partial<WorkspaceDetails> = {
      workspaceId: workspace,
      name: workspaceName,
      type: workspaceType as WorkspaceType,
      domain: workspaceDomain,
      headerIconUrl: headerIconUrl,
    };
    redirectsStore.$patch({
      redirectUrl: to.fullPath,
      redirectPath: to.path,
      workspace: newWorkspace,
      navigationFromReport,
      navigationFromSocAutomation: false,
    });
    await redirectsStore.handleRedirectFromReport();
  }
}

async function saveDataFromSocAutomation(to: RouteLocationNormalized) {
  const {
    navigationFromSocAutomation: isNavigatedFromSocAutomation,
    workspaceId,
    action,
    policyType,
    enrollmentCode,
    osType,
  } = to.query as Record<string, string>;

  const navigationFromSocAutomation = isNavigatedFromSocAutomation === "true";
  if (navigationFromSocAutomation) {
    const redirectsStore = useRedirectsStore();
    redirectsStore.$patch({
      redirectUrl: to.fullPath,
      workspaceId: workspaceId,
      navigationFromReport: false,
      navigationFromSocAutomation,
    });

    await redirectsStore.handleRedirectFromSocAutomation({
      workspaceId,
      action,
      policyType,
      enrollmentCode,
      osType,
    });
  }
}

async function saveDataToDashboard(to: RouteLocationNormalized) {
  const { forceReload, navigationFromMdm, workspaceId } = to.query as Record<string, string>;
  const isNavigationToDashboard = forceReload === "true" || navigationFromMdm === "true";

  if (isNavigationToDashboard) {
    const redirectsStore = useRedirectsStore();
    await redirectsStore.handleRedirectToDashboard(workspaceId);
  }
}

async function saveDataToControlPanel(to: RouteLocationNormalized) {
  if (to.path === "/portal/settings" && to.query["workspaceId"]) {
    const redirectsStore = useRedirectsStore();
    await redirectsStore.handleRedirectToDashboard(to.query["workspaceId"] as string);
  }
}
