import {
  defaultSubscriptionModulesConfig,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionType,
} from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";
import { defineStore } from "pinia";
import api from "@/_helpers/api.ts";
import { axiosInstance } from "@/plugins/https.ts";

interface SubscriptionModuleState {
  subscription: {
    currentPlan: CurrentPlan;
    modules: ModulesSettings;
    bundles: Array<SubscriptionBundle>;
  };
  demoMode: {
    enabled: boolean;
    endDate: number | null;
  };
}

export interface CurrentPlan {
  subscriptionType: string;
  planEndDate: number | null;
}

export type ModulesSettings = {
  [key in SubscriptionModule]: SubscriptionModuleInfo;
};

export interface SubscriptionModuleInfo {
  enabled: boolean;
  addons: SubscriptionAddonInfo[];
}

export interface SubscriptionAddonInfo {
  enabled: boolean;
  name: SubscriptionAddon;
}

const defaultSubscriptionState: SubscriptionModuleState = {
  subscription: {
    currentPlan: {
      subscriptionType: SubscriptionType.FREEZE,
      planEndDate: null,
    },
    modules: {
      ...cloneDeep(defaultSubscriptionModulesConfig),
    },
    bundles: [],
  },
  demoMode: {
    enabled: false,
    endDate: null,
  },
};

export const useSubscriptionStore = defineStore("subscription", {
  state: (): SubscriptionModuleState => cloneDeep(defaultSubscriptionState),
  persist: true,
  actions: {
    async startTrialForCurrentWorkspace() {
      const request = {
        ...api.startTrialForCurrentWorkspace(),
      };
      try {
        await axiosInstance.request(request);
      } catch (e) {
        console.error(e);
      }
    },
  },
});
