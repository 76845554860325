<template>
  <div class="d-flex align-center justify-space-between pb-4 pt-4 details-preview-header bg-white">
    <div class="d-flex flex-column">
      <span class="body2 text-indigo-medium" data-testid="ticket-preview-id">
        {{ ticketDetails.eventId }}
      </span>
      <span v-if="ticketDetails.processed" class="body2 text-green-dark">
        <v-icon icon="$circleCheck"></v-icon>
        {{ $t("general.processedOn") }} {{ processedTime }}
      </span>
      <span
        v-if="showNotProtectedUsersNotice"
        class="body2 text-red-dark"
        data-testid="ticket-preview-unprotected-user-msg"
      >
        <v-icon icon="$noProtection"></v-icon>
        {{
          $t(
            "ticketDetails.notProtectedUsersNotice",
            { n: ticketDetails.potentiallyProtectableUsers.length },
            ticketDetails.potentiallyProtectableUsers.length
          )
        }}
      </span>
    </div>
    <div class="d-flex align-baseline">
      <users-say
        class="mr-4 mb-4"
        :suspicious-vote-count="suspiciousVoteCount"
        :safe-vote-count="safeVoteCount"
      ></users-say>
      <v-btn
        v-if="buttonType === buttonTypes.ADD_TO_PROTECTION"
        :disabled="disableAddToProtection"
        rounded
        variant="outlined"
        class="ml-2"
        data-testid="tickets-page-header-add-users-btn"
        @click.stop="addUsers(getPotentiallyProtectableUsers(ticketDetails))"
      >
        {{ $t("ticketDetails.addProtectionBtn") }}
      </v-btn>
      <v-menu v-if="buttonType === buttonTypes.ACTIONS" location="bottom right">
        <template #activator="{ props }">
          <v-btn rounded color="primary" data-testid="tickets-page-actions-btn" v-bind="props">
            {{ $t("general.actions") }}
            <v-icon class="ml-1 mt-1" size="10" icon="$triangle"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="action in actionsList"
            :key="action.name"
            :data-testid="`tickets-page-action-${action.name}`"
            @click="onActionClick(action, ticketDetails)"
          >
            <v-list-item-title>
              <span
                >{{
                  $t(`ticketDetails.eventActions.${action.name}`, {
                    service: ticketDetails.sections.serviceName
                      ? $t(`services.${ticketDetails.sections.serviceName}`)
                      : "",
                  })
                }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import UsersSay from "@/components/tickets/UsersSay.vue";
import { type TicketActionWithSeverity } from "@/_store/tickets/tickets.module";
import {
  TicketAction,
  TicketActionSeverity,
  TicketTrigger,
  ViolationDirection,
} from "@/constants/tickets";
import moment from "moment/moment";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import pullAllWith from "lodash/pullAllWith";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";

import {
  RolePermissionsScope,
  type TicketsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
} from "@/_helpers/utils";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";
import { getUsersToAddFromPotentiallyProtectable } from "@/_store/tickets/adapters";
import { Service } from "@/constants/cloud-apps";
import { useI18n } from "vue-i18n";
import type { GenericCallback } from "@/types";
import TicketConfirmationModal from "@/components/modals/TicketConfirmationModal.vue";
import { ModalWidth } from "@/constants/modals";
import intersection from "lodash/intersection";
import AllowBlockEmailTicketModal from "@/components/modals/AllowBlockEmailTicketModal.vue";
import ContactUserModal from "@/components/modals/ContactUserModal.vue";
import { useTicketStore } from "@/_store/tickets/ticket.module";
import {
  type MspTicketDetails,
  useMspTicketsStore,
} from "@/_store/msp/tickets/msp-tickets.module.ts";
import TestEmailProxyModal from "@/components/modals/email-settings/TestEmailProxyModal.vue";
import { SubscriptionAddon } from "@/constants/workplaces.ts";
import { isMspActionRestricted } from "@/_helpers/msp-permissions.ts";

const buttonTypes = {
  ADD_TO_PROTECTION: "addToProtectionBtn",
  ACTIONS: "actionsBtn",
  CLOSE: "closeBtn",
  REOPEN: "reopenBtn",
} as const;

const approveEmailActions = [
  TicketAction.APPROVE_EMAILS_FROM_DOMAIN,
  TicketAction.APPROVE_EMAILS_FROM_SENDER,
  TicketAction.APPROVE_IP,
  TicketAction.APPROVE_EMAIL,
];
const discardEmailActions = [
  TicketAction.DISCARD_EMAILS_FROM_DOMAIN,
  TicketAction.DISCARD_EMAILS_FROM_SENDER,
  TicketAction.DISCARD_IP,
  TicketAction.DISCARD_EMAIL,
];

export default defineComponent({
  components: { UsersSay },
  props: {
    suspiciousVoteCount: {
      type: Number,
      default: 0,
    },
    safeVoteCount: {
      type: Number,
      default: 0,
    },
    ticketDetails: {
      type: Object as PropType<MspTicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const dialogsStore = useDialogsStore();
    const ticketsStore = useMspTicketsStore();
    const usersSettingsTabStore = useUsersSettingsTabStore();

    const actionsList = computed(() => {
      const filteredActions = cloneDeep(props.ticketDetails.actions);
      const approveTicketActions = filteredActions.filter((v) =>
        approveEmailActions.includes(v.name)
      );
      const discardTicketActions = filteredActions.filter((v) =>
        discardEmailActions.includes(v.name)
      );
      if (approveTicketActions.length) {
        pullAllWith(filteredActions, approveTicketActions, isEqual);
        filteredActions.push({
          name: TicketAction.APPROVE_EMAIL_WITH_OPTIONS,
          severity: approveTicketActions.at(0)?.severity ?? TicketActionSeverity.MEDIUM,
        });
      }
      if (discardTicketActions.length) {
        pullAllWith(filteredActions, discardTicketActions, isEqual);
        filteredActions.push({
          name: TicketAction.DISCARD_EMAIL_WITH_OPTIONS,
          severity: discardTicketActions.at(0)?.severity ?? TicketActionSeverity.MEDIUM,
        });
      }
      return filteredActions;
    });

    const buttonType = computed(() => {
      const { potentiallyProtectableUsers } = props.ticketDetails;
      if (actionsList.value.length > 0) {
        return buttonTypes.ACTIONS;
      }
      if (potentiallyProtectableUsers?.length) {
        return buttonTypes.ADD_TO_PROTECTION;
      }
      return null;
    });

    const showNotProtectedUsersNotice = computed(
      () => buttonType.value === buttonTypes.ADD_TO_PROTECTION
    );

    const processedTime = computed(() => {
      return moment(props.ticketDetails.processedTime).format("llll");
    });

    const disableAddToProtection = computed(() => {
      return isMspActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    const addUsers = debounce((usersToAdd: { users: { email: string; service: Service }[] }) => {
      usersSettingsTabStore.addUsers({
        ...usersToAdd,
        workspaceId: props.ticketDetails.workspaceId,
      });
    }, 500);

    const getPotentiallyProtectableUsers = (ticket: MspTicketDetails) =>
      getUsersToAddFromPotentiallyProtectable(ticket.potentiallyProtectableUsers);

    const getDisablePolicy = (nestedPermissionKey: TicketActionSeverity): boolean =>
      isMspActionRestricted(
        RolePermissionsScope.TICKETS,
        props.ticketDetails.moduleName as keyof TicketsScope,
        nestedPermissionKey
      );

    const handleContactUserAction = async (
      item: MspTicketDetails,
      action: TicketAction.CONTACT_USER,
      severity: TicketActionSeverity
    ) => {
      const isDeviceEvent = (item.lastLoggedInUsers ?? []).length > 0;
      const recipients = await useTicketStore().getTicketRecipients(
        item.eventId,
        isDeviceEvent,
        item.workspaceId
      );
      const disclaimer = getDisablePolicy(severity) ? i18n.t("general.noPermissions") : undefined;
      dialogsStore.openDialog(
        componentDialogsConfigConstructor({
          action,
          item: {
            id: item.eventId,
            by: "event",
            isDeviceEvent,
            workspaceId: item.workspaceId,
            recipients,
          },
          callback: ticketsStore.onContactUser,
          component: ContactUserModal,
          disable: getDisablePolicy(severity),
          disclaimer,
          width: ModalWidth.LARGE,
        })
      );
    };

    const handleGeneralApproveAction = async (
      item: MspTicketDetails,
      action: TicketAction.GENERAL_APPROVE,
      severity: TicketActionSeverity,
      showCheckbox: boolean
    ) => {
      const isProxy =
        item.sections?.serviceName === Service.PROXY ? i18n.t(`modals.${action}.proxy`) : "";
      const applySpecificDescription = [
        TicketTrigger.MISSING_REQUIRED_AUTHENTICATION,
        TicketTrigger.CROWDBLOCKED_SENDER,
        TicketTrigger.BLOCKLISTED_SENDER,
      ].includes(item.eventTrigger);
      const text = applySpecificDescription
        ? `${i18n.t(`modals.${action}.specificDescription.${item.eventTrigger}`)} ${isProxy}`
        : i18n.t(`modals.${action}.description`);

      const dialogConfig = createTicketModalWithConfirmationConfig(
        action,
        item,
        getDisablePolicy(severity),
        ticketsStore.applyMspTicketAction,
        showCheckbox,
        false,
        i18n.t("general.closeUponConfirmation"),
        text
      );

      dialogConfig.data.item.text = text;

      dialogsStore.openDialog(dialogConfig);
    };

    const onActionClick = (
      { name: action, severity: actionSeverity }: TicketActionWithSeverity,
      item: MspTicketDetails
    ) => {
      const showCheckbox = !item.processed && !getDisablePolicy(TicketActionSeverity.LOW);
      // Fallback in case severity doesn't exist
      const severity = actionSeverity ?? TicketActionSeverity.MEDIUM;
      const disclaimer = getDisablePolicy(severity) ? i18n.t("general.noPermissions") : undefined;

      switch (action) {
        case TicketAction.MARK_AS_PROCESSED:
          ticketsStore.applyMspTicketAction({
            action,
            item,
          });
          break;
        case TicketAction.UN_LOG_FOR_AUDIT_REPORTS:
        case TicketAction.LOG_FOR_AUDIT_REPORTS:
        case TicketAction.MARK_AS_UNPROCESSED:
          ticketsStore.applyMspTicketAction({
            action,
            item,
          });
          break;
        case TicketAction.DOWNLOAD_EML_FILE:
        case TicketAction.EXPORT_MASS_DELETE_FILES:
        case TicketAction.EXPORT_MASS_DOWNLOAD_FILES:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyMspTicketDownloadEmailAction,
              showCheckbox
            )
          );
          break;
        case TicketAction.APPROVE_EMAIL_WITH_OPTIONS:
        case TicketAction.DISCARD_EMAIL_WITH_OPTIONS:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              action,
              item: {
                ...item,
                allowAction: action === TicketAction.APPROVE_EMAIL_WITH_OPTIONS,
                enabledActions: intersection(
                  props.ticketDetails.actions.map((v) => v.name),
                  action === TicketAction.APPROVE_EMAIL_WITH_OPTIONS
                    ? approveEmailActions
                    : discardEmailActions
                ),
              },
              callback: ticketsStore.applyMspTicketAction,
              component: AllowBlockEmailTicketModal,
              disable: getDisablePolicy(severity),
              width: ModalWidth.MEDIUM,
              disclaimer,
            })
          );
          break;
        case TicketAction.CONTACT_USER:
          handleContactUserAction(item, action, severity);
          break;
        case TicketAction.GENERAL_APPROVE:
          handleGeneralApproveAction(item, action, severity, showCheckbox);
          break;
        case TicketAction.SUSPEND_FROM_SERVICE:
        case TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS:
        case TicketAction.ENFORCE_UAC:
        case TicketAction.ALLOW_NO_ENCRYPTION:
        case TicketAction.ENCRYPT_DRIVE:
        case TicketAction.ENABLE_FIREWALL:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyMspTicketAction,
              showCheckbox
            )
          );
          break;
        case TicketAction.ALLOW_PROCESS:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyMspTicketAction,
              showCheckbox,
              true
            )
          );
          break;
        case TicketAction.TEST_MX_RECORD:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              item,
              action,
              component: TestEmailProxyModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              disable: isMspActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionAddon.INBOUND_GATEWAY
              ),
            }),
            footer: null,
          });
          break;
        default:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyMspTicketAction,
              showCheckbox
            )
          );
          break;
      }
    };

    function createTicketModalWithConfirmationConfig(
      action: TicketAction,
      item: MspTicketDetails,
      disable: boolean,
      callback: GenericCallback = ticketsStore.applyMspTicketAction,
      showCheckbox: boolean = false,
      closeTicket: boolean = false,
      checkboxText = i18n.t("general.closeUponConfirmation"),
      text?: string
    ) {
      const disclaimer = disable ? i18n.t("general.noPermissions") : undefined;
      const serviceNameRaw = item.serviceName ?? item.sections.serviceName;
      const serviceName = i18n.t(`services.${serviceNameRaw}`);
      const violationUsers = item?.sections?.userDataAccessViolation?.users ?? [];
      const violationDirection =
        item?.sections?.userDataAccessViolation?.violationDirection ?? ViolationDirection.SHARE;
      const violationTrigger = i18n.t(
        `ticketDetails.fullDetails.accessViolations.${item.eventTrigger}`
      );
      const modalText =
        text ??
        i18n.t(`modals.${action}.description`, {
          ...item,
          serviceName,
          violationUsers: violationUsers.join(","),
          violationDirection,
          violationTrigger,
        });
      if (showCheckbox) {
        const dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...item,
            serviceName,
            violationUsers: violationUsers.join(","),
            violationDirection,
            violationTrigger,
          },
          action,
          callback,
          component: TicketConfirmationModal,
          disable,
          disclaimer,
          width: ModalWidth.SMALL,
        });
        dialogConfig.data.item.showCheckbox = true;
        dialogConfig.data.item.checkboxText = checkboxText;
        dialogConfig.data.item.closeTicket = closeTicket;
        dialogConfig.data.item.text = modalText;
        return dialogConfig;
      }

      return confirmationDialogsConfigConstructor({
        item: {
          ...item,
          serviceName,
          violationUsers: violationUsers.join(","),
          violationDirection,
          violationTrigger,
        },
        action,
        disclaimer,
        callback,
        disable,
        text: modalText,
      });
    }

    return {
      buttonType,
      showNotProtectedUsersNotice,
      TicketAction,
      buttonTypes,
      processedTime,
      disableAddToProtection,
      addUsers,
      actionsList,
      getPotentiallyProtectableUsers,
      onActionClick,
      camelCase,
    };
  },
});
</script>
