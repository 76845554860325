<template>
  <template v-if="showTopVulnerabilities">
    <div class="es-report__section-title page-break-avoid mb-10">
      {{ $t("reports.executiveSummaryReport.totalTicketsByModuleAndVulnerabilities") }}
    </div>
    <div class="es-report__top-vulnerabilities mb-14">
      <v-row class="ga-10">
        <v-col>
          <div class="subtitle1 mb-6">
            {{ $t("reports.executiveSummaryReport.totalTicketsByModule") }}
          </div>
          <div
            v-for="(count, module) in sortObjectByValue(modulesStats)"
            :key="module"
            class="page-break-avoid"
          >
            <div class="d-flex justify-space-between py-3 body2">
              <span> {{ $t(`subscriptions.modules.${module}`) }} </span>
              <span>
                {{ count }}
              </span>
            </div>
            <v-divider class="border-opacity-100" color="indigo-faint" />
          </div>
        </v-col>
        <v-col>
          <div
            class="subtitle1 mb-6"
            data-testid="executive-summary-report-top-vulnerabilities-title"
          >
            {{ $t("reports.executiveSummaryReport.topVulnerabilities") }}
          </div>
          <div
            class="page-break-avoid"
            v-for="(count, eventTrigger) in sortObjectByValue(topVulnerabilities)"
            :key="eventTrigger"
            data-testid="executive-summary-report-top-vulnerability-item"
          >
            <div class="d-flex justify-space-between text-primary py-3">
              <div class="body2">
                {{ $t(`tickets.eventTriggers.${eventTrigger}`) }}
              </div>
              <div class="body2">
                {{ count }}
              </div>
            </div>
            <v-divider class="border-opacity-100" color="indigo-faint" />
          </div>
        </v-col>
      </v-row>
    </div>
  </template>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { sortObjectByValue } from "@/_helpers/utils.ts";
import type {
  ExecutiveSummaryReportData,
  ExecutiveSummaryReportProtectionSettings,
} from "@/_store/reports.module.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import type { TicketTrigger } from "@/constants/tickets.ts";
export default defineComponent({
  props: {
    topVulnerabilities: {
      type: Object as PropType<ExecutiveSummaryReportData["topVulnerabilities"]>,
      required: true,
    },
    protectionSettings: {
      type: Object as PropType<ExecutiveSummaryReportProtectionSettings>,
      required: true,
    },
  },
  setup(props) {
    const showTopVulnerabilities = computed(() => {
      return Object.values(props.topVulnerabilities).some((v) => v > 0);
    });

    const modulesStats = computed(() => {
      return Object.fromEntries(
        Object.entries(props.protectionSettings)
          .filter(([key]) => key !== SubscriptionModule.SECURITY_AWARENESS) // Exclude SECURITY_AWARENESS
          .map(([key, value]) => {
            return [
              key,
              Object.values(
                (
                  value as {
                    vulnerabilities: {
                      [key in TicketTrigger]: number;
                    };
                  }
                ).vulnerabilities || {}
              ).reduce((sum, num) => sum + num, 0),
            ];
          })
      );
    });

    return {
      sortObjectByValue,
      showTopVulnerabilities,
      modulesStats,
    };
  },
});
</script>
<style lang="scss">
.es-report__section-title {
  border-radius: 4px;
  background: rgb(var(--v-theme-primary));
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
  padding: 8px;
}
</style>
