<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">{{ $t("backOffice.permissions") }}</div>
      <invite-global-admin-button v-if="showCreateButton(RouteName.GLOBAL_ADMIN_USERS)" />
      <create-global-role-button v-if="showCreateButton(RouteName.GLOBAL_ROLES)" />
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import CreateGlobalRoleButton from "@/views/back-office/CreateGlobalRoleButton.vue";
import InviteGlobalAdminButton from "@/views/back-office/InviteGlobalAdminButton.vue";
import { RouteName } from "@/constants/routes";
import { GlobalRoleScopes, GlobalRoleScopeSection } from "@/_store/roles.module.ts";

export default defineComponent({
  components: {
    InviteGlobalAdminButton,
    CreateGlobalRoleButton,
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t(
          `accessControlPage.roles.scopes.${GlobalRoleScopes.GLOBAL_SCOPE}.${GlobalRoleScopeSection.GLOBAL_ADMIN_USERS}.title`
        ),
        route: { name: RouteName.GLOBAL_ADMIN_USERS },
      },
      {
        id: 2,
        name: i18n.t(
          `accessControlPage.roles.scopes.${GlobalRoleScopes.GLOBAL_SCOPE}.${GlobalRoleScopeSection.GLOBAL_ROLES}.title`
        ),
        route: { name: RouteName.GLOBAL_ROLES },
      },
    ];

    const showCreateButton = (
      selectedRoute: RouteName.GLOBAL_ROLES | RouteName.GLOBAL_ADMIN_USERS
    ) => {
      return route.name === selectedRoute;
    };

    return {
      tabs,
      breadCrumbsItems: [
        {
          title: `‹ ${i18n.t("backOffice.title")}`,
          disabled: false,
          to: { name: RouteName.BACKOFFICE_DASHBOARD },
        },
      ],
      showCreateButton,
      RouteName,
    };
  },
});
</script>

<style scoped></style>
