export enum StoreModule {
  MSP_DEVICES = "mspDevices",
  MSP_DRIVES = "mspDrives",
  MSP_TERMINAL = "mspTerminal",
  MSP_TICKETS = "mspTickets",
  MSP_TICKET_COMMENTS = "mspTicketComments",
  MSP_LABELS = "mspLabels",
  MSP_DASHBOARD = "mspDashboard",
  SECURITY_AWARENESS = "securityAwareness",
  TUTORIAL = "tutorial",
}
