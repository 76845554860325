import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import {
  denormalizePermissionsData,
  denormalizePermissionsForCreation,
  getRecommendedAndOtherDataTypes,
  mapDataLossPermissionCategory,
} from "@/_store/user-data-governance/adapter";
import type { UserDataGovernancePermissionConfig } from "@/_store/user-data-governance/adapter";
import type { PermissionsFormData } from "@/_store/user-data-governance/adapter";
import type { DataLossPermissionsNormalized } from "@/_store/user-data-governance/adapter";
import type { TicketTrigger } from "@/constants/tickets";
import cloneDeep from "lodash/cloneDeep";

export interface PrivacyParametersConfig {
  monitorNonPublicData: boolean;
  monitorPersonalData: boolean;
  monitorHealthData: boolean;
  monitorCreditCardData: boolean;
  valid: boolean;
  wizardParameters: {
    industries: string[];
    isDefault?: boolean;
  };
}

export interface UserDataGovernanceSettings {
  wizardParameters: {
    industries: string[];
    isDefault: boolean;
  };
  monitorNonPublicData: boolean;
  monitorPersonalData: boolean;
  monitorHealthData: boolean;
  monitorCreditCardData: boolean;
  customData: {
    monitorPasswords: boolean;
    monitorCertificates: boolean;
    monitorSourceCodes: boolean;
    monitorSensitiveKeywords: boolean;
    sensitiveKeywords: string[];
    monitorSensitiveFileTypes: boolean;
    sensitiveFileTypes: string[];
  };
}

export interface UserDataGovernanceExclusions {
  emailSubjectKeywordsEnabled: boolean;
  emailSubjectKeywords: string[];
}

export interface UserDataGovernanceState {
  settings: UserDataGovernanceSettings;
  exclusions: UserDataGovernanceExclusions;
  permissions: DataLossPermissionsNormalized[];
  monitoredDataPolicies: string[];
  showSkeletonLoader: boolean;
  recommendedDataTypes: string[];
  otherDataTypes: string[];
}

const defaultUserDataGovernanceState: UserDataGovernanceState = {
  settings: {
    wizardParameters: {
      industries: [],
      isDefault: false,
    },
    monitorNonPublicData: false,
    monitorPersonalData: false,
    monitorHealthData: false,
    monitorCreditCardData: false,
    customData: {
      monitorPasswords: false,
      monitorCertificates: false,
      monitorSourceCodes: false,
      monitorSensitiveKeywords: false,
      sensitiveKeywords: [],
      monitorSensitiveFileTypes: false,
      sensitiveFileTypes: [],
    },
  },
  exclusions: {
    emailSubjectKeywordsEnabled: false,
    emailSubjectKeywords: [],
  },
  permissions: [],
  monitoredDataPolicies: [],
  showSkeletonLoader: false,
  recommendedDataTypes: [],
  otherDataTypes: [],
};

export const useUserDataGovernanceModule = defineStore("userDataGovernanceModule", {
  state: (): UserDataGovernanceState => ({ ...defaultUserDataGovernanceState }),
  actions: {
    async getData() {
      this.showSkeletonLoader = true;
      try {
        await this.getSettings();
        await this.getRecommentedAndOtherDataTypesData();
      } catch (error) {
        console.error(error);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
    async getSettings(): Promise<void> {
      try {
        const response = await axiosInstance.request({
          ...api.userDataGovernanceSettings(),
        });
        this.settings = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateSettings(newSettings: UserDataGovernanceSettings): Promise<void> {
      const request = {
        ...api.userDataGovernanceSettings(),
        method: "put",
        data: newSettings,
      };

      try {
        await axiosInstance.request(request);
        await this.getSettings();
      } catch (error) {
        console.error(error);
      }
    },
    async getRecommentedAndOtherDataTypesData() {
      try {
        const { data } = await axiosInstance.request({
          ...api.privacyParametersConfig(),
          params: {
            industries: this.settings.wizardParameters.industries.join(","),
          },
        });
        const { recommendedDataTypes, otherDataTypes } = getRecommendedAndOtherDataTypes(data);
        this.recommendedDataTypes = recommendedDataTypes;
        this.otherDataTypes = otherDataTypes;
      } catch (error) {
        console.error(error);
      }
    },
    async updatePrivacyConfig(industries: string[]) {
      this.showSkeletonLoader = true;
      try {
        const { data } = await axiosInstance.request({
          ...api.privacyParametersConfig(),
          params: {
            industries: industries.join(","),
          },
        });
        const { recommendedDataTypes, otherDataTypes } = getRecommendedAndOtherDataTypes(data);
        this.recommendedDataTypes = recommendedDataTypes;
        this.otherDataTypes = otherDataTypes;
        await this.updateSettings({
          customData: this.settings.customData,
          ...data,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
    async getExclusions(): Promise<void> {
      try {
        const response = await axiosInstance.request(api.userDataGovernanceExclusions());

        this.exclusions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateExclusions(exclusions: UserDataGovernanceExclusions) {
      const request = {
        ...api.userDataGovernanceExclusions(),
        method: "put",
        data: exclusions,
      };

      try {
        await axiosInstance.request(request);

        this.exclusions = cloneDeep(exclusions);
      } catch (error) {
        console.error(error);
      }
    },
    async getDataLossPermissions(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;

      try {
        const { data } = await axiosInstance.request<
          Record<TicketTrigger, UserDataGovernancePermissionConfig>
        >(api.dataLossPermissions());
        this.permissions = Object.entries(data).flatMap(([key, value]) =>
          mapDataLossPermissionCategory([key as TicketTrigger, value])
        );
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },

    async getMonitoredDataPolicies() {
      try {
        const { data } = await axiosInstance.request({
          ...api.monitoredDataPolicies(),
          method: "get",
        });
        this.monitoredDataPolicies = data;
      } catch (error) {
        console.error(error);
      }
    },

    async addDataLossPermission(payload: PermissionsFormData) {
      const { holderType, data } = denormalizePermissionsForCreation(payload);

      const request = {
        ...api.dataLossPermissions(),
        method: "POST",
        params: {
          holderType,
        },
        data,
      };

      try {
        await axiosInstance.request(request);
        await this.getDataLossPermissions();
      } catch (error) {
        console.error(error);
      }
    },
    async updateDataLossPermissions(payload: DataLossPermissionsNormalized[]) {
      const denormalizedData = denormalizePermissionsData(payload);

      const request = {
        ...api.dataLossPermissions(),
        method: "put",
        data: denormalizedData,
      };

      try {
        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
