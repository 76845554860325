<template>
  <div>
    <v-app-bar app color="white" dark height="56" elevation="0" class="app-bar">
      <v-container fluid height="56" class="pt-0">
        <div class="app-bar--content">
          <div class="d-flex align-center text-no-wrap">
            <div class="workspace-navigator" v-if="hasMspAccess">
              <div
                v-for="workspaceNavigatorButton in workspaceNavigatorButtons"
                :key="workspaceNavigatorButton.name"
                class="workspace-navigator-btn body2 item-clickable"
                :class="{
                  'workspace-navigator-btn--active subtitle2': workspaceNavigatorButton.active,
                }"
                @click="goToRoute(workspaceNavigatorButton.route)"
              >
                {{ $t(`appBar.${workspaceNavigatorButton.name}`) }}
              </div>
            </div>
            <span v-else class="subtitle2 text-primary text-uppercase" v-text="appTitle"></span>
          </div>
          <div v-if="logged" class="d-flex justify-end w-100">
            <div class="d-inline-flex align-center">
              <select-workspace-hierarchy-dropdown v-if="isMspRoute" />
              <template v-else>
                <demo-countdown-notice
                  v-if="showDemoModeNotice"
                  :show-separator="showWorkspaceSelector"
                ></demo-countdown-notice>
                <trial-countdown-notice></trial-countdown-notice>
                <select-workspace-dropdown
                  v-if="showWorkspaceSelector"
                  :enable-dropdown="account.hasMultipleWorkspaces"
                />
              </template>
            </div>
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useAccountStore } from "@/_store/account.module";
import { storeToRefs } from "pinia";
import SelectWorkspaceDropdown from "@/components/modals/SelectWorkspaceDropdown.vue";
import SelectWorkspaceHierarchyDropdown from "@/components/modals/SelectWorkspaceHierarchyDropdown.vue";
import { RouteName } from "@/constants/routes.ts";
import { useRouter } from "vue-router";
import { WorkspaceNavigationButton } from "@/constants/workplaces.ts";
import TrialCountdownNotice from "@/components/TrialCountdownNotice.vue";
import DemoCountdownNotice from "@/components/DemoCountdownNotice.vue";
import { isWorkspaceNewAndInDemoMode } from "@/_helpers/utils.ts";
import { useMspStore } from "@/_store/msp/msp.module.ts";

export default defineComponent({
  components: {
    DemoCountdownNotice,
    TrialCountdownNotice,
    SelectWorkspaceHierarchyDropdown,
    SelectWorkspaceDropdown,
  },
  props: {
    appTitle: {
      type: String,
      required: true,
    },
    isMspRoute: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const accountStore = useAccountStore();
    const mspStore = useMspStore();
    const { hasMspAccess } = storeToRefs(mspStore);
    const { account, logged } = storeToRefs(accountStore);
    const router = useRouter();
    const route = ref(RouteName.MSP_OVERVIEW_PAGE);

    const workspaceNavigatorButtons = computed(() => {
      return [
        {
          name: WorkspaceNavigationButton.GLOBAL,
          route: RouteName.MSP_DASHBOARD,
          active: props.isMspRoute,
        },
        {
          name: WorkspaceNavigationButton.WORKSPACE,
          route: RouteName.DASHBOARD,
          active: !props.isMspRoute,
        },
      ];
    });

    const goToRoute = async (route: RouteName) => {
      await router.push({ name: route });
    };

    const showDemoModeNotice = computed(() => {
      return isWorkspaceNewAndInDemoMode();
    });

    const showWorkspaceSelector = computed(() => {
      if (showDemoModeNotice.value) {
        return account.value.hasMultipleWorkspaces;
      }
      return true;
    });

    return {
      account,
      logged,
      route,
      goToRoute,
      hasMspAccess,
      RouteName,
      workspaceNavigatorButtons,
      showDemoModeNotice,
      showWorkspaceSelector,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-bar {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12) !important;

  &--content {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
  }
}

.workspace-navigator {
  height: 36px;
  border-radius: 8px;
  background-color: rgb(var(--v-theme-indigo-pale));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  &-btn {
    min-width: 155px;
    height: 28px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap !important;
    border-radius: 4px;
    &--active {
      background-color: rgb(var(--v-theme-white));
    }
  }
}
</style>
