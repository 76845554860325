import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Service } from "@/constants/cloud-apps.ts";
import { type CloudAppService, useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module.ts";
import { useSecurityAwarenessModule } from "@/_store/security-awareness/security-awareness.module.ts";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  isCloudAppConnected,
  isSecurityAwarenessAllowedFor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import { SecurityAwarenessAction } from "@/constants/security-awareness.ts";
import { ModalWidth } from "@/constants/modals.ts";
import AllowSecurityAwarenessModal from "@/components/modals/AllowSecurityAwarenessModal.vue";

export function useSecurityAwarenessEmptyState(servicesFromWidget: CloudAppService[] = []) {
  const i18n = useI18n();
  const cloudAppsStore = useCloudAppsStore();
  const securityAwarenessStore = useSecurityAwarenessModule();
  const dialogsStore = useDialogsStore();
  const { services, showSkeletonLoader } = storeToRefs(cloudAppsStore);
  const { metadata, metadataLoading } = storeToRefs(securityAwarenessStore);

  const cloudApps = computed(() => {
    return services.value.length ? services.value : servicesFromWidget;
  });

  const showEmptyStateSkeletonLoader = computed(() => {
    return showSkeletonLoader.value || metadataLoading.value;
  });

  // is Office OR Google connected?
  const isCloudAppsConnected = computed(() => {
    return isGoogleConnected.value || isOfficeConnected.value;
  });

  const isGoogleConnected = computed(() => {
    return isCloudAppConnected(Service.G_SUITE);
  });

  const isOfficeConnected = computed(() => {
    return isCloudAppConnected(Service.OFFICE_365);
  });

  // is SA allowed for Office OR Google?
  const isSecurityAwarenessAllowed = computed(() => {
    return isOfficeAllowed.value || isGoogleAllowed.value;
  });

  const isOfficeAllowed = computed(() => {
    return isSecurityAwarenessAllowedFor(Service.OFFICE_365);
  });

  const isGoogleAllowed = computed(() => {
    return isSecurityAwarenessAllowedFor(Service.G_SUITE);
  });

  const actionNotAllowed = computed(() => {
    return isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.SECURITY_AWARENESS
    );
  });

  const hasMultipleAllowOptions = computed(() => {
    return (
      isGoogleConnected.value &&
      isOfficeConnected.value &&
      !isOfficeAllowed.value &&
      !isGoogleAllowed.value
    );
  });

  const allowButtonText = computed(() => {
    if (hasMultipleAllowOptions.value) {
      return i18n.t("securityAwarenessSettings.emptyState.allowButtonText", {
        service: i18n.t("securityAwarenessSettings.cloudApps"),
      });
    } else if (isGoogleConnected.value && !isGoogleAllowed.value) {
      return i18n.t("securityAwarenessSettings.emptyState.allowButtonText", {
        service: i18n.t(`services.${Service.G_SUITE}`),
      });
    }

    return i18n.t("securityAwarenessSettings.emptyState.allowButtonText", {
      service: i18n.t(`services.${Service.OFFICE_365}`),
    });
  });

  const emptyStateTitle = computed(() => {
    return !isCloudAppsConnected.value
      ? i18n.t("securityAwarenessSettings.emptyState.connectTitle")
      : i18n.t("securityAwarenessSettings.emptyState.allowTitle");
  });

  const emptyStateSubtitle = computed(() => {
    return !isCloudAppsConnected.value
      ? i18n.t("securityAwarenessSettings.emptyState.connectSubtitle")
      : i18n.t("securityAwarenessSettings.emptyState.allowSubtitle");
  });

  const daysToAllowList = computed(() => {
    return metadata.value?.daysToAllowList || 28;
  });

  const handleAllowButtonClick = (serviceName: Service | "" = "") => {
    const officeOrGoogle =
      isGoogleConnected.value && !isGoogleAllowed.value ? Service.G_SUITE : Service.OFFICE_365;
    const service = serviceName ? serviceName : officeOrGoogle;
    const config = componentDialogsConfigConstructor({
      width: ModalWidth.LARGE,
      action: SecurityAwarenessAction.ALLOW_CLOUD_SERVICE,
      item: {
        service,
      },
      component: AllowSecurityAwarenessModal,
      disable: actionNotAllowed.value,
      callback: () => {},
    });

    dialogsStore.openDialog({
      ...config,
      header: {
        title: i18n.t(`modals.${SecurityAwarenessAction.ALLOW_CLOUD_SERVICE}.title`, {
          service: i18n.t(`services.${service}`),
        }),
        close: true,
      },
      footer: null,
    });
  };

  const loadSecurityAwarenessEmptyStateData = async () => {
    await cloudAppsStore.getServices();
    if (isCloudAppsConnected.value && !isSecurityAwarenessAllowed.value) {
      await securityAwarenessStore.getMetadata();
    }
  };

  return {
    hasMultipleAllowOptions,
    allowButtonText,
    emptyStateTitle,
    emptyStateSubtitle,
    handleAllowButtonClick,
    showEmptyStateSkeletonLoader,
    isCloudAppsConnected,
    isSecurityAwarenessAllowed,
    daysToAllowList,
    loadSecurityAwarenessEmptyStateData,
    isGoogleConnected,
    isOfficeConnected,
    isGoogleAllowed,
    isOfficeAllowed,
    cloudApps,
  };
}
