<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div class="body2 mb-4">
      {{ $t("modals.setDataGovernanceIndustry.description") }}
    </div>
    <v-text-field
      v-model="search"
      class="search-input mb-4"
      density="comfortable"
      rounded
      min-width="400"
      clear-icon="$x"
      :placeholder="$t('general.search')"
      prepend-inner-icon="$search"
      clearable
      variant="filled"
      bg-color="indigo-faint"
      :hide-details="true"
      @click:clear="search = ''"
    />
    <div
      class="industry-wrapper d-flex flex-column overflow-y-auto border pa-4 rounded"
      :class="{ 'industry-wrapper--error': valid === false }"
    >
      <div v-if="filteredIndustries.length === 0 && !!search" class="no-results">
        <span class="body1">{{ $t("modals.setDataGovernanceIndustry.noDataAvailable") }}</span>
        <span class="body1 ml-2 coro-link" @click="search = ''">{{
          $t("modals.setDataGovernanceIndustry.clearToReset")
        }}</span>
        <span class="body1 ml-2">{{ $t("modals.setDataGovernanceIndustry.differentSearch") }}</span>
      </div>
      <v-checkbox
        v-for="(item, index) in filteredIndustries"
        :key="index"
        :label="item.displayName"
        v-model="localValue.selectedIndustries"
        :value="item.key"
        :rules="[atLeastOneIsRequired()]"
      />
    </div>
    <div class="caption text-red-dark mt-2 ml-2 d-flex align-center" v-if="valid === false">
      <v-icon icon="$warning" size="14" class="mr-1"></v-icon>
      {{ $t("validations.pleaseSelectAtLeastOneOption") }}
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType, computed, watch } from "vue";
import { Industries } from "@/constants/data-governance";
import { useI18n } from "vue-i18n";
import { atLeastOneIsRequired } from "@/_helpers/validators.ts";

interface PrivacyParametersModalConfig {
  item?: {
    industries: string[];
    locations: string[];
  };
  action?: string;
}

interface LocalValue {
  selectedIndustries: string[];
}

export default defineComponent({
  emits: ["update:localValue", "change-footer", "hide-footer", "update:valid", "dismissed"],
  props: {
    config: {
      type: Object as PropType<PrivacyParametersModalConfig>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const search = ref("");
    const valid = ref(true);
    const form = ref();
    const localValue = ref<LocalValue>({
      selectedIndustries: props.config?.item?.industries || [],
    });

    const getTranslatedIndustry = (industry: string): string =>
      t(`modals.setDataGovernanceIndustry.industries.${industry}`);

    const filteredIndustries = computed(() => {
      const searchTerm = search.value.toLowerCase().trim();

      return Object.values(Industries)
        .map((key) => ({
          key,
          displayName: getTranslatedIndustry(key),
        }))
        .filter(({ displayName }) =>
          searchTerm ? displayName.toLowerCase().includes(searchTerm) : true
        );
    });

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    watch(
      () => localValue.value,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(
      () => search.value,
      () => {
        validate();
      }
    );

    return {
      valid,
      form,
      validate,
      search,
      localValue,
      filteredIndustries,
      atLeastOneIsRequired,
    };
  },
});
</script>

<style scoped lang="scss">
.industry-wrapper {
  max-height: 320px;
  &--error {
    border-color: rgb(var(--v-theme-error)) !important;
  }
}

.selected-industries {
  width: 350px;
}

:deep(*) {
  .v-field__outline {
    display: none;
  }

  .v-selection-control--error:not(.v-selection-control--disabled) .v-label {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
</style>
