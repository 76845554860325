<template>
  <div>
    <div class="d-flex justify-end mb-5">
      <div class="w-70">
        <filter-wrapper
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="onClearFiltersClicked"
        >
          <v-select
            v-model="localFilters.types"
            :items="exportTypes"
            bg-color="white"
            menu-icon="icon-triangle"
            item-value="subType"
            item-title="subType"
            :menu-props="{ maxHeight: '300' }"
            class="types-filter filter-menu mr-2"
            :class="{ 'filter-active': localFilters.types?.length }"
            :placeholder="$t('reports.exports.filters.types')"
            data-testid="devices-page-label-filter"
            density="compact"
            variant="outlined"
            multiple
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("reports.exports.filters.types")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.types?.length" class="filter-label">
                {{ localFilters.types.length }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <v-list-item-title title="">
                  {{ $t(`reports.scheduled.subTypes.${item.raw.subType}`) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-text-field
            v-model="localFilters.search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            rounded
            persistent-clear
            bg-color="white"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            clearable
            variant="outlined"
            clear-icon="icon-x"
            @click:clear="localFilters.search = ''"
          />
        </filter-wrapper>
      </div>
    </div>
    <v-data-table-server
      class="coronet-table--highlightable"
      :headers="headers"
      :items="scheduledReportsList.items"
      :loading="loading"
      :page="pagination.page + 1"
      :items-length="scheduledReportsList.total"
      @update:options="
        handlePaginationChange({
          pageSize: $event.itemsPerPage,
          page: $event.page - 1,
        })
      "
      :items-per-page="pagination.pageSize"
    >
      <template #item="{ item }">
        <tr :key="item.id">
          <td class="w-25">
            <div class="d-flex align-center">
              <v-icon :icon="iconMap[item.type]" size="40" class="mr-2"></v-icon>
              <div class="d-flex flex-column">
                <span class="subtitle2">{{ item.name }}</span>
                <span class="caption">{{ $t("general.by") }} {{ item.createdBy }}</span>
              </div>
            </div>
          </td>
          <td class="body2 w-15">
            {{ $t(`reports.scheduled.subTypes.${item.subType}`) }}
          </td>
          <td class="body2 w-15">
            {{ $t(`reports.scheduled.frequencies.${item.frequency}`) }}
          </td>
          <td class="body2 w-25">
            <template v-if="!item.recipients.length">
              {{ $t(`reports.scheduled.recipients.allAdminUsers`) }}
            </template>
            <template v-else>
              <div class="d-flex align-center">
                <span class="mr-2">
                  {{ $t(`reports.scheduled.recipients.specificAdminUsers`) }}
                </span>
                <v-menu :open-on-hover="false" :close-on-content-click="false" location="top">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="primary" icon="$info" />
                  </template>

                  <v-card min-width="300" elevation="10" class="pa-3">
                    <ul>
                      <li v-for="recipient in item.recipients" :key="recipient">
                        {{ recipient }}
                      </li>
                    </ul>
                  </v-card>
                </v-menu>
              </div>
            </template>
          </td>
          <td v-if="isChannelWorkspace" class="body2 w-25">
            {{ $t(`reports.scheduled.scopes.${item.scope}`) }}
          </td>
          <td class="actions-col w-5">
            <v-menu bottom left>
              <template v-slot:activator="{ props }">
                <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in getActions(item)"
                  :key="index"
                  :value="index"
                  @click="onActionClick(action, item)"
                >
                  <v-list-item-title>
                    {{ $t(`reports.scheduled.actions.${action}`) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { Pagination } from "@/types";
import {
  componentDialogsConfigConstructor,
  dialogsConfigConstructor,
  isActionRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { ExportCategory } from "@/_store/exports.module";
import { storeToRefs } from "pinia";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import {
  type ReportTypeFilterItem,
  type ScheduledReportsListItem,
  ScheduledType,
  useScheduledReportsStore,
} from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import { ScheduledReportsAction } from "@/constants/scheduled-reports";
import { useAccountStore } from "@/_store";
import { WorkspaceType } from "@/constants/workplaces";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import ScheduledExportModal from "@/components/modals/scheduled-reports/ScheduledExportModal.vue";
import ScheduledReportModal from "@/components/modals/scheduled-reports/ScheduledReportModal.vue";

const exportTypes: ReportTypeFilterItem[] = [
  { type: ScheduledType.EXPORT, subType: ExportCategory.ACTIVITY_LOGS },
  { type: ScheduledType.EXPORT, subType: ExportCategory.DEVICES },
  { type: ScheduledType.EXPORT, subType: ExportCategory.TICKETS },
  { type: ScheduledType.EXPORT, subType: ExportCategory.USERS },
  // todo: uncomment when library for PDF generation on backend will be fixed
  // { type: ScheduledType.REPORT, subType: ReportType.EXECUTIVE_SUMMARY },
  // { type: ScheduledType.REPORT, subType: ReportType.DNS_SUMMARY },
  // { type: ScheduledType.REPORT, subType: ReportType.MANAGED_SERVICES_SUMMARY },
  // { type: ScheduledType.REPORT, subType: ReportType.SECURED_MESSAGES },
];

const iconMap: Record<ScheduledType, string> = {
  [ScheduledType.EXPORT]: "$csvFile",
  [ScheduledType.REPORT]: "$activityLogs",
};

export default defineComponent({
  components: { FilterWrapper },
  setup() {
    const isChannelWorkspace = useAccountStore().account.workspaceType === WorkspaceType.CHANNEL;
    const i18n = useI18n();
    const headers = [
      {
        title: i18n.t("reports.scheduled.table.name"),
        key: "name",
        sortable: false,
      },
      {
        title: i18n.t("reports.scheduled.table.reportType"),
        key: "reportType",
        sortable: false,
      },
      {
        title: i18n.t("reports.scheduled.table.frequency"),
        key: "frequency",
        sortable: false,
      },
      {
        title: i18n.t("reports.scheduled.table.recipients"),
        key: "recipients",
        sortable: false,
      },
      ...(isChannelWorkspace
        ? [
            {
              title: i18n.t("reports.scheduled.table.scope"),
              key: "scope",
              sortable: false,
            },
          ]
        : []),
      { title: "", key: "actions", sortable: false },
    ];
    const dialogsStore = useDialogsStore();
    const scheduledReportsStore = useScheduledReportsStore();
    const { loading, pagination, scheduledReportsList } = storeToRefs(scheduledReportsStore);
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } = useFilters(
      FilterContext.SCHEDULED_REPORTS_FILTERS
    );
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.REPORTS
      );
    });

    const handlePaginationChange = async (pagination: Pagination) => {
      scheduledReportsStore.$patch({ pagination });
      await scheduledReportsStore.getScheduledReportsList();
    };

    watch(
      filtersUpdating,
      async (val) => {
        if (val) {
          scheduledReportsStore.$patch({
            pagination: { page: 0, pageSize: 25 },
          });
          await scheduledReportsStore.getScheduledReportsList();
        }
      },
      { deep: true }
    );

    const onClearFiltersClicked = () => {
      clearFilters(scheduledReportsStore.getScheduledReportsList);
    };

    const onActionClick = (action: ScheduledReportsAction, item: ScheduledReportsListItem) => {
      switch (action) {
        case ScheduledReportsAction.EDIT_EXPORT:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              item,
              callback: scheduledReportsStore.editScheduledReport,
              component: ScheduledExportModal,
              width: ModalWidth.LARGE,
              disable: actionNotAllowed.value,
            }),
            scrollable: true,
            disablePersistentAnimation: true,
          });
          break;
        case ScheduledReportsAction.EDIT_REPORT:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              item,
              callback: scheduledReportsStore.editScheduledReport,
              component: ScheduledReportModal,
              width: ModalWidth.LARGE,
              disable: actionNotAllowed.value,
            }),
            scrollable: true,
            disablePersistentAnimation: true,
          });
          break;
        case ScheduledReportsAction.DELETE:
          dialogsStore.openDialog(
            dialogsConfigConstructor({
              width: ModalWidth.SMALL,
              header: {
                title: i18n.t(`modals.${action}.title`),
                titleClass: "text-red-dark",
                titleIcon: "warning",
              },
              content: {
                html: i18n.t(`modals.${action}.description`),
              },
              footer: {
                buttons: [
                  {
                    title: i18n.t("general.cancel"),
                    spacer: "before",
                    type: "text",
                    cancel: true,
                  },
                  {
                    title: i18n.t(`modals.${action}.actionBtn`),
                    color: "primary",
                    callback: async () => {
                      scheduledReportsStore.deleteScheduledReport(item.id);
                    },
                    cancel: false,
                  },
                ],
              },
              disable: actionNotAllowed.value,
            })
          );
          break;
      }
    };

    const getActions = (item: ScheduledReportsListItem) => {
      return [
        item.type === ScheduledType.EXPORT
          ? ScheduledReportsAction.EDIT_EXPORT
          : ScheduledReportsAction.EDIT_REPORT,
        ScheduledReportsAction.DELETE,
      ];
    };

    return {
      headers,
      ScheduledReportsAction,
      scheduledReportsList,
      pagination,
      loading,
      localFilters,
      showClearFiltersButton,
      exportTypes,
      actionNotAllowed,
      isChannelWorkspace,
      clearFilters,
      iconMap,
      handlePaginationChange,
      isActionRestricted,
      onClearFiltersClicked,
      onActionClick,
      getActions,
    };
  },
});
</script>

<style lang="scss" scoped>
.actions-col {
  width: 56px;
  text-align: center;
}

.types-filter {
  width: 110px !important;
}
ul {
  list-style-type: none;
}

.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
