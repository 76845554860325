<template>
  <div class="module-selector">
    <div>
      <div class="w-100 d-flex flex-column align-center" style="height: 200px">
        <div class="d-flex w-100 justify-start">
          <v-chip label size="small" v-show="isAddon" class="mb-2">
            <span class="text--semibold text-primary text-uppercase">
              {{ $t("general.addon") }}
            </span>
          </v-chip>
        </div>
        <v-icon :icon="icon" size="70" />
        <div class="subtitle1 mt-2 mb-2">
          {{ isAddon ? $t(`subscriptions.addons.${name}`) : $t(`subscriptions.modules.${name}`) }}
        </div>
        <div class="body3 text-indigo-medium text-center mb-2">
          {{ $t(`moduleOrAddonSelector.${name}.description`) }}
        </div>
      </div>
      <slot name="content:after"></slot>
    </div>
    <slot name="footer">
      <div class="w-100">
        <v-divider class="mb-4"></v-divider>
        <div class="d-flex w-100 justify-space-between">
          <div>
            <slot name="footer:prepend"></slot>
          </div>
          <v-btn
            color="primary"
            rounded
            block
            size="large"
            :disabled="disabled"
            :class="!isSelected ? 'bg-primary' : 'bg-white'"
            @click="isSelected = !isSelected"
          >
            <template v-if="isSelected">
              <v-icon size="24" icon="$check"></v-icon>
              <span class="ml-1">{{ $t("general.selected") }}</span>
            </template>
            <template v-else>
              {{ $t("general.select") }}
            </template>
          </v-btn>
        </div>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    isAddon: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isSelected = ref(props.modelValue);

    watch(isSelected, (newVal) => {
      emit("update:modelValue", newVal);
    });

    const iconsMap: Partial<Record<SubscriptionAddon | SubscriptionModule, string>> = {
      [SubscriptionModule.ENDPOINT_SECURITY]: "icon-devices",
      [SubscriptionModule.NETWORK]: "icon-privatize",
      [SubscriptionModule.SECURITY_AWARENESS]: "icon-security-awareness",
      [SubscriptionModule.EMAIL_SECURITY]: "icon-emails",
      [SubscriptionModule.USER_DATA_GOVERNANCE]: "icon-data-copy",
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "icon-endpoint-data-governance",
      [SubscriptionModule.MDM]: "icon-mdm",
      [SubscriptionModule.CLOUD_SECURITY]: "icon-cloud-apps",
      [SubscriptionModule.EDR]: "icon-edr-hexagon",
      [SubscriptionAddon.WIFI_PHISHING]: "icon-wifi-phishing-l",
      [SubscriptionAddon.SWG]: "icon-access-restirctions",
      [SubscriptionAddon.SECURED_MESSAGES]: "icon-coro-mail",
      [SubscriptionAddon.ZTNA]: "icon-ztna-l",
      [SubscriptionAddon.INBOUND_GATEWAY]: "icon-inbound-gateway-l",
      [SubscriptionAddon.SOC]: "icon-soc-settings",
    };

    const icon = computed(() => {
      return iconsMap[props.name as SubscriptionAddon | SubscriptionModule];
    });

    return {
      isSelected,
      icon,
    };
  },
});
</script>

<style scoped lang="scss">
.module-selector {
  background-color: rgb(var(--v-theme-white));
  border-radius: 8px;
  height: 460px;
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: start;
  padding: 20px;
  justify-content: space-between;
}
</style>
