<template>
  <v-form ref="form" v-model="valid" validate-on="lazy input">
    <v-radio-group v-model="convertType">
      <template #label>
        <div class="subtitle1 mb-1">
          {{ $t("modals.convertWorkspace.selectTypeLabel") }}
        </div>
      </template>
      <v-radio
        v-for="type in convertOptions"
        class="mb-4"
        :key="type"
        :value="type"
        :ripple="false"
      >
        <template #label>
          <div class="body1">
            {{ $t(`modals.convertWorkspace.${type}`) }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>

    <v-autocomplete
      v-if="showParentWorkspaceAutocomplete"
      v-model="localValue.item.parentWorkspaceId"
      :loading="dataLoading"
      :items="workspaces"
      :label="$t('modals.convertWorkspace.workspacesAutocomplete.label')"
      :placeholder="$t('modals.convertWorkspace.workspacesAutocomplete.placeholder')"
      :rules="[required()]"
      class="mt-2"
      maxlength="150"
      variant="outlined"
      no-filter
      item-value="workspaceId"
      item-title="workspaceId"
      :no-data-text="$t('general.noData')"
      @update:search="searchWorkspaces($event)"
    >
    </v-autocomplete>
    <div v-if="showAdditionalConvertSettings" class="pl-4">
      <div class="body2 my-3">
        {{ $t("modals.convertWorkspace.descendantConversionOptionsTitle") }}
      </div>
      <v-radio-group v-model="localValue.item.descendantConversionOption">
        <v-radio
          v-for="option in [
            DescendantConversionOption.CASCADE_MIGRATION,
            DescendantConversionOption.CONVERT_TO_REGULAR,
          ]"
          :value="option"
          :key="option"
          :ripple="false"
          :label="$t(`modals.convertWorkspace.descendantConversionOptions.${option}`)"
        ></v-radio>
      </v-radio-group>
    </div>
  </v-form>
</template>

<script lang="ts">
import { WorkspaceType } from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { required } from "@/_helpers/validators";
import { useMspStore } from "@/_store/msp/msp.module.ts";

enum ConvertType {
  CONVERT_TO_REGULAR = "convertToRegular",
  CHANGE_PARENT = "changeParent",
  CONVERT_TO_CHANNEL = "convertToChannel",
  CONVERT_TO_CHILD = "convertToChild",
  CHANGE_CHANNEL_PARENT = "changeChannelParent",
}

enum DescendantConversionOption {
  CONVERT_TO_REGULAR = "convertToRegular",
  CASCADE_MIGRATION = "cascadeMigration",
}

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const workspaces = ref<{ workspaceId: string }[]>([]);
    const dataLoading = ref(false);
    const valid = ref(true);
    const form = ref();
    const localValue = ref(cloneDeep(props.config));
    const convertType = ref<ConvertType>();
    const mspStore = useMspStore();
    const showParentWorkspaceAutocomplete = computed(() =>
      [
        ConvertType.CHANGE_PARENT,
        ConvertType.CONVERT_TO_CHILD,
        ConvertType.CHANGE_CHANNEL_PARENT,
      ].includes(convertType.value!)
    );
    const showAdditionalConvertSettings = computed(
      () => convertType.value === ConvertType.CHANGE_CHANNEL_PARENT
    );

    const convertOptions = computed(() => {
      switch (props.config.item.type) {
        case WorkspaceType.CHILD:
          return [ConvertType.CONVERT_TO_REGULAR, ConvertType.CHANGE_PARENT];
        case WorkspaceType.REGULAR:
          return [ConvertType.CONVERT_TO_CHANNEL, ConvertType.CONVERT_TO_CHILD];
        case WorkspaceType.CHANNEL:
        default:
          return [
            ConvertType.CONVERT_TO_REGULAR,
            ConvertType.CONVERT_TO_CHILD,
            ConvertType.CHANGE_CHANNEL_PARENT,
          ];
      }
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(valid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    watch(convertType, (value) => {
      switch (value) {
        case ConvertType.CONVERT_TO_CHILD:
        case ConvertType.CHANGE_PARENT:
          localValue.value.item.type = WorkspaceType.CHILD;
          break;
        case ConvertType.CONVERT_TO_REGULAR:
          localValue.value.item.type = WorkspaceType.REGULAR;
          break;
        case ConvertType.CONVERT_TO_CHANNEL:
        case ConvertType.CHANGE_CHANNEL_PARENT:
          localValue.value.item.type = WorkspaceType.CHANNEL;
          localValue.value.item.descendantConversionOption =
            DescendantConversionOption.CASCADE_MIGRATION;
          break;
      }
    });

    onMounted(() => {
      if (props.config.item.type === WorkspaceType.REGULAR) {
        convertType.value = ConvertType.CONVERT_TO_CHANNEL;
      }

      if (props.config.item.type === WorkspaceType.CHILD) {
        convertType.value = ConvertType.CONVERT_TO_REGULAR;
      }

      if (props.config.item.type === WorkspaceType.CHANNEL) {
        convertType.value = ConvertType.CONVERT_TO_REGULAR;
      }
    });

    const fetchWorkspaces = async (search: string = "") => {
      const channelWorkspaceIdToExclude =
        convertType.value === ConvertType.CHANGE_CHANNEL_PARENT
          ? localValue.value.item.workspaceId
          : undefined;
      const data = await mspStore.searchMspConversionWorkplaceCandidates({
        name: search,
        type: WorkspaceType.CHANNEL,
        channelWorkspaceIdToExclude,
      });
      workspaces.value = data?.items ?? [];
    };

    const searchWorkspaces = debounce(async function (search: string) {
      // if something is selected and there is no search - do not make request with empty search param
      if (localValue.value.item.parentWorkspaceId && !search) return;
      dataLoading.value = true;
      await fetchWorkspaces(search);
      dataLoading.value = false;
    }, 500);

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    watch(showParentWorkspaceAutocomplete, (val) => {
      if (val && !workspaces.value.length) {
        fetchWorkspaces();
      }
    });

    return {
      localValue,
      WorkspaceType,
      convertType,
      dataLoading,
      workspaces,
      ConvertType,
      valid,
      form,
      showAdditionalConvertSettings,
      DescendantConversionOption,
      validate,
      required,
      showParentWorkspaceAutocomplete,
      convertOptions,
      searchWorkspaces,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-label {
    opacity: 1 !important;
  }
}
</style>
