<template>
  <div class="pb-6 px-12">
    <v-skeleton-loader
      v-if="preview"
      :loading="detailsLoading"
      type="list-item, divider, list-item-avatar, list-item-three-line@4, list-item-avatar-two-line@4"
      class="skeleton-loader--table"
    >
      <div class="d-flex align-center details-preview-header justify-space-between pb-4 pt-4">
        <div v-if="details.blocked" class="body2 text-red-dark">
          {{ $t("edr.details.blockedOn") }} {{ getFormattedDateTime(details.blockedTime, "ll") }}
        </div>
        <v-menu offset-y location="bottom right">
          <template #activator="{ props }">
            <v-btn
              class="ml-auto"
              rounded
              color="primary"
              data-testid="edr-process-preview-action-button"
              v-bind="props"
            >
              {{ $t("general.actions") }}
              <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="action in details.actions"
              :key="action"
              :data-testid="`edr-process-preview-${kebabCase(action)}-action`"
              @click="onActionClick(action, details)"
            >
              <v-list-item-title>
                {{ $t(`edr.actions.${action}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center mt-5 mb-5">
        <v-icon icon="$process" size="40" class="mr-3" />
        <span class="headline5" data-testid="edr-process-preview-name">
          {{ details.name }}
        </span>
      </div>
      <div class="mt-6 subtitle1">
        {{ $t("edr.details.hash") }}
      </div>
      <div class="body2">{{ details.processHash }}</div>
      <template
        v-if="details.firstSeenOnHostname || details.firstUsername || details.firstActivity"
      >
        <div class="mt-6 subtitle1">
          {{ $t("edr.details.firstExecutionDetails") }}
        </div>
        <div
          v-if="details.firstSeenOnHostname"
          class="coro-link cursor-pointer"
          @click="viewFirstSeenDevice"
        >
          {{ details.firstSeenOnHostname }}
        </div>
        <div v-if="details.firstUsername" class="body2">{{ details.firstUsername }}</div>
        <div v-if="details.firstActivity" class="caption">
          {{ getFormattedDateTime(details.firstActivity) }}
        </div>
      </template>
      <div class="mt-6 subtitle1">
        {{ $t("edr.details.devicesAffected") }}
      </div>
      <div v-if="hideDevicesTableLink" class="body2">
        {{ details.affectedDevicesCount }}
      </div>
      <div v-else class="coro-link cursor-pointer" @click="viewDevicesFromDetails">
        {{ details.affectedDevicesCount }}
      </div>
      <div class="coro-link cursor-pointer text-right" @click="viewTelemetry">
        {{ `${$t("edr.details.viewTelemetry")} ›` }}
      </div>

      <v-divider class="mt-4 mb-4"></v-divider>

      <div class="headline6">{{ $t("edr.details.knownPaths") }}</div>

      <div class="mt-3 edr-details-list">
        <div
          v-for="(item, index) in knownPaths"
          :key="index"
          v-intersect="handlePathsVirtualScroll"
          class="edr-details-list--item d-flex flex-column pa-4 coro-body2"
        >
          <v-tooltip open-delay="300" location="top">
            <template #activator="{ props }">
              <div
                class="ellipsis"
                :data-testid="`edr-process-preview-path-${index}`"
                v-bind="props"
              >
                {{ item.path }}
              </div>
            </template>
            <div>{{ item.path }}</div>
          </v-tooltip>
          <div class="coronet-neutral-darken1--text">
            <span v-if="hideDevicesTableLink" class="coro-body2">
              {{
                item.deviceCount > 1
                  ? `${item.deviceCount} ${$t("edr.details.devicesAffected")}`
                  : item.hostname
              }}
            </span>
            <span v-else role="button" class="coro-link" @click="viewDevicesFromPaths(item)">
              {{
                item.deviceCount > 1
                  ? `${item.deviceCount} ${$t("edr.details.devicesAffected")}`
                  : item.hostname
              }}
            </span>
          </div>
        </div>
      </div>
      <template v-if="aliases.length > 1">
        <div class="headline6 mt-12">{{ $t("edr.details.processAliases") }}</div>
        <div class="mt-3 edr-details-list">
          <div
            v-for="(item, index) in aliases"
            :key="index"
            v-intersect="handleAliasesVirtualScroll"
            class="edr-details-list--item d-flex flex-column pa-4 coro-body2"
          >
            <div class="ellipsis" :data-testid="`edr-process-preview-alias-${index}`">
              {{ item.alias }}
            </div>
            <div class="coronet-neutral-darken1--text">
              <span v-if="hideDevicesTableLink" class="body2">
                {{
                  item.deviceCount > 1
                    ? `${item.deviceCount} ${$t("edr.details.devicesAffected")}`
                    : item.hostname
                }}
              </span>
              <span v-else class="coro-link" role="button" @click="viewDevicesFromAliases(item)">
                {{
                  item.deviceCount > 1
                    ? `${item.deviceCount} ${$t("edr.details.devicesAffected")}`
                    : item.hostname
                }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="openTickets.length">
        <div class="mt-12 d-flex justify-space-between">
          <div class="headline6">{{ $t("edr.details.openTickets") }}</div>
          <div class="coro-link cursor-pointer text-right" @click="viewOpenTickets">
            {{ `${$t("edr.details.viewOpenTickets")} ›` }}
          </div>
        </div>
        <v-table class="mt-3 edr-details-list open-tickets-table">
          <tbody>
            <tr v-for="(ticket, index) in openTickets" :key="index">
              <td class="text-left vulnerability-col">
                <div class="text-no-wrap d-flex align-center">
                  <div class="circle red-circle mr-3" />
                  <div class="body2">{{ ticket.issue }}</div>
                </div>
              </td>
              <td class="text-center number-col subtitle2">
                {{ ticket.count }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import {
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isAccessRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { computed, defineComponent, type PropType, watch } from "vue";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { i18n } from "@/plugins/i18n";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";
import type {
  AliasItem,
  PathItem,
  ProcessDetails,
  ProcessItem,
} from "@/_store/endpoint-security/processes.module";
import { useProcessesStore } from "@/_store/endpoint-security/processes.module";
import type { EdrAction } from "@/constants/edr";
import kebabCase from "lodash/kebabCase";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    preview: {
      type: Object as PropType<ProcessItem | null>,
      default: null,
    },
  },
  setup(props) {
    const dialogsStore = useDialogsStore();
    const processesStore = useProcessesStore();
    const router = useRouter();
    const {
      detailsLoading,
      details,
      knownPaths,
      aliases,
      hasMorePaths,
      hasMoreAliases,
      openTickets,
    } = storeToRefs(processesStore);
    const { applyProcessAction, getKnownPaths, getAliases, getDetails } = processesStore;
    let pathsPage = 0;
    let aliasesPage = 0;

    const hideDevicesTableLink = computed(() => {
      return isAccessRestricted(RolePermissionsScope.VIEWS, "devicesView");
    });

    const viewDevicesFromDetails = () => {
      const search = `processHash:"${details.value.processHash}"`;
      router.push({ name: "devices", query: { search } });
    };

    const viewFirstSeenDevice = () => {
      const search = details.value.firstSeenOnEnrollmentCode;
      router.push({ name: "devices", query: { search } });
    };

    const viewDevicesFromPaths = (item: PathItem) => {
      let search =
        item.deviceCount > 1 ? `processHash:"${details.value.processHash}"` : item.enrollmentCode;
      if (item.deviceCount > 1) {
        search += ` processPath:"${item.path}"`;
      }
      router.push({ name: "devices", query: { search } });
    };

    const viewDevicesFromAliases = (item: AliasItem) => {
      let search =
        item.deviceCount > 1 ? `processHash:"${details.value.processHash}"` : item.enrollmentCode;
      if (item.deviceCount > 1) {
        search += ` processName:"${item.alias}"`;
      }
      router.push({ name: "devices", query: { search } });
    };

    const viewTelemetry = () => {
      router.push({
        name: RouteName.EDR_TELEMETRY_TAB,
        query: { processHash: details.value.processHash },
      });
    };

    const viewOpenTickets = () => {
      router.push({
        name: "tickets",
        query: { search: `process-hash:${details.value.processHash}` },
      });
    };

    async function onActionClick(action: EdrAction, details: Partial<ProcessDetails>) {
      const item = {
        action,
        item: details,
        selection: {
          isAllPages: false,
          include: [details],
          exclude: [],
        },
      };

      const disable = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.EDR
      );

      dialogsStore.openDialog({
        ...confirmationDialogsConfigConstructor({
          action,
          disable,
          item,
          text: i18n.global.t(`modals.${action}.description`, { ...details }),
          callback: () => applyProcessAction(item),
        }),
      });
    }

    const handlePathsVirtualScroll = async (
      isIntersecting: boolean,
      entries: IntersectionObserverEntry[]
    ) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === (knownPaths.value ?? []).length - 1) {
        if (!hasMorePaths.value) {
          return;
        }
        pathsPage++;
        await getKnownPaths({
          processId: details.value.id,
          page: pathsPage,
          virtualScroll: true,
        });
      }
    };

    const handleAliasesVirtualScroll = async (
      isIntersecting: boolean,
      entries: IntersectionObserverEntry[]
    ) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === (aliases.value ?? []).length - 1) {
        if (!hasMoreAliases.value) {
          return;
        }
        aliasesPage++;
        await getAliases({
          processId: details.value.id,
          page: aliasesPage,
          virtualScroll: true,
        });
      }
    };

    watch(
      () => props.preview,
      async (preview) => {
        if (preview) {
          await getDetails(preview.id);
        }
      },
      { immediate: true, deep: true }
    );

    return {
      onActionClick,
      getFormattedDateTime,
      detailsLoading,
      kebabCase,
      details,
      hideDevicesTableLink,
      viewDevicesFromDetails,
      viewDevicesFromPaths,
      viewDevicesFromAliases,
      viewTelemetry,
      handlePathsVirtualScroll,
      handleAliasesVirtualScroll,
      knownPaths,
      aliases,
      openTickets,
      viewOpenTickets,
      viewFirstSeenDevice,
    };
  },
});
</script>

<style lang="scss">
.edr-details-list {
  border: 1px solid rgb(var(--v-theme-gray-light));
  max-height: 300px;
  overflow: auto;
}

.edr-details-list--item {
  height: 78px;
}

.edr-details-list--item:not(:last-child) {
  border-bottom: 1px solid rgb(var(--v-theme-gray-light));
}

.vulnerability-col {
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.number-col {
  min-width: 80px;
  padding-left: 0 !important;
  color: rgb(var(--v-theme-gray-dark)) !important;
}

.red-circle {
  background-color: rgb(var(--v-theme-red-dark));
  border-color: rgb(var(--v-theme-red-dark));
}

.open-tickets-table {
  td {
    border: 0 !important;
    height: 46px !important;
  }
}
</style>
