export enum WebsocketRawMessage {
  USERS_UPDATED = "usersUpdated",
  TICKETS_UPDATED = "eventsUpdated",
  GROUPS_UPDATED = "groupsUpdated", // we have it, but it's not handled on the UI.
  SUBSCRIPTION_UPDATED = "subscriptionUpdated",
}

export enum WebsocketMessageCode {
  SHELL_COMMAND_RESPONSE = "shellCommandResponse",
  SHELL_SESSION_OPENED = "shellSessionOpened",
  SHELL_SESSION_CLOSED = "shellSessionClosed",
  SHELL_COMMAND_CONFIRMATION_REQUEST = "shellCommandConfirmationRequest",
  SHELL_SESSION_OPENING_FAILED = "shellSessionOpeningFailed",
  TOO_MANY_SHELL_SESSIONS_OPENED = "tooManyShellSessionsOpened",
  IMPORT_USERS_FROM_CSV = "importUsersFromCsv",
  IMPORT_ALLOW_BLOCK_LIST = "importAllowBlockListFromCsv",
  OPERATOR_EXPORT_ADDED = "operatorExportCreated",
}
