import { computed, onMounted, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import {
  useScheduledReportsStore,
  ScheduledReportScope,
  ScheduledReportFrequency,
  type ScheduledReportsListItem,
  type BaseScheduledReportItem,
} from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import { WorkspaceType } from "@/constants/workplaces";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions";
import {
  MspPortalScopeSection,
  MspRolePermissionScopes,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import { isAccessRestricted } from "@/_helpers/utils";
import type { VuetifyFormRef } from "@/types";
import { useAccountStore } from "@/_store/account.module";
import type { AdminUser } from "@/_store/admin-users.module";

export enum RecipientsChoices {
  ALL_ADMIN_USERS = "allAdminUsers",
  SPECIFIC_ADMIN_USERS = "specificAdminUsers",
}

export function useScheduledReportModal<
  T extends BaseScheduledReportItem = ScheduledReportsListItem,
>(item: T, defaultItem: T, isEditMode: boolean) {
  const valid = ref(true);
  const adminUsersLoading = ref(false);
  const { searchSpecificAdministrators } = useScheduledReportsStore();
  const adminUsers = ref<AdminUser[]>([]);
  const form = ref<VuetifyFormRef>();
  const isChannelWorkspace = useAccountStore().account.workspaceType === WorkspaceType.CHANNEL;
  const recipientsChoice = ref<RecipientsChoices>(RecipientsChoices.ALL_ADMIN_USERS);
  const localValue = ref<T>(cloneDeep(item ?? defaultItem));

  watch(recipientsChoice, (value) => {
    if (value === RecipientsChoices.SPECIFIC_ADMIN_USERS) {
      onAdminUserSearch();
    } else {
      localValue.value.recipients = [];
    }
  });

  watch(
    () => localValue.value.scope,
    () => {
      if (recipientsChoice.value === RecipientsChoices.SPECIFIC_ADMIN_USERS) {
        localValue.value.recipients = [];
        onAdminUserSearch();
      }
    }
  );

  const validate = async () => {
    const validationResult = await form.value?.validate();
    return validationResult?.valid;
  };

  const adminUsersSearchNotAllowed = computed(() => {
    if (
      localValue.value.scope &&
      [
        ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS,
        ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS,
      ].includes(localValue.value.scope)
    ) {
      return isMspAccessRestricted(
        MspRolePermissionScopes.MSP_PORTAL_SCOPE,
        MspPortalScopeSection.MSP_ADMIN_USERS
      );
    } else {
      return isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ADMIN_USERS
      );
    }
  });

  const onAdminUserSearch = debounce(async function (value: string = "") {
    if (adminUsersSearchNotAllowed.value) return;
    adminUsersLoading.value = true;
    adminUsers.value = await searchSpecificAdministrators(value, localValue.value.scope);
    adminUsersLoading.value = false;
  }, 500);

  onMounted(() => {
    if (isChannelWorkspace) {
      localValue.value.scope ??= ScheduledReportScope.WORKSPACE;
    }
    if (isEditMode) {
      recipientsChoice.value = item.recipients.length
        ? RecipientsChoices.SPECIFIC_ADMIN_USERS
        : RecipientsChoices.ALL_ADMIN_USERS;
    }
  });

  return {
    frequencies: [
      ScheduledReportFrequency.DAILY,
      ScheduledReportFrequency.WEEKLY,
      ScheduledReportFrequency.MONTHLY,
    ],
    scopes: [
      ScheduledReportScope.WORKSPACE,
      ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS,
      ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS,
    ],
    valid,
    form,
    recipientsChoice,
    RecipientsChoices,
    isChannelWorkspace,
    localValue,
    adminUsers,
    onAdminUserSearch,
    validate,
    adminUsersLoading,
  };
}
