<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
      class="skeleton-loader--users-settings"
    >
      <div class="mb-3">
        <v-breadcrumbs :items="breadcrumbsItems" />
      </div>
      <template v-if="showTabs">
        <div class="d-flex justify-space-between align-center w-100 mb-3">
          <div class="d-flex align-center">
            <span data-testid="users-page-users-heading" class="headline5">{{
              $t("usersSettings.title")
            }}</span>
            <v-chip data-testid="users-page-users-count" class="ml-2 chip--counter">
              {{ protectedTotalUsers }}
              <span class="users-page-excluded-count ml-1" data-testid="users-page-excluded-count">
                ({{ excludedTotalUsers }} {{ $t("usersSettings.excluded") }})
              </span>
            </v-chip>
          </div>

          <v-btn
            v-if="currentTab === usersTabs.EXCEPTIONS"
            class="add-btn"
            data-testid="users-page-add-exceptions-btn"
            color="primary"
            rounded
            size="large"
            @click="
              openModal({
                action: exceptionsActions.ADD_NEW_EXCEPTION,
                disable: actionNotAllowed,
              })
            "
          >
            <v-icon color="white" class="mr-2" icon="$add" />
            <span>{{ $t("usersSettings.addExceptionsBtn") }}</span>
          </v-btn>

          <v-menu v-else offset-y>
            <template v-slot:activator="{ props }">
              <div v-if="currentTab === usersTabs.USERS" class="d-flex">
                <div @click="exportCsv" class="coro-link item-clickable d-flex align-center mr-4">
                  <v-icon icon="$export" />
                  <span>{{ $t("general.exportCsv") }}</span>
                </div>
                <v-btn
                  class="add-btn"
                  data-testid="users-page-add-users-btn"
                  color="primary"
                  rounded
                  size="large"
                  v-bind="props"
                >
                  <v-icon color="white" class="mr-2" icon="$add" />
                  <span>{{ $t("usersSettings.addUsersBtn") }}</span>
                </v-btn>
              </div>
              <v-btn
                v-else
                class="add-btn"
                data-testid="users-page-add-groups-btn"
                color="primary"
                rounded
                size="large"
                v-bind="props"
              >
                <v-icon color="white" class="mr-2" icon="$add" />
                <span>{{ $t("usersSettings.addGroupsBtn") }}</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="currentTab === usersTabs.USERS"
                data-testid="users-page-add-users-action"
                @click="
                  openModal({
                    action: userActions.ADD_USERS,
                    disable: actionNotAllowed,
                  })
                "
              >
                <v-list-item-title>
                  {{ $t("usersSettings.actions.addUsers") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="currentTab === usersTabs.USERS"
                data-testid="users-page-import-users-action"
                @click="
                  openModal({
                    action: userActions.IMPORT_USERS_FROM_CSV,
                    disable: actionNotAllowed,
                  })
                "
              >
                <v-list-item-title>
                  {{ $t("usersSettings.actions.importUsersFromCsv") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                data-testid="users-page-add-groups-action"
                @click="
                  openModal({
                    action: userGroupActions.ADD_NEW_GROUP,
                    disable: actionNotAllowed,
                  })
                "
              >
                <v-list-item-title>
                  {{ $t("usersSettings.actions.addGroups") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                data-testid="users-page-add-all-users-action"
                @click="
                  openModal({
                    action: userActions.ADD_ALL_USERS,
                    disable: actionNotAllowed,
                  })
                "
              >
                <v-list-item-title>
                  {{ $t("usersSettings.actions.addAllUsers") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="w-100 mt-3 mb-3">
          <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs d-flex align-center">
          </page-tabs>
          <template v-if="currentTab === usersTabs.USERS">
            <filter-wrapper
              class="mt-4 mb-2 justify-end"
              :show-clear-button="showClearFiltersButton"
              @clear-filters-clicked="clearFilters()"
            >
              <div
                class="grid-container"
                :class="{
                  'grid-container--users-tab-4-filters': shouldShowStatus,
                  'grid-container--users-tab-3-filters': !shouldShowStatus,
                }"
              >
                <v-select
                  v-model="localFilters.joinedVia"
                  :items="filterItems"
                  class="filter-menu"
                  :class="{
                    'filter-active': localFilters.joinedVia?.length,
                  }"
                  :placeholder="$t('usersSettings.filters.protectedVia')"
                  data-testid="users-page-cloud-apps-filter"
                  density="compact"
                  variant="outlined"
                  multiple
                  rounded
                  hide-details
                  background-color="white"
                  @close="joinedViaFilterSearch = ''"
                  return-object
                >
                  <template #prepend-item>
                    <v-list-item class="pa-0">
                      <v-text-field
                        density="compact"
                        rounded
                        v-model="joinedViaFilterSearch"
                        class="mr-4 ml-4 mb-3 mt-1 search-field joined-via-search"
                        clear-icon="$x"
                        :class="{ 'filter-active': !!joinedViaFilterSearch }"
                        :placeholder="$t('general.search')"
                        prepend-inner-icon="$search"
                        @click:clear="joinedViaFilterSearch = ''"
                        clearable
                        @keydown.space="$event.stopPropagation()"
                        @mousedown="$event.stopPropagation()"
                        hide-details
                      >
                      </v-text-field>
                    </v-list-item>
                  </template>
                  <template #selection="{ index }">
                    <span v-if="index === 0" class="coro-body2">
                      {{ $t("usersSettings.filters.protectedVia") }}
                    </span>
                  </template>
                  <template #label>
                    <span v-if="localFilters.joinedVia?.length" class="filter-label">
                      {{ localFilters.joinedVia?.length }}
                    </span>
                  </template>
                  <template #item="{ item, props }">
                    <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.subheader" />
                    <v-list-item
                      v-else
                      v-bind="props"
                      class="joined-via-list-item"
                      :title="item.raw.name"
                    >
                      <template #prepend="{ isActive }">
                        <v-list-item-action start>
                          <v-checkbox-btn
                            density="compact"
                            :model-value="isActive"
                          ></v-checkbox-btn>
                        </v-list-item-action>
                      </template>
                      <template #append>
                        <span class="subtitle2">{{ item.raw.amount }}</span>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
                <v-select
                  v-model="localFilters.connectedApps"
                  :items="protectedAppsFilters"
                  bg-color="white"
                  menu-icon="icon-triangle"
                  item-value="id"
                  item-title="name"
                  :menu-props="{ maxHeight: '300' }"
                  class="label-filter filter-menu"
                  :class="{
                    'filter-active': localFilters.connectedApps?.length,
                  }"
                  :placeholder="$t('usersSettings.filters.protectedApps')"
                  data-testid="third-party-apps-page-cloud-apps-filter"
                  density="compact"
                  variant="outlined"
                  multiple
                  rounded
                  hide-details
                  background-color="white"
                  return-object
                >
                  <template #prepend-item>
                    <v-text-field
                      density="compact"
                      rounded
                      v-model="protectedAppsSearch"
                      class="mr-4 ml-4 mb-3 mt-1 search-field"
                      clear-icon="$x"
                      :class="{ 'filter-active': !!protectedAppsSearch }"
                      :placeholder="$t('general.search')"
                      prepend-inner-icon="$search"
                      clearable
                      hide-details
                    >
                    </v-text-field>
                  </template>
                  <template #selection="{ index }">
                    <span v-if="index === 0" class="body2">{{
                      $t("usersSettings.filters.protectedApps")
                    }}</span>
                  </template>
                  <template #label>
                    <span v-if="localFilters.connectedApps?.length" class="filter-label">
                      {{ localFilters.connectedApps.length }}
                    </span>
                  </template>
                  <template #item="{ item, props }">
                    <v-list-item v-bind="props" :title="$t(`services.${item.title}`)">
                      <template v-slot:prepend="{ isActive }">
                        <v-list-item-action start>
                          <v-checkbox-btn
                            density="compact"
                            :model-value="isActive"
                          ></v-checkbox-btn>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
                <v-select
                  v-if="shouldShowStatus"
                  v-model="localFilters.status"
                  :items="Object.values(UserSettingsAppStatus)"
                  :menu-props="{ maxHeight: '300' }"
                  class="status-filter fit"
                  :class="{ 'filter-active': !!localFilters.status }"
                  :placeholder="$t('usersSettings.filters.status')"
                  density="compact"
                  item-value="name"
                  item-title="name"
                  rounded
                  hide-details
                  background-color="white"
                >
                  <template #selection="{ item }">
                    <span class="body2">
                      {{ $t(`usersSettings.usersTab.status.${item.title}`) }}
                    </span>
                  </template>
                  <template #item="{ item, props }">
                    <v-list-item
                      v-bind="props"
                      :title="$t(`usersSettings.usersTab.status.${item.title}`)"
                    >
                    </v-list-item>
                  </template>
                </v-select>
                <v-text-field
                  v-model="localFilters.searchTerm"
                  data-testid="users-page-users-search"
                  density="compact"
                  variant="outlined"
                  background-color="white"
                  rounded
                  :placeholder="$t('general.search')"
                  :class="{ 'filter-active': localFilters.searchTerm?.length }"
                  prepend-inner-icon="$search"
                  hide-details
                  clearable
                  @click:clear="localFilters.searchTerm = ''"
                  persistent-clear
                  clear-icon="$x"
                ></v-text-field>
              </div>
            </filter-wrapper>
          </template>
          <template v-else-if="currentTab === usersTabs.EXCEPTIONS">
            <filter-wrapper
              class="mt-4 mb-2 justify-end"
              :show-clear-button="showExceptionsClearButton"
              @clear-filters-clicked="clearExceptionFilters()"
            >
              <div class="grid-container grid-container--exclusions-tab">
                <v-select
                  v-model="exceptionsFilters.cloudServices"
                  :items="exceptionFilterItems"
                  class="filter-menu"
                  :class="{
                    'filter-active': exceptionsFilters.cloudServices?.length > 0,
                  }"
                  :placeholder="$t('usersSettings.filters.protectedVia')"
                  data-testid="users-page-cloud-apps-filter"
                  density="compact"
                  variant="outlined"
                  multiple
                  rounded
                  hide-details
                  background-color="white"
                  return-object
                  @update:modelValue="setExceptionsCloudServicesFilters($event)"
                >
                  <template #selection="{ index }">
                    <span v-if="index === 0" class="coro-body2">
                      {{ $t("usersSettings.filters.protectedVia") }}
                    </span>
                  </template>
                  <template #label>
                    <span v-if="exceptionsFilters.cloudServices?.length" class="filter-label">
                      {{ exceptionsFilters.cloudServices?.length }}
                    </span>
                  </template>
                  <template #item="{ item, props }">
                    <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.subheader" />
                    <v-divider v-else-if="'divider' in item.raw" class="mt-6 mb-6"></v-divider>
                    <v-list-item
                      v-else
                      v-bind="props"
                      :title="
                        item.raw.type === 'domain' ? item.raw.name : $t(`services.${item.raw.name}`)
                      "
                    >
                      <template v-slot:prepend="{ isActive }">
                        <v-list-item-action start>
                          <v-checkbox-btn
                            density="compact"
                            :model-value="isActive"
                          ></v-checkbox-btn>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
                <v-text-field
                  v-model="exceptionsSearchTerm"
                  data-testid="users-page-exceptions-search"
                  density="compact"
                  variant="outlined"
                  background-color="white"
                  :placeholder="$t('general.search')"
                  :class="{ 'filter-active': exceptionsSearchTerm?.length }"
                  class="search-field"
                  prepend-inner-icon="$search"
                  hide-details
                  clearable
                  persistent-clear
                  clear-icon="$x"
                ></v-text-field>
              </div>
            </filter-wrapper>
          </template>
          <template v-else>
            <filter-wrapper
              class="mt-4 mb-2 justify-end"
              :show-clear-button="showGroupsClearButton"
              @clear-filters-clicked="clearGroupsFilters()"
            >
              <div class="grid-container grid-container--groups-tab">
                <v-text-field
                  v-model="groupsSearchTerm"
                  data-testid="users-page-groups-search"
                  density="compact"
                  variant="outlined"
                  background-color="white"
                  :placeholder="$t('general.search')"
                  class="search-field"
                  prepend-inner-icon="$search"
                  hide-details
                  clearable
                  persistent-clear
                  clear-icon="$x"
                ></v-text-field>
              </div>
            </filter-wrapper>
          </template>
        </div>
        <section class="content">
          <router-view></router-view>
        </section>
      </template>
      <template v-else>
        <div class="d-flex align-center">
          <span data-testid="users-page-users-heading-empty" class="headline5">{{
            $t("usersSettings.title")
          }}</span>
          <v-chip data-testid="users-page-users-count-empty" class="ml-2 chip--counter">
            {{ protectedTotalUsers }}
          </v-chip>
        </div>

        <div class="mt-16">
          <no-users @action-clicked="openModal" />
        </div>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import FilterWrapper from "@/components/FilterWrapper.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isAddonDisabled,
  isModuleDisabled,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ExceptionsAction, UserAction, UserGroupAction } from "@/constants/users";
import { useUsersSettingsStore } from "@/_store/users-settings/users-settings.module";
import { storeToRefs } from "pinia";
import isEqual from "lodash/isEqual";
import {
  UserSettingsAppStatus,
  useUsersSettingsTabStore,
} from "@/_store/users-settings/users-tab.module";
import {
  defaultUsersExceptionsTabFilters,
  type UserExceptionsFilterItem,
  useUsersExceptionsSettingsTabStore,
} from "@/_store/users-settings/user-exceptions-tab.module";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import {
  defaultGroupsFilters,
  useGroupsSettingsTabStore,
} from "@/_store/users-settings/user-groups-tab.module";
import { useRoute, useRouter } from "vue-router";
import { ModalWidth } from "@/constants/modals";
import AddUsersModal from "@/components/modals/AddUsersModal.vue";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import AddAllUsersToProtectionModal from "@/components/modals/AddAllUsersToProtectionModal.vue";
import ImportCsvModal from "@/components/modals/ImportCSVModal.vue";
import NoUsers from "@/views/settings/users/NoUsers.vue";
import AddGroupModal from "@/components/modals/AddGroupModal.vue";

import { RouteName } from "@/constants/routes";
import type { EmailProxyDomain } from "@/_store/email-security/email-proxy.module";
import { Service } from "@/constants/cloud-apps";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import { debounce } from "lodash";
import { createUserSettingsJoinedViaFilterOptions } from "@/_store/users-settings/adapters";
import { createUsersFiltersWithProxyDomains } from "@/_store/cloud-apps/adapters";

const CLOUD_SERVICES = [
  Service.BOX,
  Service.DROPBOX,
  Service.G_SUITE,
  Service.OFFICE_365,
  Service.SLACK,
  Service.SALES_FORCE,
  Service.PROXY,
];

const usersTabs = {
  USERS: "users",
  GROUPS: "groups",
  EXCEPTIONS: "exceptions",
};

export default defineComponent({
  components: {
    FilterWrapper,
    PageTabs,
    NoUsers,
  },
  setup() {
    const groupsSearchTerm = ref("");
    const usersSearchTerm = ref("");
    const exceptionsSearchTerm = ref("");
    const currentTab = ref("");
    let groupsTimer: ReturnType<typeof setTimeout>;
    let exceptionsTimer: ReturnType<typeof setTimeout>;
    const proxyDomains = ref<EmailProxyDomain[]>([]);
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();
    const usersSettingsStore = useUsersSettingsStore();
    const userTabStore = useUsersSettingsTabStore();
    const userExceptionsStore = useUsersExceptionsSettingsTabStore();
    const cloudAppsStore = useCloudAppsStore();
    const userGroupsStore = useGroupsSettingsTabStore();
    const dialogsStore = useDialogsStore();
    const { init } = usersSettingsStore;
    const {
      setUsersPagination,
      getUsers,
      exportCsv,
      addUsers,
      addAllUsers,
      downloadUsersExampleCsv,
      importUsersFromCsv,
    } = userTabStore;
    const { joinedViaFilterOptions, usersTabFilters, defaultUsersTabFilters } =
      storeToRefs(userTabStore);
    const { getServices } = cloudAppsStore;
    const { getUserGroups, setGroupsPagination, setGroupsFilters, addNewGroup } = userGroupsStore;
    const { addNewException, getExceptions, setExceptionsFilters, setExceptionsPagination } =
      userExceptionsStore;
    const {
      protectedTotalUsers,
      excludedTotalUsers,
      totalGroups,
      showSkeletonLoader: showUsersSettingsSkeletonLoader,
    } = storeToRefs(usersSettingsStore);
    const { filters: exceptionsFilters } = storeToRefs(userExceptionsStore);
    const { filters: groupsFilters } = storeToRefs(userGroupsStore);
    const { showSkeletonLoader: showCloudAppsSkeletonLoader, connectedServices } =
      storeToRefs(cloudAppsStore);
    const protectedAppsSearch = ref("");
    const joinedViaFilterSearch = ref("");
    const joinedViaOptions = computed(() => {
      return createUserSettingsJoinedViaFilterOptions(
        joinedViaFilterSearch.value,
        joinedViaFilterOptions.value
      );
    });
    const exceptionFilterItems = computed(() => {
      return createUsersFiltersWithProxyDomains(proxyDomains.value);
    });

    const showExceptionsClearButton = computed(() => {
      return !isEqual(exceptionsFilters.value, defaultUsersExceptionsTabFilters);
    });

    const showGroupsClearButton = computed(() => {
      return !isEqual(groupsFilters.value, defaultGroupsFilters);
    });

    const showSkeletonLoader = computed(() => {
      return showCloudAppsSkeletonLoader.value || showUsersSettingsSkeletonLoader.value;
    });

    const showTabs = computed(() => {
      return protectedTotalUsers.value || excludedTotalUsers.value || totalGroups.value;
    });

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    const openAddAllUsersConfirmation = (service: string = connectedServices.value[0].name) => {
      const serviceName = i18n.t(`services.${service}`);
      const dialogConfig = confirmationDialogsConfigConstructor({
        item: {
          service,
        },
        action: UserAction.ADD_ALL_USERS_CONFIRMATION,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.USERS
        ),
        text: i18n.t(`modals.addAllUsersConfirmation.description`, {
          service: serviceName,
        }),
        callback: addAllUsers,
      });
      dialogConfig.header.title = i18n.t(`modals.addAllUsersConfirmation.title`, {
        service: serviceName,
      });
      dialogsStore.openDialog(dialogConfig);
    };

    const openModal = (payload: { action: string; disable: boolean }) => {
      let dialogConfig = {} as DialogConfig;
      switch (payload.action) {
        case UserGroupAction.ADD_NEW_GROUP:
          dialogConfig = componentDialogsConfigConstructor({
            action: payload.action,
            component: AddGroupModal,
            width: ModalWidth.LARGE,
            callback: addNewGroup,
            disable: payload.disable,
          });
          break;
        case ExceptionsAction.ADD_NEW_EXCEPTION:
          dialogConfig = componentDialogsConfigConstructor({
            item: {
              description: i18n.t("modals.addNewException.description"),
            },
            action: payload.action,
            component: AddUsersModal,
            width: ModalWidth.LARGE,
            callback: addNewException,
            disable: payload.disable,
          });
          break;
        case UserAction.ADD_USERS:
          dialogConfig = componentDialogsConfigConstructor({
            action: payload.action,
            component: AddUsersModal,
            width: ModalWidth.LARGE,
            callback: addUsers,
            disable: payload.disable,
          });
          break;
        case UserAction.ADD_ALL_USERS:
          if (connectedServices.value.length === 1) {
            openAddAllUsersConfirmation();
            return;
          } else {
            dialogConfig = componentDialogsConfigConstructor({
              item: {
                callback: openAddAllUsersConfirmation,
              },
              action: payload.action,
              component: AddAllUsersToProtectionModal,
              width: ModalWidth.LARGE,
              callback: () => {},
              disable: payload.disable,
              hideFooter: true,
            });
          }
          break;
        case UserAction.IMPORT_USERS_FROM_CSV:
          dialogConfig = componentDialogsConfigConstructor({
            item: {
              downloadTemplateCallback: downloadUsersExampleCsv,
              uploadCallback: importUsersFromCsv,
            },
            action: payload.action,
            component: ImportCsvModal,
            width: ModalWidth.MEDIUM,
            callback: () => {},
            disable: payload.disable,
            hideFooter: true,
          });
      }
      dialogsStore.openDialog(dialogConfig);
    };

    const clearFilters = () => {
      userTabStore.$patch({
        usersTabFilters: {
          ...defaultUsersTabFilters.value,
        },
      });
    };

    const showClearFiltersButton = computed(() => {
      return !isEqual(defaultUsersTabFilters.value, usersTabFilters.value);
    });
    const clearGroupsFilters = () => {
      groupsSearchTerm.value = "";
      setGroupsFilters({ ...defaultGroupsFilters });
      getUserGroups();
    };

    watch(
      usersTabFilters,
      debounce(async () => {
        setUsersPagination({
          page: 0,
          pageSize: 25,
        });
        await getUsers();
      }, 300),
      { deep: true }
    );

    const clearExceptionFilters = () => {
      exceptionsSearchTerm.value = "";
      setExceptionsFilters({ ...defaultUsersExceptionsTabFilters });
      getExceptions();
    };

    const setExceptionsCloudServicesFilters = (cloudServices: Array<UserExceptionsFilterItem>) => {
      setExceptionsFilters({ cloudServices });
      setExceptionsPagination({
        page: 0,
        pageSize: 25,
      });
      getExceptions();
    };

    onMounted(async () => {
      if (route.query.connectedApps) {
        usersTabFilters.value = {
          ...usersTabFilters.value,
          connectedApps: [route.query.connectedApps] as Service[],
        };
        router.replace({}).catch(() => {});
      } else {
        await getServices();
      }

      init();
      if (!isAddonDisabled(SubscriptionAddon.INBOUND_GATEWAY)) {
        proxyDomains.value = await userTabStore.getProxyDomains();
      }
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get("openImportModal") === "true") {
        openModal({
          action: UserAction.IMPORT_USERS_FROM_CSV,
          disable: actionNotAllowed.value,
        });
        router.replace({}).catch(() => {});
        return;
      }
    });

    watch(groupsSearchTerm, (newVal: string) => {
      clearTimeout(groupsTimer);
      groupsTimer = setTimeout(() => {
        setGroupsFilters({
          searchTerm: newVal,
        });
        setGroupsPagination({
          page: 0,
          pageSize: 25,
        });
        getUserGroups();
      }, 500);
    });

    watch(exceptionsSearchTerm, (newVal: string) => {
      clearTimeout(exceptionsTimer);
      exceptionsTimer = setTimeout(() => {
        setExceptionsFilters({
          searchTerm: newVal,
          cloudServices: [],
        });
        setExceptionsPagination({
          page: 0,
          pageSize: 25,
        });
        getExceptions();
      }, 500);
    });

    watch(
      () => route.name,
      (routeName) => {
        if (routeName === RouteName.USERS_SETTINGS_USERS_TAB) {
          currentTab.value = usersTabs.USERS;
        } else if (routeName === RouteName.USERS_SETTINGS_EXCEPTIONS_TAB) {
          currentTab.value = usersTabs.EXCEPTIONS;
        } else {
          currentTab.value = usersTabs.GROUPS;
        }
        usersSearchTerm.value = "";
        groupsSearchTerm.value = "";
        exceptionsSearchTerm.value = "";
      },
      { immediate: true }
    );

    const protectedAppsFilters = computed(() =>
      CLOUD_SERVICES.filter((item) =>
        i18n
          .t(`services.${item}`)
          .toLowerCase()
          .includes(protectedAppsSearch.value.trim().toLowerCase())
      )
    );

    const shouldShowStatus = computed(() => !isModuleDisabled(SubscriptionModule.CLOUD_SECURITY));

    return {
      shouldShowStatus,
      localFilters: usersTabFilters,
      coronetSkeletonLoaderTypes,
      groupsSearchTerm,
      usersSearchTerm,
      exceptionsSearchTerm,
      showClearFiltersButton,
      protectedAppsFilters,
      clearFilters,
      filterItems: joinedViaOptions,
      exceptionFilterItems,
      joinedViaFilterSearch,
      userActions: UserAction,
      userGroupActions: UserGroupAction,
      usersTabs,
      exceptionsActions: ExceptionsAction,
      protectedTotalUsers,
      excludedTotalUsers,
      totalGroups,
      protectedAppsSearch,
      showExceptionsClearButton,
      showSkeletonLoader,
      showTabs,
      UserSettingsAppStatus,
      actionNotAllowed,
      showGroupsClearButton,
      openModal,
      clearExceptionFilters,
      setExceptionsCloudServicesFilters,
      addAllUsers,
      clearGroupsFilters,
      exportCsv,
      currentTab,
      exceptionsFilters,
      breadcrumbsItems: [
        {
          title: `${i18n.t("general.backToControlPanel")}`,
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      tabs: [
        {
          id: 1,
          name: i18n.t("usersSettings.tabs.users"),
          route: { name: RouteName.USERS_SETTINGS_USERS_TAB },
        },
        {
          id: 2,
          name: i18n.t("usersSettings.tabs.groups"),
          route: { name: RouteName.USERS_SETTINGS_GROUPS_TAB },
        },
        {
          id: 3,
          name: i18n.t("usersSettings.tabs.exceptions"),
          route: { name: RouteName.USERS_SETTINGS_EXCEPTIONS_TAB },
        },
      ] as CoroTab[],
    };
  },
});
</script>

<style lang="scss" scoped>
.users-page-excluded-count {
  font-size: 14px;
}

.joined-via-search {
  width: 380px;
}

.icon-export:before {
  font-size: 16px;
  color: rgb(var(--v-theme-anchor-base)) !important;
}
:deep(*) {
  .filter-wrapper__content {
    flex-grow: 0 !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(160px, 200px) minmax(160px, 240px) minmax(120px, 160px) minmax(
      160px,
      300px
    );
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;

  &.grid-container--users-tab-3-filters {
    grid-template-columns: minmax(160px, 200px) minmax(160px, 240px) minmax(160px, 300px);
  }

  &.grid-container--users-tab-4-filters {
    grid-template-columns: minmax(160px, 200px) minmax(160px, 240px) minmax(120px, 160px) minmax(
        160px,
        300px
      );
  }

  &.grid-container--groups-tab {
    grid-template-columns: minmax(160px, 300px);
  }

  &.grid-container--exclusions-tab {
    grid-template-columns: minmax(160px, 200px) minmax(160px, 300px);
  }
}
</style>
