<template>
  <div class="d-flex align-center">
    <v-checkbox-btn
      density="compact"
      :model-value="checkboxModel"
      :indeterminate="isIndeterminate"
      @click.prevent="handleCheckboxClick()"
    ></v-checkbox-btn>
    <v-menu v-if="showMenu">
      <template v-slot:activator="{ props }">
        <v-icon icon="$triangle" v-bind="props" size="9" class="item-clickable" />
      </template>
      <v-list>
        <v-list-item v-for="action in actions" :key="action" @click="handleSelectionChange(action)">
          <v-list-item-title>{{ $t(`selection.${action}`) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { SelectorValue } from "@/constants/selector.ts";
import { intersectionWith } from "lodash";
import isEqual from "lodash/isEqual";
import { type ISelection } from "@/_store/selector.module.ts";

export default defineComponent({
  props: {
    isIndeterminate: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Object as PropType<ISelection>,
      required: true,
    },
  },
  emits: ["selection-changed"],
  setup(props, { emit }) {
    const actions = [SelectorValue.SELECT_ALL_PAGES, SelectorValue.DESELECT_ALL_PAGES];

    const shouldUnselect = computed(() => {
      if (props.selection.isAllPages) {
        return intersectionWith(props.selection.exclude, props.items, isEqual).length === 0;
      } else {
        return intersectionWith(props.selection.include, props.items, isEqual).length > 0;
      }
    });

    const checkboxModel = computed(() => {
      return props.selection.isAllPages || props.isIndeterminate;
    });

    const handleSelectionChange = (action: SelectorValue) => {
      emit("selection-changed", {
        selectorValue: action,
        items: props.items,
      });
    };

    const handleCheckboxClick = () => {
      emit("selection-changed", {
        selectorValue: shouldUnselect.value
          ? SelectorValue.DESELECT_ITEMS
          : SelectorValue.SELECT_ITEMS,
        items: props.items,
      });
    };

    return {
      handleSelectionChange,
      handleCheckboxClick,
      checkboxModel,
      SelectorValue,
      shouldUnselect,
      actions,
    };
  },
});
</script>

<style scoped lang="scss"></style>
