<template>
  <div class="d-flex justify-space-between align-start mb-10">
    <img alt="Logo" :src="logoPath" width="56" />
    <div class="d-flex flex-column text-right">
      <div
        class="body3 text--semitransparent"
        data-testid="executive-summary-report-general-info-generated-on"
      >
        {{ $t("reports.executiveSummaryReport.generatedOn") }} {{ generatedOn }}
      </div>
      <div class="body3 text--semitransparent">
        {{ $t("reports.executiveSummaryReport.version") }} {{ version }}
      </div>
    </div>
  </div>
  <v-chip
    class="px-2 py-1 mb-3"
    label
    rounded="3"
    color="indigo-faint"
    variant="flat"
    density="compact"
    data-testid="executive-summary-report-general-info-workspace-chip"
  >
    <span class="coro-label text-uppercase">
      {{ workplace }}
    </span>
  </v-chip>
  <div class="mb-14">
    <div class="headline5 mb-1">
      {{ $t("reports.executiveSummaryReport.executiveSummary") }}
    </div>
    <div class="body2 mb-2" data-testid="executive-summary-report-general-info-date-range">
      {{ selectedDateRange }}
    </div>
  </div>
  <div class="d-flex report-general-info__columns mb-14">
    <div class="flex-grow-1" data-testid="executive-summary-report-general-info-users-column">
      <div class="d-flex flex-column">
        <div class="big-number mb-2 text-primary">
          {{ reportData.users.total }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.executiveSummaryReport.users.total") }}
        </div>
        <div class="d-flex justify-space-between text-primary py-2">
          <div class="body2">
            {{ $t("reports.executiveSummaryReport.users.protected") }}
          </div>
          <div
            class="body2"
            data-testid="executive-summary-report-general-info-users-column-protected"
          >
            {{ reportData.users.protected }}
          </div>
        </div>
        <v-divider class="border-opacity-100" color="indigo-faint" />
        <div class="d-flex justify-space-between text-primary py-2">
          <div class="body2">
            {{ $t("reports.executiveSummaryReport.users.unprotected") }}
          </div>
          <div
            class="body2"
            data-testid="executive-summary-report-general-info-users-column-unprotected"
          >
            {{ reportData.users.unProtected }}
          </div>
        </div>
        <v-divider class="border-opacity-100" color="indigo-faint" />
      </div>
    </div>

    <div class="flex-grow-1" data-testid="executive-summary-report-general-info-devices-column">
      <div class="d-flex flex-column">
        <div class="big-number mb-2 text-primary">
          {{ reportData.devices.total }}
        </div>
        <div class="d-flex">
          <div class="subtitle2 mb-2">
            {{ $t("reports.executiveSummaryReport.devices.total") }}
          </div>
          <div
            v-if="reportData.devices.totalOffline"
            class="ml-2 body2 text-indigo-medium"
            data-testid="executive-summary-report-general-info-devices-column-total-offline"
          >
            ({{
              $t("reports.executiveSummaryReport.devices.totalOffline", {
                n: reportData.devices.totalOffline,
              })
            }})
          </div>
        </div>
        <template v-for="(count, osName) in reportData.devices.types" :key="osName">
          <div
            class="d-flex justify-space-between text-primary py-2 executive-summary-report-general-info-devices-column-windows"
            :data-testid="`executive-summary-report-general-info-devices-column-${osName}-device-info`"
          >
            <div class="body2">
              {{ $t(`general.osVersion.${osName.toLowerCase()}`) }}
            </div>
            <div class="body2">
              {{ count }}
              <span
                v-if="reportData.devices.outdatedDevices?.[osName]"
                class="caption text-indigo-medium"
                :data-testid="`executive-summary-report-general-info-devices-column-${osName}-outdated-device`"
              >
                ({{
                  $t("reports.executiveSummaryReport.devices.outdated", {
                    n: reportData.devices.outdatedDevices[osName],
                  })
                }})
              </span>
            </div>
          </div>
          <v-divider class="border-opacity-100" color="indigo-faint" />
        </template>
      </div>
    </div>

    <div class="flex-grow-1" data-testid="executive-summary-report-general-info-tickets-column">
      <div class="d-flex flex-column">
        <div
          class="big-number mb-2 text-primary"
          data-testid="executive-summary-report-general-info-tickets-column-total"
        >
          {{ reportData.tickets.total }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.executiveSummaryReport.tickets.total") }}
        </div>

        <div class="d-flex justify-space-between text-primary py-2">
          <div class="body2">
            {{ $t("reports.executiveSummaryReport.tickets.unprocessed") }}
          </div>
          <div
            class="body2"
            data-testid="executive-summary-report-general-info-tickets-column-unprocessed"
          >
            {{ reportData.tickets.unProcessed }}
          </div>
        </div>
        <v-divider class="border-opacity-100" color="indigo-faint" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ExecutiveSummaryReportData } from "@/_store/reports.module";
import { computed } from "vue";
import moment from "moment";

const props = defineProps<{
  workplace: string;
  reportData: ExecutiveSummaryReportData;
  selectedDateRange: string;
  logoPath: string;
  version: string;
}>();
const generatedOn = computed(() => moment(props.version, "YYYYMMDD_HHmmss").format("L LT"));
</script>

<style scoped lang="scss">
.report-general-info {
  &__columns {
    gap: 56px;
  }
}
</style>
