<template>
  <div class="content-md margin-auto mt-4">
    <div class="d-flex align-center justify-end mb-4">
      <date-range-picker
        v-model="localFilters.reportTimeFrame"
        :preset-dates="presets"
        :class="{
          'filter-active': localFilters.reportTimeFrame.start && localFilters.reportTimeFrame.end,
        }"
        :placeholder="$t('reports.executiveSummaryReport.dateRange')"
        class="date-range-picker"
      ></date-range-picker>
    </div>
    <v-card class="pa-12" v-if="loading || showSkeletonLoader">
      <v-skeleton-loader color="transparent" type="heading,list-item-three-line" />
      <v-row>
        <v-col v-for="item in 3" :key="item" cols="4">
          <v-skeleton-loader color="transparent" type="avatar, list-item-two-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader
        color="transparent"
        type="heading,list-item-three-line, article, article"
      />
      <v-skeleton-loader class="mx-3 rounded-lg" color="transparent" type="image" />
    </v-card>
    <v-card class="pa-12 mt-1" v-else-if="securityAwarenessReport" id="report-card">
      <div class="d-flex justify-space-between align-start">
        <v-chip
          class="px-2 py-1 mb-3"
          label
          rounded="3"
          color="indigo-faint"
          variant="flat"
          density="compact"
        >
          <span class="coro-label text-uppercase">
            {{ account.customerName }}
          </span>
        </v-chip>
        <img alt="Logo" :src="logoPath" width="56" />
      </div>
      <div class="mb-14">
        <div class="headline5">
          {{ $t("reports.securityAwarenessReport.title") }}
        </div>
        <div class="body2 mb-2">
          {{ formattedSelectedDateRange }}
        </div>
        <div class="body3 text--semitransparent mb-2">
          {{ $t("reports.executiveSummaryReport.generatedOn") }} {{ generatedOn }}
        </div>
        <div class="body3 text--semitransparent">
          {{ $t("reports.executiveSummaryReport.version") }} {{ reportVersion }}
        </div>
      </div>

      <div class="es-report__section-title mb-6">
        {{ $t("reports.securityAwarenessReport.securityTrainingSummary") }}
      </div>

      <div class="d-flex">
        <div class="doughnut-chart-wrapper">
          <doughnut-chart class="mb-8 html2pdf__page-break" :data="chartData" :options="options" />
        </div>
        <div class="ml-4 w-100 d-flex flex-column justify-center">
          <div class="d-flex justify-space-between">
            <div>
              <div class="inner-circle green-circle mr-2" />
              <span>{{ $t("reports.securityAwarenessReport.passed") }}</span>
            </div>
            <div>
              <span class="subtitle2"
                >{{
                  getPercentage(securityAwarenessReport.totalSent, summaryData.completed)
                }}%</span
              >
              ({{ summaryData.completed }})
            </div>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
          <div class="d-flex justify-space-between">
            <div>
              <div class="inner-circle grey-circle mr-2" />
              <span>{{ $t("reports.securityAwarenessReport.noAction") }}</span>
            </div>
            <div>
              <span class="subtitle2"
                >{{ getPercentage(securityAwarenessReport.totalSent, summaryData.toDo) }}%</span
              >
              ({{ summaryData.toDo }})
            </div>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
          <div class="d-flex justify-space-between">
            <div>
              <div class="inner-circle red-circle mr-2" />
              <span>{{ $t("reports.securityAwarenessReport.failed") }}</span>
            </div>
            <div>
              <span class="subtitle2"
                >{{ getPercentage(securityAwarenessReport.totalSent, summaryData.overDue) }}%</span
              >
              ({{ summaryData.overDue }})
            </div>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>
      <div class="es-report__section-title mb-6 mt-8">
        {{ $t("reports.securityAwarenessReport.trainingEngagement") }}
      </div>

      <div class="d-flex flex-row">
        <div class="v-col-6">
          <div
            class="subtitle2 mb-4"
            v-html="$t('reports.securityAwarenessReport.trainingStatus')"
          ></div>
          <div
            v-for="(training, index) in trainingsListSliced"
            :key="index"
            :class="{ 'mb-4': index !== trainingEngagementData.trainingStatus.length }"
          >
            <span>{{ training.courseName }}</span>
            <div class="d-flex align-center">
              <coro-progress
                :value="training.percentage"
                :height="4"
                color="rgb(var(--v-theme-indigo-light))"
              >
              </coro-progress>
              <span class="ml-3">{{ training.percentage }}%</span>
            </div>
          </div>
          <span
            class="d-inline-flex align-center link-with-icon"
            data-html2canvas-ignore
            v-if="trainingEngagementData.trainingStatus.length > 5"
          >
            <span
              class="coro-link view-all-button"
              @click="showAllTrainingsClicked = !showAllTrainingsClicked"
            >
              {{ showAllTrainingsClicked ? $t("general.viewLess") : $t("general.viewAll") }}
              <v-icon
                class="icon-link"
                icon="$chevron"
                :class="{ rotated: showAllTrainingsClicked }"
              ></v-icon>
            </span>
          </span>
        </div>
        <v-divider :vertical="true"></v-divider>
        <div class="v-col-6">
          <div
            class="subtitle2 mb-4"
            v-html="$t('reports.securityAwarenessReport.overdueTraining')"
          ></div>
          <div v-for="(user, index) in usersListSliced" class="page-break-avoid" :key="user.userId">
            <div class="d-flex justify-space-between mb-4">
              <span>{{ user.userId }}</span>
              <span>{{ user.offenses }}</span>
            </div>
            <v-divider v-if="index !== usersListSliced.length - 1" class="mb-4"></v-divider>
          </div>
          <span
            class="d-inline-flex align-center link-with-icon"
            data-html2canvas-ignore
            v-if="trainingEngagementData.overdueCourses.length > 5"
          >
            <span class="coro-link" @click="showAllUsersClicked = !showAllUsersClicked">
              {{ showAllUsersClicked ? $t("general.viewLess") : $t("general.viewAll") }}
              <v-icon
                class="icon-link"
                icon="$chevron"
                :class="{ rotated: showAllUsersClicked }"
              ></v-icon>
            </span>
          </span>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>
      <div class="es-report__section-title mb-6 mt-8">
        {{ $t("reports.securityAwarenessReport.listOfUsers") }}
      </div>

      <div class="d-flex justify-space-between" data-html2canvas-ignore>
        <div>
          <v-text-field
            v-model="userSearch"
            variant="outlined"
            class="mt-3 report-user-search"
            density="compact"
            rounded="xl"
            bg-color="indigo-faint"
            width="280"
            prepend-inner-icon="icon-search"
            :placeholder="$t('general.search')"
          ></v-text-field>
        </div>
        <div
          @click="exportCsv()"
          class="export-btn body2 coro-link item-clickable d-flex align-center mr-4"
        >
          <v-icon icon="$export" color="link" size="16" />
          <span>{{ $t("reports.securityAwarenessReport.exportAllUsers") }}</span>
        </div>
      </div>
      <v-data-table
        disable-sort
        :items="searchedUsers"
        item-value="userId"
        v-model:expanded="expanded"
        :items-per-page="-1"
        :items-length="0"
        hide-default-footer
        hide-no-data
      >
        <template #headers>
          <tr>
            <th class="w-5"></th>
            <th>
              {{ $t("reports.securityAwarenessReport.user") }}
            </th>
            <th class="pr-6">
              {{ $t("reports.securityAwarenessReport.trainingSent") }}
            </th>
            <th class="pr-6 w-20">
              {{ $t("reports.securityAwarenessReport.completed") }}
            </th>
            <th class="pr-6">
              {{ $t("reports.securityAwarenessReport.averageScore") }}
            </th>
          </tr>
        </template>
        <template #no-data></template>
        <template v-slot:body.append v-if="searchedUsers.length === 0">
          <tr>
            <td :colspan="5" class="text-center">
              {{ $t("general.noData") }}
            </td>
          </tr>
        </template>
        <template #item="{ item, toggleExpand, isExpanded, internalItem }">
          <tr>
            <td class="w-5">
              <v-icon
                class="mr-2 cursor-pointer"
                icon="$dropdown"
                color="primary"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
              />
            </td>
            <td class="subtitle2">{{ item.userId }}</td>
            <td>{{ item.total }}</td>
            <td>{{ item.completed }}</td>
            <td
              :class="{
                'text-red-dark': item.avgScore < 70,
                'text-green-dark': item.avgScore >= 70,
              }"
            >
              {{ item.avgScore }}%
            </td>
          </tr>
        </template>
        <template v-slot:expanded-row="{ item }">
          <tr v-for="training in item.trainings" :key="training.trainingName">
            <td></td>
            <td>{{ training.trainingName }}</td>
            <td></td>
            <td>
              <span
                v-if="!training.overdue"
                :class="{
                  'text-green-dark': training.completed,
                }"
                >{{ training.completed ? $t("general.yes") : $t("general.no") }}</span
              >
              <span v-else class="text-red-dark">
                {{ $t("reports.securityAwarenessReport.overdueDays", { n: training.overdue }) }}
              </span>
            </td>
            <td
              :class="{
                'text-red-dark': training.score < 70,
                'text-green-dark': training.score >= 70,
              }"
            >
              <span v-if="training.completed">{{ training.score }}%</span>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store/account.module";
import moment from "moment";
import { ReportType, useReportsStore } from "@/_store/reports.module";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { useSWGStore } from "@/_store/secure-web-gateway.module";
import { getDateRangePresets } from "@/constants/date-range-picker";
import { useReportsTab } from "@/composables/useReportsTab";
import DoughnutChart from "@/components/DoughnutChart.vue";
import { DoughnutChartColors } from "@/constants/charts.ts";
import { getPercentage } from "@/_helpers/utils.ts";
import { COLORS } from "@/constants/colors.ts";
import { useExportsStore } from "@/_store/exports.module.ts";
import type { TimeFrame } from "@/types.ts";
import CoroProgress from "@/components/CoroProgress.vue";

export default defineComponent({
  components: {
    DateRangePicker,
    DoughnutChart,
    CoroProgress,
  },
  setup() {
    const i18n = useI18n();
    const userSearch = ref("");
    const accountStore = useAccountStore();
    const reportsStore = useReportsStore();
    const swgStore = useSWGStore();
    const exportsStore = useExportsStore();
    const reportType = ref(null);
    const { account } = storeToRefs(accountStore);
    const { showSkeletonLoader, settings } = storeToRefs(swgStore);
    const { dnsSummaryReport, loading, reportVersion, securityAwarenessReport, selectedTimeFrame } =
      storeToRefs(reportsStore);
    const { localFilters, logoPath, formattedSelectedDateRange } = useReportsTab(
      ReportType.SECURITY_AWARENESS,
      366
    );
    const expanded = ref<string[]>([]);

    const showAllTrainingsClicked = ref(false);
    const showAllUsersClicked = ref(false);

    const generatedOn = computed(() =>
      moment(reportVersion.value, "YYYYMMDD_HHmmss").format("L LT")
    );

    const presets = getDateRangePresets();

    onMounted(async () => {
      await reportsStore.getSecurityAwarenessReport();
    });

    const summaryData = computed(() => securityAwarenessReport.value.securityTrainingReport);
    const trainingEngagementData = computed(
      () => securityAwarenessReport.value.trainingEngagementSummary
    );

    const exportCsv = async () => {
      exportsStore.exportSecurityTrainingReportToCsv(selectedTimeFrame?.value || ({} as TimeFrame));
    };

    const trainingsListSliced = computed(() => {
      if (showAllTrainingsClicked.value) {
        return trainingEngagementData.value.trainingStatus;
      } else {
        return trainingEngagementData.value.trainingStatus.slice(0, 5);
      }
    });

    const usersListSliced = computed(() => {
      if (showAllUsersClicked.value) {
        return trainingEngagementData.value.overdueCourses;
      } else {
        return trainingEngagementData.value.overdueCourses.slice(0, 5);
      }
    });

    const searchedUsers = computed(() => {
      return (
        trainingEngagementData.value.userDetails.filter(({ userId }) =>
          userId.includes(userSearch.value)
        ) || []
      );
    });

    const chartData = computed(() => {
      const passedPercentage = getPercentage(
        securityAwarenessReport?.value.totalSent,
        summaryData.value.completed
      );
      const failedPercentage = getPercentage(
        securityAwarenessReport?.value.totalSent,
        summaryData.value.overDue
      );
      const todoPercentage = getPercentage(
        securityAwarenessReport?.value.totalSent,
        summaryData.value.toDo
      );

      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          i18n.t("reports.securityAwarenessReport.passed"),
          i18n.t("reports.securityAwarenessReport.failed"),
          i18n.t("reports.securityAwarenessReport.noAction"),
        ],
        datasets: [
          {
            label: "",
            backgroundColor: [
              DoughnutChartColors.SUCCESS,
              DoughnutChartColors.ERROR,
              DoughnutChartColors.GRAY,
            ],
            hoverBackgroundColor: [
              DoughnutChartColors.SUCCESS,
              DoughnutChartColors.ERROR,
              DoughnutChartColors.GRAY,
            ],
            data: [passedPercentage, failedPercentage, todoPercentage],
          },
        ],
      };
    });

    const options = ref({
      cutout: "80%",
      insideTextData: {
        title: securityAwarenessReport.value.totalSent,
        subtitle: i18n.t("reports.securityAwarenessReport.trainingsSent"),
      },
      hoverOffset: 0,
    });

    watch(
      () => securityAwarenessReport.value.totalSent,
      (val) => {
        options.value.insideTextData.title = val;
      },
      { immediate: false }
    );

    const columnsLayout = {
      totalTicketsColumns: 0,
      vulnerabilitiesColumns: 7,
      topViolatorsColumns: 4,
    };

    const getProgressStyle = (percentage: number) => {
      return `width: ${Math.ceil(percentage)}%;`;
    };

    return {
      chartData,
      userSearch,
      options,
      generatedOn,
      expanded,
      exportCsv,
      searchedUsers,
      COLORS,
      getProgressStyle,
      account,
      trainingsListSliced,
      usersListSliced,
      showAllTrainingsClicked,
      showAllUsersClicked,
      trainingEngagementData,
      getPercentage,
      summaryData,
      dnsSummaryReport,
      securityAwarenessReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      reportType,
      columnsLayout,
      showSkeletonLoader,
      settings,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field__outline {
    opacity: 0;
  }
}

.range-picker {
  max-width: 280px;
}

.doughnut-chart-wrapper {
  height: 260px;
  width: 260px;
}

.date-range-picker {
  width: 280px !important;
}

.es-report {
  .range-picker {
    max-width: 280px;
  }
  &__section-title {
    border-radius: 4px;
    background: rgb(var(--v-theme-primary));
    color: rgb(var(--v-theme-white));
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    padding: 8px;
    margin-bottom: 8px;
  }
}

.icon-link::before {
  color: rgb(var(--v-theme-link));
}

.link-with-icon {
  border-bottom: 2px solid rgb(var(--v-theme-white));

  &:hover {
    border-bottom: 2px solid rgb(var(--v-theme-link));
  }

  .coro-link {
    text-decoration: none;
  }
}

.export-btn {
  text-decoration: none !important;
  .icon {
    text-decoration: none;
  }

  &:hover span {
    text-decoration: underline; /* Underline only the text */
  }
}
</style>
