import { appLogoDefaultPath, useAccountStore } from "@/_store/account.module.ts";
import { ReportType, useReportsStore } from "@/_store/reports.module";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import type { TimeFrame } from "@/types";
import moment from "moment/moment";
import { getReportsDefaultDateRangePresets } from "@/constants/reports";
import isEqual from "lodash/isEqual";

export function useReportsTab(reportType: ReportType, defaultDayPeriod: number = 30) {
  const defaultFiltersState = {
    showZeroValues: false,
    reportTimeFrame: {
      start: moment().subtract(defaultDayPeriod, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    },
  };

  const accountStore = useAccountStore();
  const reportsStore = useReportsStore();
  const { account } = storeToRefs(accountStore);
  const {
    executiveSummaryReport,
    dnsSummaryReport,
    securedMessagesSummaryReport,
    managedServicesSummaryReport,
    loading,
    reportVersion,
  } = storeToRefs(reportsStore);
  const reportCallbackMap = {
    [ReportType.EXECUTIVE_SUMMARY]: reportsStore.getExecutiveSummaryReport,
    [ReportType.SECURED_MESSAGES]: reportsStore.getSecuredMessagesSummaryReport,
    [ReportType.MANAGED_SERVICES_SUMMARY]: reportsStore.getManagedServicesSummaryReport,
    [ReportType.DNS_SUMMARY]: reportsStore.getDnsReport,
    [ReportType.SECURITY_AWARENESS]: reportsStore.getSecurityAwarenessReport,
  } as const;

  const getReportCallback = reportCallbackMap[reportType];

  const localFilters = ref<{ reportTimeFrame: TimeFrame; showZeroValues: boolean }>({
    ...defaultFiltersState,
  });

  const logoPath = computed(() => {
    return accountStore.account.appLogo === appLogoDefaultPath
      ? "/images/logos/coro-logo-vertical.svg"
      : accountStore.account.appLogo;
  });

  const formattedSelectedDateRange = computed(() => {
    const { start, end } = momentTimeFrame.value;
    return `${start.format("ll")} - ${end.format("ll")}`;
  });

  const momentTimeFrame = computed(() => {
    const { end, start } = localFilters.value.reportTimeFrame;
    const timeframeStart = start ? moment(start) : moment().subtract(defaultDayPeriod, "days");
    const timeframeEnd = end ? moment(end) : moment();
    return {
      start: timeframeStart,
      end: timeframeEnd,
    };
  });

  const showClearFiltersButton = computed(() => {
    return !isEqual(localFilters.value, defaultFiltersState);
  });

  const resetFilters = () => {
    localFilters.value = { ...defaultFiltersState };
  };

  onMounted(async () => {
    reportsStore.$patch({
      selectedTimeFrame: {
        start: momentTimeFrame.value.start.format("YYYY-MM-DD"),
        end: momentTimeFrame.value.end.format("YYYY-MM-DD"),
      },
    });
    await getReportCallback();
  });

  onUnmounted(() => {
    reportsStore.$patch({ selectedTimeFrame: undefined });
  });

  watch(
    () => localFilters.value.reportTimeFrame,
    async () => {
      reportsStore.$patch({
        selectedTimeFrame: {
          start: momentTimeFrame.value.start.format("YYYY-MM-DD"),
          end: momentTimeFrame.value.end.format("YYYY-MM-DD"),
        },
      });
      await getReportCallback();
    }
  );

  return {
    account,
    executiveSummaryReport,
    securedMessagesSummaryReport,
    managedServicesSummaryReport,
    dnsSummaryReport,
    loading,
    logoPath,
    localFilters,
    formattedSelectedDateRange,
    presets: getReportsDefaultDateRangePresets(),
    reportVersion,
    momentTimeFrame,
    showClearFiltersButton,
    resetFilters,
  };
}
