<template>
  <div class="content-md margin-auto mt-4 es-report">
    <filter-wrapper
      class="d-flex align-center mt-4 mb-4 justify-end"
      :show-clear-button="showClearFiltersButton"
      @clear-filters-clicked="resetFilters()"
    >
      <div class="grid-container">
        <v-checkbox class="ml-4" v-model="localFilters.showZeroValues" hide-details :ripple="false">
          <template #label>
            <span class="body2">Show 0 values</span>
          </template>
        </v-checkbox>
        <date-range-picker
          v-model="localFilters.reportTimeFrame"
          :preset-dates="presets"
          :placeholder="$t('reports.executiveSummaryReport.dateRange')"
          class="range-picker"
        ></date-range-picker>
      </div>
    </filter-wrapper>
    <v-card class="pa-12" v-if="loading">
      <v-skeleton-loader color="transparent" type="heading,list-item-three-line" />
      <v-row>
        <v-col v-for="item in 3" :key="item" cols="4">
          <v-skeleton-loader color="transparent" type="avatar, list-item-two-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader
        color="transparent"
        type="heading,list-item-three-line, article, article"
      />
      <v-skeleton-loader class="mx-3 rounded-lg" color="transparent" type="image" />
    </v-card>
    <v-card class="pa-12 mt-1" v-else-if="executiveSummaryReport" id="report-card">
      <executive-summary-report-general-info
        :version="reportVersion"
        :logo-path="logoPath"
        :workplace="account.customerName"
        :selected-date-range="formattedSelectedDateRange"
        :report-data="executiveSummaryReport"
      />
      <executive-summary-report-top-vulnerabilities
        :protection-settings="executiveSummaryReport.protection"
        :top-vulnerabilities="executiveSummaryReport.topVulnerabilities"
      />
      <div class="es-report__section-title page-break-avoid mb-6">
        {{ $t("reports.executiveSummaryReport.protection") }}
      </div>
      <div v-for="(data, index) in sortedProtectionData" :key="data.moduleName" class="pb-6">
        <executive-summary-report-module-overview
          :violators="data.violators"
          :vulnerabilities="data.vulnerabilities"
          :protection-module="data.moduleName"
          :total-items-processed="data.total"
          :total-items-title="$t('reports.executiveSummaryReport.ticketsGenerated')"
          :columns-layout="columnsLayout"
          :show-zero-values="localFilters.showZeroValues"
        />
        <v-divider
          v-if="index !== (sortedProtectionData ?? []).length - 1"
          class="border-opacity-100 my-10"
          color="indigo-faint"
        />
      </div>
      <executive-summary-security-awareness
        :data="executiveSummaryReport.securityAwareness"
        :show-zero-values="localFilters.showZeroValues"
      >
      </executive-summary-security-awareness>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import moment from "moment";
import ExecutiveSummaryReportGeneralInfo from "@/components/reports/ExecutiveSummaryReportGeneralInfo.vue";
import ExecutiveSummaryReportModuleOverview from "@/components/reports/ExecutiveSummaryReportModuleOverview.vue";
import { ReportType } from "@/_store/reports.module";
import { SubscriptionModule } from "@/constants/workplaces";
import DateRangePicker from "@/components/DateRangePicker.vue";
import type { TicketTrigger } from "@/constants/tickets";
import { useReportsTab } from "@/composables/useReportsTab";
import ExecutiveSummarySecurityAwareness from "@/components/reports/ExecutiveSummarySecurityAwareness.vue";
import ExecutiveSummaryReportTopVulnerabilities from "@/components/reports/ExecutiveSummaryReportTopVulnerabilities.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";

interface ExecutiveSummaryGenericReportData {
  violators: { [p: string]: number };
  vulnerabilities: { [key in TicketTrigger]: number };
  total?: number;
}

type ReportData = { moduleName: SubscriptionModule } & ExecutiveSummaryGenericReportData;

export default defineComponent({
  components: {
    FilterWrapper,
    ExecutiveSummaryReportTopVulnerabilities,
    ExecutiveSummarySecurityAwareness,
    DateRangePicker,
    ExecutiveSummaryReportModuleOverview,
    ExecutiveSummaryReportGeneralInfo,
  },
  setup() {
    const {
      account,
      executiveSummaryReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      showClearFiltersButton,
      resetFilters,
    } = useReportsTab(ReportType.EXECUTIVE_SUMMARY);

    const sortedProtectionData = computed(() => {
      const data = executiveSummaryReport?.value?.protection;
      if (!data) return;
      const sortOrder = [
        SubscriptionModule.CLOUD_SECURITY,
        SubscriptionModule.EMAIL_SECURITY,
        SubscriptionModule.ENDPOINT_SECURITY,
        SubscriptionModule.USER_DATA_GOVERNANCE,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
        SubscriptionModule.EDR,
      ];
      return Object.entries(data)
        .map(([module, moduleData]): ReportData => {
          const moduleName = module as SubscriptionModule;

          const total = Object.values(
            (moduleData as ExecutiveSummaryGenericReportData).vulnerabilities
          ).reduce((sum, num) => sum + num, 0);
          return {
            moduleName,
            total,
            ...(moduleData as ExecutiveSummaryGenericReportData),
          };
        })
        .slice()
        .sort((a, b) => {
          return sortOrder.indexOf(a.moduleName) - sortOrder.indexOf(b.moduleName);
        });
    });

    const hasVulnerabilities = (vulnerabilities: {
      [key in TicketTrigger]: number;
    }): boolean => {
      return Object.values(vulnerabilities).some((num) => Boolean(num));
    };

    const columnsLayout = {
      totalTicketsColumns: 2,
      vulnerabilitiesColumns: 6,
      topViolatorsColumns: 4,
    };

    return {
      account,
      executiveSummaryReport,
      sortedProtectionData,
      loading,
      logoPath,
      localFilters,
      showClearFiltersButton,
      resetFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      moment,
      SubscriptionModule,
      hasVulnerabilities,
      columnsLayout,
    };
  },
});
</script>

<style scoped lang="scss">
.es-report {
  &__section-title {
    border-radius: 4px;
    background: rgb(var(--v-theme-primary));
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    padding: 8px;
  }
}

:deep(*) {
  .coro-chip--default .coro-chip__content,
  .coro-chip--default.coro-chip__content {
    color: rgb(var(--v-theme-primary)) !important;
    mix-blend-mode: initial !important;
  }
  .filter-wrapper__content {
    flex-grow: 0 !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(min-content, max-content) minmax(240px, 280px);
  align-items: end;
  justify-items: end;
  gap: 16px;
  width: 100%;
}

.v-chip {
  height: 160px !important;
  border-radius: 6px;
}

.provider-selection-chip {
  max-height: 71px;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}
</style>
