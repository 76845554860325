import { defineStore } from "pinia";
interface ErrorsState {
  errors: Array<any>;
}

export interface ErrorPayload {
  message: string;
  showDialog?: boolean;
  index?: number;
  field?: string;
  customErrorHandling?: boolean;
  errors?: ErrorPayload[];
}

const defaultErrorsState: ErrorsState = {
  errors: [],
};

export const useErrorsStore = defineStore("errors", {
  state: (): ErrorsState => ({ ...defaultErrorsState }),
  persist: false,
  getters: {
    error(state) {
      return state.errors.length > 0 ? state.errors[state.errors.length - 1] : null;
    },
  },
  actions: {
    push(error: any) {
      this.errors = [...this.errors, error];
    },
    pop() {
      this.errors = this.errors.slice(0, -1);
    },
  },
});
