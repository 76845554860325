import { DeviceAction, DriveEncryptionStatus, DriveFailedReason } from "@/constants/devices.ts";
import { defineStore } from "pinia";
import api from "@/_helpers/api.ts";
import { axiosInstance } from "@/plugins/https.ts";
import { useFiltersStore } from "@/_store/filters.module.ts";
import type { Pagination } from "@/types.ts";
import { StoreModule } from "@/constants/store.ts";
import cloneDeep from "lodash/cloneDeep";
import { i18n } from "@/plugins/i18n.ts";
import { useSnackbarStore } from "@/_store";
import { type ISelection } from "@/_store/selector.module.ts";
import { getSelectionAsStringsArray } from "@/_helpers/utils.ts";

export interface DrivesState {
  pagination: Pagination;
  showSkeletonLoader: boolean;
  loading: boolean;
  totalDrives: number;
  drives: DrivesItem[];
}

export interface DrivesItem {
  id: string;
  friendlyName: string;
  name: string;
  hostname: string;
  isRemovable: boolean;
  model: string;
  status: DriveEncryptionStatus;
  serialKey: string;
  recoveryKey: string;
  lastEncryptionAttempt: number;
  enrollmentCode: string;
  workspace: string;
  failedReason: DriveFailedReason;
}

const defaultDrivesState: DrivesState = {
  pagination: {
    page: 0,
    pageSize: 25,
  },
  showSkeletonLoader: true,
  loading: true,
  totalDrives: 0,
  drives: [],
};

export const useMspDrivesStore = defineStore(StoreModule.MSP_DRIVES, {
  state: () => cloneDeep({ ...defaultDrivesState }),
  actions: {
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = { ...defaultDrivesState.pagination };
    },
    async getMspDrives(showSkeletonLoader = false) {
      this.loading = true;
      this.showSkeletonLoader = showSkeletonLoader;
      const filtersStore = useFiltersStore();
      const { statuses, types, search, childWorkspaceIds } = filtersStore.filters.mspDrives;
      const request = {
        ...api.getMspDrives(),
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          workspaceIds: childWorkspaceIds?.length ? childWorkspaceIds.join(",") : undefined,
          statuses: statuses?.length ? statuses.join(",") : undefined,
          types: types?.length ? types.join(",") : undefined,
          search,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.drives = data.items;
        this.totalDrives = data.total;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
        this.showSkeletonLoader = false;
      }
    },
    async applyDriveAction(payload: {
      action?: DeviceAction;
      item: {
        action: DeviceAction;
        selection: ISelection<DrivesItem>;
        quantity: number;
      };
    }) {
      const action = payload.action || payload.item.action;
      const quantity = payload.item.quantity;
      const snackbarMessage = i18n.global.t(
        `snackbar.messages.event.${action}`,
        { quantity },
        quantity
      );
      const actionPayload = {
        action,
        selection: getSelectionAsStringsArray(payload.item.selection, "id"),
      };

      const request = {
        ...api.mspDrivesAction,
        data: actionPayload,
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(snackbarMessage);
        await this.getMspDrives();
      } catch (e) {
        console.error(e);
      }
    },
  },
});
