<template>
  <div>
    <v-autocomplete
      v-model="localValue.item.labels"
      class="mt-4"
      :placeholder="$t('modals.editMspWorkspaceLabel.selectLabel')"
      :label="$t('modals.editMspWorkspaceLabel.selectLabel')"
      :hide-no-data="false"
      :no-filter="true"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="labels"
      variant="outlined"
      multiple
      return-object
      @update:search="onLabelSearch($event)"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          :color="item.raw.color"
          close-icon="$closeCircle"
          :text="item.title"
        >
          <div class="d-flex align-center">
            {{ item.raw.name }}
          </div>
        </v-chip>
      </template>
      <template #item="{ item, props }">
        <v-list-item v-bind="props" title="">
          <v-list-item-title title="">
            <div class="d-flex align-center label-popup-block">
              <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
              <span>
                {{ item.raw.name }}
              </span>
            </div>
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-autocomplete>
    <a class="coro-link" @click="goToLabels()" v-if="!hideManageLabelsLink">
      {{ $t("modals.addLabel.manageLabels") }}
    </a>
  </div>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { MspWorkspaceDialogItem } from "@/_store/msp/msp.module";
import { type MspLabel, useMspLabelsStore } from "@/_store/msp/msp-labels.module.ts";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes.ts";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions.ts";
import { MspPortalScopeSection, MspRolePermissionScopes } from "@/_store/roles.module.ts";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<MspWorkspaceDialogItem>>,
      required: true,
    },
  },
  emits: ["update:localValue", "dismissed"],
  setup(props, { emit }) {
    const labelsStore = useMspLabelsStore();
    const localValue = ref(cloneDeep(props.config));
    const labels = ref<MspLabel[]>([] as MspLabel[]);
    const router = useRouter();
    const getLabels = async (search: string = "") => {
      try {
        const { data } = await labelsStore.searchLabels(search);
        labels.value = uniqBy([...localValue.value.item.labels, ...data], "id");
      } catch (e) {
        console.error(e);
        labels.value = [];
      }
    };

    const hideManageLabelsLink = computed(() => {
      return isMspAccessRestricted(
        MspRolePermissionScopes.MSP_PORTAL_SCOPE,
        MspPortalScopeSection.MSP_WORKSPACE_LABELS
      );
    });

    const onLabelSearch = debounce(async (search: string) => {
      await getLabels(search);
    }, 500);

    const goToLabels = async () => {
      emit("dismissed");
      await router.push({ name: RouteName.MSP_LABELS });
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    onMounted(() => {
      getLabels();
      emit("update:localValue", localValue.value);
    });

    return {
      labels,
      localValue,
      onLabelSearch,
      goToLabels,
      hideManageLabelsLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
</style>
