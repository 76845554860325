<template>
  <div class="d-flex" v-if="isOnTrial || isOnFreeze">
    <span
      class="body2"
      v-if="isOnTrial"
      v-html="
        $t('trialCountdownNotice.trialPeriodFinishes', {
          time: getDaysUntilTrialEnds(subscription.currentPlan.planEndDate),
        })
      "
    >
    </span>
    <div class="d-flex align-center body2" v-else>
      <span v-html="$t('trialCountdownNotice.trialPeriodExpired')"></span>
      <v-tooltip content-class="frozen-description-tooltip" open-delay="300" location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" icon="$info" size="16" class="ml-2"> </v-icon>
        </template>
        <span v-html="frozenSubscriptionDescription"></span>
      </v-tooltip>
    </div>
    <div class="separator mx-3"></div>
  </div>
</template>
<script lang="ts">
import { useAccountStore } from "@/_store";
import { computed, defineComponent } from "vue";
import { useSubscriptionStore } from "@/_store/subscription.module";
import { getDaysUntilTrialEnds } from "@/_helpers/utils";
import { SubscriptionType, WorkspaceType } from "@/constants/workplaces";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup() {
    const subscriptionStore = useSubscriptionStore();
    const accountStore = useAccountStore();
    const { subscription } = storeToRefs(subscriptionStore);
    const i18n = useI18n();

    const isOnTrial = computed(() => {
      return (
        subscriptionStore.subscription.currentPlan?.subscriptionType === SubscriptionType.TRIAL
      );
    });

    const isOnFreeze = computed(() => {
      return (
        subscriptionStore.subscription.currentPlan?.subscriptionType === SubscriptionType.FREEZE
      );
    });

    const frozenSubscriptionDescription = computed(() => {
      if (accountStore.account.workspaceType === WorkspaceType.REGULAR) {
        return i18n.t("dashboard.widgets.alerts.regularSubscriptionExpiredDescription");
      }
      return i18n.t("dashboard.widgets.alerts.mspSubscriptionExpiredDescription");
    });

    return {
      subscription,
      isOnTrial,
      isOnFreeze,
      frozenSubscriptionDescription,
      getDaysUntilTrialEnds,
    };
  },
});
</script>

<style lang="scss" scoped>
.separator {
  border-left: 1px solid rgb(var(--v-theme-indigo-faint));
}

.v-tooltip :deep(.frozen-description-tooltip.v-overlay__content) {
  &::after {
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    background-color: transparent;
    margin-top: -6px;
    margin-left: -6px;
    transform: rotate(180deg);
  }
}
</style>
