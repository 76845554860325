import { defineStore } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import { AddonOverviewWidgetType, OverviewWidgetType, WidgetType } from "@/constants/dashboard";
import moment from "moment/moment";
import { isAccessRestricted, isAddonDisabled, isModuleDisabled } from "@/_helpers/utils";
import { ProxyDirection } from "@/constants/email-proxy";
import { axiosInstance } from "@/plugins/https";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { useAccountStore } from "@/_store";
import {
  addonOverviewWidgetAdapters,
  overviewWidgetAdapters,
  type OverviewWidgetConfiguration,
  sortOverviewWidgets,
  widgetAdapters,
} from "@/_store/dashboard/adapters";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import type { TicketType } from "@/constants/tickets.ts";
import type { CloudAppService } from "@/_store/cloud-apps/cloud-apps.module.ts";

export interface WidgetVulnerability {
  issue: string;
  count: number;
}

export interface WidgetData {
  activeVulnerabilities: WidgetVulnerability[];
  resolvedVulnerabilities: WidgetVulnerability[];
  [key: string]: any;
}

export const ninetyDaysAgoStartOfDay = () =>
  moment().subtract(90, "days").startOf("day").utc().valueOf();

interface WidgetRequestConfig {
  requestUrl: string;
  requestMethod: string;
  data: Record<string, any>;
}

export interface WidgetConfig {
  widgetType: WidgetType;
  widgetId: WidgetType;
  header: {
    widgetTitle: string;
    componentName: string;
  };
  content: {
    componentName: string;
  };
  dataEndpoints: WidgetRequestConfig[];
  loading: boolean;
  showSkeletonLoader: boolean;
  disabled: boolean;
  restricted?: boolean;
}

export interface SecurityAwarenessWidgetUserRecord {
  email: string;
  count: number;
}

export interface SecurityAwarenessWidgetData {
  simulationsFailed: number;
  simulationsReported: number;
  simulationsIgnored: number;
  coursesCompleted: number;
  coursesOverdue: number;
  coursesPending: number;
  adaptiveCourses: number;
  topUsersFailedPhishing: SecurityAwarenessWidgetUserRecord[];
  topUsersFailedTraining: SecurityAwarenessWidgetUserRecord[];
  activeVulnerabilities: [
    {
      issue: TicketType;
      count: number;
    },
  ];
  resolvedVulnerabilities: [
    {
      issue: TicketType;
      count: number;
    },
  ];
  services: CloudAppService[];
}

type WidgetsConfig = {
  [key in WidgetType]: { config: WidgetConfig };
};

interface DashboardState {
  widgets: WidgetsConfig;
  widgetsData: Record<string, WidgetData>;
  overviewWidgets: OverviewWidgetConfiguration[];
  addonOverviewWidgets: OverviewWidgetConfiguration[];
  loadedWidgetsCount: number;
  overviewWidgetsSkeletonLoader: boolean;
}

const baseURL = import.meta.env.VITE_API_HOST;

const defaultDashboardState: DashboardState = {
  widgets: {
    [WidgetType.CLOUD_SECURITY]: {
      config: {
        widgetType: WidgetType.CLOUD_SECURITY,
        widgetId: WidgetType.CLOUD_SECURITY,
        header: {
          widgetTitle: "dashboard.widgets.cloudSecurity.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "cloud-security-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/cloudSecurity?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
      },
    },
    [WidgetType.EMAIL_SECURITY]: {
      config: {
        widgetType: WidgetType.EMAIL_SECURITY,
        widgetId: WidgetType.EMAIL_SECURITY,
        header: {
          widgetTitle: "dashboard.widgets.emailPhishing.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "email-security-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/emails?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
          {
            requestUrl: `/settings/emailProxy/proxyStatus?direction=${ProxyDirection.INBOUND}`,
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
        restricted: false,
      },
    },
    [WidgetType.ENDPOINT_SECURITY]: {
      config: {
        widgetType: WidgetType.ENDPOINT_SECURITY,
        widgetId: WidgetType.ENDPOINT_SECURITY,
        header: {
          widgetTitle: "dashboard.widgets.devices.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "endpoint-security-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/devices?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
        restricted: false,
      },
    },
    [WidgetType.USER_DATA_GOVERNANCE]: {
      config: {
        widgetType: WidgetType.USER_DATA_GOVERNANCE,
        widgetId: WidgetType.USER_DATA_GOVERNANCE,
        header: {
          widgetTitle: "dashboard.widgets.userDataGovernance.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "user-data-governance-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/userDataGovernance?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
        restricted: false,
      },
    },
    [WidgetType.ENDPOINT_DATA_GOVERNANCE]: {
      config: {
        widgetType: WidgetType.ENDPOINT_DATA_GOVERNANCE,
        widgetId: WidgetType.ENDPOINT_DATA_GOVERNANCE,
        header: {
          widgetTitle: "dashboard.widgets.endpointDataGovernance.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "endpoint-security-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/endpointDataGovernance?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
        restricted: false,
      },
    },
    [WidgetType.EDR]: {
      config: {
        widgetType: WidgetType.EDR,
        widgetId: WidgetType.EDR,
        header: {
          widgetTitle: "dashboard.widgets.edr.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "edr-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/edr?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
        restricted: false,
      },
    },
    [WidgetType.SECURITY_AWARENESS]: {
      config: {
        widgetType: WidgetType.SECURITY_AWARENESS,
        widgetId: WidgetType.SECURITY_AWARENESS,
        header: {
          widgetTitle: "dashboard.widgets.securityAwareness.title",
          componentName: "widget-header",
        },
        content: {
          componentName: "security-awareness-widget",
        },
        dataEndpoints: [
          {
            requestUrl: `dashboard/widget/securityAwareness?resolvedFromTime=${ninetyDaysAgoStartOfDay()}`,
            requestMethod: "get",
            data: {},
          },
          {
            requestUrl: "dashboard/services",
            requestMethod: "get",
            data: {},
          },
          {
            requestUrl: "security-awareness/settings/metadata",
            requestMethod: "get",
            data: {},
          },
        ],
        loading: false,
        showSkeletonLoader: true,
        disabled: false,
      },
    },
  },
  widgetsData: {},
  overviewWidgets: [],
  addonOverviewWidgets: [],
  loadedWidgetsCount: 0,
  overviewWidgetsSkeletonLoader: false,
};

export const useDashboardStore = defineStore({
  id: "dashboard",
  state: () => cloneDeep(defaultDashboardState),
  getters: {
    enabledOverviewWidgets: (state) =>
      sortOverviewWidgets(state.overviewWidgets).filter((widget) => !widget.disabled),
    disabledOverviewWidgets: (state) =>
      sortOverviewWidgets(state.overviewWidgets).filter((widget) => widget.disabled),
    enabledAddonOverviewWidgets: (state) =>
      sortOverviewWidgets(state.addonOverviewWidgets).filter((widget) => !widget.disabled),
    disabledAddonOverviewWidgets: (state) =>
      sortOverviewWidgets(state.addonOverviewWidgets).filter((widget) => widget.disabled),
    hasNotDisabledRestrictedOverviewWidgets: (state) =>
      state.overviewWidgets.filter((widget) => !widget.disabled && !widget.restricted).length > 0,
  },
  actions: {
    async init() {
      const workspacesStore = useWorkspacesStore();
      await workspacesStore.getCurrentWorkspace();
      await this.getWidgetsData();
    },
    async getWidgetsData() {
      try {
        this.setOverviewsWidgetSkeletonLoader(true);
        const widgetDataHandlers = Object.entries(this.widgets).map(([widgetKey, widgetValue]) => {
          const widgetConfig = widgetValue.config;
          widgetConfig.disabled = isModuleDisabled(widgetKey as SubscriptionModule);
          if (widgetKey !== SubscriptionModule.SECURITY_AWARENESS) {
            widgetConfig.restricted = isAccessRestricted(
              RolePermissionsScope.TICKETS,
              widgetKey as any
            );
          }
          widgetConfig.showSkeletonLoader = true;
          return this.getWidgetData(widgetConfig);
        });
        await Promise.all(widgetDataHandlers);
        this.getAdditionalOverviewWidgets();
        this.setOverviewsWidgetSkeletonLoader(false);
      } catch (error) {
        console.error(error);
      }
    },
    async getWidgetData(widgetConfig: WidgetConfig) {
      const promisesArray: Promise<any>[] = [];
      widgetConfig.dataEndpoints.forEach((requestConfig: WidgetRequestConfig) => {
        const request = {
          url: requestConfig.requestUrl,
          baseURL,
          method: requestConfig.requestMethod,
          data: requestConfig.data,
        };
        if (!widgetConfig.disabled && !widgetConfig.restricted) {
          promisesArray.push(axiosInstance.request(request));
        }
      });

      const loaderStateOnComplete = {
        widgetId: widgetConfig.widgetId,
        isLoading: false,
      };
      return Promise.all(promisesArray)
        .then((res: { data: WidgetData }[]) => {
          const accountStore = useAccountStore();
          const skipOverviewWidget =
            accountStore.account.showDisabledModules && widgetConfig.disabled
              ? false
              : widgetConfig.restricted;
          if (skipOverviewWidget) {
            this.setWidgetSkeletonLoaderState(loaderStateOnComplete);
            return;
          }
          const emptyOverviewWidgetDataResponse = [{ data: {} as WidgetData }];
          const widgetDataAdapter = widgetAdapters[widgetConfig.widgetType];
          const overviewWidgetAdapter = overviewWidgetAdapters[widgetConfig.widgetType];
          const widgetData =
            widgetConfig.disabled || widgetConfig.restricted
              ? ({} as WidgetData)
              : widgetDataAdapter(res);
          const overviewWidgetData = {
            ...overviewWidgetAdapter(
              widgetConfig.disabled || widgetConfig.restricted
                ? emptyOverviewWidgetDataResponse
                : res,
              widgetConfig.disabled
            ),
            restricted: widgetConfig.restricted,
          };
          this.setOverviewWidget(overviewWidgetData);
          this.setWidgetData({ widgetId: widgetConfig.widgetId, data: widgetData });
          this.setWidgetLoaderState(loaderStateOnComplete);
          this.setWidgetSkeletonLoaderState(loaderStateOnComplete);
          this.setLoadedWidgetsCount(
            widgetConfig.disabled ? this.loadedWidgetsCount : this.loadedWidgetsCount + 1
          );
        })
        .catch((err) => {
          console.error(err);
          this.setWidgetLoaderState(loaderStateOnComplete);
          this.setWidgetSkeletonLoaderState(loaderStateOnComplete);
        });
    },
    getAdditionalOverviewWidgets() {
      const accountStore = useAccountStore();
      const disabledOverviewWidgetsMap = {
        [OverviewWidgetType.NETWORK]: {
          disabled: isModuleDisabled(SubscriptionModule.NETWORK),
          restricted: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.NETWORK
          ),
        },
        [OverviewWidgetType.MOBILE_DEVICE_MANAGEMENT]: {
          disabled: isModuleDisabled(SubscriptionModule.MDM),
          restricted: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.MDM),
        },
        [OverviewWidgetType.SECURITY_AWARENESS]: {
          disabled: isModuleDisabled(SubscriptionModule.SECURITY_AWARENESS),
          restricted: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.SECURITY_AWARENESS
          ),
        },
      };

      const disabledAddonOverviewWidgetsMap = {
        [AddonOverviewWidgetType.SECURED_MESSAGES]: {
          disabled: isAddonDisabled(SubscriptionAddon.SECURED_MESSAGES),
          restricted:
            isAccessRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.EMAIL_SECURITY
            ) ||
            isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SECURED_MESSAGES),
        },
        [AddonOverviewWidgetType.SECURE_WEB_GATEWAY]: {
          disabled: isAddonDisabled(SubscriptionAddon.SWG),
          restricted:
            isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.NETWORK) ||
            isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG),
        },
        [AddonOverviewWidgetType.WIFI_PHISHING]: {
          disabled: isAddonDisabled(SubscriptionAddon.WIFI_PHISHING),
          restricted:
            isAccessRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.ENDPOINT_SECURITY
            ) ||
            isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.WIFI_PHISHING),
        },
        [AddonOverviewWidgetType.INBOUND_GATEWAY]: {
          disabled: isAddonDisabled(SubscriptionAddon.INBOUND_GATEWAY),
          restricted:
            isAccessRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.EMAIL_SECURITY
            ) ||
            isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.INBOUND_GATEWAY),
        },
      };

      Object.entries(disabledOverviewWidgetsMap)
        .filter(([, { restricted, disabled }]) => {
          if (accountStore.account.showDisabledModules && disabled) {
            return true;
          }
          return !restricted;
        })
        .forEach(([overviewWidget, { disabled }]) => {
          const adapter = overviewWidgetAdapters[overviewWidget as OverviewWidgetType];
          const overviewWidgetData = adapter({} as any, disabled);
          this.setOverviewWidget(overviewWidgetData); // Direct mutation in Pinia
        });

      Object.entries(disabledAddonOverviewWidgetsMap)
        .filter(([, { restricted, disabled }]) => {
          if (accountStore.account.showDisabledModules && disabled) {
            return true;
          }
          return !restricted;
        })
        .forEach(([addonOverviewWidget, { disabled }]) => {
          const adapter =
            addonOverviewWidgetAdapters[addonOverviewWidget as AddonOverviewWidgetType];
          const addonOverviewWidgetData = adapter(disabled);
          this.setAddonOverviewWidget(addonOverviewWidgetData);
        });
    },
    updateWidgetData(widgetId: WidgetType) {
      const widgetConfig = this.widgets[widgetId].config;
      this.setWidgetLoaderState({ widgetId, isLoading: true });
      this.getWidgetData(widgetConfig);
    },
    setWidgets(widgets: WidgetsConfig) {
      this.widgets = widgets;
    },
    addWidget(widget: { config: WidgetConfig }) {
      const widgets = cloneDeep(this.widgets);
      widgets[widget.config.widgetId] = widget;
      this.widgets = widgets;
    },
    setWidgetData(widgetsData: { widgetId: string; data: WidgetData }) {
      const currentWidgetsData = cloneDeep(this.widgetsData);
      currentWidgetsData[widgetsData.widgetId] = widgetsData.data;
      this.widgetsData = currentWidgetsData;
    },
    resetState() {
      Object.assign(this.$state, cloneDeep(defaultDashboardState));
    },
    setWidgetLoaderState(payload: { widgetId: WidgetType; isLoading: boolean }) {
      this.widgets[payload.widgetId].config.loading = payload.isLoading;
    },
    setWidgetSkeletonLoaderState(payload: { widgetId: WidgetType; isLoading: boolean }) {
      this.widgets[payload.widgetId].config.showSkeletonLoader = payload.isLoading;
    },
    setLoadedWidgetsCount(payload: number) {
      this.loadedWidgetsCount = payload;
    },
    setOverviewWidget(overviewWidget: OverviewWidgetConfiguration) {
      const widgetIndex = findIndex(this.overviewWidgets, { type: overviewWidget.type });
      if (widgetIndex !== -1) {
        this.overviewWidgets[widgetIndex] = overviewWidget;
        this.overviewWidgets = cloneDeep(this.overviewWidgets);
      } else {
        this.overviewWidgets.push(overviewWidget);
      }
    },
    setAddonOverviewWidget(addonOverviewWidget: OverviewWidgetConfiguration) {
      const widgetIndex = findIndex(this.addonOverviewWidgets, { type: addonOverviewWidget.type });
      if (widgetIndex !== -1) {
        this.addonOverviewWidgets[widgetIndex] = addonOverviewWidget;
        this.addonOverviewWidgets = cloneDeep(this.addonOverviewWidgets);
      } else {
        this.addonOverviewWidgets.push(addonOverviewWidget);
      }
    },
    setOverviewsWidgetSkeletonLoader(payload: boolean) {
      this.overviewWidgetsSkeletonLoader = payload;
    },
  },
});
