import {
  type GlobalAdminsAccessEditAccessModePermission,
  type GlobalRolePermissions,
  type GlobalRolesAccessEditAccessModePermission,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  type NestedGlobalPermissions,
  RolePermissionsScope,
  type SocPortalAccessEditAccessModePermission,
  type SpecialPermissions,
  WorkspaceManagementScopeSections,
  type WorkspaceSubscriptionTypeAccess,
  type WorkspaceTypeAccess,
} from "@/_store/roles.module";
import { useAccountStore } from "@/_store";
import { toRaw } from "vue";
import get from "lodash/get";
import { RolePermissionAccessMode } from "@/constants/roles";

export function isGlobalActionRestricted<
  T extends keyof GlobalRolePermissions,
  K extends keyof GlobalRolePermissions[T],
  V extends GlobalRolePermissions[T][K] extends NestedGlobalPermissions
    ? NonNullable<GlobalRolePermissions[T][K]["editAccessModePermission"]>
    : GlobalRolePermissions[T][K],
>(scopeName: T, key: NonNullable<K>, nestedPermissionKey?: keyof V) {
  const permissions = getGlobalPermissionsByScopeNameAndKey(scopeName, key);
  // Filter case when permissions doesn't exist
  if (!permissions) return true;

  const isEditAccessModePermission = (
    permission:
      | NestedGlobalPermissions
      | WorkspaceSubscriptionTypeAccess
      | WorkspaceTypeAccess
      | SpecialPermissions
  ): permission is NestedGlobalPermissions => {
    if (scopeName === RolePermissionsScope.TICKETS) {
      return true;
    }
    return [
      GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
      GlobalRoleScopeSection.GLOBAL_ROLES,
      GlobalRoleScopeSection.SOC_PORTAL,
      MspPortalScopeSection.MSP_WORKSPACES,
      MspPortalScopeSection.MSP_ROLES,
      MspPortalScopeSection.MSP_ADMIN_USERS,
      WorkspaceManagementScopeSections.ADMIN_USERS,
    ].includes(key as GlobalRoleScopeSection);
  };

  // Filter case when permissions is an object of such shape
  // {
  //   accessMode: RolePermissionAccessMode;
  //   editAccessModePermission?: {[key: string]: boolean}
  // };
  if (typeof permissions === "string") {
    return permissions !== RolePermissionAccessMode.EDIT;
  }

  if (isEditAccessModePermission(permissions)) {
    // If no key provided, then check if it's edit or not
    if (!nestedPermissionKey) {
      return permissions.accessMode !== RolePermissionAccessMode.EDIT;
    }

    // If key is provided, then check the particular action
    if (
      nestedPermissionKey &&
      permissions.accessMode === RolePermissionAccessMode.EDIT &&
      permissions.editAccessModePermission
    ) {
      return permissions.editAccessModePermission
        ? !permissions.editAccessModePermission[
            nestedPermissionKey as keyof (
              | GlobalAdminsAccessEditAccessModePermission
              | GlobalRolesAccessEditAccessModePermission
              | SocPortalAccessEditAccessModePermission
            )
          ]
        : true;
    }
  } else {
    return !permissions[
      nestedPermissionKey as keyof (
        | WorkspaceSubscriptionTypeAccess
        | WorkspaceTypeAccess
        | SpecialPermissions
      )
    ];
  }

  return true;
}

export function isGlobalAccessRestricted<
  T extends keyof GlobalRolePermissions,
  K extends keyof GlobalRolePermissions[T] = keyof GlobalRolePermissions[T],
>(scopeName: T, key: K) {
  const permissions = getGlobalPermissionsByScopeNameAndKey(scopeName, key);
  if (!permissions) return true;

  if (typeof permissions === "string") {
    return permissions === RolePermissionAccessMode.NO_ACCESS;
  }

  return (permissions as NestedGlobalPermissions).accessMode
    ? (permissions as NestedGlobalPermissions).accessMode === RolePermissionAccessMode.NO_ACCESS
    : true;
}

export function getGlobalPermissionsByScopeNameAndKey<
  T extends keyof GlobalRolePermissions = keyof GlobalRolePermissions,
  K extends keyof GlobalRolePermissions[T] = keyof GlobalRolePermissions[T],
>(scopeName: T, key: K) {
  const accountStore = useAccountStore();
  const globalRolePermissions = toRaw(accountStore.account.globalWorkspacePermissions);
  return get(globalRolePermissions, [scopeName, key]) as
    | NestedGlobalPermissions
    | WorkspaceSubscriptionTypeAccess
    | WorkspaceTypeAccess
    | SpecialPermissions
    | RolePermissionAccessMode
    | undefined;
}
