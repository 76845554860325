import {
  type GlobalRolePermissions,
  type MspPortalPermissions,
  type NestedGlobalPermissions,
  type NestedMspPermissions,
} from "@/_store/roles.module";
import { RolePermissionAccessMode } from "@/constants/roles";
import { useAccountStore } from "@/_store";
import { toRaw } from "vue";
import { isGlobalAccessRestricted, isGlobalActionRestricted } from "@/_helpers/global-permissions";
import get from "lodash/get";

export function isMspActionRestricted<
  T extends keyof MspPortalPermissions,
  K extends keyof MspPortalPermissions[T],
  V extends MspPortalPermissions[T][K] extends NestedMspPermissions
    ? NonNullable<MspPortalPermissions[T][K]["editAccessModePermission"]>
    : undefined,
>(scopeName: T, key: NonNullable<K>, nestedPermissionKey?: keyof V) {
  const permissions = getMspPermissionsByScopeNameAndKey(scopeName, key);
  // Filter case when permissions doesn't exist
  if (!permissions) {
    return isGlobalActionRestricted(
      scopeName,
      key,
      nestedPermissionKey as keyof (GlobalRolePermissions[T][K] extends NestedGlobalPermissions
        ? NonNullable<GlobalRolePermissions[T][K]["editAccessModePermission"]>
        : GlobalRolePermissions[T][K])
    );
  }

  // Filter case when permissions is just string
  if (typeof permissions === "string") {
    return permissions !== RolePermissionAccessMode.EDIT;
  }

  // Filter case when permissions is an object of such shape
  // {
  //   accessMode: RolePermissionAccessMode;
  //   editAccessModePermission?: {[key: string]: boolean}
  // };

  if (typeof permissions === "object") {
    // If no key provided, then check if it's edit or not
    if (!nestedPermissionKey) {
      return permissions.accessMode !== RolePermissionAccessMode.EDIT;
    }

    // If key is provided, then check the particular action
    if (
      nestedPermissionKey &&
      permissions.accessMode === RolePermissionAccessMode.EDIT &&
      permissions.editAccessModePermission
    ) {
      return permissions.editAccessModePermission
        ? // @ts-ignore
          !permissions.editAccessModePermission[nestedPermissionKey]
        : true;
    }
  }

  return true;
}

export function isMspAccessRestricted<
  T extends keyof MspPortalPermissions = keyof MspPortalPermissions,
  K extends keyof MspPortalPermissions[T] = keyof MspPortalPermissions[T],
>(scopeName: T, key: K) {
  const permissions = getMspPermissionsByScopeNameAndKey(scopeName, key);
  if (!permissions) return isGlobalAccessRestricted(scopeName, key);

  if (typeof permissions === "string") {
    return permissions === RolePermissionAccessMode.NO_ACCESS;
  }
  return permissions.accessMode === RolePermissionAccessMode.NO_ACCESS;
}

function getMspPermissionsByScopeNameAndKey<
  T extends keyof MspPortalPermissions = keyof MspPortalPermissions,
  K extends keyof MspPortalPermissions[T] = keyof MspPortalPermissions[T],
>(scopeName: T, key: K) {
  const accountStore = useAccountStore();
  const mspPermissions: MspPortalPermissions | null = toRaw(
    accountStore.account.mspPortalPermissions
  );
  return get(mspPermissions, [scopeName, key]) as
    | NestedMspPermissions
    | RolePermissionAccessMode
    | undefined;
}
