import {
  MspRolePermissionScopes,
  type NestedPermissions,
  type ProtectionScope,
  ProtectionScopeModules,
  RolePermissionsScope,
  type TicketsScope,
} from "@/_store/roles.module";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import { isAddonDisabled, isModuleDisabled, socAddonsEnabled } from "@/_helpers/utils";
import type { RolePermissionAccessMode } from "@/constants/roles";
import { isPlainObject } from "lodash";

export function useRolesPermissions(scopeName: RolePermissionsScope | MspRolePermissionScopes) {
  const isNestedPermission = (
    permissionValue: NestedPermissions | RolePermissionAccessMode
  ): permissionValue is NestedPermissions => {
    return isPlainObject(permissionValue);
  };

  const showPermission = (pName: string | number): boolean => {
    if (
      [
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        RolePermissionsScope.VIEWS,
        MspRolePermissionScopes.MSP_PORTAL_SCOPE,
      ].includes(scopeName)
    ) {
      return true;
    }
    const permissionName = pName as keyof ProtectionScope | keyof TicketsScope;
    if (permissionName === ProtectionScopeModules.MANAGED_SOC) return socAddonsEnabled();
    if (
      [
        SubscriptionAddon.INBOUND_GATEWAY,
        SubscriptionAddon.SWG,
        SubscriptionAddon.WIFI_PHISHING,
        SubscriptionAddon.SECURED_MESSAGES,
      ].includes(permissionName as SubscriptionAddon)
    ) {
      return !isAddonDisabled(permissionName as SubscriptionAddon);
    }

    return !isModuleDisabled(permissionName as SubscriptionModule);
  };
  return {
    isNestedPermission,
    showPermission,
  };
}
