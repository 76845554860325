<template>
  <div class="content-md margin-auto mt-4 report-select-wrapper">
    <v-card class="pa-12" v-for="report in reportOptions" :key="report.name">
      <div
        class="d-flex flex-column align-center w-100 provider-selection-chip"
        :data-testid="`${report.name}-chip`"
      >
        <v-icon class="service-icon" size="62" :icon="`$${report.icon}`"></v-icon>
        <div class="subtitle1 text-center text-no-wrap">
          {{ report.name }}
        </div>
        <router-link :to="{ name: report.route }" class="coro-link mt-2">
          {{ $t("general.view") }}</router-link
        >
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { isAccessRestricted, isAddonDisabled, socAddonsEnabled } from "@/_helpers/utils";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import { RolePermissionsScope } from "@/_store/roles.module";
import { RouteName } from "@/constants/routes.ts";

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const reportType = ref(null);

    const showDnsReports = computed(() => {
      return (
        !isAddonDisabled(SubscriptionAddon.SWG) &&
        !isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.NETWORK) &&
        !isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG)
      );
    });

    const showSecurityAwarenessReport = computed(() => {
      return isAccessRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.SECURITY_AWARENESS
      );
    });

    const reportOptions = [
      {
        name: i18n.t("reports.executiveSummaryReport.executiveSummary"),
        icon: "activityLogs",
        route: RouteName.REPORTS_EXECUTIVE_SUMMARY_TAB,
      },
    ];

    if (showDnsReports.value) {
      reportOptions.push({
        name: i18n.t("reports.dnsSummaryReport.title"),
        icon: "dnsReport",
        route: RouteName.REPORTS_DNS_SUMMARY_TAB,
      });
    }

    if (socAddonsEnabled()) {
      reportOptions.push({
        name: i18n.t("reports.managedServicesSummaryReport.title"),
        icon: "soc",
        route: RouteName.REPORTS_MANAGED_SERVICES_SUMMARY_TAB,
      });
    }

    if (!isAddonDisabled(SubscriptionAddon.SECURED_MESSAGES)) {
      reportOptions.push({
        name: i18n.t("reports.securedMessagesSummaryReport.title"),
        icon: "secureMessagesAlternative",
        route: RouteName.REPORTS_SECURED_MESSAGES_SUMMARY_TAB,
      });
    }

    if (showSecurityAwarenessReport.value) {
      reportOptions.push({
        name: i18n.t("reports.securityAwarenessReport.title"),
        icon: "securityAwarenessNoHex",
        route: RouteName.REPORTS_SECURITY_AWARENESS_TAB,
      });
    }

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      reportType,
      reportOptions,
    };
  },
});
</script>

<style scoped lang="scss">
.report-select-wrapper {
  margin: 0 auto;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(max(240px, 33% - 16px), 1fr));

  .v-card {
    height: 200px;
  }
}

.icon:before {
  color: rgb(var(--v-theme-primary));
}

.provider-selection-chip {
  max-height: 71px;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}
</style>
