<template>
  <v-form ref="form" v-model="valid">
    <v-text-field
      v-model="localValue.name"
      type="text"
      class="mb-6"
      :rules="[required()]"
      :placeholder="$t(`modals.${ScheduledReportsAction.ADD_REPORT}.name.label`)"
      :label="$t(`modals.${ScheduledReportsAction.ADD_REPORT}.name.label`)"
      variant="outlined"
    >
    </v-text-field>
    <div class="subtitle1 mb-4">
      {{ $t(`modals.${ScheduledReportsAction.ADD_REPORT}.reportType`) }}
    </div>
    <v-select
      v-model="localValue.subType"
      class="mb-6"
      :placeholder="$t(`modals.${ScheduledReportsAction.ADD_REPORT}.reportType`)"
      :label="$t(`modals.${ScheduledReportsAction.ADD_REPORT}.reportType`)"
      :items="reportTypes"
      :rules="[required()]"
      variant="outlined"
      :return-object="false"
      :disabled="isEditMode"
    >
      <template #selection="{ item }">
        {{
          item.value
            ? $t(`reports.scheduled.subTypes.${item.value}`)
            : $t(`modals.${ScheduledReportsAction.ADD_REPORT}.reportType`)
        }}
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="$t(`reports.scheduled.subTypes.${item.value}`)">
        </v-list-item>
      </template>
    </v-select>

    <div class="subtitle1 mb-4">
      {{ $t(`modals.${ScheduledReportsAction.ADD_REPORT}.frequency`) }}
    </div>
    <v-btn-toggle
      class="d-flex border border-md border-opacity-100 border-primary mb-6"
      v-model="localValue.frequency"
      color="indigo-pale"
      group
      mandatory
      density="comfortable"
      rounded="xl"
    >
      <v-btn
        v-for="frequency in frequencies"
        class="flex-grow-1"
        :value="frequency"
        :key="frequency"
      >
        {{ $t(`reports.scheduled.frequencies.${frequency}`) }}
      </v-btn>
    </v-btn-toggle>

    <template v-if="isChannelWorkspace">
      <div class="subtitle1 mb-4">
        {{ $t(`modals.${ScheduledReportsAction.ADD_REPORT}.scope`) }}
      </div>
      <v-radio-group v-model="localValue.scope" hide-details>
        <v-radio v-for="scope in scopes" class="mb-4" :key="scope" :value="scope" :ripple="false">
          <template #label>
            <div class="body1">
              {{ $t(`reports.scheduled.scopes.${scope}`) }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </template>

    <div class="subtitle1 mb-4">
      {{ $t(`modals.${ScheduledReportsAction.ADD_REPORT}.recipients`) }}
    </div>
    <v-radio-group v-model="recipientsChoice" hide-details>
      <v-radio
        v-for="choice in RecipientsChoices"
        class="mb-4"
        :key="choice"
        :value="choice"
        :ripple="false"
      >
        <template #label>
          <div class="body1">
            {{ $t(`modals.${ScheduledReportsAction.ADD_REPORT}.recipientsChoice.${choice}`) }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>

    <v-autocomplete
      v-if="recipientsChoice === RecipientsChoices.SPECIFIC_ADMIN_USERS"
      v-model="localValue.recipients"
      class="ml-10"
      :placeholder="$t(`modals.${ScheduledReportsAction.ADD_REPORT}.recipients`)"
      :label="$t(`modals.${ScheduledReportsAction.ADD_REPORT}.recipients`)"
      item-title="email"
      item-value="email"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="[atLeastOneIsRequired()]"
      multiple
      :menu-props="{
        maxHeight: '250',
      }"
      :items="adminUsers"
      variant="outlined"
      chips
      closable-chips
      clear-on-select
      @update:search="onAdminUserSearch($event)"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          color="indigo-faint"
          close-icon="$closeCircle"
          :text="item.title"
        >
          <div class="d-flex align-center">
            <span class="ml-1 mr-1">{{ item.title }}</span>
          </div>
        </v-chip>
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item.title"></v-list-item>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, type PropType, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { atLeastOneIsRequired, required } from "@/_helpers/validators";
import {
  ScheduledReportFrequency,
  type ScheduledReportItem,
  ScheduledType,
} from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import { ScheduledReportsAction } from "@/constants/scheduled-reports";
import { ReportType } from "@/_store/reports.module";
import { useScheduledReportModal } from "@/composables/useScheduledReportModal";

const defaultScheduledReportItem = {
  id: undefined,
  name: undefined,
  subType: undefined,
  frequency: ScheduledReportFrequency.DAILY,
  scope: undefined,
  recipients: [],
  type: ScheduledType.REPORT,
} as unknown as ScheduledReportItem;

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<ScheduledReportItem>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isEditMode = props.config.action === ScheduledReportsAction.EDIT_REPORT;
    const {
      valid,
      validate,
      localValue,
      isChannelWorkspace,
      adminUsersLoading,
      adminUsers,
      onAdminUserSearch,
      recipientsChoice,
      RecipientsChoices,
      form,
      frequencies,
      scopes,
    } = useScheduledReportModal(props.config.item, defaultScheduledReportItem, isEditMode);

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );

    watch(valid, (value: boolean | null) => {
      emit("update:valid", value ?? true);
    });

    return {
      valid,
      form,
      recipientsChoice,
      RecipientsChoices,
      isChannelWorkspace,
      localValue,
      reportTypes: Object.values(ReportType),
      ScheduledReportFrequency,
      ScheduledReportsAction,
      adminUsers,
      onAdminUserSearch,
      required,
      validate,
      atLeastOneIsRequired,
      adminUsersLoading,
      isEditMode,
      frequencies,
      scopes,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter {
  width: 240px !important;
}

.v-btn-group > .v-btn {
  border-radius: 24px !important;
  text-transform: capitalize !important;
}
</style>
