import { computed, ref } from "vue";
import type { Ref } from "vue";
import { useSelectorStore } from "@/_store/selector.module";
import { storeToRefs } from "pinia";
import { intersectionWith } from "lodash";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import { SelectorValue } from "@/constants/selector";

/**
 * Composable which handle the behavior of the selectable table. Handles items selection change.
 */
export function useSelectableTable<T>(props: { items: Ref<T[]>; totalItems: Ref<number> }) {
  const allItemsCheckboxChecked = ref(false);
  const selectionStore = useSelectorStore();
  const { selection } = storeToRefs(selectionStore);

  const isIndeterminate = computed(() => {
    if (selection.value.isAllPages) {
      return selection.value.exclude.length > 0;
    }
    return selection.value.include.length > 0;
  });

  const allItemsSelected = computed({
    get() {
      return selection.value.isAllPages;
    },
    set(value) {
      allItemsCheckboxChecked.value = value;
    },
  });

  const selectedItems = computed(() => {
    if (selection.value.isAllPages) {
      return differenceWith(props.items.value, selection.value.exclude, isEqual);
    }
    return intersectionWith(props.items.value, selection.value.include, isEqual);
  });

  const selectedItemsQuantity = computed(() => {
    if (selection.value.isAllPages) {
      if (selection.value.exclude.length > 0) {
        return props.totalItems.value - selection.value.exclude.length;
      }
      return props.totalItems.value;
    }
    return selection.value.include.length;
  });

  const handleAllRowsSelection = () => {
    allItemsCheckboxChecked.value = !allItemsCheckboxChecked.value;
    const payload = {
      selectorValue: allItemsCheckboxChecked.value
        ? SelectorValue.SELECT_ALL_PAGES
        : SelectorValue.DESELECT_ALL_PAGES,
      items: props.items.value,
    };
    onSelectionChanged(payload);
  };

  const handleOneRowSelection = (selected: boolean, item: T) => {
    onSelectionChanged({
      selectorValue: selected ? SelectorValue.SELECT_ITEMS : SelectorValue.DESELECT_ITEMS,
      items: [item],
    });
  };

  const onSelectionChanged = (selectionData: { selectorValue: string; items: Array<T> }) => {
    const payload = {
      items: selectionData.items,
      totalItems: props.totalItems.value,
    };
    switch (selectionData.selectorValue) {
      case SelectorValue.DESELECT_ITEMS:
        selectionStore.deselectItems(payload);
        break;
      case SelectorValue.DESELECT_ALL_PAGES:
        selectionStore.deselectAllPages();
        break;
      case SelectorValue.SELECT_ITEMS:
        selectionStore.selectItems(payload);
        break;
      case SelectorValue.SELECT_ALL_PAGES:
        selectionStore.selectAllPages();
        break;
    }
  };

  return {
    selectedItems,
    allItemsSelected,
    isIndeterminate,
    handleOneRowSelection,
    handleAllRowsSelection,
    onSelectionChanged,
    selectedItemsQuantity,
  };
}
