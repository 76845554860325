<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS_DENSE"
    >
      <v-card class="pa-4 pt-3" v-if="localValue">
        <div class="d-flex align-center">
          <span class="subtitle1 mr-6">
            {{ $t("securityAwarenessSettings.phishingSimulation.activate") }}
          </span>
          <v-switch
            v-model="localValue.phishingSimulationEnabled"
            class="allow-blocklist-tab-content fit-content"
            color="primary lighten-1"
            density="compact"
            data-testid="enable-phishing-settings-checkbox"
            hide-details
            :ripple="false"
            :disabled="actionNotAllowed"
          >
          </v-switch>
        </div>
        <div class="text-indigo-medium">
          {{ $t("securityAwarenessSettings.phishingSimulation.activateHint") }}
        </div>
        <v-divider class="mt-8 mb-6"></v-divider>
        <div>
          <v-checkbox
            v-model="localValue.phishingAutoEnrolment"
            :disabled="actionNotAllowed || !localValue.phishingSimulationEnabled"
            data-testid="enroll-users-checkbox"
            :ripple="false"
            :label="$t('securityAwarenessSettings.phishingSimulation.enrollUsers')"
          />
          <div class="text-indigo-medium">
            {{ $t("securityAwarenessSettings.phishingSimulation.enrollUsersHint") }}
          </div>
        </div>
      </v-card>

      <v-expansion-panels
        :model-value="expanded"
        @update:modelValue="expanded = $event as number[]"
        flat
        multiple
        class="mt-6"
        :ripple="false"
      >
        <v-expansion-panel :hide-actions="true" data-testid="scheduled-simulations-expansion-panel">
          <template #title>
            <div class="d-flex align-center">
              <v-icon :class="{ rotated: expanded.includes(0) }" icon="$chevron" size="36"></v-icon>
              <div class="mt-6 mb-6 ml-2 d-flex">
                <v-icon class="mr-2" icon="$emptyCalendar"></v-icon>
                <span class="subtitle1">{{
                  $t("securityAwarenessSettings.phishingSimulation.scheduledSimulations")
                }}</span>
              </div>
            </div>
          </template>
          <v-expansion-panel-text>
            <v-table
              class="white"
              density="compact"
              v-if="phishingSettings.scheduledSimulations.length"
            >
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("securityAwarenessSettings.phishingSimulation.deliveryDate") }}
                    </th>
                    <th class="text-left">
                      {{ $t("securityAwarenessSettings.phishingSimulation.emailPreview") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="service-row"
                    v-bind:key="i"
                    v-for="(simulation, i) in phishingSettings.scheduledSimulations"
                  >
                    <td class="subtitle2 w-50" data-testid="simulation-time-period">
                      {{ getFormattedDateTime(simulation.startDate, "ll") }} -
                      {{ getFormattedDateTime(simulation.endDate, "ll") }}
                    </td>
                    <td
                      class="coro-link"
                      data-testid="simulation-preview-name"
                      @click="
                        openEmailPreview(simulation.emailPreviewId, simulation.emailPreviewName)
                      "
                    >
                      {{ simulation.emailPreviewName }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
            <div v-else class="text-indigo-medium empty-table">
              {{ $t("securityAwarenessSettings.phishingSimulation.scheduledSimulationsEmpty") }}
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel
          :hide-actions="true"
          class="mt-4"
          data-testid="simulations-history-expansion-panel"
        >
          <template #title>
            <div class="d-flex align-center">
              <v-icon :class="{ rotated: expanded.includes(1) }" icon="$chevron" size="36"></v-icon>
              <div class="mt-6 mb-6 ml-2 d-flex">
                <v-icon class="mr-2" icon="$recentSearch" size="24"></v-icon>
                <span class="subtitle1">
                  {{ $t("securityAwarenessSettings.phishingSimulation.simulationsHistory") }}
                </span>
              </div>
            </div>
          </template>
          <v-expansion-panel-text>
            <v-data-table-server
              v-if="phishingSettings.simulationsHistory.length"
              class="coronet-table--highlightable"
              :items="phishingSettings.simulationsHistory"
              :fixed-header="true"
              :show-select="false"
              item-key="sessionId"
              density="compact"
              hide-default-footer
              :headers="headers"
              :items-per-page="-1"
              :items-length="0"
            >
              <template #headers="{ columns }">
                <tr>
                  <th v-for="header of columns" :key="header.key as string">
                    <span
                      v-if="!header.sortable && header.value !== 'data-table-select'"
                      :data-testid="`table-${header.value}-header`"
                    >
                      {{ header.title }}
                    </span>
                  </th>
                </tr>
              </template>

              <template #item="{ item }">
                <tr>
                  <td class="subtitle2 w-50" data-testid="simulation-time-period">
                    {{ getFormattedDateTime(item.startDate, "ll") }} -
                    {{ getFormattedDateTime(item.endDate, "ll") }}
                  </td>
                  <td
                    class="coro-link"
                    data-testid="simulation-preview-name"
                    @click="openEmailPreview(item.emailPreviewId, item.emailPreviewName)"
                  >
                    {{ item.emailPreviewName }}
                  </td>
                </tr>
              </template>
            </v-data-table-server>
            <div v-else class="text-indigo-medium empty-table">
              {{ $t("securityAwarenessSettings.phishingSimulation.simulationsHistoryEmpty") }}
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { storeToRefs } from "pinia";
import { SubscriptionModule } from "@/constants/workplaces";
import {
  componentDialogsConfigConstructor,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import EmailPreviewModal from "@/views/settings/security-awareness/EmailPreviewModal.vue";
import { ModalWidth } from "@/constants/modals.ts";
import {
  type PhishingSimulationSettings,
  useSecurityAwarenessModule,
} from "@/_store/security-awareness/security-awareness.module.ts";

export default defineComponent({
  setup() {
    const securityAwarenessModule = useSecurityAwarenessModule();
    const dialogsStore = useDialogsStore();
    const { phishingSettings, showSkeletonLoader } = storeToRefs(securityAwarenessModule);
    const expanded = ref([0, 1]);
    const {
      getPhishingSimulations,
      updatePhishingSettings,
      getEmailTemplate,
      getSecurityTrainingSettings,
    } = securityAwarenessModule;
    const localValue: Ref<Partial<PhishingSimulationSettings> | null> = ref(null);
    const i18n = useI18n();

    onMounted(async () => {
      await getPhishingSimulations();
      await getSecurityTrainingSettings();

      const { phishingSimulationEnabled, phishingAutoEnrolment } = phishingSettings.value;
      localValue.value = {
        phishingSimulationEnabled,
        phishingAutoEnrolment,
      };
    });

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.SECURITY_AWARENESS
    );

    watch(
      () => localValue.value,
      debounce(async (newVal) => {
        const { phishingSimulationEnabled, phishingAutoEnrolment } = phishingSettings.value;

        const deltaToCompare = {
          phishingSimulationEnabled,
          phishingAutoEnrolment,
        };

        if (!isEqual(newVal, deltaToCompare)) {
          await updatePhishingSettings(newVal);
        }
      }, 500),
      { immediate: false, deep: true }
    );

    const openEmailPreview = async (emailId: string, emailName: string) => {
      const emailData = await getEmailTemplate(emailId);

      if (!emailData) {
        return;
      }

      const config = componentDialogsConfigConstructor({
        component: EmailPreviewModal,
        action: "",
        width: ModalWidth.LARGE,
        callback: () => ({}),
        item: emailData.data,
      });

      dialogsStore.openDialog({
        ...config,
        header: {
          ...config.header,
          title: emailName,
        },
        footer: null,
      });
    };

    return {
      coronetSkeletonLoaderTypes,
      phishingSettings,
      showSkeletonLoader,
      localValue,
      actionNotAllowed,
      moment,
      getFormattedDateTime,
      openEmailPreview,
      expanded,
      headers: [
        {
          title: i18n.t("securityAwarenessSettings.phishingSimulation.deliveryDate"),
          key: "startDate",
          sortable: false,
        },
        {
          title: i18n.t("securityAwarenessSettings.phishingSimulation.emailPreview"),
          key: "emailPreviewName",
          sortable: false,
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-expansion-panel-title {
    height: 60px;

    &:hover {
      color: rgb(var(--v-theme-white));
    }
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
}

.v-table {
  box-shadow: none !important;
}

.empty-table {
  padding-left: 100px;
  padding-bottom: 16px;
}
</style>
