<template>
  <v-skeleton-loader
    v-if="!widgetConfig.disabled && !widgetConfig.restricted"
    :loading="widgetConfig.showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.CARD"
  >
    <v-card
      :loading="widgetConfig.loading"
      :data-testid="`coro-${kebabCase(widgetConfig.widgetType)}`"
    >
      <v-card-title id="widget-card-title-slot" ref="cardHeader">
        <slot name="header"></slot>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text id="widget-card-text-slot" ref="cardContent">
        <slot name="content"></slot>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import kebabCase from "lodash/kebabCase";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    widgetConfig: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      coronetSkeletonLoaderTypes,
      kebabCase,
    };
  },
});
</script>

<style scoped lang="scss">
@use "./mixins";
.v-card {
  height: inherit;
  display: flex !important;
  flex-direction: column;
}

.v-card-title {
  height: 52px;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.v-card-text {
  flex-grow: 1;
  overflow: hidden;
  padding: 0 !important;
}

.v-skeleton-loader {
  height: inherit;
}

:deep(*) {
  .coro-widget-number {
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    flex-grow: 0;
    letter-spacing: -0.6px;
    color: rgb(var(--v-theme-primary));
    font-family: Quicksand, sans-serif;
  }

  .coro-widget--empty {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 390px !important;
    padding: 0 24px !important;
  }

  .v-btn__content {
    font-size: 12px !important;
  }

  .v-btn:not(.v-btn--round).v-size--small {
    padding: 0 7px !important;
  }

  .coro-widget {
    &--active-vulnerability-circle {
      background-color: rgb(var(--v-theme-error));
      border-color: rgb(var(--v-theme-error));
    }
    &--resolved-vulnerability-circle {
      background-color: rgb(var(--v-theme-green-light));
      border-color: rgb(var(--v-theme-green-light));
    }
  }

  .coro-widget {
    &--active-vulnerability-circle {
      background-color: rgb(var(--v-theme-error));
      border-color: rgb(var(--v-theme-error));
    }
    &--resolved-vulnerability-circle {
      background-color: rgb(var(--v-theme-green-light));
      border-color: rgb(var(--v-theme-green-light));
    }
  }

  .coro-widget {
    @include mixins.row-alignment(40%, 60%);
  }

  @media only screen and (max-width: 650px) {
    .coro-widget {
      @include mixins.column-alignment(true);
    }
  }
  .v-slide-group__prev {
    display: none !important;
  }

  .coro-widget {
    &-xs {
      .v-tab {
        margin-right: 10px !important;
      }
    }
  }

  .v-table {
    thead tr th {
      border-bottom: 5px solid rgb(var(--v-theme-white)) !important;
    }

    td {
      padding: unset !important;
      border: unset !important;
      a {
        color: rgb(var(--v-theme-primary)) !important;
      }
    }

    tbody tr:hover {
      background-color: rgb(var(--v-theme-indigo-pale)) !important;
      &::before {
        background-color: transparent;
      }
    }

    th:first-child,
    td:first-child {
      padding-left: 24px !important;
    }
    th:last-child,
    td:last-child {
      padding-right: 24px !important;
      width: 25%;
    }

    th {
      height: unset !important;
      margin-bottom: 10px;
    }
    td {
      height: 44px !important;
      .mdi-check:before {
        color: rgb(var(--v-theme-gray-pale)) !important;
        font-size: 17px;
      }
    }
  }

  .empty-data-icon {
    font-size: 100px !important;

    i:before {
      color: rgb(var(--v-theme-indigo-pale));
    }
  }

  .empty-tile-icon {
    font-size: 80px !important;
  }
}
</style>
