<template>
  <div>
    <div class="subtitle1 mb-1">
      {{ $t(`modals.${MspExportAction.EXPORT_USERS}.subtitle`) }}
    </div>
    <v-radio-group v-model="localValue.type" hide-details>
      <v-radio
        v-for="choice in [ExportChoice.ALL, ExportChoice.SPECIFIC]"
        :key="choice"
        class="mb-3"
        :value="choice"
        :ripple="false"
        :label="$t(`modals.${MspExportAction.EXPORT_USERS}.choices.${choice}`)"
      ></v-radio>
    </v-radio-group>
    <msp-csv-export-modal-filters
      :filter-context="FilterContext.MSP_USERS"
      :disable-filters="localValue.type === ExportChoice.ALL"
      @update:filters="localValue.filters = $event"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watch } from "vue";
import { ExportChoice, type ExportDialogPayload } from "@/_store/exports.module";
import { defaultFiltersState, FilterContext, type MspUsersFilters } from "@/_store/filters.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import MspCsvExportModalFilters from "@/components/modals/msp/MspCsvExportModalFilters.vue";
import { MspExportAction } from "@/constants/msp-exports.ts";

export default defineComponent({
  components: { MspCsvExportModalFilters },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localValue = ref<ExportDialogPayload<MspUsersFilters>>({
      type: ExportChoice.ALL,
      filters: {
        ...defaultFiltersState[FilterContext.MSP_USERS],
      },
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );
    return {
      localValue,
      ExportChoice,
      FilterContext,
      MspExportAction,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .clear-btn {
    display: none !important;
  }
}
</style>
