import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { i18n } from "@/plugins/i18n";
import { useSnackbarStore } from "@/_store/snackbar.module";
import type { AxiosRequestConfig } from "axios";
import { useFiltersStore } from "@/_store/filters.module";
import type { AdminUser } from "@/_store/admin-users.module";
import type { Paged, Pagination } from "@/types";

export interface NewMspAdminUser {
  email: string;
  roleId: string;
  workspaceId: string;
}

export interface MspAdminUser extends AdminUser {
  workspaceId: string;
  workspaceDisplayName: string;
}

interface MspAdminUsersState {
  adminUsers: Paged<MspAdminUser>;
  showSkeletonLoader: boolean;
  loading: boolean;
  pagination: Pagination;
}

const defaultAdminAccountsState: MspAdminUsersState = {
  adminUsers: {
    items: [],
    total: 0,
  },
  showSkeletonLoader: false,
  loading: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
};

export const useMspAdminUsersStore = defineStore("mspAdminUsers", {
  state: (): MspAdminUsersState => ({ ...defaultAdminAccountsState }),
  persist: false,
  actions: {
    async getMspAdminUsers(showSkeletonLoader = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const filters = useFiltersStore().filters.mspAdminUsersFilters;
      try {
        const { data } = await axiosInstance.request({
          ...api.mspAdminUsers(),
          params: { ...filters, ...this.pagination },
        });
        this.adminUsers = data;
      } catch (e) {
        console.error(e);
      }
      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async editMspAdminUser(data: DialogDataConfig<MspAdminUser>) {
      const { role, workspaceId } = data.item;
      const request: AxiosRequestConfig = {
        ...api.mspAdminUser(data.item.id),
        method: "PUT",
        data: {
          workspaceId,
          roleId: role.roleId,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getMspAdminUsers();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.adminAccounts.adminUsersUpdated")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async addMspAdminUser(
      data: DialogDataConfig<Pick<MspAdminUser, "role" | "email" | "workspaceId">>
    ) {
      const { role, email, workspaceId } = data.item;
      const request: AxiosRequestConfig<NewMspAdminUser> = {
        ...api.mspAdminUsers(),
        method: "POST",
        data: {
          email,
          roleId: role?.roleId,
          workspaceId,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getMspAdminUsers();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.adminAccounts.adminUsersUpdated")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async deleteMspAdminUser(data: DialogDataConfig<MspAdminUser>) {
      const request: AxiosRequestConfig = {
        ...api.mspAdminUser(data.item.id),
        params: {
          workspaceId: data.item.workspaceId,
        },
        method: "DELETE",
      };
      try {
        await axiosInstance.request(request);
        await this.getMspAdminUsers();
        const snackbarStore = useSnackbarStore();
        snackbarStore.addGenericSuccess(
          i18n.global.t("snackbar.messages.adminAccounts.adminUsersUpdated")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async searchMspAdminUserCandidates(
      workspaceId: string,
      search: string = ""
    ): Promise<{ email: string }[]> {
      const request = {
        ...api.mspAdminUsersCandidates(),
        params: {
          search,
          workspaceId,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async searchMspAdminUserWorkspaces(
      page = 0,
      search: string = ""
    ): Promise<Paged<{ workspaceId: string; workspaceName: string }>> {
      const request = {
        ...api.mspAdminUsersWorkspaces(),
        params: {
          search,
          page,
          pageSize: 25,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (e) {
        console.error(e);
        return {
          items: [],
          total: 0,
        };
      }
    },
  },
});
