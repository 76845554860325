import { defineStore } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import type { TelemetryType } from "@/constants/edr";
import type { SubscriptionType, WorkspaceType } from "@/constants/workplaces";
import type { DeviceStatusFilterItem } from "@/_store/devices.module";
import type { ActivityLogsFilters } from "@/_store/activity-logs.module";
import type { ExportCategory } from "@/_store/exports.module";
import type { DeviceVulnerability } from "@/constants/devices";
import type { SocStatus, TicketsModuleFilter } from "@/constants/tickets";
import type { TimeFrame } from "@/types";
import type { SmartSearchSegment } from "@/components/smart-search/SearchSegment.vue";
import moment from "moment/moment";
import type { ReportType } from "@/_store/reports.module";
import type { SecureWebGatewayListFilterChoices } from "@/constants/network";
import type {
  WorkspaceCodeStatus,
  WorkspaceCodeType,
} from "@/_store/back-office/workspace-codes.module";
import type { Service } from "@/constants/cloud-apps";
import { TicketStatus } from "@/constants/tickets";
import type { SecureWebGatewayCategories } from "@/constants/network";
import type { DeviceAllowBlockListTypes } from "@/constants/devices";
import type { AllowBlockListType, EmailSecurityAllowBlockItemType } from "@/constants/phishing";
import type { ThirdPartyAppStatus } from "@/_store/cloud-apps/third-party-apps.module";
import { MspUsersSuspendedFilterItem, MspUserVulnerabilityStatus } from "@/constants/msp-users.ts";
import { DriveEncryptionStatus, DriveType } from "@/constants/devices";

export interface FiltersState {
  [FilterContext.DEVICES]: DevicesFilters;
  [FilterContext.ALLOW_BLOCK_LIST]: EmailSecurityAllowBlockListFilters;
  [FilterContext.TICKETS]: TicketsFilters;
  [FilterContext.AGENT_DEPLOYMENT]: AgentDeploymentFilters;
  [FilterContext.BACK_OFFICE]: BackOfficeFilters;
  [FilterContext.LABELS]: SearchFilters;
  [FilterContext.USERS]: ProtectedUsersFilters;
  [FilterContext.MSP]: MspFilters;
  [FilterContext.ACTIVITY_LOGS]: ActivityLogsFilters;
  [FilterContext.VIRTUAL_OFFICE_INCLUDED]: VirtualOfficeIncludedFilters;
  [FilterContext.VIRTUAL_OFFICE_EXCLUDED]: VirtualOfficeExcludedFilters;
  [FilterContext.EDR_PROCESSES]: SearchFilters;
  [FilterContext.EDR_TELEMETRY]: EdrTelemetryFilters;
  [FilterContext.DEVICE_ALLOW_BLOCK_LIST]: DeviceAllowBlockListFilters;
  [FilterContext.EDR_ALLOW_BLOCK_LIST]: DeviceAllowBlockListFilters;
  [FilterContext.SECURE_WEB_GATEWAY]: SecureWebGatewayFilters;
  [FilterContext.ADMIN_USERS]: SearchFilters;
  [FilterContext.MSP_ADMIN_USERS]: SearchFilters & { workspaceId: string | null };
  [FilterContext.MSP_ROLES]: { workspaceId: string | null };
  [FilterContext.EXPORTS_FILTERS]: ExportsFilters;
  [FilterContext.SCHEDULED_REPORTS_FILTERS]: ScheduledReportsFilters;
  [FilterContext.BACK_OFFICE_ADMIN_USERS]: SearchFilters;
  [FilterContext.DNS_REPORT_FILTERS]: DnsReportFilters;
  [FilterContext.THIRD_PARTY_APPS]: ThirdPartyAppsFilters;
  [FilterContext.WORKSPACE_CODES]: WorkspaceCodeFilters;
  [FilterContext.SOC_TICKETS]: SocTicketsFilters;
  [FilterContext.MSP_USERS]: MspUsersFilters;
  [FilterContext.MSP_DEVICES]: MspDevicesFilters;
  [FilterContext.MSP_DRIVES]: MspDrivesFilters;
  [FilterContext.MSP_TICKETS]: MspTicketsFilters;
}

export interface SearchFilters {
  search?: string | null;
}

export interface AgentDeploymentFilters {
  showBetaVersions: boolean;
}

export interface DnsReportFilters {
  timeFrame: TimeFrame;
}

export interface WorkspaceCodeFilters {
  name: string | null;
  type: WorkspaceCodeType[];
  status: WorkspaceCodeStatus;
}

export interface EmailSecurityAllowBlockListFilters {
  search?: string | null;
  type?: EmailSecurityAllowBlockItemType | null;
  listType?: AllowBlockListType | null;
}

export interface BackOfficeFilters {
  timeRange: TimeRange;
}

export interface TimeRange {
  start: string;
  end: string;
}

export interface DeviceAllowBlockListFilters {
  search?: string | null;
  type?: DeviceAllowBlockListTypes;
}

export interface DevicesFilters {
  hasVulnerabilities?: boolean | null;
  osType?: string | null;
  osVersion?: string | null;
  clientVersion?: string | null;
  statuses?: string[];
  vulnerabilities?: DeviceVulnerability[];
  labels?: DeviceStatusFilterItem[];
  search?: string | null;
  widget?: string | null;
}

export interface MspDevicesFilters {
  hasVulnerabilities?: boolean | null;
  osType?: string | null;
  osVersion?: string | null;
  clientVersion?: string | null;
  statuses?: string[];
  vulnerabilities?: DeviceVulnerability[];
  labels?: DeviceStatusFilterItem[];
  search?: string | null;
  widget?: string | null;
  childWorkspaceIds: string[];
}

export interface MspDrivesFilters {
  statuses: DriveEncryptionStatus[];
  search?: string | null;
  types: DriveType[];
  childWorkspaceIds: string[];
}

export interface EdrTelemetryFilters {
  searches: SmartSearchSegment[];
  types: TelemetryType[];
  timeRange: TimeRange;
}

export interface MspFilters {
  search?: string | null;
  types: WorkspaceType[];
  labels: string[];
  subscriptionTypes: SubscriptionType[];
  addons: MspAddonFilterItem[];
  nearExpirationOnly: boolean;
}

export interface MspAddonFilterItem {
  name: string;
  type: "addon" | "module" | "bundle" | "subheader";
}

export interface ProtectedUsersFilters {
  search?: string | null;
  vulnerabilityState?: string;
  hasVulnerabilities?: boolean;
  widget?: string;
}

export interface SecureWebGatewayFilters {
  search?: string | null;
  allowed?: SecureWebGatewayListFilterChoices;
  category?: SecureWebGatewayCategories;
}

export interface TicketsFilters {
  eventTriggers?: string[];
  eventTimeRange: TimeRange;
  search?: string | null;
  widget?: TicketsModuleFilter | null;
  protectedUsersOnly?: boolean;
  status: string;
}

export interface SocTicketsFilters extends TicketsFilters {
  socStatuses: SocStatus[];
  workspaceId?: string | null;
}

export interface MspTicketsFilters extends TicketsFilters {
  directDescendantsOnly: boolean;
  childWorkspaceIds?: string[];
}

export interface VirtualOfficeIncludedFilters {
  search?: string | null;
}

export interface VirtualOfficeExcludedFilters {
  search?: string | null;
}

export interface ExportsFilters {
  search?: string | null;
  types?: ExportCategory[];
  timeFrame: {
    start: string;
    end: string;
  };
}

export interface ScheduledReportsFilters {
  search?: string | null;
  types?: (ExportCategory | ReportType)[];
}

export interface ThirdPartyAppsFilters {
  search?: string | null;
  cloudApplications: Service[];
  status: ThirdPartyAppStatus | null;
}

export interface MspUsersFilters {
  hasVulnerabilities: MspUserVulnerabilityStatus;
  workspaceIds?: string[] | null;
  suspended?: MspUsersSuspendedFilterItem | null;
  search?: string | null;
  cloudServices?: Service[];
}

export enum FilterContext {
  LABELS = "labelsFilters",
  ALLOW_BLOCK_LIST = "allowBlockListFilters",
  USERS = "usersFilters",
  DEVICES = "devicesFilters",
  MSP = "mspFilters",
  BACK_OFFICE = "backOfficeFilters",
  BACK_OFFICE_ADMIN_USERS = "backOfficeAdminUsersFilters",
  TICKETS = "ticketsFilters",
  SOC_TICKETS = "socTicketsFilters",
  ACTIVITY_LOGS = "activityLogsFilters",
  VIRTUAL_OFFICE_INCLUDED = "virtualOfficeIncludedFilters",
  VIRTUAL_OFFICE_EXCLUDED = "virtualOfficeExcludedFilters",
  DEVICE_ALLOW_BLOCK_LIST = "deviceAllowBlockListFilters",
  EDR_ALLOW_BLOCK_LIST = "edrAllowBlockListFilters",
  EDR_PROCESSES = "edrProcessesFilters",
  EDR_TELEMETRY = "edrTelemetryFilters",
  SECURE_WEB_GATEWAY = "secureWebGatewayFilters",
  ADMIN_USERS = "adminUsersFilters",
  MSP_ADMIN_USERS = "mspAdminUsersFilters",
  MSP_ROLES = "mspRolesFilters",
  AGENT_DEPLOYMENT = "agentDeploymentFilters",
  EXPORTS_FILTERS = "exportsFilters",
  DNS_REPORT_FILTERS = "dnsReportFilters",
  SCHEDULED_REPORTS_FILTERS = "scheduledReportsFilters",
  THIRD_PARTY_APPS = "thirdPartyApps",
  WORKSPACE_CODES = "workspaceCodesFilters",
  MSP_USERS = "mspUsers",
  MSP_DEVICES = "mspDevices",
  MSP_TICKETS = "mspTickets",
  MSP_DRIVES = "mspDrives",
}

export const defaultFiltersState: FiltersState = {
  [FilterContext.VIRTUAL_OFFICE_INCLUDED]: {
    search: "",
  },
  [FilterContext.VIRTUAL_OFFICE_EXCLUDED]: {
    search: "",
  },
  [FilterContext.DEVICES]: {
    hasVulnerabilities: null,
    osType: null,
    osVersion: null,
    clientVersion: null,
    statuses: [],
    vulnerabilities: [],
    labels: [],
    search: null,
    widget: null,
  },
  [FilterContext.MSP_DEVICES]: {
    hasVulnerabilities: null,
    osType: null,
    osVersion: null,
    clientVersion: null,
    statuses: [],
    vulnerabilities: [],
    labels: [],
    search: null,
    widget: null,
    childWorkspaceIds: [],
  },
  [FilterContext.MSP_DRIVES]: {
    statuses: [],
    search: null,
    types: [],
    childWorkspaceIds: [],
  },
  [FilterContext.ALLOW_BLOCK_LIST]: {
    search: "",
    type: null,
    listType: null,
  },
  [FilterContext.THIRD_PARTY_APPS]: {
    cloudApplications: [],
    status: null,
    search: "",
  },
  [FilterContext.TICKETS]: {
    eventTriggers: [],
    eventTimeRange: {
      start: "",
      end: "",
    },
    search: null,
    widget: null,
    protectedUsersOnly: false,
    status: "open",
  },
  [FilterContext.AGENT_DEPLOYMENT]: {
    showBetaVersions: false,
  },
  [FilterContext.BACK_OFFICE]: {
    timeRange: {
      start: "",
      end: "",
    },
  },
  [FilterContext.LABELS]: {
    search: null,
  },
  [FilterContext.USERS]: {
    search: null,
    hasVulnerabilities: undefined,
    widget: undefined,
  },
  [FilterContext.MSP_USERS]: {
    search: "",
    hasVulnerabilities: MspUserVulnerabilityStatus.WITH_ISSUES,
    suspended: null,
    workspaceIds: [],
    cloudServices: [],
  },
  [FilterContext.MSP]: {
    search: "",
    types: [],
    labels: [],
    subscriptionTypes: [],
    addons: [],
    nearExpirationOnly: false,
  },
  [FilterContext.ACTIVITY_LOGS]: {
    search: null,
    types: [],
    timeFrame: {
      start: "",
      end: "",
    },
  },
  [FilterContext.SOC_TICKETS]: {
    eventTriggers: [],
    eventTimeRange: {
      start: "",
      end: "",
    },
    search: null,
    widget: null,
    protectedUsersOnly: false,
    status: TicketStatus.OPEN,
    socStatuses: [],
    workspaceId: null,
  },
  [FilterContext.MSP_TICKETS]: {
    eventTriggers: [],
    eventTimeRange: {
      start: "",
      end: "",
    },
    search: null,
    widget: null,
    protectedUsersOnly: false,
    status: TicketStatus.OPEN,
    childWorkspaceIds: [],
    directDescendantsOnly: false,
  },
  [FilterContext.EDR_PROCESSES]: {
    search: null,
  },
  [FilterContext.EDR_TELEMETRY]: {
    searches: [],
    types: [],
    timeRange: {
      start: "",
      end: "",
    },
  },
  [FilterContext.DEVICE_ALLOW_BLOCK_LIST]: {
    search: null,
    type: undefined,
  },
  [FilterContext.EDR_ALLOW_BLOCK_LIST]: {
    search: null,
    type: undefined,
  },
  [FilterContext.SECURE_WEB_GATEWAY]: {
    search: null,
    allowed: undefined,
    category: undefined,
  },
  [FilterContext.ADMIN_USERS]: {
    search: null,
  },
  [FilterContext.MSP_ADMIN_USERS]: {
    search: "",
    workspaceId: null,
  },
  [FilterContext.MSP_ROLES]: {
    workspaceId: null,
  },
  [FilterContext.EXPORTS_FILTERS]: {
    search: "",
    types: [],
    timeFrame: {
      start: "",
      end: "",
    },
  },
  [FilterContext.SCHEDULED_REPORTS_FILTERS]: {
    search: "",
    types: [],
  },
  [FilterContext.BACK_OFFICE_ADMIN_USERS]: {
    search: "",
  },
  [FilterContext.DNS_REPORT_FILTERS]: {
    timeFrame: {
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    },
  },
  [FilterContext.WORKSPACE_CODES]: {
    name: null,
    type: [],
    status: "" as WorkspaceCodeStatus,
  },
};

export const useFiltersStore = defineStore("filters", {
  persist: true,
  state: () => ({ filters: cloneDeep({ ...defaultFiltersState }) }),
  actions: {
    setFilters<K extends keyof FiltersState>(key: K, value: FiltersState[K]) {
      this.filters[key] = { ...value };
    },
    resetFilters<K extends keyof FiltersState>(key: K) {
      this.filters[key] = { ...defaultFiltersState[key] };
    },
    /**
     * Resets whole state.
     */
    resetState() {
      this.$reset();
    },
  },
});
