<template>
  <div>
    <v-btn
      color="primary"
      rounded
      elevation="0"
      size="large"
      class="add-btn"
      @click="openAddRoleDialog()"
    >
      <v-icon color="white" class="mr-2" icon="$add"></v-icon>
      <span>{{ $t("manageWorkspaces.roles.table.actions.addMspRole") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { emptyMspPermissions, MspRolesAction, RoleType } from "@/constants/roles";
import { ModalWidth } from "@/constants/modals";
import {
  MspPortalScopeSection,
  MspRolePermissionScopes,
  useRolesStore,
} from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import AddMspRoleModal from "@/components/modals/add-role/AddMspRoleModal.vue";
import { isMspActionRestricted } from "@/_helpers/msp-permissions";

export default defineComponent({
  setup() {
    const dialogsStore = useDialogsStore();
    const rolesStore = useRolesStore(RoleType.MSP);
    const openAddRoleDialog = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: MspRolesAction.ADD,
        item: { ...emptyMspPermissions },
        component: AddMspRoleModal,
        width: ModalWidth.LARGE,
        callback: rolesStore.addRole,
        disable: isMspActionRestricted(
          MspRolePermissionScopes.MSP_PORTAL_SCOPE,
          MspPortalScopeSection.MSP_ROLES,
          "add"
        ),
      });
      dialogsStore.openDialog({ ...dialogConfig, scrollable: true });
    };

    return {
      openAddRoleDialog,
    };
  },
});
</script>

<style scoped></style>
