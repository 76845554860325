<template>
  <v-card class="h-100">
    <v-card-text>
      <div class="ml-4 mt-3 mr-4">
        <v-checkbox
          v-model="localValue.realTimeMalwareProtectionEnabled"
          data-testid="devices-settings-realtime-malware-protection-enabled-checkbox"
          :ripple="false"
          class="mb-1"
          :label="$t('devicesSettings.settingsCard.realTimeMalwareProtection')"
          :disabled="actionNotAllowed"
          @click.native.prevent.stop.capture="
            onChangeRiskyNgavItem('realTimeMalwareProtectionEnabled')
          "
        />
        <div class="ml-7">
          <v-checkbox
            v-model="localValue.atcEnabled"
            data-testid="enable-atc-checkbox"
            :disabled="isRealTimeMalwareProtectionDisabled"
            :ripple="false"
            class="mt-3"
            :label="$t('devicesSettings.settingsCard.atcEnabled')"
            @click.native.prevent.stop.capture="onChangeRiskyNgavItem('atcEnabled')"
          />
          <div class="body2 ml-7">
            {{ $t("devicesSettings.settingsCard.atcDescription") }}
          </div>
          <div
            class="body1 mt-4 ml-7"
            :class="{
              'autocomplete-label text-indigo-medium': isParentSettingDisabled(
                localValue.atcEnabled
              ),
            }"
          >
            {{ $t("devicesSettings.settingsCard.applyToDevices") }}
          </div>
          <v-autocomplete
            v-model="localValue.atcLabels"
            class="mt-4 ml-7"
            :items="labels"
            :label="deviceLabelsPlaceholder(localValue.atcLabels?.length)"
            :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
            :disabled="isParentSettingDisabled(localValue.atcEnabled)"
            variant="outlined"
            multiple
            return-object
            data-testid="devices-settings-enable-atc-labels"
            item-value="id"
            item-text="name"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                variant="flat"
                size="default"
                :color="item.raw.color"
                close-icon="$closeCircle"
                :text="item.raw.name"
                :disabled="isParentSettingDisabled(localValue.atcEnabled)"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2">{{ item.raw.name }}</span>
                </div>
              </v-chip>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" class="d-flex align-center label-popup-block" title="">
                <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span
                  :data-testid="`devices-settings-enable-atc-${kebabCase(
                    item.raw.name
                  )}-select-item`"
                  >{{ item.raw.name }}
                </span>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-checkbox
            v-model="localValue.vssPolicyEnabled"
            data-testid="devices-settings-vcc-policy-enabled-checkbox"
            :disabled="isRealTimeMalwareProtectionDisabled"
            :ripple="false"
            class="mt-1"
            :label="$t('devicesSettings.settingsCard.vssPolicyEnabled')"
            @click.native.prevent.stop.capture="onChangeRiskyNgavItem('vssPolicyEnabled')"
          />
          <div class="body2 ml-7">
            {{ $t("devicesSettings.settingsCard.vssPolicyDescription") }}
          </div>
          <div
            class="body1 mt-4 ml-7"
            :class="{
              'autocomplete-label text-indigo-medium': isParentSettingDisabled(
                localValue.vssPolicyEnabled
              ),
            }"
          >
            {{ $t("devicesSettings.settingsCard.applyToDevices") }}
          </div>
          <v-autocomplete
            v-model="localValue.vssPolicyLabels"
            class="mt-4 ml-7"
            :items="labels"
            :label="deviceLabelsPlaceholder(localValue?.vssPolicyLabels?.length)"
            :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
            :disabled="isParentSettingDisabled(localValue.vssPolicyEnabled)"
            variant="outlined"
            multiple
            return-object
            data-testid="devices-settings-enable-vcc-labels"
            item-value="id"
            item-text="name"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                variant="flat"
                size="default"
                :color="item.raw.color"
                close-icon="$closeCircle"
                :text="item.raw.name"
                :disabled="isParentSettingDisabled(localValue.vssPolicyEnabled)"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2">{{ item.raw.name }}</span>
                </div>
              </v-chip>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <v-list-item class="d-flex align-center label-popup-block">
                  <span
                    class="color-preview mr-2"
                    :style="{ 'background-color': item.raw.color }"
                  />
                  <span
                    :data-testid="`devices-settings-enable-vcc-${kebabCase(
                      item.raw.name
                    )}-select-item`"
                  >
                    {{ item.raw.name }}
                  </span>
                </v-list-item>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-checkbox
            v-model="localValue.enforceContainerQuarantineEnabled"
            data-testid="devices-settings-enable-edr-block-checkbox"
            :ripple="false"
            :disabled="isRealTimeMalwareProtectionDisabled"
            class="mt-1"
            :label="$t('devicesSettings.settingsCard.enforceContainerQuarantine')"
          />
          <div class="body2 ml-7">
            {{ $t("devicesSettings.settingsCard.enforceContainerQuarantineDescription") }}
          </div>
          <div
            class="body1 mt-4 ml-7"
            :class="{
              'autocomplete-label text-indigo-medium': isParentSettingDisabled(
                localValue.enforceContainerQuarantineEnabled
              ),
            }"
          >
            {{ $t("devicesSettings.settingsCard.applyToDevices") }}
          </div>
          <v-autocomplete
            v-model="localValue.enforceContainerQuarantineLabels"
            class="mt-4 ml-7"
            :items="labels"
            :label="deviceLabelsPlaceholder(localValue.enforceContainerQuarantineLabels?.length)"
            :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
            :disabled="isParentSettingDisabled(localValue.enforceContainerQuarantineEnabled)"
            variant="outlined"
            multiple
            return-object
            data-testid="devices-settings-enable-malware-labels"
            item-value="id"
            item-text="name"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                variant="flat"
                size="default"
                :color="item.raw.color"
                close-icon="$closeCircle"
                :text="item.raw.name"
                :disabled="isParentSettingDisabled(localValue.enforceContainerQuarantineEnabled)"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2">{{ item.raw.name }}</span>
                </div>
              </v-chip>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <v-list-item class="d-flex align-center label-popup-block">
                  <span
                    class="color-preview mr-2"
                    :style="{ 'background-color': item.raw.color }"
                  />
                  <span
                    :data-testid="`devices-settings-enable-malware-${kebabCase(
                      item.raw.name
                    )}-select-item`"
                  >
                    {{ item.raw.name }}
                  </span>
                </v-list-item>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-checkbox
            v-model="localValue.initialDevicesScanEnabled"
            data-testid="devices-settings-initial-scan-checkbox"
            :disabled="isRealTimeMalwareProtectionDisabled"
            :ripple="false"
            class="mt-1"
            :label="$t('devicesSettings.settingsCard.initialDevicesScan')"
            @click.native.prevent.stop.capture="onChangeInitialScanToggle()"
          />
          <div class="body2 ml-7">
            {{ $t("devicesSettings.settingsCard.initialDevicesScanDescription") }}
          </div>
          <div
            class="body1 mt-4 ml-7"
            :class="{
              'autocomplete-label text-indigo-medium': isParentSettingDisabled(
                localValue.initialDevicesScanEnabled
              ),
            }"
          >
            {{ $t("devicesSettings.settingsCard.applyToDevices") }}
          </div>
          <v-autocomplete
            v-model="localValue.initialDevicesScanLabels"
            class="mt-4 ml-7"
            :items="labels"
            :label="deviceLabelsPlaceholder(localValue.initialDevicesScanLabels?.length)"
            :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
            :disabled="isParentSettingDisabled(localValue.initialDevicesScanEnabled)"
            variant="outlined"
            multiple
            return-object
            data-testid="devices-settings-initial-scan-labels"
            item-value="id"
            item-text="name"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                variant="flat"
                size="default"
                :color="item.raw.color"
                close-icon="$closeCircle"
                :text="item.raw.name"
                :disabled="isParentSettingDisabled(localValue.initialDevicesScanEnabled)"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2">{{ item.raw.name }}</span>
                </div>
              </v-chip>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="" class="d-flex align-center label-popup-block">
                <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span
                  :data-testid="`devices-settings-initial-scan-${kebabCase(
                    item.raw.name
                  )}-select-item`"
                  >{{ item.raw.name }}</span
                >
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { MalwareAction } from "@/constants/malware-settings";
import { kebabCase } from "lodash";
import type { Ref } from "vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import type { NgavSettings } from "@/_store/devices-settings.module";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import isEqual from "lodash/isEqual";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import { useDialogsStore } from "@/_store/dialogs.module";
import { DisableNgavAction } from "@/constants/devices.ts";

const fieldToActionMap: Record<
  "realTimeMalwareProtectionEnabled" | "vssPolicyEnabled" | "atcEnabled",
  DisableNgavAction
> = {
  realTimeMalwareProtectionEnabled: DisableNgavAction.REAL_TIME_PROTECTION,
  vssPolicyEnabled: DisableNgavAction.SECURED_SHADOW_BACKUP,
  atcEnabled: DisableNgavAction.ADVANCED_THREAT_CONTROL,
};

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const localValue: Ref<NgavSettings> = ref({} as NgavSettings);
    const dialogsStore = useDialogsStore();
    const devicesSettingsStore = useDevicesSettingsStore();
    const { ngavSettings, labels } = storeToRefs(devicesSettingsStore);
    const { getNgavSettings, updateNgavSettings, getLabels } = devicesSettingsStore;

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.ENDPOINT_SECURITY
      );
    });
    const isRealTimeMalwareProtectionDisabled = computed(() => {
      return actionNotAllowed.value || !localValue.value.realTimeMalwareProtectionEnabled;
    });
    const onChangeInitialScanToggle = () => {
      if (localValue.value.initialDevicesScanEnabled) {
        localValue.value.initialDevicesScanEnabled = !localValue.value.initialDevicesScanEnabled;
      } else {
        const dialogConfig = confirmationDialogsConfigConstructor({
          item: {},
          action: MalwareAction.TOGGLE_INIT_SCAN,
          callback: () => {
            localValue.value.initialDevicesScanEnabled =
              !localValue.value.initialDevicesScanEnabled;
          },
        });

        dialogsStore.openDialog(dialogConfig);
      }
    };

    watch(ngavSettings, (val) => {
      localValue.value = cloneDeep(val);
    });

    const onChangeRiskyNgavItem = (
      field: "realTimeMalwareProtectionEnabled" | "vssPolicyEnabled" | "atcEnabled"
    ) => {
      if (!localValue.value[field]) {
        localValue.value[field] = !localValue.value[field];
      } else {
        const dialogConfig = confirmationDialogsConfigConstructor({
          item: {},
          action: fieldToActionMap[field],
          callback: () => {
            localValue.value[field] = !localValue.value[field];
          },
        });

        dialogsStore.openDialog({
          ...dialogConfig,
          header: {
            ...dialogConfig.header,
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
        });
      }
    };

    watch(
      localValue,
      async (value) => {
        if (!isEqual(value, ngavSettings.value)) {
          await updateNgavSettings(value);
        }
      },
      { deep: true }
    );

    const deviceLabelsPlaceholder = (labelsLength: number) => {
      return labelsLength ? i18n.t("devicesSettings.settingsCard.deviceLabelsPlaceholder") : "";
    };

    const isParentSettingDisabled = (parentSettingEnabled: boolean) => {
      return isRealTimeMalwareProtectionDisabled.value || !parentSettingEnabled;
    };

    onMounted(async () => {
      await getLabels();
      await getNgavSettings();
    });

    return {
      kebabCase,
      localValue,
      isRealTimeMalwareProtectionDisabled,
      actionNotAllowed,
      onChangeInitialScanToggle,
      onChangeRiskyNgavItem,
      isParentSettingDisabled,
      deviceLabelsPlaceholder,
      labels,
    };
  },
});
</script>

<style lang="scss" scoped>
.label-popup-block {
  .v-list-item__content {
    display: flex;
    align-items: center;
  }
}

.v-label {
  font-size: 18px !important;
}

.autocomplete-label {
  opacity: var(--v-disabled-opacity);
}
</style>
