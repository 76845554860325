<template>
  <div class="h-auto">
    <empty-state class="w-auto mt-12 w-80">
      <template #icon>
        <v-icon class="mr-1" size="80" icon="$securityAwareness" />
      </template>
      <template #description>
        <span> {{ emptyStateTitle }} </span>
      </template>
      <template #subtitle>
        <span>{{ emptyStateSubtitle }}</span>
      </template>
      <template #button>
        <v-btn
          v-if="!isCloudAppsConnected"
          class="mb-4"
          color="primary"
          rounded
          size="large"
          :to="{
            name: RouteName.CLOUD_APPS_PAGE,
            query: {
              noServicesConnected: 'true',
              showOnlyPrimaryCloudApps: 'true',
            },
          }"
        >
          {{ $t("cloudSecurity.noItems.connectBtn") }}
        </v-btn>
        <v-menu v-else-if="hasMultipleAllowOptions">
          <template #activator="{ props }">
            <v-btn
              data-testid="allow-actions-menu"
              class="mb-4"
              rounded
              color="primary"
              v-bind="props"
            >
              {{ allowButtonText }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="py-0" min-height="16" disabled>
              <div class="caption menu-heading">
                {{ $t("securityAwarenessSettings.emptyState.selectCloudApp") }}
              </div>
            </v-list-item>
            <v-list-item
              v-for="service in [Service.G_SUITE, Service.OFFICE_365]"
              :key="service"
              :data-testid="`menu-item-allow-${service}-acton`"
              @click="handleAllowButtonClick(service)"
            >
              <v-list-item-title>
                {{ $t(`services.${service}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="!isSecurityAwarenessAllowed"
          class="mb-4"
          color="primary"
          rounded
          size="large"
          @click="handleAllowButtonClick()"
        >
          {{ allowButtonText }}
        </v-btn>
      </template>
    </empty-state>
    <div class="d-flex justify-center pt-12 sa-empty-state-footer">
      <span class="body2 empty-state-footer-text text-center">
        <v-icon size="16" class="mr-1" icon="$warning" />
        {{ $t("securityAwarenessSettings.emptyState.warning", { n: daysToAllowList }) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import EmptyState from "@/components/EmptyState.vue";
import { useSecurityAwarenessEmptyState } from "@/composables/useSecurityAwarenessEmptyState.ts";
import { Service } from "@/constants/cloud-apps.ts";
import { RouteName } from "@/constants/routes.ts";
import type { CloudAppService } from "@/_store/cloud-apps/cloud-apps.module.ts";

export default defineComponent({
  components: { EmptyState },
  props: {
    services: {
      type: Array as PropType<Array<CloudAppService>>,
      default: () => [],
    },
  },
  setup(props) {
    const {
      hasMultipleAllowOptions,
      allowButtonText,
      emptyStateTitle,
      emptyStateSubtitle,
      handleAllowButtonClick,
      isCloudAppsConnected,
      isSecurityAwarenessAllowed,
      daysToAllowList,
    } = useSecurityAwarenessEmptyState(props.services);

    return {
      RouteName,
      Service,
      hasMultipleAllowOptions,
      allowButtonText,
      emptyStateTitle,
      emptyStateSubtitle,
      handleAllowButtonClick,
      isCloudAppsConnected,
      isSecurityAwarenessAllowed,
      daysToAllowList,
    };
  },
});
</script>

<style scoped lang="scss">
.empty-state-footer-text,
.icon-warning:before {
  color: rgba(var(--v-theme-primary), 0.65);
}

.coro-widget {
  .coro-empty-state {
    margin-top: 0 !important;
  }

  .sa-empty-state-footer {
    width: 70%;
    padding-top: 12px !important;
    justify-self: center;
    align-items: inherit;
  }
}

.menu-heading {
  color: rgb(var(--v-theme-indigo-medium));
  font-size: 12px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 0 !important;
}
</style>
