<template>
  <div>
    <v-stepper-vertical hide-actions v-model="currentStep">
      <template v-slot:default>
        <v-stepper-vertical-item
          :value="1"
          :elevation="0"
          :class="{ 'step-completed': currentStep > 1 }"
        >
          <template #title>
            <div class="subtitle1">{{ $t("modals.allowCloudService.step1.title") }}</div>
          </template>
          <template #icon>
            <v-icon v-if="currentStep > 1" icon="$check" />
            <span v-else>1</span>
          </template>
          <div>
            <p
              class="body2 mb-4"
              v-html="
                $t('modals.allowCloudService.step1.description', {
                  service: $t(`services.${service}`),
                })
              "
            />

            <a :href="guideLink" target="_blank" class="d-flex align-center mb-6">
              <span class="coro-link body2">{{
                $t("modals.allowCloudService.step1.guide", { service: $t(`services.${service}`) })
              }}</span>
              <v-icon icon="$externalLink" class="ml-1" size="16" />
            </a>
            <div class="mt-5">
              <v-btn color="primary" rounded :disabled="disable" @click="currentStep++">
                {{ $t("modals.allowCloudService.step1.actionBtn") }}
              </v-btn>
            </div>
          </div>
        </v-stepper-vertical-item>

        <v-stepper-vertical-item
          :value="2"
          :elevation="0"
          :class="{ 'step-completed': currentStep > 2 }"
        >
          <template #title>
            <div class="subtitle1">{{ $t("modals.allowCloudService.step2.title") }}</div>
          </template>
          <template #icon>
            <v-icon v-if="currentStep > 2" icon="$check" />
            <span v-else>2</span>
          </template>
          <v-div>
            <p class="body2 mb-4" v-html="$t('modals.allowCloudService.step2.description')" />
            <div class="mt-5 d-flex">
              <v-btn color="primary" rounded :loading="loading" @click="sendTestEmails">
                {{ $t("modals.allowCloudService.step2.actionBtn") }}
              </v-btn>
              <v-btn class="ml-2" variant="text" color="primary" @click="currentStep = 1">
                {{ $t("general.back") }}
              </v-btn>
            </div>
          </v-div>
        </v-stepper-vertical-item>

        <v-stepper-vertical-item
          :value="3"
          :elevation="0"
          :class="{ 'step-completed': isFlowCompleted }"
        >
          <template #title>
            <div class="subtitle1">{{ $t("modals.allowCloudService.step3.title") }}</div>
          </template>
          <template #icon>
            <v-icon v-if="isFlowCompleted" icon="$check" />
            <span v-else>3</span>
          </template>
          <div v-if="!isFlowCompleted">
            <p
              class="body2 mb-4"
              v-html="
                $t('modals.allowCloudService.step3.description', { service: cloudEmailServiceName })
              "
            />
            <div class="mt-5 d-flex">
              <v-btn color="primary" rounded min-width="120" @click="handleCompleteFlow">
                {{ $t("modals.allowCloudService.step3.actionBtn") }}
              </v-btn>
              <v-btn class="ml-2" variant="text" color="primary" @click="currentStep = 2">
                {{ $t("general.back") }}
              </v-btn>
            </div>
          </div>
        </v-stepper-vertical-item>
      </template>
    </v-stepper-vertical>
    <div v-if="isFlowCompleted">
      <div class="success-block d-flex align-center pa-2">
        <v-icon class="mr-3 ml-4" icon="$circleCheck" />
        <span
          v-html="
            $t('modals.allowCloudService.successMessage', { service: $t(`services.${service}`) })
          "
          class="body2 text-green"
        />
      </div>
      <div class="d-flex justify-end">
        <v-btn class="mt-12" color="primary" rounded @click="closeModal">
          {{ $t("general.close") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { type PropType, computed, defineComponent, ref } from "vue";
import { Service } from "@/constants/cloud-apps.ts";
import { useI18n } from "vue-i18n";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module.ts";
import type { SecurityAwarenessAction } from "@/constants/security-awareness.ts";
import { useSecurityAwarenessModule } from "@/_store/security-awareness/security-awareness.module.ts";

export interface AllowSecurityAwarenessModalConfig {
  action: SecurityAwarenessAction;
  item: {
    service: Service;
  };
}

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<AllowSecurityAwarenessModalConfig>,
      required: true,
    },
    disable: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["dismissed"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const securityAwarenessStore = useSecurityAwarenessModule();
    const cloudAppsStore = useCloudAppsStore();
    const currentStep = ref(1);
    const service = ref(props.config.item.service);
    const loading = ref(false);
    const isFlowCompleted = ref(false);

    const isGoogle = computed(() => {
      return service.value === Service.G_SUITE;
    });

    const modalTitle = computed(() => {
      return i18n.t("modals.allowCloudService.title", { service: service.value });
    });

    const cloudEmailServiceName = computed(() => {
      return isGoogle.value ? i18n.t("general.gmail") : i18n.t(`services.${Service.OFFICE_365}`);
    });

    const guideLink = computed(() => {
      // @TODO Replace with actual guide links when available
      return isGoogle.value
        ? "https://support.google.com/a/answer/60751?hl=en"
        : "https://docs.microsoft.com/en-us/microsoft-365/security/office-365-security/set-up-anti-phishing-policies";
    });

    const sendTestEmails = async () => {
      loading.value = true;
      try {
        await securityAwarenessStore.sendTestEmails(service.value);
        currentStep.value++;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const handleCompleteFlow = async () => {
      try {
        await securityAwarenessStore.confirmEmails(service.value);
        isFlowCompleted.value = true;
        await cloudAppsStore.getServices();
      } catch (err) {
        console.error(err);
      }
    };

    const closeModal = () => {
      emit("dismissed");
    };

    return {
      currentStep,
      service,
      modalTitle,
      guideLink,
      sendTestEmails,
      loading,
      handleCompleteFlow,
      isFlowCompleted,
      cloudEmailServiceName,
      closeModal,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-stepper-vertical__item:not(:last-child)) {
  .v-stepper-vertical__item:not(:last-child)::after {
    border-left: 1px solid rgba(var(--v-theme-on-surface));
  }
}

:deep(*) {
  .icon-check:before {
    color: rgba(var(--v-theme-white));
  }

  .step-completed {
    .v-avatar {
      background: rgba(var(--v-theme-success)) !important;
    }
  }
}
</style>
