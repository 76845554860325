import { defineStore } from "pinia";
import type { OpenSearchCursor, OpenSearchQuery, Pagination } from "@/types";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { ActivityLogsType } from "@/constants/activity-logs";
import moment from "moment/moment";
import { useFiltersStore } from "@/_store/filters.module";
import { i18n } from "@/plugins/i18n";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { parseOpenSearchQuery, getOpenSearchQuery } from "@/_helpers/utils.ts";

export interface ActivityLogsState {
  loading: boolean;
  showSkeletonLoader: boolean;
  items: ActivityLogItem[];
  total: number;
  pages: number;
  pagination: Pagination;
  cursor: OpenSearchCursor;
  openSearchQuery: OpenSearchQuery;
}

export interface ActivityLogItem {
  id: string;
  operatorEmail: string;
  date: number;
  type: ActivityLogsType;
  description: string;
  showUndo: boolean;
  undo: boolean;
  hidden: boolean;
  autoGenerated: boolean;
  eventId?: string;
  metadata?: any;
}

export interface ActivityLogsFilters {
  search?: string | null;
  types?: ActivityLogsType[];
  timeFrame: {
    start: string;
    end: string;
  };
}

const defaultState: ActivityLogsState = {
  showSkeletonLoader: false,
  loading: false,
  items: [],
  total: 0,
  pages: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  cursor: {
    prevPage: {},
    nextPage: {},
  },
  openSearchQuery: {},
};

export const useActivityLogsStore = defineStore("activityLogs", {
  state: (): ActivityLogsState => ({ ...defaultState }),
  actions: {
    setPagination(pagination: Pagination) {
      this.openSearchQuery = getOpenSearchQuery(
        pagination,
        this.pagination,
        this.pages,
        this.cursor
      );
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = { ...defaultState.pagination };
    },
    resetState() {
      this.$reset();
    },
    setLogs({ items, total }: { items: ActivityLogItem[]; total: number }) {
      this.items = items;
      this.total = total;
      this.pages = Math.ceil(total / this.pagination.pageSize);
    },
    async getActivityLogs(showSkeletonLoader = true): Promise<void> {
      const filters = useFiltersStore();
      const { search, timeFrame, types } = filters.filters.activityLogsFilters;

      if (showSkeletonLoader) {
        this.showSkeletonLoader = true;
      } else {
        this.loading = true;
      }

      try {
        const request = {
          ...api.getActivityLogs(),
          params: {
            ...parseOpenSearchQuery(this.openSearchQuery),
            pageSize: this.pagination.pageSize,
            search: search || "",
            toTime: timeFrame?.end ? moment(timeFrame?.end).endOf("day").utc().valueOf() : null,
            fromTime: timeFrame?.start
              ? moment(timeFrame?.start).startOf("day").utc().valueOf()
              : null,
            types: types?.length ? types.join(",") : null,
          },
        };

        const { data } = await axiosInstance.request(request);
        this.cursor = data.cursor;
        this.setLogs(data);
        this.showSkeletonLoader = false;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.showSkeletonLoader = false;
        this.loading = false;
      }
    },
    async undoAction(id: string): Promise<void> {
      this.loading = true;

      try {
        const request = { ...api.activityLogsUndo(id) };

        await axiosInstance.request(request);

        await this.getActivityLogs(false);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async exportCsv(): Promise<void> {
      const filtersStore = useFiltersStore();
      const snackbarStore = useSnackbarStore();
      const { search, timeFrame, types } = filtersStore.filters.activityLogsFilters;
      try {
        const request = {
          ...api.activityLogsExportCSV,
          params: {
            search,
            types,
            toTime: timeFrame?.end ? moment(timeFrame?.end).endOf("day").utc().valueOf() : null,
            fromTime: timeFrame?.start
              ? moment(timeFrame?.start).startOf("day").utc().valueOf()
              : null,
          },
        };

        await axiosInstance.request(request);

        snackbarStore.addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
});
