export enum SecurityAwarenessTraining {
  SECURITY = "security",
  PHISHING = "phishing",
  COMPLIANCE_PCIDDS = "compliancePCIDDS",
  COMPLIANCE_GDPR = "complianceGDPR",
  COMPLIANCE_HIPPA = "complianceHIPPA",
  ADAPTIVE = "adaptive",
}

export enum SecurityAwarenessAction {
  ALLOW_CLOUD_SERVICE = "allowCloudService",
}

export enum ComplianceTraining {
  GDPR = "GDPR",
  HIPAA = "HIPAA",
  PCI_DSS = "PCI_DSS",
}
