import { defineStore } from "pinia";
import type { AxiosError, AxiosRequestConfig } from "axios";
import api from "@/_helpers/api";
import router from "@/_helpers/router";
import { axiosInstance } from "@/plugins/https";
import type {
  GlobalRolePermissions,
  MspPortalPermissions,
  WorkspaceRolePermissions,
} from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { AccountErrors, MfaStatus } from "@/constants/account";
import {
  extractCoroSubdomain,
  navigateWithReload,
  parseJwt,
  resetPersistedStores,
} from "@/_helpers/utils";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import type { Service } from "@/constants/cloud-apps";
import type { WorkspaceType } from "@/constants/workplaces";
import { WorkspaceLocale } from "@/constants/workplaces";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { i18n } from "@/plugins/i18n";
import { useMyAccountStore } from "@/_store/my-account.module";
import { RouteName } from "@/constants/routes";
import { camelCase } from "lodash";
import { useMfaStore } from "@/_store/mfa.module";
import { useTutorialStore } from "@/_store/tutorial.module.ts";
import { useGtm } from "@gtm-support/vue-gtm";

export const appLogoDefaultPath = "/images/logos/coro-logo-small.svg";

export interface LoginData {
  token: string;
  refreshToken: string;
  mfaConfig: MfaStatus;
}

type AccountState = {
  error: string;
  requestInProgress: boolean;
  requestCompleted: boolean;
  email: string;
  token: string;
  workplace: string;
  languageCode: WorkspaceLocale;
  customerName: string;
  refreshToken: string;
  service: string;
  inviteAccepted: boolean;
  dashboardGuideShown: boolean;
  controlPanelGuideShown: boolean;
  psaEnabled: boolean;
  isDemoModeEnabled: boolean;
  workspaceType: WorkspaceType;
  appLogo: string;
  socialLogin: boolean;
  domain: string;
  brandingAlias: string;
  brandColor: string;
  supportEnabled: boolean;
  isCoronetWorkspace: boolean;
  // null means that user logged in the workspace is not the workspace admin thus he has some global role
  currentWorkspacePermissions: WorkspaceRolePermissions | null;
  globalWorkspacePermissions: GlobalRolePermissions | null;
  mspPortalPermissions: MspPortalPermissions | null;
  showDisabledModules: boolean;
  parentWorkspaceName?: string;
  hasMultipleWorkspaces: boolean;
};

export interface AccountStateVue2 {
  account: AccountState;
}

const RECAPTCHA_SITE_KEY = "6Le7dXgUAAAAAKD_vdTFFmWPA6-4wTwNsU2WpTC6";

const defaultAccountState: AccountState = {
  error: "",
  requestInProgress: false,
  requestCompleted: false,
  email: "",
  token: "",
  workplace: "",
  customerName: "",
  refreshToken: "",
  service: "",
  inviteAccepted: false,
  dashboardGuideShown: true,
  controlPanelGuideShown: true,
  psaEnabled: false,
  isDemoModeEnabled: false,
  workspaceType: "" as WorkspaceType,
  appLogo: appLogoDefaultPath,
  socialLogin: false,
  domain: "",
  brandingAlias: "",
  brandColor: "",
  supportEnabled: true,
  isCoronetWorkspace: false,
  currentWorkspacePermissions: null,
  globalWorkspacePermissions: null,
  showDisabledModules: false,
  mspPortalPermissions: null,
  languageCode: WorkspaceLocale.EN_US,
  hasMultipleWorkspaces: false,
};

export const useAccountStore = defineStore("account", {
  state: (): AccountStateVue2 => cloneDeep({ account: cloneDeep(defaultAccountState) }),
  persist: true,
  getters: {
    logged(state) {
      return Boolean(state.account.email && state.account.token && state.account.refreshToken);
    },
    isGlobalAdmin(state) {
      return !isEmpty(state.account.globalWorkspacePermissions);
    },
    hasMspPermissions(state) {
      return !isEmpty(state.account.mspPortalPermissions);
    },
    displayLanguage(state) {
      const routePreferredLocale = router.currentRoute.value.meta.preferredLocale;
      if (routePreferredLocale) {
        return routePreferredLocale;
      }
      const myAccountStore = useMyAccountStore();
      const preferredLanguage = myAccountStore.myAccount.profileData.preferredLanguageCode;
      return preferredLanguage || state.account.languageCode || WorkspaceLocale.EN_US;
    },
  },
  actions: {
    async login(credentials: { userName: string; password: string }) {
      const brandingAlias = this.account.brandingAlias;
      const params = brandingAlias && { brandingAlias };
      const request: AxiosRequestConfig = {
        ...api.login,
        data: credentials,
        params,
      };

      this.account.requestInProgress = true;
      try {
        const token = await getRecaptchaToken();
        request.headers = { "g-recaptcha-response": token };

        const { data } = await axiosInstance.request(request);
        await this.processLoginData(data);
        this.account.requestInProgress = false;
      } catch (e: any) {
        if (!e.response) {
          this.account.error = AccountErrors.GENERIC;
        } else if (e.response.status === 403) {
          this.account.error = camelCase(e.response.data.message);
        }
        this.account.requestInProgress = false;
        console.error(e);
      }
    },
    async loginViaSSO(token: string) {
      const request = {
        ...api.loginViaSSO(token),
      };

      this.setError("");
      this.setRequestInProgress(true);

      try {
        const res = await axiosInstance.request(request);
        await this.processLoginData(res.data);
      } catch (e) {
        const error = e as AxiosError<{ message?: string }>;
        if (!error.response || !error.response.data.message) {
          this.setError(i18n.global.t(`sso.errors.${AccountErrors.GENERIC}`));
        } else {
          this.setError(error.response.data.message);
        }
      } finally {
        this.setRequestInProgress(false);
      }
    },
    async socialLogin(service: Service) {
      const brandingAlias = this.account.brandingAlias;
      const request = {
        ...api.socialLogin(service, brandingAlias),
      };
      this.account.service = service;
      try {
        const { data } = await axiosInstance.request(request);
        window.location.href = data.redirectUrl;
      } catch (e) {
        console.error(e);
      }
    },
    async socialSignUp(
      service: Service,
      utmParams: object,
      languageCode: WorkspaceLocale
    ): Promise<void> {
      const brandingAlias = this.account.brandingAlias;
      const request = {
        ...api.socialSignUp(),
        params: {
          service,
          brandingAlias,
          languageCode,
          ...utmParams,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        window.location.href = data.redirectUrl;
      } catch (e) {
        console.error(e);
      }
    },
    async verifyPublicEmail(
      email: string
    ): Promise<{ valid: true } | { valid: false; message: string }> {
      try {
        const token = await getRecaptchaToken();
        const request = {
          ...api.verifyPublicEmail(email),
          headers: { "g-recaptcha-response": token },
        };

        await axiosInstance.request(request);
        return { valid: true };
      } catch (e) {
        console.error(e);
        const error = e as AxiosError<{ errors: [string] }>;
        const [message] = error.response?.data.errors ?? [i18n.global.t("errors.generic")];
        return { valid: false, message };
      }
    },
    async requestSignUpVerificationCode({
      email,
      languageCode,
      password,
    }: {
      email: string;
      password: string;
      languageCode: WorkspaceLocale;
    }): Promise<{ success: boolean; message?: string }> {
      try {
        const token = await getRecaptchaToken();

        const request: AxiosRequestConfig = {
          ...api.requestSignUpVerificationCode(),
          method: "POST",
          data: {
            email,
            password,
            languageCode,
          },
          headers: { "g-recaptcha-response": token },
        };

        await axiosInstance.request(request);
        return { success: true };
      } catch (e) {
        console.error(e);
        const error = e as AxiosError<{ errors: [string] }>;
        const [message] = error.response?.data.errors ?? [i18n.global.t("errors.generic")];
        return { success: false, message };
      }
    },
    async completeSignUp(
      payload: {
        email: string;
        password: string;
        languageCode: WorkspaceLocale;
        verificationCode?: string;
      },
      utmParams: { [key: string]: string } = {}
    ): Promise<{ success: true } | { success: false; message: string }> {
      try {
        const token = await getRecaptchaToken();
        const request = {
          ...api.completeSignUp(),
          method: "POST",
          data: {
            ...payload,
          },
          params: {
            ...utmParams,
          },
          headers: { "g-recaptcha-response": token },
        };
        const { data } = await axiosInstance.request<{ token: string; refreshToken: string }>(
          request
        );
        await this.processLoginData({ ...data, mfaConfig: MfaStatus.NONE });
        useTutorialStore().$patch({ isNewUser: true });
        const gtm = useGtm();
        if (gtm?.enabled()) {
          window.dataLayer?.push({ event: `Signup clicked - with email` });
        }
        return { success: true };
      } catch (err) {
        console.error(err);
        const error = err as AxiosError<{ errors: [string] }>;
        const [message] = error.response?.data.errors ?? [i18n.global.t("errors.generic")];
        return {
          success: false,
          message,
        };
      }
    },
    async processLoginData(payload: LoginData) {
      const workspacesStore = useWorkspacesStore();
      const hasNavigationFromEmail = false;
      const { refreshToken, token, mfaConfig } = payload;
      const { sub } = parseJwt(token) ?? {};
      const email = sub ?? this.account.email;
      this.$patch({ account: { email } });

      if (mfaConfig === MfaStatus.TOTP) {
        const mfaStore = useMfaStore();
        mfaStore.$patch({
          mfaFlowToken: token,
        });
        await router.push({ name: RouteName.MFA_VERIFICATION });
        return;
      }

      try {
        const workspacesRequest = {
          ...api.workplaces({
            name: "",
            type: "",
            page: 0,
            pageSize: 25,
          }),
          headers: {
            Authorization: `Bearer ${token}`, //the token is a variable which holds the token
          },
          method: "get",
        };

        const { data } = await axiosInstance.request(workspacesRequest);
        const multipleWorkspaces = data.total > 1;
        this.account.hasMultipleWorkspaces = multipleWorkspaces;
        if (multipleWorkspaces || hasNavigationFromEmail) {
          this.$patch({
            account: {
              token,
              refreshToken,
            },
          });
          await this.getProfileInformation(multipleWorkspaces);
          this.account.requestInProgress = false;
        } else {
          const workspace = data.items[0];
          this.$patch({
            account: {
              token,
              refreshToken,
              workplace: workspace.workspaceId,
            },
          });
          await this.getProfileInformation(multipleWorkspaces);
          await workspacesStore.updateWorkspaceAndPermissions(workspace.workspaceId);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getProfileInformation(multipleWorkspaces: boolean) {
      const myAccountStore = useMyAccountStore();
      try {
        await myAccountStore.getProfileData();
        if (multipleWorkspaces) {
          await router.push({ name: RouteName.WORKSPACES });
        } else {
          await router.push({
            name: RouteName.DASHBOARD,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getRefreshToken() {
      return axiosInstance.request(api.refreshToken());
    },
    async logout() {
      try {
        await axiosInstance.request(api.logout());
      } catch (e) {
        console.error(e);
      } finally {
        const subdomain = extractCoroSubdomain(window.location.hostname);
        subdomain ? this.resetStateWithoutBranding() : this.$reset();
        resetPersistedStores();
        useDialogsStore().closeDialog();
        localStorage.setItem("logout-event", `logout${Math.random()}`);
        // we need to reload page on logout in order to reload chameleon banner
        navigateWithReload(RouteName.LOGIN);
      }
    },
    async forgotPassword(payload: { email: string; showSnackbar?: boolean }) {
      const { email, showSnackbar } = payload;
      const request = {
        ...api.forgotPassword(email, this.account.brandingAlias),
      };
      try {
        await axiosInstance.request(request);
        if (showSnackbar) {
          useSnackbarStore().addGenericSuccess(
            i18n.global.t("snackbar.messages.resetPassword.linkSent", { email })
          );
        }
      } catch (e) {
        console.error(e);
        if (!(e as AxiosError).response) {
          this.account.error = AccountErrors.GENERIC;
        } else if ((e as AxiosError).response?.status === 404) {
          this.account.error = AccountErrors.EMAIL_NOT_FOUND;
        }
      }
    },
    async resetPassword(data: { token: string; newPassword: string }) {
      this.setRequestCompleted(false);
      this.setRequestInProgress(true);

      try {
        await axiosInstance.request({ ...api.resetPassword, data });
        this.setRequestCompleted(true);
      } catch (e) {
        console.error(e);

        const error = e as AxiosError<{ message?: string }>;
        if (!error.response) {
          this.account.error = AccountErrors.GENERIC;
          return;
        }

        const status = error.response?.status;
        const message = error.response?.data?.message;

        if (status === 400) {
          switch (message) {
            case "Reset password link is expired or not valid.":
              this.account.error = AccountErrors.RESET_LINK_INVALID;
              break;
            case "Password should be min 8 symbols and contain uppercase, numeric and special character.":
              this.account.error = AccountErrors.WEAK_PASSWORD;
              break;
            default:
              this.account.error = AccountErrors.PASSWORD_SHOULD_BE_DIFFERENT;
              break;
          }
        }
      } finally {
        this.setRequestInProgress(false);
      }
    },
    async requestBrandingInfo(alias: string) {
      const request = {
        ...api.getBranding(alias),
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.account.brandingAlias = alias;
        this.setAppLogo(data.headerIconUrl);
        this.setBrandColor(data.brandColor);
      } catch {
        this.account.brandingAlias = "";
        if (!this.logged) {
          this.setAppLogo(appLogoDefaultPath);
          this.setBrandColor("");
        }
      }
    },
    setWorkspace(workplace: string) {
      this.account.workplace = workplace;
    },
    setError(value: string) {
      this.account.error = value;
    },
    setRequestInProgress(value: boolean) {
      this.account.requestInProgress = value;
    },
    setRequestCompleted(value: boolean) {
      this.account.requestCompleted = value;
    },
    setCustomerName(customerName: string) {
      this.account.customerName = customerName;
    },
    setWorkspaceType(payload: WorkspaceType) {
      this.account.workspaceType = payload;
    },
    setAppLogo(logo?: string) {
      if (logo) {
        this.account.appLogo = logo;
      } else {
        this.account.appLogo = appLogoDefaultPath;
      }
    },
    setDomain(domain: string) {
      this.account.domain = domain;
    },
    setBrandColor(color?: string) {
      this.account.brandColor = color ? color : "";
    },
    setSupportEnabled(supportEnabled: boolean) {
      this.account.supportEnabled = supportEnabled;
    },
    setIsCoronetWorkspace(isCoronetWorkspace: boolean) {
      this.account.isCoronetWorkspace = isCoronetWorkspace;
    },
    setPsaEnabled(payload: boolean) {
      this.account.psaEnabled = payload;
    },
    setShowDisabledModules(payload: boolean) {
      this.account.showDisabledModules = payload;
    },
    setMspPortalPermissions(payload: MspPortalPermissions | null) {
      this.account.mspPortalPermissions = payload;
    },
    setGlobalPermissions(payload: GlobalRolePermissions | null) {
      this.account.globalWorkspacePermissions = payload;
    },
    setToken(payload: string) {
      this.account.token = payload;
    },
    resetStateWithoutBranding() {
      // Preserve specified fields
      const { brandingAlias, brandColor, appLogo } = this.account;
      this.account = {
        ...cloneDeep(defaultAccountState),
        brandingAlias,
        brandColor,
        appLogo,
      };
    },
    setCurrentWorkspacePermissions(permissions: WorkspaceRolePermissions | null) {
      this.account.currentWorkspacePermissions = permissions;
    },
  },
});

async function getRecaptchaToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: "action_name" });
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  });
}
