<template>
  <div class="content-md margin-auto">
    <div class="d-flex justify-space-between align-center w-100 mt-4 mb-4">
      <div class="headline5 mb-2">
        {{ $t("manageWorkspaces.title") }}
      </div>
      <msp-create-workspace-button />
    </div>
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
      class="skeleton-loader--table"
    >
      <div class="d-flex justify-end">
        <filter-wrapper
          class="mb-5"
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters(clearFiltersCallback)"
        >
          <v-select
            v-model="localFilters.subscriptionTypes"
            :items="plans"
            :menu-props="{ maxHeight: '320', width: 275 }"
            class="filter-menu mr-2"
            rounded
            menu-icon="icon-triangle"
            :class="{ 'filter-active': localFilters.subscriptionTypes?.length }"
            :placeholder="$t('manageWorkspaces.filters.plan')"
            density="compact"
            variant="outlined"
            multiple
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("manageWorkspaces.filters.plan")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.subscriptionTypes.length" class="filter-label">
                {{ localFilters.subscriptionTypes.length }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <template v-slot:title>
                  {{ $t(`subscriptions.plans.${item.title}`) }}
                </template>
              </v-list-item>
            </template>
            <template
              #append-item
              v-if="localFilters.subscriptionTypes.includes(SubscriptionType.TRIAL)"
            >
              <v-divider class="my-1"></v-divider>
              <v-list-item
                :title="$t('manageWorkspaces.filters.expiringWithin7Days')"
                @click="localFilters.nearExpirationOnly = !localFilters.nearExpirationOnly"
              >
                <template #prepend>
                  <v-list-item-action start>
                    <v-switch
                      :model-value="localFilters.nearExpirationOnly"
                      color="primary"
                      inset
                      :ripple="false"
                      density="compact"
                      hide-details
                    >
                    </v-switch>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.labels"
            :items="labels"
            :menu-props="{ maxHeight: '300' }"
            class="filter-menu addon-filter mr-2"
            menu-icon="icon-triangle"
            :class="{ 'filter-active': localFilters.labels?.length }"
            :placeholder="$t('manageWorkspaces.filters.labels')"
            density="compact"
            variant="outlined"
            multiple
            rounded
            item-value="id"
            item-title=""
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("manageWorkspaces.filters.labels")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.labels.length" class="filter-label">{{
                localFilters.labels.length
              }}</span>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <template v-slot:title>
                  <div class="d-flex align-center label-popup-block">
                    <div
                      class="color-preview mr-2"
                      :style="{ 'background-color': item.raw.color }"
                    />
                    <span>{{ item.raw.name }}</span>
                  </div>
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.addons"
            :items="bundlesModuleAddons"
            :menu-props="{ maxHeight: '300' }"
            class="filter-menu addon-filter mr-2"
            menu-icon="icon-triangle"
            :class="{ 'filter-active': localFilters.addons?.length }"
            :placeholder="$t('manageWorkspaces.filters.addons')"
            density="compact"
            variant="outlined"
            multiple
            rounded
            return-object
            item-value="name"
            item-title=""
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("manageWorkspaces.filters.addons")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.addons.length" class="filter-label">{{
                localFilters.addons.length
              }}</span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="item.raw.type === 'subheader'" :title="item.raw.name" />
              <v-list-item v-else v-bind="props">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <template v-slot:title>
                  {{ getAddonFilterTranslation(item.raw) }}
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.types"
            :items="workspaceTypes"
            :menu-props="{ maxHeight: '300' }"
            menu-icon="icon-triangle"
            class="filter-menu mr-2"
            :class="{ 'filter-active': localFilters.types?.length }"
            :placeholder="$t('manageWorkspaces.filters.type')"
            density="compact"
            rounded
            variant="outlined"
            multiple
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("manageWorkspaces.filters.type")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.types.length" class="filter-label">{{
                localFilters.types.length
              }}</span>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <template v-slot:title>
                  {{ $t(`manageWorkspaces.workspaceTypes.${item.title}`) }}
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-text-field
            v-model="localFilters.search"
            data-testid="users-page-users-search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            variant="outlined"
            rounded
            clearable
            persistent-clear
            clear-icon="icon-x"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
          />
        </filter-wrapper>
      </div>
      <manage-workspaces-table
        :items="workspaces"
        :item-actions="actions"
        :pagination="pagination"
        :loading="loading"
        @item-action-applied="handleAction($event)"
        @bulk-action-clicked="handleBulkAction($event)"
        @page-changed="onPageChange($event)"
        :total="total"
        :filters-update-time="filtersChangeTimestamp"
      />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import ManageWorkspacesTable from "@/components/tables/ManageWorkspacesTable.vue";
import {
  defaultSubscriptionModulesConfig,
  MspWorkspaceAction,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionsFilterType,
  SubscriptionType,
  WorkspaceType,
} from "@/constants/workplaces";
import { i18n } from "@/plugins/i18n";
import { useAccountStore } from "@/_store";
import {
  type EditSubscriptionData,
  type MspWorkspaceListItem,
  useMspStore,
} from "@/_store/msp/msp.module.ts";
import { FilterContext, type MspAddonFilterItem, useFiltersStore } from "@/_store/filters.module";
import type { Pagination } from "@/types";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { useFilters } from "@/composables/useFilters";
import { storeToRefs } from "pinia";
import { type DialogDataConfig, useDialogsStore } from "@/_store/dialogs.module";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  useResetStore,
} from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import ExtendTrialModal from "@/components/modals/msp/ExtendTrialModal.vue";
import ConvertWorkspaceModal from "@/components/modals/msp/ConvertWorkspaceModal.vue";
import EditMspWorkspaceModal from "@/components/modals/msp/EditMspWorkspaceModal.vue";
import defaultsDeep from "lodash/defaultsDeep";
import { useRouter } from "vue-router";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  MspRolePermissionScopes,
} from "@/_store/roles.module";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import UpdateBillingSubscriptionModal from "@/components/modals/subscription/UpdateSubscriptionModal.vue";
import isEqual from "lodash/isEqual";
import { RouteName } from "@/constants/routes.ts";
import cloneDeep from "lodash/cloneDeep";
import MspCreateWorkspaceButton from "@/views/msp/MspCreateWorkspaceButton.vue";
import { type MspLabel, useMspLabelsStore } from "@/_store/msp/msp-labels.module.ts";
import EditMspWorkspaceLabelsModal from "@/components/modals/msp/EditMspWorkspaceLabelsModal.vue";
import { isMspActionRestricted } from "@/_helpers/msp-permissions.ts";

const bundlesModuleAddons: MspAddonFilterItem[] = [
  { type: "subheader", name: i18n.global.t("subscriptions.bundles.title") },
  {
    name: SubscriptionBundle.ENDPOINT_PROTECTION,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.EMAIL_PROTECTION,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.SASE,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.MANAGED_SASE,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.CORO_ESSENTIALS,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.CORO_CLASSIC,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.MANAGED_CORO_CLASSIC,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.CORO_COMPLETE,
    type: SubscriptionsFilterType.BUNDLE,
  },
  {
    name: SubscriptionBundle.MANAGED_CORO_COMPLETE,
    type: SubscriptionsFilterType.BUNDLE,
  },
  { type: "subheader", name: i18n.global.t("subscriptions.modules.title") },
  {
    name: SubscriptionModule.CLOUD_SECURITY,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.ENDPOINT_SECURITY,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.EMAIL_SECURITY,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.USER_DATA_GOVERNANCE,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.EDR,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.NETWORK,
    type: SubscriptionsFilterType.MODULE,
  },
  {
    name: SubscriptionModule.MDM,
    type: SubscriptionsFilterType.MODULE,
  },
  { type: "subheader", name: i18n.global.t("subscriptions.addons.title") },
  {
    name: SubscriptionAddon.SOC_EMAIL,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.SOC_CLOUD,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.SOC_ENDPOINT,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.MDR,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.WIFI_PHISHING,
    type: SubscriptionsFilterType.ADDON,
  },
  {
    name: SubscriptionAddon.SECURED_MESSAGES,
    type: SubscriptionsFilterType.ADDON,
  },
  // @TODO uncomment in 3.0
  // {
  //   name: SubscriptionAddon.INBOUND_GATEWAY,
  //   type: SubscriptionsFilterType.ADDON
  // },
  // {
  //   name: SubscriptionAddon.OUTBOUND_GATEWAY,
  //   type: SubscriptionsFilterType.ADDON
  // },
  {
    name: SubscriptionAddon.SWG,
    type: SubscriptionsFilterType.ADDON,
  },
];

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false,
    },
  },
  components: {
    ManageWorkspacesTable,
    MspCreateWorkspaceButton,
    FilterWrapper,
  },
  setup(props) {
    const { localFilters, showClearFiltersButton, clearFilters, filtersUpdating } = useFilters(
      FilterContext.MSP
    );
    const filtersChangeTimestamp = ref(0);
    const filtersStore = useFiltersStore();
    const accountStore = useAccountStore();
    const dialogsStore = useDialogsStore();
    const labelsStore = useMspLabelsStore();
    const workspaceStore = useWorkspacesStore();
    const mspStore = useMspStore();
    const router = useRouter();
    const { showSkeletonLoader, loading, workspaces, total, pagination } = storeToRefs(mspStore);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(workspaceStore);
    const { labels } = storeToRefs(labelsStore);
    const isGlobalAdmin = accountStore.isGlobalAdmin;

    const workspaceTypes = computed(() => {
      if (isGlobalAdmin) {
        return [WorkspaceType.CHILD, WorkspaceType.CHANNEL, WorkspaceType.REGULAR];
      }
      return [WorkspaceType.CHILD, WorkspaceType.CHANNEL];
    });

    const plans = computed(() => {
      if (isGlobalAdmin) {
        return [
          SubscriptionType.INITIAL,
          SubscriptionType.SUBSCRIPTION,
          SubscriptionType.ARCHIVED,
          SubscriptionType.FREEZE,
          SubscriptionType.TRIAL,
        ];
      }
      return [
        SubscriptionType.INITIAL,
        SubscriptionType.SUBSCRIPTION,
        SubscriptionType.FREEZE,
        SubscriptionType.TRIAL,
      ];
    });

    const getAddonFilterTranslation = (item: MspAddonFilterItem) => {
      switch (item.type) {
        case "addon":
          return i18n.global.t(`subscriptions.addons.${item.name}`);
        case "module":
          return i18n.global.t(`subscriptions.modules.${item.name}`);
        case "bundle":
          return i18n.global.t(`subscriptions.bundles.${item.name}`);
        default:
          return null;
      }
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          await mspStore.getMspWorkspaces({ triggeredByFilters: true });
          filtersChangeTimestamp.value = new Date().getTime();
        }
      },
      { deep: true, immediate: true }
    );

    watch(workspaceAndPermissionsUpdatedTimestamp, async () => {
      mspStore.resetPagination();
      await mspStore.getMspWorkspaces();
    });

    watch(
      () => localFilters.value.subscriptionTypes,
      (newValue, oldValue) => {
        const trialWasRemoved =
          oldValue.includes(SubscriptionType.TRIAL) && !newValue.includes(SubscriptionType.TRIAL);
        if (newValue.length === 0 || trialWasRemoved) {
          localFilters.value.nearExpirationOnly = false;
        }
      },
      { deep: true }
    );

    const handleBulkAction = async ({
      itemAction,
      selection,
    }: {
      itemAction: MspWorkspaceAction;
      selection: string[];
    }) => {
      const openConfirmationDialog = (payload: DialogDataConfig<EditSubscriptionData>) => {
        useDialogsStore().closeDialog();
        nextTick(() => {
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item: {},
              disable: false,
              action: MspWorkspaceAction.BULK_SUBSCRIPTION_CONFIRMATION,
              callback: async () => {
                await mspStore.editSubscription({
                  ...payload.item,
                  workspaceIds: selection,
                  modulesRemovedDuringEdit: [],
                });
                filtersChangeTimestamp.value = new Date().getTime();
              },
            })
          );
        });
      };

      switch (itemAction) {
        case MspWorkspaceAction.START_BULK_SUBSCRIPTION:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item: {},
              action: itemAction,
              callback: async (payload) => {
                await mspStore.startSubscription({
                  item: selection,
                  action: payload.action,
                });
                filtersChangeTimestamp.value = new Date().getTime();
              },
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACES,
                "startSubscription"
              ),
            })
          );
          break;
        case MspWorkspaceAction.STOP_BULK_SUBSCRIPTION:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item: {},
              action: itemAction,
              callback: async (payload) => {
                await mspStore.stopSubscription({
                  item: selection,
                  action: payload.action,
                });
                filtersChangeTimestamp.value = new Date().getTime();
              },
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACES,
                "stopSubscription"
              ),
            })
          );
          break;
        case MspWorkspaceAction.ADD_MSP_BULK_LABEL:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action: itemAction,
              callback: async (payload) => {
                await mspStore.bulkEditLabels({
                  workspaceIds: selection,
                  labelIds: payload.item.labels.map((label: MspLabel) => label.id),
                });
                filtersChangeTimestamp.value = new Date().getTime();
              },
              component: EditMspWorkspaceLabelsModal,
              item: {
                labels: [],
              },
              width: ModalWidth.SMALL,
            }),
            disable: isMspActionRestricted(
              MspRolePermissionScopes.MSP_PORTAL_SCOPE,
              MspPortalScopeSection.MSP_WORKSPACES,
              "editWorkspace"
            ),
          });
          break;
        case MspWorkspaceAction.EDIT_SUBSCRIPTION:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action: itemAction,
              callback: async (payload) => {
                openConfirmationDialog(payload);
              },
              component: UpdateBillingSubscriptionModal,
              item: {
                bundles: [],
                modules: cloneDeep(defaultSubscriptionModulesConfig),
              },
              width: ModalWidth.LARGE,
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACES,
                "editSubscription"
              ),
            }),
            scrollable: true,
            fullscreen: true,
          });
          break;
      }
    };

    const handleAction = async ({
      itemAction,
      item,
    }: {
      itemAction: MspWorkspaceAction;
      item: MspWorkspaceListItem;
    }) => {
      const confirmationCallbackMap = {
        [MspWorkspaceAction.START_SUBSCRIPTION]: mspStore.startSubscription,
        [MspWorkspaceAction.STOP_SUBSCRIPTION]: mspStore.stopSubscription,
        [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: mspStore.unarchiveWorkspace,
        [MspWorkspaceAction.ARCHIVE_WORKSPACE]: mspStore.archiveWorkspace,
      } as const;

      const confirmationPermissionKey = {
        [MspWorkspaceAction.START_SUBSCRIPTION]: "startSubscription",
        [MspWorkspaceAction.STOP_SUBSCRIPTION]: "stopSubscription",
        [MspWorkspaceAction.ARCHIVE_WORKSPACE]: "archiveWorkspace",
      } as const;

      switch (itemAction) {
        case MspWorkspaceAction.EDIT_MSP_WORKSPACE_LABEL:
          const workspaceDetails = await mspStore.getMspWorkspaceDetails(item.workspaceId);
          if (!workspaceDetails) return;
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action: itemAction,
              callback: mspStore.editWorkspace,
              component: EditMspWorkspaceLabelsModal,
              item: workspaceDetails,
              width: ModalWidth.SMALL,
            }),
            disable: isMspActionRestricted(
              MspRolePermissionScopes.MSP_PORTAL_SCOPE,
              MspPortalScopeSection.MSP_WORKSPACES,
              "editWorkspace"
            ),
          });
          break;
        case MspWorkspaceAction.EDIT_WORKSPACE:
          const details = await mspStore.getMspWorkspaceDetails(item.workspaceId);
          if (!details) return;
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action: itemAction,
              callback: mspStore.editWorkspace,
              component: EditMspWorkspaceModal,
              item: details,
              width: ModalWidth.LARGE,
            }),
            disable: isMspActionRestricted(
              MspRolePermissionScopes.MSP_PORTAL_SCOPE,
              MspPortalScopeSection.MSP_WORKSPACES,
              "editWorkspace"
            ),
            scrollable: true,
            header: {
              title: i18n.global.t(`modals.editWorkspace.titles.${item.type}`),
            },
          });
          break;
        case MspWorkspaceAction.EDIT_SUBSCRIPTION:
          handleEditSubscriptionDialog(itemAction, item);
          break;
        case MspWorkspaceAction.CONVERT:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              action: itemAction,
              callback: mspStore.convertWorkspace,
              component: ConvertWorkspaceModal,
              item,
              width: ModalWidth.LARGE,
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
                "convertWorkspace"
              ),
            })
          );
          break;
        case MspWorkspaceAction.UNARCHIVE_WORKSPACE:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item,
              action: itemAction,
              callback: confirmationCallbackMap[itemAction],
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
                "restoreWorkspace"
              ),
            })
          );
          break;
        case MspWorkspaceAction.START_SUBSCRIPTION:
        case MspWorkspaceAction.STOP_SUBSCRIPTION:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item: [item.workspaceId],
              action: itemAction,
              callback: confirmationCallbackMap[itemAction],
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACES,
                confirmationPermissionKey[itemAction]
              ),
            })
          );
          break;
        case MspWorkspaceAction.ARCHIVE_WORKSPACE:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item,
              action: itemAction,
              callback: confirmationCallbackMap[itemAction],
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_WORKSPACES,
                confirmationPermissionKey[itemAction]
              ),
            })
          );
          break;
        case MspWorkspaceAction.EXTEND_TRIAL:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              action: itemAction,
              callback: mspStore.extendTrial,
              component: ExtendTrialModal,
              item,
              width: ModalWidth.LARGE,
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
                "extendTrial"
              ),
            })
          );
          break;
        case MspWorkspaceAction.VIEW_WORKSPACE:
          await workspaceStore.updateWorkspaceAndPermissions(item.workspaceId);
          useResetStore().allExceptIgnoreList();
          await useMspStore().checkMspAccess();
          await router.push({ name: RouteName.DASHBOARD });
          break;
        case MspWorkspaceAction.VIEW_PROTECTED_USERS:
          await workspaceStore.updateWorkspaceAndPermissions(item.workspaceId);
          useResetStore().allExceptIgnoreList();
          await useMspStore().checkMspAccess();
          await router.push({ name: RouteName.PROTECTED_USERS });
          break;
        case MspWorkspaceAction.VIEW_DEVICES:
          await workspaceStore.updateWorkspaceAndPermissions(item.workspaceId);
          useResetStore().allExceptIgnoreList();
          await useMspStore().checkMspAccess();
          await router.push({ name: RouteName.DEVICES });
          break;
      }
    };

    const onPageChange = async (pageObj: Pagination) => {
      if (isEqual(pageObj, pagination.value)) return;
      mspStore.$patch({ pagination: pageObj });
      await mspStore.getMspWorkspaces();
    };

    onMounted(async () => {
      const filtersFromQuery = {
        search: props.search,
      };

      localFilters.value = defaultsDeep(filtersFromQuery, localFilters.value);
      await mspStore.getMspWorkspaces({ showSkeletonLoader: true });
      await router.replace({});
      await labelsStore.getLabels(false);
    });

    onUnmounted(() => {
      filtersStore.resetFilters(FilterContext.MSP);
    });

    const clearFiltersCallback = async () => {
      mspStore.resetPagination();
      await mspStore.getMspWorkspaces();
    };

    function handleEditSubscriptionDialog(action: MspWorkspaceAction, item: MspWorkspaceListItem) {
      const openSubscriptionConfirmationDialog = (
        payload: MspWorkspaceListItem & {
          modulesWhichRemovedDuringEdit: SubscriptionModule[];
        }
      ) => {
        useDialogsStore().closeDialog();
        nextTick(() => {
          const removedModules = payload.modulesWhichRemovedDuringEdit
            .map((module) => {
              return i18n.global.t(`subscriptions.modules.${module}`);
            })
            .join(", ");
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item: {
                ...payload,
                removedModules,
              },
              action: MspWorkspaceAction.EDIT_SUBSCRIPTION_CONFIRMATION,
              callback: async (payload) => {
                const currentWorkspace = accountStore.account.workplace;
                await mspStore.editSubscription({ ...payload.item });
                if (currentWorkspace === payload.item.workspaceId) {
                  await useWorkspacesStore().updateWorkspaceAndPermissions(
                    payload.item.workspaceId
                  );
                }
              },
            })
          );
        });
      };

      dialogsStore.openDialog({
        ...componentDialogsConfigConstructor({
          action,
          callback: async (payload) => {
            if (payload.item.modulesWhichRemovedDuringEdit.length > 0) {
              openSubscriptionConfirmationDialog({
                ...payload.item,
                workspaceIds: [item.workspaceId],
              });
            } else {
              await mspStore.editSubscription({
                ...payload.item,
                workspaceIds: [item.workspaceId],
              });
            }
          },
          component: UpdateBillingSubscriptionModal,
          item: {
            modules: item.subscription.modules,
            bundles: item.subscription.bundles,
          },
          width: ModalWidth.LARGE,
          disable: isMspActionRestricted(
            MspRolePermissionScopes.MSP_PORTAL_SCOPE,
            MspPortalScopeSection.MSP_WORKSPACES,
            "editSubscription"
          ),
        }),
        scrollable: true,
        fullscreen: true,
      });
    }

    return {
      bundlesModuleAddons,
      coronetSkeletonLoaderTypes,
      actions: [
        MspWorkspaceAction.ARCHIVE_WORKSPACE,
        MspWorkspaceAction.UNARCHIVE_WORKSPACE,
        MspWorkspaceAction.EDIT_WORKSPACE,
        MspWorkspaceAction.VIEW_WORKSPACE,
        MspWorkspaceAction.CONVERT,
        MspWorkspaceAction.EDIT_SUBSCRIPTION,
        MspWorkspaceAction.STOP_SUBSCRIPTION,
        MspWorkspaceAction.START_SUBSCRIPTION,
        MspWorkspaceAction.EXTEND_TRIAL,
        MspWorkspaceAction.EDIT_MSP_WORKSPACE_LABEL,
      ],
      workspaceTypes,
      plans,
      localFilters,
      showClearFiltersButton,
      clearFilters,
      filtersUpdating,
      filtersChangeTimestamp,
      showSkeletonLoader,
      loading,
      workspaces,
      total,
      pagination,
      clearFiltersCallback,
      getAddonFilterTranslation,
      handleAction,
      onPageChange,
      handleBulkAction,
      labels,
      SubscriptionType,
    };
  },
});
</script>

<style scoped lang="scss">
.filter-menu {
  width: 100px;
}

.addon-filter {
  width: 150px;
}

.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
</style>
