<template>
  <div class="mb-4 d-flex justify-end">
    <filter-wrapper
      :show-clear-button="showClearFiltersButton"
      @clear-filters-clicked="clearFilters()"
    >
      <msp-workspace-descentant-select
        class="mr-2 filter-menu"
        :value="localFilters.childWorkspaceIds"
        @value-updated="localFilters.childWorkspaceIds = $event"
      />
      <v-select
        v-model="localFilters.statuses"
        :items="Object.values(DriveEncryptionStatus)"
        :menu-props="{ maxHeight: '300' }"
        class="filter-menu mr-2"
        :class="{
          'filter-active': localFilters.statuses && localFilters.statuses.length,
        }"
        :placeholder="$t('drives.filters.status')"
        density="compact"
        outlined
        multiple
        rounded
        hide-details
        background-color="white"
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="body2">{{ $t("drives.filters.status") }}</span>
        </template>
        <template #label>
          <span v-if="localFilters.statuses?.length" class="filter-label">{{
            localFilters.statuses.length
          }}</span>
        </template>
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="$t(`devices.drives.statuses.${camelCase(item.raw)}`)">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive" />
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-select>
      <v-select
        v-model="localFilters.types"
        :items="Object.values(DriveType)"
        :menu-props="{ maxHeight: '300' }"
        class="filter-menu mr-2"
        :class="{
          'filter-active': localFilters.types && localFilters.types.length,
        }"
        :placeholder="$t('drives.filters.type')"
        density="compact"
        outlined
        multiple
        rounded
        hide-details
        background-color="white"
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="body2">{{ $t("drives.filters.type") }}</span>
        </template>
        <template #label>
          <span v-if="localFilters.types?.length" class="filter-label">{{
            localFilters.types.length
          }}</span>
        </template>
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="$t(`devices.drives.types.${camelCase(item.raw)}`)">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive" />
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-select>
      <v-combobox
        v-model="localFilters.search"
        variant="outlined"
        clearable
        clear-icon="icon-x"
        background-color="white"
        :placeholder="$t('general.search')"
        class="search-field"
        hide-details
        density="compact"
        rounded
        bg-color="white"
        menu-icon=""
        persistent-clear
        data-testid="tickets-page-search-field"
        :class="{
          'filter-active': localFilters.search,
        }"
      >
      </v-combobox>
    </filter-wrapper>
  </div>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.HIGHLIGHTABLE_TABLE"
  >
    <v-progress-linear v-if="loading" indeterminate height="2px" />
    <table-wrapper>
      <v-data-table-server
        :items="drives"
        v-model:expanded="expanded"
        v-model="selectedItems"
        :show-expand="true"
        item-key="id"
        fixed-header
        expand-icon="icon-chevron"
        :items-length="totalDrives"
        :items-per-page="pagination.pageSize"
        select-strategy="all"
        show-select
        return-object
        @update:options="
          onPageChange({
            pageSize: $event.itemsPerPage,
            page: $event.page - 1,
          })
        "
        :no-data-text="$t('general.noDataAvailable')"
      >
        <template #headers>
          <tr>
            <th>
              <bulk-selector-checkbox
                class="ml-2"
                :is-indeterminate="isIndeterminate"
                :items="drives"
                :all-items-selected="allItemsSelected"
                :selection="selection"
                @selection-changed="onSelectionChanged"
              />
            </th>
            <template v-if="selectedItems.length === 0">
              <th />
              <th>
                {{ $t("drives.table.headers.workspace") }}
              </th>
              <th>
                {{ $t("drives.table.headers.driveName") }}
              </th>
              <th>
                {{ $t("drives.table.headers.deviceName") }}
              </th>
              <th>
                {{ $t("drives.table.headers.model") }}
              </th>
              <th>
                {{ $t("drives.table.headers.status") }}
              </th>
              <th />
            </template>
            <template v-else>
              <th :colspan="8">
                <span>{{ selectedItems.length }} {{ $t("general.selected") }}</span>
                <v-menu v-if="!!selectedItems.length" offset-y bottom right>
                  <template #activator="{ props }">
                    <v-btn
                      data-testid="bulk-actions-button"
                      class="ml-8"
                      rounded
                      color="primary"
                      v-bind="props"
                    >
                      {{ $t("general.actions") }}
                      <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="action in actionList"
                      :key="action"
                      @click="onBulkActionClick(action)"
                    >
                      <v-list-item-title>
                        {{ $t(`drives.table.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
            </template>
          </tr>
        </template>
        <template #item="{ item, toggleExpand, isExpanded, internalItem, index, isSelected }">
          <tr :key="index">
            <td class="checkbox-col">
              <v-checkbox-btn
                :data-testid="`table-checkbox-row-${index}`"
                :model-value="isSelected([internalItem])"
                @update:modelValue="handleOneRowSelection($event, item)"
                @click.stop
              />
            </td>
            <td class="w-5">
              <v-icon
                class="mr-2 cursor-pointer toggle-expander"
                icon="$dropdown"
                color="primary"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
              />
            </td>
            <td class="subtitle2">
              <span>{{ item.workspace }}</span>
            </td>
            <td class="body2">
              {{ `${item.friendlyName}(${item.name})` }}
            </td>
            <td class="body2">
              <div class="body2 coro-link" @click="goToDevices(item.enrollmentCode)">
                {{ item.hostname }}
              </div>
            </td>
            <td class="body2">
              {{ item.model }}
            </td>
            <td>
              <div :class="`${getStatusColor(item.status)}`" class="d-flex align-center">
                <span>{{ $t(`devices.drives.statuses.${item.status}`) }}</span>
                <v-tooltip
                  v-if="showIEncryptionFailedTooltip(item.status)"
                  open-delay="300"
                  location="top"
                >
                  <template #activator="{ props }">
                    <v-icon v-bind="props" class="ml-2" icon="$warning" size="16"> </v-icon>
                  </template>
                  <span v-html="$t('drives.table.encryptionFailedTooltip')" />
                </v-tooltip>
              </div>
            </td>
            <td class="text-right" @click="$event.stopPropagation()">
              <v-menu v-if="show3DotMenu(item.status)" bottom left>
                <template #activator="{ props }">
                  <v-btn v-bind="props" dark icon :data-testid="`${item.name}-actions-btn`">
                    <v-icon icon="$dots"></v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <template v-for="action in actionList">
                    <v-list-item
                      v-if="showAction(item.status, action)"
                      :key="action"
                      @click="onActionClick(item, action)"
                    >
                      <v-list-item-title>
                        {{ $t(`drives.table.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template #expanded-row="{ item, columns }">
          <td :colspan="columns.length" class="pl-16 pb-4">
            <div v-if="item.failedReason" class="mt-4 mr-16">
              <div class="warning-block d-flex align-center">
                <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                <span
                  class="body2"
                  v-html="
                    $t(`drives.table.failedReasons.${item.failedReason}`, {
                      time: getFormattedDateTime(
                        item.lastEncryptionAttempt,
                        'MMM, DD YYYY, h:mm A'
                      ),
                    })
                  "
                />
              </div>
            </div>
            <div class="subtitle2 text-primary mt-4">
              {{ $t("drives.table.type") }}
            </div>
            <div class="body2 text-indigo-medium">
              {{
                item.isRemovable
                  ? $t(`devices.drives.types.removable`)
                  : $t(`devices.drives.types.nonRemovable`)
              }}
            </div>
            <template v-if="item.recoveryKey">
              <div class="subtitle2 text-primary mt-4">
                {{ $t("drives.table.recoveryKey") }}
              </div>
              <div>{{ item.recoveryKey }}</div>
            </template>
            <template v-if="item.serialKey">
              <div class="subtitle2 text-primary mt-4">
                {{ $t("drives.table.serialKey") }}
              </div>
              <div>{{ item.serialKey }}</div>
            </template>
          </td>
        </template>
      </v-data-table-server>
    </table-wrapper>
  </v-skeleton-loader>
</template>

<script lang="ts">
import FilterWrapper from "@/components/FilterWrapper.vue";
import MspWorkspaceDescentantSelect from "@/components/msp/MspWorkspaceDescentantSelect.vue";
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { useFilters } from "@/composables/useFilters.ts";
import { FilterContext, useFiltersStore } from "@/_store/filters.module.ts";
import { DeviceAction, DriveEncryptionStatus, DriveType } from "@/constants/devices.ts";
import camelCase from "lodash/camelCase";
import { type DrivesItem, useMspDrivesStore } from "@/_store/msp/devices/msp-drives.module.ts";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader.ts";
import TableWrapper from "@/components/TableWrapper.vue";
import { RouteName } from "@/constants/routes.ts";
import { useRouter } from "vue-router";
import type { Pagination } from "@/types.ts";
import {
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { useI18n } from "vue-i18n";
import BulkSelectorCheckbox from "@/components/BulkSelectorCheckbox.vue";
import { useSelectableTable } from "@/composables/useSelectableTable.ts";
import { useSelectorStore } from "@/_store/selector.module.ts";

const filterContext = FilterContext.MSP_DRIVES;

export default defineComponent({
  components: {
    TableWrapper,
    FilterWrapper,
    MspWorkspaceDescentantSelect,
    BulkSelectorCheckbox,
  },
  setup() {
    const router = useRouter();
    const drivesStore = useMspDrivesStore();
    const filtersStore = useFiltersStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const { getMspDrives, applyDriveAction, resetPagination, setPagination } = drivesStore;
    const { showSkeletonLoader, loading, drives, totalDrives, pagination } =
      storeToRefs(drivesStore);
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } =
      useFilters(filterContext);
    const expanded = ref<string[]>([]);
    const disable = isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "devicesView");
    const {
      selectedItems,
      handleOneRowSelection,
      onSelectionChanged,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems: totalDrives,
      items: drives,
    });
    const { selection } = storeToRefs(useSelectorStore());

    const getStatusColor = (status: DriveEncryptionStatus) => {
      switch (status) {
        case DriveEncryptionStatus.FAILED:
        case DriveEncryptionStatus.NOT_ENCRYPTED:
        case DriveEncryptionStatus.SUSPENDED:
          return "text-red-dark";
      }
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          resetPagination();
          await getMspDrives();
        }
      },
      { deep: true, immediate: true }
    );

    onMounted(async () => {
      await getMspDrives(true);
    });

    onUnmounted(() => {
      filtersStore.resetFilters(FilterContext.MSP_DRIVES);
      drivesStore.$reset();
    });

    const goToDevices = (enrollmentCode: string) => {
      const mspDevicesTabRoute = router.resolve({
        name: RouteName.MSP_DEVICES_TAB,
        query: {
          search: enrollmentCode,
        },
      });
      window.open(mspDevicesTabRoute.href, "_blank");
    };

    const showAction = (status: DriveEncryptionStatus, action: DeviceAction) => {
      switch (action) {
        case DeviceAction.ENCRYPT_DRIVE:
          return [
            DriveEncryptionStatus.FAILED,
            DriveEncryptionStatus.SUSPENDED,
            DriveEncryptionStatus.NOT_ENCRYPTED,
          ].includes(status);
        case DeviceAction.DECRYPT_DRIVE:
          return [
            DriveEncryptionStatus.ENCRYPTED_BY_CORO,
            DriveEncryptionStatus.ENCRYPTED,
          ].includes(status);
      }
    };

    const showIEncryptionFailedTooltip = (status: DriveEncryptionStatus) => {
      return status === DriveEncryptionStatus.FAILED;
    };

    const onActionClick = (payload: DrivesItem, action: DeviceAction) => {
      const item = {
        selection: {
          isAllPages: false,
          include: [payload],
          exclude: [],
        },
      };
      dialogsStore.openDialog({
        ...confirmationDialogsConfigConstructor({
          action,
          item,
          callback: applyDriveAction,
          disable,
        }),
        header: {
          title: i18n.t(`modals.${action}.title`),
          titleClass: "text-red-dark",
          titleIcon: "warning",
        },
      });
    };

    const onBulkActionClick = (action: DeviceAction) => {
      const quantity = selectedItemsQuantity.value;

      const item = {
        action: action as DeviceAction,
        selection: { ...selection.value },
        quantity,
      };

      dialogsStore.openDialog({
        ...confirmationDialogsConfigConstructor({
          action,
          item,
          callback: applyDriveAction,
          disable,
        }),
        header: {
          title: i18n.t(`modals.${action}.title`, quantity),
          titleClass: "text-red-dark",
          titleIcon: "warning",
        },
        content: {
          html: i18n.t(`modals.${action}.description`, { quantity }, quantity),
        },
      });
    };

    const onPageChange = async (pagination: Pagination) => {
      setPagination(pagination);
      await getMspDrives();
    };

    const show3DotMenu = (status: DriveEncryptionStatus) => {
      return [
        DriveEncryptionStatus.FAILED,
        DriveEncryptionStatus.SUSPENDED,
        DriveEncryptionStatus.NOT_ENCRYPTED,
      ].includes(status);
    };

    return {
      DriveEncryptionStatus,
      DriveType,
      showClearFiltersButton,
      clearFilters,
      localFilters,
      camelCase,
      showSkeletonLoader,
      coronetSkeletonLoaderTypes,
      loading,
      drives,
      expanded,
      goToDevices,
      getStatusColor,
      onActionClick,
      totalDrives,
      pagination,
      actionList: [
        DeviceAction.ENCRYPT_DRIVE,
        // DeviceAction.DECRYPT_DRIVE Uncomment after 3.5 and possible disable execution for all pages selection
      ],
      onPageChange,
      selectedItems,
      onBulkActionClick,
      showAction,
      showIEncryptionFailedTooltip,
      getFormattedDateTime,
      handleOneRowSelection,
      onSelectionChanged,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      selection,
      show3DotMenu,
    };
  },
});
</script>

<style scoped lang="scss">
.filter-menu {
  width: 125px;
}
</style>
