<template>
  <v-stepper-vertical hide-actions v-model="currentStep" v-if="!config.item.signup">
    <template v-slot:default>
      <v-stepper-vertical-item
        value="1"
        :elevation="0"
        error-icon="$warning"
        :rules="firstStepRules"
      >
        <template #title>
          <div class="subtitle1">{{ $t("service.googleDirectoryEnforcement.step1.title") }}</div>
        </template>
        <template #subtitle>
          <span v-if="errorCode" class="caption text-red-dark" data-testid="google-step1-error">{{
            $t(`service.errors.${errorCode}`, { email })
          }}</span>
        </template>
        <template #icon="{ hasError, step }">
          <v-icon v-if="hasError" icon="$warning" size="24" class="mb-1"></v-icon>
          <span v-else>{{ step }}</span>
        </template>
        <v-card variant="flat">
          <div
            class="body2 mt-2"
            data-testid="office-step1-description"
            v-html="gSuiteStep1Description"
          ></div>
          <div class="mt-5">
            <v-btn
              v-if="isNextStep && !errorCode"
              rounded
              color="primary"
              width="120"
              data-testid="office-step1-continue-btn"
              @click="currentStep++"
            >
              {{ $t("cloudApplications.buttons.continue") }}
            </v-btn>
            <v-btn
              v-else
              rounded
              color="primary"
              width="120"
              :loading="isLoading"
              data-testid="office-step1-connect-btn"
              @click="connect({ service: config.item.name as Service })"
            >
              {{ $t("cloudApplications.buttons.connectService") }}
            </v-btn>
          </div>
        </v-card>
      </v-stepper-vertical-item>

      <v-stepper-vertical-item value="2" :elevation="0">
        <template #title>
          <div class="subtitle1">{{ $t("service.googleDirectoryEnforcement.step2.title") }}</div>
        </template>
        <v-card variant="flat">
          <div
            data-testid="google-step2-description1"
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description1')"
          />
          <div
            data-testid="google-step2-description2"
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description2')"
          />
          <div
            data-testid="google-step2-description3"
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description3')"
          />

          <v-text-field
            :value="googleClientId"
            class="copy-text-input client-id-copy-field"
            readonly
            variant="outlined"
            density="compact"
            color="primary"
            data-testid="google-step2-copy-field"
          >
            <template #append-inner>
              <v-btn width="116" color="primary" class="ma-0" @click="copyGoogleClientId()">
                <template v-if="idCopiedToClipboard">
                  <v-icon class="mr-2" color="white" icon="$check"></v-icon>
                  <span>{{ $t("general.copied") }}</span>
                </template>
                <span v-else>{{ $t("general.copy") }}</span>
              </v-btn>
            </template>
          </v-text-field>

          <div
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description4')"
          />
          <v-text-field
            variant="outlined"
            data-testid="google-step2-scopes"
            class="google-scopes-input-field"
            readonly
            hide-details
          >
            <template #default>
              {{ gSuiteScopes }}
            </template>
            <template #append-inner>
              <v-btn
                data-testid="google-step2-copy-scopes-btn"
                tile
                color="primary"
                class="ma-0 google-scopes-copy-field__btn"
                @click="copyPermissionScopes()"
              >
                <template v-if="scopesCopiedToClipboard">
                  <v-icon color="white" icon="$check"></v-icon>
                  <span>{{ $t("general.copied") }}</span>
                </template>
                <span v-else>{{ $t("general.copy") }}</span>
              </v-btn>
            </template>
          </v-text-field>

          <div
            class="body2 mt-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description5')"
          />
          <div class="mt-5">
            <v-btn
              rounded
              color="primary"
              width="120"
              data-testid="google-step2-complete-btn"
              @click="onCompleteServiceConnectionClick()"
            >
              {{ $t("general.confirm") }}
            </v-btn>
            <v-btn
              class="ml-2"
              variant="text"
              color="primary"
              data-testid="google-step2-back-btn"
              @click="currentStep = 1"
            >
              {{ $t("general.back") }}
            </v-btn>
          </div>
        </v-card>
      </v-stepper-vertical-item>
    </template>
  </v-stepper-vertical>
  <div v-else>
    <div
      class="body2 mb-2"
      data-testid="google-signup-description1"
      v-html="$t('service.googleDirectoryEnforcement.step2.description1')"
    />
    <div
      class="body2 mb-2"
      data-testid="google-signup-description2"
      v-html="$t('service.googleDirectoryEnforcement.step2.description2')"
    />
    <div
      class="body2 mb-2"
      data-testid="google-signup-description3"
      v-html="$t('service.googleDirectoryEnforcement.step2.description3')"
    />
    <v-text-field
      :value="googleClientId"
      class="copy-text-input client-id-copy-field"
      readonly
      variant="outlined"
      density="compact"
      color="primary"
      data-testid="google-step2-copy-field"
    >
      <template #append-inner>
        <v-btn width="116" color="primary" class="ma-0" height="38" @click="copyGoogleClientId()">
          <template v-if="idCopiedToClipboard">
            <v-icon class="mr-2" color="white" icon="$check"></v-icon>
            <span>{{ $t("general.copied") }}</span>
          </template>
          <span v-else>{{ $t("general.copy") }}</span>
        </v-btn>
      </template>
    </v-text-field>
    <div
      class="body2 mb-2"
      data-testid="google-signup-description4"
      v-html="$t('service.googleDirectoryEnforcement.step2.description4')"
    />
    <div class="body2 mb-2" v-html="$t('service.googleDirectoryEnforcement.step2.description4')" />

    <v-text-field
      variant="outlined"
      data-testid="google-step2-scopes"
      class="google-scopes-input-field"
      readonly
      hide-details
    >
      <template #default>
        {{ gSuiteScopes }}
      </template>
      <template #append-inner>
        <v-btn
          data-testid="google-step2-copy-scopes-btn"
          tile
          color="primary"
          class="ma-0 google-scopes-copy-field__btn"
          @click="copyPermissionScopes()"
        >
          <template v-if="scopesCopiedToClipboard">
            <v-icon color="white" icon="$check"></v-icon>
            <span>{{ $t("general.copied") }}</span>
          </template>
          <span v-else>{{ $t("general.copy") }}</span>
        </v-btn>
      </template>
    </v-text-field>
    <div
      class="body2 mt-2"
      data-testid="google-signup-description5"
      v-html="$t('service.googleDirectoryEnforcement.step2.description5')"
    />
    <div class="mt-5">
      <v-btn
        rounded
        color="primary"
        width="120"
        data-testid="google-signup-confirm-btn"
        :loading="completeServiceConnectionLoading"
        @click="onSignupComplete()"
      >
        {{ $t("general.confirm") }}
      </v-btn>
      <v-btn
        variant="text"
        color="primary"
        data-testid="google-signup-cancel-btn"
        @click="$emit('dismissed')"
      >
        {{ $t("general.cancel") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, type PropType } from "vue";
import {
  GOOGLE_PERMISSIONS_SCOPES,
  useCloudAppsStore,
} from "@/_store/cloud-apps/cloud-apps.module";
import { useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Service, ServiceStatus } from "@/constants/cloud-apps";
import { copyToClipboard } from "@/_helpers/utils";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  emits: ["complete-connection", "complete", "dismissed"],
  setup(props, { emit }) {
    const idCopiedToClipboard = ref(false);
    const scopesCopiedToClipboard = ref(false);

    const cloudAppsModule = useCloudAppsStore();
    const accountModule = useAccountStore();
    const { account } = storeToRefs(accountModule);
    const { isLoading, googleClientId } = storeToRefs(cloudAppsModule);
    const { fetchGoogleClientId } = cloudAppsModule;
    const route = useRoute();
    const router = useRouter();
    const currentStep = ref(1);
    const connectionCompleted = ref(false);
    const completeServiceConnectionLoading = ref(false);
    const i18n = useI18n();
    const errorCode = route.query.error;

    const updateCurrentStep = () => {
      // navigating to proper step which returned from backend response url
      if (route.query.step) {
        currentStep.value = Number.parseInt(route.query.step as string, 10);
      }

      // if service is incomplete, navigating to proper step
      if (props.config.item.serviceStatus === ServiceStatus.INCOMPLETE) {
        currentStep.value = 2;
      }
    };

    const isNextStep = computed(() => {
      return route.query.isNextStep === "true";
    });

    const onCompleteServiceConnectionClick = async () => {
      completeServiceConnectionLoading.value = true;
      await cloudAppsModule.completeServiceConnection(Service.G_SUITE);
      completeServiceConnectionLoading.value = false;
      router.replace({ query: {} });
      emit("complete-connection");
    };

    const onSignupComplete = async () => {
      emit("complete-connection");
    };

    onMounted(async () => {
      connectionCompleted.value = props.config.item.serviceStatus === ServiceStatus.CONNECTED;
      updateCurrentStep();
      await fetchGoogleClientId();
    });

    const isGSuite = computed(() => {
      return props.config.item.name === Service.G_SUITE;
    });

    const email = computed(() => {
      return route.query.email;
    });

    const gSuiteStep1Description = computed(() => {
      if (account.value.brandingAlias && errorCode === "generic") {
        return i18n.t(
          "service.googleDirectoryEnforcement.step1.descriptionError.genericWithoutCoro"
        );
      }
      return errorCode
        ? i18n.t(`service.googleDirectoryEnforcement.step1.descriptionError.${errorCode}`, {
            email: email.value,
          })
        : i18n.t("service.googleDirectoryEnforcement.step1.description");
    });

    const firstStepRules = computed(() => (errorCode ? [() => !errorCode] : []));

    const copyGoogleClientId = () => {
      copyToClipboard(googleClientId.value);
      idCopiedToClipboard.value = true;
    };

    const copyPermissionScopes = () => {
      copyToClipboard(GOOGLE_PERMISSIONS_SCOPES);
      scopesCopiedToClipboard.value = true;
    };

    return {
      googleClientId,
      completeServiceConnectionLoading,
      onCompleteServiceConnectionClick,
      currentStep,
      isNextStep,
      errorCode,
      isLoading,
      connect: cloudAppsModule.connect,
      account,
      copyGoogleClientId,
      copyPermissionScopes,
      idCopiedToClipboard,
      scopesCopiedToClipboard,
      gSuiteStep1Description,
      isGSuite,
      email,
      firstStepRules,
      Service,
      onSignupComplete,
      gSuiteScopes: GOOGLE_PERMISSIONS_SCOPES,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field--appended {
    padding: 0 !important;
  }

  .copy-text-input {
    .v-field {
      height: 36px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .v-field__field {
        height: 36px;
      }
    }

    .v-btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
