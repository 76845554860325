export enum MspUserVulnerabilityStatus {
  WITH_ISSUES = "withIssues",
  NO_ISSUES = "noIssues",
  ALL = "all",
}

export enum MspUsersSuspendedFilterItem {
  SUSPENDED = "suspended",
  ACTIVE = "active",
}
