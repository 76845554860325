<template>
  <div
    v-if="localValue?.wizardParameters.isDefault"
    class="mt-2 d-flex flex-column align-center pa-16"
  >
    <v-icon class="mb-3" size="80" icon="$endpointDataGovernance" />
    <span
      class="headline6 mb-3"
      v-text="$t(`endpointDataGovernance.privacySensitiveDataTab.emptyState.title`)"
    />
    <span
      class="text-center body1 mb-3"
      v-html="$t(`endpointDataGovernance.privacySensitiveDataTab.emptyState.subtitle`)"
    />
    <v-btn
      class="mt-4"
      color="primary"
      rounded
      @click="onSetParametersClick"
      :text="$t(`endpointDataGovernance.privacySensitiveDataTab.emptyState.cta`)"
    />
  </div>
  <v-skeleton-loader
    v-else
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
  >
    <div class="d-flex justify-space-between mb-4">
      <v-card class="sensitive-data-wrapper mr-4 pa-2 pt-3" v-if="localValue">
        <v-card-text>
          <div class="subtitle1">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.title") }}
          </div>
          <div
            class="mb-4"
            v-html="$t('endpointDataGovernance.privacySensitiveDataTab.sensitiveDataSubtitle')"
          ></div>
          <div class="subtitle1 mb-2">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.recommendedDataTypes") }}
          </div>
          <v-checkbox
            v-for="recommendedDataType in recommendedDataTypes"
            :key="recommendedDataType"
            :disabled="actionNotAllowed"
            v-model="localValue[recommendedDataType as keyof PrivacySensitiveDataSettings]"
            :label="$t(`userDataGovernanceSettings.monitoringTab.${recommendedDataType}`)"
          />
          <div class="subtitle1 mt-4 mb-2">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.otherDataTypes") }}
          </div>
          <span v-if="otherDataTypes.length === 0">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.noOtherDataTypes") }}
          </span>
          <v-checkbox
            v-for="otherDataType in otherDataTypes"
            :key="otherDataType"
            :disabled="actionNotAllowed"
            v-model="localValue[otherDataType as keyof PrivacySensitiveDataSettings]"
            :label="$t(`userDataGovernanceSettings.monitoringTab.${otherDataType}`)"
          />
        </v-card-text>
      </v-card>

      <v-card class="w-25">
        <v-card-text>
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="subtitle1">
              {{ $t("endpointDataGovernance.privacySensitiveDataTab.selectedParameters.title") }}
            </div>
            <span class="coro-link" @click="onSetParametersClick">
              {{ $t("general.edit") }}
            </span>
          </div>
          <div class="mb-6">
            <div class="subtitle1 mb-2">
              {{
                $t("endpointDataGovernance.privacySensitiveDataTab.selectedParameters.industries")
              }}
            </div>
            <div v-for="industry in localValue?.wizardParameters.industries.sort()" :key="industry">
              <span>{{ $t(`modals.setDataGovernanceIndustry.industries.${industry}`) }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, type WritableComputedRef } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  type PrivacySensitiveDataSettings,
  usePrivacySensitiveDataStore,
} from "@/_store/endpoint-data-governance/privacy-sensitive-data.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { isWorkspaceFrozenOrActionRestricted, useTwoWayBinding } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { EndpointDataGovernanceAction } from "@/constants/endpoint-data-governance";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import SetIndustryModal from "@/components/modals/data-governance/SetIndustryModal.vue";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup() {
    const privacySensitiveDataStore = usePrivacySensitiveDataStore();
    const dialogsStore = useDialogsStore();
    const { openDialog } = dialogsStore;
    const { settings, recommendedDataTypes, otherDataTypes, showSkeletonLoader } =
      storeToRefs(privacySensitiveDataStore);

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
      );
    });

    const onSetParametersClick = () => {
      const dialogConfig = {
        ...componentDialogsConfigConstructor({
          action: EndpointDataGovernanceAction.SET_INDUSTRY,
          component: SetIndustryModal,
          width: ModalWidth.LARGE,
          disable: actionNotAllowed.value,
          item: localValue.value?.wizardParameters.isDefault
            ? { selectedIndustries: [] }
            : localValue.value?.wizardParameters,
          callback: async (val: { selectedIndustries: string[] }) => {
            await privacySensitiveDataStore.updatePrivacyConfig(val.selectedIndustries);
          },
        }),
      };

      openDialog(dialogConfig);
    };

    onMounted(async () => {
      await privacySensitiveDataStore.getData();
    });

    const localValue: WritableComputedRef<PrivacySensitiveDataSettings> = useTwoWayBinding(
      settings,
      privacySensitiveDataStore.updateSettings
    );

    onUnmounted(() => {
      privacySensitiveDataStore.$reset();
    });

    return {
      localValue,
      showSkeletonLoader,
      actionNotAllowed,
      coronetSkeletonLoaderTypes,
      onSetParametersClick,
      recommendedDataTypes,
      otherDataTypes,
    };
  },
});
</script>

<style scoped>
.sensitive-data-wrapper {
  flex-grow: 1;
}
</style>
