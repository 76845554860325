<template>
  <div class="page-break-avoid">
    <div class="d-flex align-center mb-3">
      <v-icon size="40" icon="$securityAwarenessSmall"></v-icon>
      <div class="headline6" data-testid="executive-summary-security-awareness-title">
        {{ $t(`reports.executiveSummaryReport.securityAwareness.title`) }}
      </div>
    </div>
    <div
      class="body1 mb-6 flex-column"
      data-testid="executive-summary-security-awareness-description"
    >
      <div>
        {{ $t(`reports.executiveSummaryReport.securityAwareness.description`) }}
      </div>
      <div
        v-if="isModuleDisabled(SubscriptionModule.SECURITY_AWARENESS)"
        class="body1 text--semitransparent mt-2 d-flex align-center"
        data-testid="executive-summary-security-awareness-disable-notification"
      >
        <v-icon class="mr-1" icon="$warning" size="24" />
        <span>{{ $t(`reports.executiveSummaryReport.disabledModuleNotification`) }}</span>
      </div>
    </div>
  </div>
  <v-row class="d-flex" no-gutters>
    <v-col :cols="2" class="pr-10">
      <div
        class="d-flex flex-column page-break-avoid"
        data-testid="executive-summary-security-awareness-emails-sent-wrapper"
      >
        <div class="big-number mb-2 text-primary">
          {{ data.emailsSent }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.executiveSummaryReport.securityAwareness.simulationsSent") }}
        </div>
      </div>
      <div
        class="d-flex flex-column mt-4 page-break-avoid"
        data-testid="executive-summary-security-awareness-trainings-sent-wrapper"
      >
        <div class="big-number mb-2 text-primary">
          {{ data.trainingsSent }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.executiveSummaryReport.securityAwareness.trainingsSent") }}
        </div>
      </div>
    </v-col>
    <v-col :cols="6" class="pr-10">
      <div class="subtitle1">
        {{ $t("reports.executiveSummaryReport.securityAwareness.trainingsCompleted") }}
      </div>
      <div
        v-for="({ trainingName, count }, index) in trainingsCompleted"
        :key="trainingName"
        class="page-break-avoid es-report-module-overview__ticket-type"
        :data-testid="`executive-summary-security-awareness-training-completed-item-${index}`"
      >
        <div class="d-flex justify-space-between align-center h-100 body2 py-3">
          <span> {{ $t(`dashboard.widgets.securityAwareness.training.${trainingName}`) }} </span>
          <span>
            {{ count }}
          </span>
        </div>
        <v-divider class="border-opacity-100" color="indigo-faint" />
      </div>
    </v-col>
    <v-col :cols="4">
      <div v-if="data.topUsersFailedPhishing.length" class="mb-12">
        <div class="subtitle1">
          {{ $t(`reports.executiveSummaryReport.securityAwareness.topPhishedUsers`) }}
        </div>
        <div
          v-for="(record, index) in sortUsers(data.topUsersFailedPhishing)"
          :key="record.userId"
          class="page-break-avoid es-report-module-overview__ticket-type"
          :data-testid="`executive-summary-security-awareness-top-failed-phishing-user-${index}`"
        >
          <div class="d-flex justify-space-between align-center h-100 body2 py-3">
            <div class="body2 word-break-all">
              {{ record.userId }}
            </div>
            <div class="body2 ml-4">
              {{ record.offenses }}
            </div>
          </div>
          <v-divider class="border-opacity-100" color="indigo-faint" />
        </div>
      </div>
      <div>
        <div v-if="data.topUsersFailedTraining.length" class="subtitle1">
          {{ $t(`reports.executiveSummaryReport.securityAwareness.topOverdueUsers`) }}
        </div>
        <div
          v-for="(record, index) in sortUsers(data.topUsersFailedTraining)"
          :key="record.userId"
          class="page-break-avoid es-report-module-overview__ticket-type"
          :data-testid="`executive-summary-security-awareness-top-failed-training-user-${index}`"
        >
          <div class="d-flex justify-space-between align-center h-100 body2 py-3">
            <div class="body2 word-break-all">
              {{ record.userId }}
            </div>
            <div class="body2 ml-4">
              {{ record.offenses }}
            </div>
          </div>
          <v-divider class="border-opacity-100" color="indigo-faint" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { CoroIcons } from "@/constants/coro-icon";
import { SubscriptionModule } from "@/constants/workplaces";
import { isModuleDisabled } from "@/_helpers/utils";
import type {
  ExecutiveSummarySecurityAwarenessReportData,
  SecurityAwarenessUserFailureRecord,
} from "@/_store/reports.module.ts";
import { orderBy } from "lodash";

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<ExecutiveSummarySecurityAwarenessReportData>,
      required: true,
    },
    showZeroValues: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const trainingsCompleted = computed(() =>
      Object.entries(props.data.trainingsCompleted)
        .map(
          ([trainingName, count]) =>
            ({
              trainingName,
              count,
            }) as { trainingName: string; count: number }
        )
        .filter(({ count }) => {
          if (props.showZeroValues) {
            return true;
          }
          return count > 0;
        })
    );

    const sortUsers = (input: SecurityAwarenessUserFailureRecord[]) =>
      orderBy(input, "offenses", "desc");

    return {
      isModuleDisabled,
      trainingsCompleted,
      CoroIcons,
      SubscriptionModule,
      sortUsers,
    };
  },
});
</script>

<style lang="scss" scoped>
.es-report-module-overview {
  &__ticket-type {
    height: 63px;
  }
}

:deep(*) {
  .icon-warning:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
</style>
