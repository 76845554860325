import type { DevicesFilters, ScheduledReportsFilters } from "@/_store/filters.module.ts";
import { ExportCategory } from "@/_store/exports.module.ts";
import type {
  ScheduledExportItem,
  ScheduledReportItem,
} from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import { ScheduledType } from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import { ActivityLogsType } from "@/constants/activity-logs.ts";
import {
  convertDevicesGroupsFilter,
  type DeviceStatusFilterItem,
} from "@/_store/devices.module.ts";

export const convertFiltersForBackend = ({ search, types }: ScheduledReportsFilters) => ({
  search,
  subTypes: types?.join(","),
});

export const convertDataForBE = (data: ScheduledExportItem | ScheduledReportItem) => {
  if (data.type === ScheduledType.EXPORT) {
    switch (data.subType) {
      case ExportCategory.ACTIVITY_LOGS:
        return convertActivityLogsExportFilters(data);
      case ExportCategory.DEVICES:
        return convertDevicesExportFilters(data);
      default:
        return data;
    }
  }
  return data;
};

const convertActivityLogsExportFilters = (data: ScheduledExportItem) => ({
  ...data,
  filters: {
    ...data.filters,
    activityLogs: {
      ...data.filters.activityLogs,
      types: mapActivityLogsTypesToKeyArray(
        data.filters.activityLogs.types ?? []
      ) as ActivityLogsType[],
    },
  },
});

const convertDevicesExportFilters = (data: ScheduledExportItem) => {
  const { labels: groups, vulnerabilities, osVersion, clientVersion } = data.filters.devices;
  const { labels, deviceStatuses } = convertDevicesGroupsFilter(groups);

  return {
    ...data,
    filters: {
      ...data.filters,
      devices: {
        labels,
        deviceStatuses,
        vulnerabilities,
        osVersion,
        clientVersion,
      } as unknown as DevicesFilters,
    },
  };
};

// Simplify enum mapping using reduce
export const mapActivityLogsTypesToKeyArray = (types: ActivityLogsType[]): string[] => {
  const valueToKey = Object.entries(ActivityLogsType).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<string, string>
  );

  return types.map((type) => valueToKey[type]);
};

export const revertDataFromBE = (data: ScheduledExportItem | ScheduledReportItem) => {
  if (data.type === ScheduledType.EXPORT) {
    switch (data.subType) {
      case ExportCategory.ACTIVITY_LOGS:
        return revertActivityLogsExportFilters(data);
      case ExportCategory.DEVICES:
        return revertDevicesExportFilters(data);
      default:
        return data;
    }
  }
  return data;
};

// Convert backend data back to frontend state (Activity Logs)
export const revertActivityLogsExportFilters = (data: ScheduledExportItem) => ({
  ...data,
  filters: {
    ...data.filters,
    activityLogs: {
      ...data.filters.activityLogs,
      types: mapActivityLogsKeysToTypeArray(data.filters.activityLogs.types ?? []),
    },
  },
});

// Helper function to revert keys to original ActivityLogsType enum values
export const mapActivityLogsKeysToTypeArray = (keys: string[]): ActivityLogsType[] => {
  return keys.map((key) => ActivityLogsType[key as keyof typeof ActivityLogsType]);
};

// Convert backend data back to frontend state (Devices)
export const revertDevicesExportFilters = (data: ScheduledExportItem) => {
  const { labels, deviceStatuses, vulnerabilities, osVersion, clientVersion } = data.filters
    .devices as any;

  return {
    ...data,
    filters: {
      ...data.filters,
      devices: {
        labels: [
          ...(labels ?? []).map((id: string) => ({ id, name: id, statusLabel: false })),
          ...(deviceStatuses ?? []).map((v: Pick<DeviceStatusFilterItem, "name" | "color">) => ({
            id: v,
            name: v,
            statusLabel: true,
          })),
        ],
        vulnerabilities,
        osVersion,
        clientVersion,
      } as unknown as DevicesFilters,
    },
  };
};
