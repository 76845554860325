<template>
  <div class="pb-6 px-12" data-testid="ticket-preview">
    <v-skeleton-loader
      v-if="ticket"
      :loading="ticketDetailsLoading"
      type="list-item, divider, list-item-avatar, list-item-three-line@4, list-item-avatar-two-line@4"
      class="skeleton-loader--table"
    >
      <msp-ticket-preview-header
        :safe-vote-count="ticket.safeVoteCount"
        :suspicious-vote-count="ticket.suspiciousVoteCount"
        :ticket-details="ticketDetails"
      />
      <div class="d-flex align-center mt-5 mb-7">
        <v-icon
          color="primary"
          class="event-type-icon mr-3"
          :icon="getIconName(ticketDetails)"
          size="40"
        >
        </v-icon>
        <span class="headline5" data-testid="ticket-preview-type">
          {{ $t(`tickets.eventTriggers.${camelCase(ticketDetails.eventTrigger)}`) }}
        </span>
      </div>
      <v-row>
        <v-col :cols="generalInfoCols">
          <msp-ticket-general-info :ticket-details="ticketDetails" />
        </v-col>
        <v-col v-if="showKeyDetails" cols="6">
          <key-details :ticket="ticketDetails"></key-details>
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-6"></v-divider>
      <msp-ticket-full-details class="mb-5" :ticket-details="ticketDetails" />
      <entity-activity-logs
        :activity-logs="ticketDetails.activityLogs"
        :activity-logs-total="ticketDetails.activityLogsTotal"
        :entity-id="ticketDetails.eventId"
        :loading="activityLogsLoading"
        :hide-all-activity-link="true"
        :access-restricted="activityLogsAccessRestricted"
        show-load-more
        search-prefix="ticketId"
        class="mb-5"
        @undo-clicked="undoActivityLog($event)"
        @load-more-items-clicked="loadMoreActivityLogs()"
      />
      <msp-ticket-comments :ticket="ticketDetails" :comments="comments" />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import camelCase from "lodash/camelCase";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { computed, defineComponent, type PropType, toRefs, watch } from "vue";
import { storeToRefs } from "pinia";
import { ticketTriggerIcons, TicketType, ticketTypeIcons } from "@/constants/tickets";
import {
  type MspTicket,
  type MspTicketDetails,
  useMspTicketsStore,
} from "@/_store/msp/tickets/msp-tickets.module.ts";
import { useMspTicketCommentsStore } from "@/_store/msp/tickets/msp-ticket-comments.module.ts";
import EntityActivityLogs from "@/components/EntityActivityLogs.vue";
import KeyDetails from "@/components/tickets/ticket-preview-details/KeyDetails.vue";
import MspTicketFullDetails from "@/components/msp/tickets/MspTicketFullDetails.vue";
import MspTicketGeneralInfo from "@/components/msp/tickets/MspTicketGeneralInfo.vue";
import MspTicketPreviewHeader from "@/components/msp/tickets/MspTicketPreviewHeader.vue";
import MspTicketComments from "@/components/msp/tickets/MspTicketComments.vue";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions.ts";

export default defineComponent({
  components: {
    MspTicketComments,
    MspTicketPreviewHeader,
    MspTicketGeneralInfo,
    MspTicketFullDetails,
    EntityActivityLogs,
    KeyDetails,
  },
  props: {
    ticket: {
      type: Object as PropType<MspTicket | null>,
      required: false,
    },
  },
  setup(props) {
    const { ticket } = toRefs(props);
    const ticketsStore = useMspTicketsStore();
    const ticketCommentsStore = useMspTicketCommentsStore();
    const { ticketDetailsLoading, ticketDetails, activityLogsLoading } = storeToRefs(ticketsStore);
    const { comments } = storeToRefs(ticketCommentsStore);
    const showKeyDetails = computed(
      () => ticketDetails.value.eventType !== TicketType.EDR_DETECTION
    );
    const generalInfoCols = computed(() => (showKeyDetails.value ? 6 : 12));
    const activityLogsAccessRestricted = computed(() =>
      isMspAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      )
    );

    watch(
      ticket,
      (newValue) => {
        if (newValue) {
          ticketsStore.getMspTicketDetails(newValue);
          ticketCommentsStore.getComments(newValue);
          ticketCommentsStore.$patch({
            pagination: {
              page: 0,
              pageSize: 5,
            },
          });
          document.querySelector("#scroll-container")?.scrollTo(0, 0);
        }
      },
      { deep: true, immediate: true }
    );

    const getIconName = ({ eventType, eventTrigger }: MspTicketDetails) => {
      const icon = ticketTypeIcons[eventType] || ticketTriggerIcons[eventTrigger];
      return `$${icon}`;
    };

    const loadMoreActivityLogs = () => {
      ticketsStore.getMspTicketActivityLogs({
        eventId: ticket.value!.eventId,
        workspaceId: ticket.value!.workspaceId,
      });
    };

    return {
      camelCase,
      RolePermissionsScope,
      ticketDetailsLoading,
      ticketDetails,
      activityLogsLoading,
      comments,
      generalInfoCols,
      activityLogsAccessRestricted,
      showKeyDetails,
      undoActivityLog: ticketsStore.undoActivityLog,
      getIconName,
      loadMoreActivityLogs,
    };
  },
});
</script>

<style scoped lang="scss">
.event-type-icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
</style>
