<template>
  <div class="mb-4">
    <template v-if="systemMetaData?.proxyMisconfiguration?.domain">
      <div class="subtitle1">
        {{ $t("general.domain") }}
      </div>
      <div class="body1">
        {{ systemMetaData.proxyMisconfiguration.domain }}
      </div>
      <div class="subtitle1 mt-4">
        {{ $t("ticketDetails.lastFailedTest") }}
      </div>
      <div class="body1">
        {{ getFormattedDateTime(systemMetaData.proxyMisconfiguration.lastFailedTestTime) }}
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";

export default defineComponent({
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const systemMetaData = computed(() => props.ticketDetails?.sections?.systemMetaData);

    const { getFormattedDateTime } = useTicketPreviewGeneralInfo(props.ticketDetails);

    return {
      systemMetaData,
      getFormattedDateTime,
    };
  },
});
</script>
