export enum TutorialAction {
  WELCOME_MODAL = "welcomeModal",
  START_TRIAL_FROM_DEMO_STATE = "startTrialFromDemoState",
  TRIAL_HAS_STARTED = "trialHasStarted",
  ENABLE_WORKSPACE_DEMO_MODE = "enableWorkspaceDemoMode",
}

export enum DemoWillStartModal {
  CLOUD_APPS = "demoWillStartCloudApps",
  DEVICES = "demoWillStartDevices",
  PROXY = "demoWillStartProxy",
  NETWORK = "demoWillStartNetwork",
}
