<template>
  <div class="subtitle1">{{ $t("general.details") }}</div>
  <template v-if="isMxRecordMisconfiguration">
    <div
      class="mt-4"
      v-html="
        $t('ticketDetails.inboundGatewayMxRecordMisconfigurationDescription', {
          domain: systemMetaData?.proxyMisconfiguration?.domain,
        })
      "
    />
  </template>
  <v-divider class="mt-8" />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { type PropType } from "vue";
import { type TicketDetails } from "@/_store/tickets/tickets.module.ts";
import { TicketTrigger } from "@/constants/tickets.ts";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const isMxRecordMisconfiguration = computed(
      () => props.ticket.eventTrigger === TicketTrigger.INBOUND_GATEWAY_MX_RECORD_MISCONFIGURATION
    );
    const systemMetaData = computed(() => props.ticket?.sections?.systemMetaData);

    return {
      systemMetaData,
      isMxRecordMisconfiguration,
    };
  },
});
</script>

<style scoped lang="scss"></style>
