import { BackOfficeOverviewWidgetType } from "@/constants/back-office";
import moment from "moment";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useAccountStore } from "@/_store/account.module";
import { useFiltersStore } from "@/_store/filters.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { DeviceVersionItem } from "@/_store/devices-settings.module";

export interface BackOfficeState {
  backOfficeData: BackOfficeData;
  globalAdmins: GlobalAdmin[];
  loading: boolean;
  showSkeletonLoader: boolean;
  agentGradualUpdateData: AgentGradualUpdateData;
  defaultWorkspaceGroup: number;
  defaultDevicesGroup: number;
  defaultBetaGroupUpdate: boolean;
}

export interface BackOfficeData {
  overviewWidgets: OverviewWidget[];
  widgets: Widget[];
  commitHash: string;
  branchName: string;
}

export interface AgentGradualUpdateData {
  workspacesGroup: number;
  devicesGroup: number;
  betaGroupUpdate: boolean;
  workspacesCount: number;
  devicesCount: number;
  setDefaults?: boolean;
  agentVersions: DeviceVersionItem[];
}

export interface OverviewWidget {
  type: BackOfficeOverviewWidgetType;
  count?: number;
  index: number;
}

interface Widget {
  type: string;
  devices: Device[];
}

interface Device {
  appVersion: string;
  count: number;
}

export interface GlobalAdmin {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: { roleName: string; roleId: string };
}

export interface BackofficeResponseData {
  windowsDevices: number;
  macosDevices: number;
  customers: number;
  protectableUsers: number;
  protectedUsers: number;
  paidProtectedUsers: number;
  devices: {
    macos: Device[];
    windows: Device[];
  };
  branchName: string;
  commitHash: string;
  paidRecurlyProtectedUsers?: number;
}

const defaultBackOfficeState: BackOfficeState = {
  backOfficeData: {
    overviewWidgets: [],
    branchName: "",
    commitHash: "",
    widgets: [],
  },
  globalAdmins: [],
  loading: false,
  showSkeletonLoader: true,
  agentGradualUpdateData: {
    workspacesGroup: 1,
    devicesGroup: 1,
    betaGroupUpdate: true,
    workspacesCount: 0,
    devicesCount: 0,
    agentVersions: [],
  },
  defaultWorkspaceGroup: 1,
  defaultDevicesGroup: 1,
  defaultBetaGroupUpdate: true,
};

export const useBackOfficeStore = defineStore("backOffice", {
  state: (): BackOfficeState => ({ ...defaultBackOfficeState }),
  actions: {
    async getBackOfficeData() {
      this.loading = true;
      const filtersStore = useFiltersStore();
      const backOfficeFilters = convertFiltersForBackend({
        ...filtersStore.filters.backOfficeFilters,
      });

      try {
        const request = {
          ...api.getBackOfficeData(backOfficeFilters),
        };

        const { data } = await axiosInstance.request(request);
        this.backOfficeData = getConvertedBackOfficeData(data);
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async getAgentGradualUpdateData(
      payload: Partial<AgentGradualUpdateData> = {},
      showSkeletonLoader = true
    ) {
      this.showSkeletonLoader = showSkeletonLoader;

      try {
        const request = {
          ...api.getAgentGradualUpdateData(payload),
        };

        const { data } = await axiosInstance.request(request);
        if (payload.setDefaults) {
          this.defaultWorkspaceGroup = data.workspacesGroup;
          this.defaultDevicesGroup = data.devicesGroup;
          this.defaultBetaGroupUpdate = data.betaGroupUpdate;
        }
        this.agentGradualUpdateData = data;
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
    },
    async saveAgentGradualUpdateData(payload: Partial<AgentGradualUpdateData>) {
      this.showSkeletonLoader = true;

      try {
        const request = {
          ...api.saveAgentGradualUpdateData(payload),
        };

        await axiosInstance.request(request);
        this.defaultWorkspaceGroup = this.agentGradualUpdateData.workspacesGroup;
        this.defaultDevicesGroup = this.agentGradualUpdateData.devicesGroup;
        this.defaultBetaGroupUpdate = this.agentGradualUpdateData.betaGroupUpdate;
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
    },
    async getGlobalAdmins(showSkeletonLoader = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      try {
        const filters = useFiltersStore().filters.backOfficeAdminUsersFilters;
        const request = {
          ...api.globalAdminUsers,
          params: {
            ...filters,
          },
        };

        const { data } = await axiosInstance.request(request);

        this.globalAdmins = data;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
      this.showSkeletonLoader = false;
    },
    async updateGlobalAdminUser(payload: DialogDataConfig<Pick<GlobalAdmin, "id" | "role">>) {
      const { id, role } = payload.item;
      try {
        const request = {
          ...api.globalAdminUser(id),
          method: "PUT",
          data: {
            roleId: role.roleId,
          },
        };

        await axiosInstance.request(request);

        await this.getGlobalAdmins();
      } catch (error) {
        console.error(error);
      }
    },
    async removeGlobalAdminUser(payload: DialogDataConfig<GlobalAdmin>) {
      try {
        const request = {
          ...api.globalAdminUser(payload.item.id),
          method: "DELETE",
        };

        await axiosInstance.request(request);

        await this.getGlobalAdmins();
      } catch (error) {
        console.error(error);
      }
    },
    async addGlobalAdminUser(payload: DialogDataConfig<Pick<GlobalAdmin, "email" | "role">>) {
      try {
        const { role, email } = payload.item;
        const request = {
          ...api.globalAdminUsers,
          method: "POST",
          data: {
            email: email,
            roleId: role.roleId,
          },
        };

        await axiosInstance.request(request);

        await this.getGlobalAdmins();
      } catch (error) {
        console.error(error);
      }
    },
    async searchGlobalAdminUsersCandidates(search: string): Promise<{ email: string }[]> {
      const request = {
        ...api.globalAdminUsersCandidates(),
        params: {
          search,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch {
        return [];
      }
    },
    async getWorkspacePermissions() {
      const accountStore = useAccountStore();
      if (!accountStore.account.workplace) return;
      try {
        const workspacePermissionsRequest = {
          ...api.getWorkspacePermissions(accountStore.account.workplace),
        };

        const mspPermissionsRequest = {
          ...api.getMspPermissions(),
        };

        const globalPermissionsRequest = {
          ...api.getGlobalPermissions(),
        };

        const [workspacePermissionsResponse, mspPermissionsResponse, globalRoleResponse] =
          await Promise.all([
            axiosInstance.request(workspacePermissionsRequest),
            axiosInstance.request(mspPermissionsRequest),
            axiosInstance.request(globalPermissionsRequest),
          ]);
        accountStore.setCurrentWorkspacePermissions(workspacePermissionsResponse.data);
        accountStore.setMspPortalPermissions(mspPermissionsResponse.data);
        accountStore.setGlobalPermissions(globalRoleResponse.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
});

const getConvertedBackOfficeData = (data: BackofficeResponseData) => {
  const branchName = data.branchName;
  const commitHash = data.commitHash;
  const overviewWidgets = [
    {
      type: BackOfficeOverviewWidgetType.WINDOWS_DEVICES,
      count: data[BackOfficeOverviewWidgetType.WINDOWS_DEVICES],
      index: 0,
    },
    {
      type: BackOfficeOverviewWidgetType.MACOS_DEVICES,
      count: data[BackOfficeOverviewWidgetType.MACOS_DEVICES],
      index: 1,
    },
    {
      type: BackOfficeOverviewWidgetType.CUSTOMERS,
      count: data[BackOfficeOverviewWidgetType.CUSTOMERS],
      index: 2,
    },
    {
      type: BackOfficeOverviewWidgetType.PROTECTABLE_USERS,
      count: data[BackOfficeOverviewWidgetType.PROTECTABLE_USERS],
      index: 3,
    },
    {
      type: BackOfficeOverviewWidgetType.PROTECTED_USERS,
      count: data[BackOfficeOverviewWidgetType.PROTECTED_USERS],
      index: 4,
    },
    {
      type: BackOfficeOverviewWidgetType.PROTECTED_USERS_IN_PAID_WORKSPACES,
      count: data[BackOfficeOverviewWidgetType.PROTECTED_USERS_IN_PAID_WORKSPACES],
      index: 5,
    },
    {
      type: BackOfficeOverviewWidgetType.PROTECTED_USERS_REPORTED_TO_RECURLY,
      count: data[BackOfficeOverviewWidgetType.PROTECTED_USERS_REPORTED_TO_RECURLY],
      index: 6,
    },
  ];
  const widgets = [];
  if (data.devices.windows.length) {
    widgets.push({
      type: BackOfficeOverviewWidgetType.WINDOWS_DEVICES,
      devices: data.devices.windows,
    });
  }

  if (data.devices.macos.length) {
    widgets.push({
      type: BackOfficeOverviewWidgetType.MACOS_DEVICES,
      devices: data.devices.macos,
    });
  }

  return {
    overviewWidgets,
    widgets,
    commitHash,
    branchName,
  };
};

const convertFiltersForBackend = ({
  timeRange,
}: {
  timeRange: { start?: string; end?: string };
}) => {
  const fromTime = timeRange.start
    ? moment(timeRange.start).startOf("day").utc().valueOf()
    : moment().subtract(30, "days").startOf("day").utc().valueOf();

  const toTime = timeRange.end
    ? moment(timeRange.end).endOf("day").utc().valueOf()
    : moment().add(1, "days").utc().valueOf();

  return {
    fromTime,
    toTime,
  };
};
