<template>
  <div class="content-md mt-4 margin-auto">
    <div class="d-flex justify-space-between align-center mb-4">
      <div class="headline5">
        {{ devicePageTitle }}
      </div>

      <div
        v-if="showExportDevicesToCsvBtn"
        @click="exportDevicesToCsv"
        class="export-csv-btn body2 coro-link item-clickable d-flex align-center mr-4"
      >
        <v-icon icon="$export" color="link" size="16" />
        <span>{{ $t("general.exportCsv") }}</span>
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-4"> </page-tabs>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes";
import { useRoute } from "vue-router";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { useMspDevicesStore } from "@/_store/msp/devices/msp-devices.module.ts";
import MspExportDevicesModal from "@/components/modals/msp/MspExportDevicesModal.vue";
import { componentDialogsConfigConstructor } from "@/_helpers/utils.ts";
import { MspExportAction } from "@/constants/msp-exports.ts";
import { ModalWidth } from "@/constants/modals.ts";
import { useFiltersStore } from "@/_store/filters.module.ts";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const dialogStore = useDialogsStore();
    const mspDevicesStore = useMspDevicesStore();
    const filtersStore = useFiltersStore();

    const showExportDevicesToCsvBtn = computed(() => {
      return route.name === RouteName.MSP_DEVICES_TAB;
    });

    const devicePageTitle = computed(() => {
      if (route.name === RouteName.MSP_DEVICES_TAB) {
        const { hasVulnerabilities } = filtersStore.filters.mspDevices;
        if (hasVulnerabilities === null) {
          return i18n.t("devices.mspTitle");
        }
        const suffix = hasVulnerabilities
          ? i18n.t("mspDashboard.widgets.protectedDevicesWidget.withIssues")
          : i18n.t("mspDashboard.widgets.protectedDevicesWidget.noIssues");

        return `${i18n.t("devices.mspTitle")} ${suffix.toLowerCase()}`;
      }
      return i18n.t("devices.mspTitle");
    });

    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("devices.defaultTitle"),
        route: { name: RouteName.MSP_DEVICES_TAB },
      },
      {
        id: 2,
        name: i18n.t("drives.title"),
        route: { name: RouteName.MSP_DRIVES_TAB },
      },
    ];

    const exportDevicesToCsv = () => {
      dialogStore.openDialog({
        ...componentDialogsConfigConstructor({
          action: MspExportAction.EXPORT_DEVICES,
          callback: async (data) => {
            await mspDevicesStore.exportDevicesToCsv(data);
          },
          component: MspExportDevicesModal,
          width: ModalWidth.LARGE,
        }),
      });
    };

    return {
      devicePageTitle,
      tabs,
      showExportDevicesToCsvBtn,
      exportDevicesToCsv,
    };
  },
});
</script>
