import isEmpty from "lodash/isEmpty";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import { getRecommendedAndOtherDataTypes } from "@/_store/user-data-governance/adapter.ts";

export interface PrivacySensitiveDataSettings {
  monitorNonPublicData: boolean;
  monitorPersonalData: boolean;
  monitorHealthData: boolean;
  monitorCreditCardData: boolean;
  wizardParameters: {
    industries: string[];
    isDefault: boolean;
  };
}

interface PrivacySensitiveDataState {
  settings: PrivacySensitiveDataSettings;
  showSkeletonLoader: boolean;
  recommendedDataTypes: string[];
  otherDataTypes: string[];
}

export const defaultPrivacySensitiveDataState: PrivacySensitiveDataState = {
  settings: {
    wizardParameters: {
      industries: [],
      isDefault: false,
    },
    monitorNonPublicData: false,
    monitorPersonalData: false,
    monitorHealthData: false,
    monitorCreditCardData: false,
  },
  showSkeletonLoader: false,
  recommendedDataTypes: [],
  otherDataTypes: [],
};

export const usePrivacySensitiveDataStore = defineStore("privacySensitiveData", {
  state: (): PrivacySensitiveDataState => ({
    ...defaultPrivacySensitiveDataState,
  }),
  getters: {
    deviceSettingsDisabled(state) {
      const deviceSettings = state.settings;
      return (
        !isEmpty(deviceSettings) &&
        !(
          deviceSettings.monitorNonPublicData ||
          deviceSettings.monitorPersonalData ||
          deviceSettings.monitorHealthData ||
          deviceSettings.monitorCreditCardData
        )
      );
    },
  },
  actions: {
    async getData() {
      this.showSkeletonLoader = true;
      try {
        await this.getSettings();
        await this.getRecommentedAndOtherDataTypesData();
      } catch (error) {
        console.error(error);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
    async getSettings() {
      const request = {
        ...api.endpointDataGovernanceSettings,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.settings = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRecommentedAndOtherDataTypesData() {
      try {
        const { data } = await axiosInstance.request({
          ...api.privacyParametersConfig(),
          params: {
            industries: this.settings.wizardParameters.industries.join(","),
          },
        });
        const { recommendedDataTypes, otherDataTypes } = getRecommendedAndOtherDataTypes(data);
        this.recommendedDataTypes = recommendedDataTypes;
        this.otherDataTypes = otherDataTypes;
      } catch (error) {
        console.error(error);
      }
    },
    async updatePrivacyConfig(industries: string[]) {
      this.showSkeletonLoader = true;
      try {
        const { data } = await axiosInstance.request({
          ...api.privacyParametersConfig(),
          params: {
            industries: industries.join(","),
          },
        });
        const { recommendedDataTypes, otherDataTypes } = getRecommendedAndOtherDataTypes(data);
        this.recommendedDataTypes = recommendedDataTypes;
        this.otherDataTypes = otherDataTypes;
        await this.updateSettings(data);
      } catch (error) {
        console.error(error);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
    async updateSettings(newSettings: PrivacySensitiveDataSettings) {
      const request = {
        ...api.endpointDataGovernanceSettings,
        method: "put",
        data: newSettings,
      };

      try {
        await axiosInstance.request(request);
        await this.getSettings();
      } catch (error) {
        console.error(error);
      }
    },
  },
});
