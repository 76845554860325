<template>
  <v-menu offset-y location="bottom right">
    <template #activator="{ props }">
      <v-btn
        rounded
        color="primary"
        v-bind="props"
        size="large"
        data-testid="manage-workspace-create-btn-menu"
      >
        {{ $t("general.actions") }}
        <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="action in createActions"
        :key="action"
        :data-testid="`manage-workspaces-action-${action}`"
        @click="handleCreateAction(action)"
      >
        <v-list-item-title>
          {{ $t(`manageWorkspaces.actions.${action}`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { MspWorkspaceAction } from "@/constants/workplaces";
import { computed, defineComponent } from "vue";
import { useAccountStore } from "@/_store";
import { useMspStore } from "@/_store/msp/msp.module.ts";
import { useDialogsStore } from "@/_store/dialogs.module";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import CreateMspWorkspaceModal from "@/components/modals/msp/CreateMspWorkspaceModal.vue";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  MspRolePermissionScopes,
} from "@/_store/roles.module";
import { isMspActionRestricted } from "@/_helpers/msp-permissions";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { i18n } from "@/plugins/i18n.ts";

export default defineComponent({
  setup() {
    const accountStore = useAccountStore();
    const dialogsStore = useDialogsStore();
    const mspStore = useMspStore();
    const isGlobalAdmin = accountStore.isGlobalAdmin;

    const createActions = computed(() => {
      if (isGlobalAdmin) {
        return [
          MspWorkspaceAction.CREATE_REGULAR_WORKSPACE,
          MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE,
          MspWorkspaceAction.CREATE_CHILD_WORKSPACE,
          MspWorkspaceAction.EXPORT_MSP,
          MspWorkspaceAction.EXPORT_MSP_NOTIFICATIONS,
          MspWorkspaceAction.EXPORT_MSP_SUMMARY,
        ];
      }

      return [
        MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE,
        MspWorkspaceAction.CREATE_CHILD_WORKSPACE,
        MspWorkspaceAction.EXPORT_MSP,
        MspWorkspaceAction.EXPORT_MSP_NOTIFICATIONS,
        MspWorkspaceAction.EXPORT_MSP_SUMMARY,
      ];
    });

    const handleCreateAction = (action: MspWorkspaceAction) => {
      const disablePermission = {
        [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
          "createRegularWorkspace"
        ),
        [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: isMspActionRestricted(
          MspRolePermissionScopes.MSP_PORTAL_SCOPE,
          MspPortalScopeSection.MSP_WORKSPACES,
          "createChannelWorkspace"
        ),
        [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: isMspActionRestricted(
          MspRolePermissionScopes.MSP_PORTAL_SCOPE,
          MspPortalScopeSection.MSP_WORKSPACES,
          "createChildWorkspace"
        ),
      } as const;
      switch (action) {
        case MspWorkspaceAction.EXPORT_MSP:
          return mspStore.exportToCsv();
        case MspWorkspaceAction.EXPORT_MSP_NOTIFICATIONS:
          return mspStore.exportMspNotifications();
        case MspWorkspaceAction.EXPORT_MSP_SUMMARY:
          return mspStore.exportMspSummary();
        case MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE:
        case MspWorkspaceAction.CREATE_CHILD_WORKSPACE:
        case MspWorkspaceAction.CREATE_REGULAR_WORKSPACE:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: mspStore.createWorkspace,
              component: CreateMspWorkspaceModal,
              width: ModalWidth.LARGE,
              disable: disablePermission[action],
            }),
            disclaimer: disablePermission[action] ? i18n.global.t("general.noPermissions") : "",
            scrollable: true,
            persistOnAction: true,
            fullscreen: true,
            footer: null,
          });
          break;
      }
    };

    return {
      createActions,
      handleCreateAction,
    };
  },
});
</script>
