import { defineStore } from "pinia";
import api from "@/_helpers/api.ts";
import { axiosInstance } from "@/plugins/https.ts";
import type { Paged, Pagination } from "@/types.ts";
import {
  type DevicesFilters,
  type TicketsFilters,
  useFiltersStore,
} from "@/_store/filters.module.ts";
import type { ActivityLogsFilters } from "@/_store/activity-logs.module.ts";
import { ExportCategory } from "@/_store/exports.module.ts";
import type { ReportType } from "@/_store/reports.module.ts";
import { useAccountStore } from "@/_store/account.module.ts";
import type { MspAdminUser } from "@/_store/msp-admin-users.module.ts";
import type { AdminUser } from "@/_store/admin-users.module.ts";
import {
  convertDataForBE,
  convertFiltersForBackend,
  revertDataFromBE,
} from "@/_store/scheduled-reports/adapters.ts";
import type { Service } from "@/constants/cloud-apps.ts";

export type ScheduledReportsListItem = ScheduledReportItem | ScheduledExportItem;

export type ReportTypeFilterItem =
  | { type: ScheduledType.REPORT; subType: ReportType }
  | { type: ScheduledType.EXPORT; subType: ExportCategory };

export enum ScheduledType {
  REPORT = "report",
  EXPORT = "export",
}

export interface BaseScheduledReportItem {
  id: string;
  name: string;
  createdBy: string;
  type: ScheduledType;
  frequency: ScheduledReportFrequency;
  scope?: ScheduledReportScope;
  recipients: string[];
}

export interface ScheduledReportItem extends BaseScheduledReportItem {
  type: ScheduledType.REPORT;
  subType: ReportType;
}

export interface ScheduledExportItem extends BaseScheduledReportItem {
  type: ScheduledType.EXPORT;
  subType: ExportCategory;
  filters: ScheduledExportItemFilters;
}

export interface ScheduledExportItemFilters {
  activityLogs: ActivityLogsFilters;
  devices: DevicesFilters;
  users: { cloudServices: Service[] };
  tickets: TicketsFilters;
}

export enum ScheduledReportFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum ScheduledReportScope {
  WORKSPACE = "workspace",
  WORKSPACE_AND_DIRECT_DESCENDANTS = "workspaceAndDirectDescendants",
  WORKSPACE_AND_ALL_DESCENDANTS = "workspaceAndAllDescendants",
}

interface ScheduledReportsState {
  loading: boolean;
  scheduledReportsList: Paged<ScheduledReportsListItem>;
  pagination: Pagination;
}

const defaultReportsState: ScheduledReportsState = {
  loading: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  scheduledReportsList: {
    items: [],
    total: 0,
  },
};

export const useScheduledReportsStore = defineStore("scheduledReports", {
  state: (): ScheduledReportsState => ({ ...defaultReportsState }),
  actions: {
    async getScheduledReportsList() {
      const { page, pageSize } = this.pagination;
      const filters = convertFiltersForBackend(useFiltersStore().filters.scheduledReportsFilters);
      const request = {
        ...api.scheduledReports(),
        params: {
          page,
          pageSize,
          ...filters,
        },
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.scheduledReportsList = {
          items: (data.items ?? []).map((v: ScheduledExportItem) => revertDataFromBE(v)),
          total: data.total,
        };
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async addScheduledReport(data: ScheduledExportItem | ScheduledReportItem): Promise<void> {
      try {
        const request = {
          ...api.scheduledReports(),
          data: convertDataForBE(data),
          method: "POST",
        };
        await axiosInstance.request(request);
        this.pagination = { ...defaultReportsState.pagination };
        await this.getScheduledReportsList();
      } catch (e) {
        console.error(e);
      }
    },
    async deleteScheduledReport(id: string): Promise<void> {
      try {
        const request = {
          ...api.scheduledReport(id),
          method: "DELETE",
        };
        await axiosInstance.request(request);
        this.pagination = { ...defaultReportsState.pagination };
        await this.getScheduledReportsList();
      } catch (e) {
        console.error(e);
      }
    },
    async editScheduledReport(data: ScheduledExportItem | ScheduledReportItem): Promise<void> {
      try {
        const request = {
          ...api.scheduledReport(data.id),
          data: convertDataForBE(data),
          method: "PUT",
        };
        await axiosInstance.request(request);
        this.pagination = { ...defaultReportsState.pagination };
        await this.getScheduledReportsList();
      } catch (e) {
        console.error(e);
      }
    },
    async searchSpecificAdministrators(
      search: string,
      scope?: ScheduledReportScope
    ): Promise<AdminUser[]> {
      if (
        scope === ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS ||
        scope === ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS
      ) {
        try {
          const { data } = await axiosInstance.request<Paged<MspAdminUser>>({
            ...api.mspAdminUsers(),
            params: {
              workspaceId: useAccountStore().account.workplace,
              search,
              page: 0,
              pageSize: 25,
            },
          });
          return data.items;
        } catch (e) {
          console.error(e);
          return [];
        }
      } else {
        try {
          const { data } = await axiosInstance.request({
            ...api.adminUsers,
            params: { search },
          });
          return data;
        } catch (e) {
          console.error(e);
          return [];
        }
      }
    },
  },
});
