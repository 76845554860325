<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlightable workspaces-table"
      :items="items"
      fixed-footer
      :page="pagination.page + 1"
      :show-select="false"
      :headers-length="headers.length"
      item-key="sessionId"
      :loading="loading"
      :headers="[]"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      @update:options="
        $emit('page-changed', {
          pageSize: $event.itemsPerPage,
          page: $event.page - 1,
        })
      "
    >
      <template #item="{ item, index }">
        <tr :key="index" :data-testid="`table-row-${index}`">
          <td :data-testid="`workplaces-table-id-col-row-${index}`" class="no-border">
            <div class="d-flex justify-space-between w-100 align-center">
              <div class="d-inline-flex align-center">
                <v-icon class="workspace-icon" size="40">{{ iconsMap[item.type] }}</v-icon>
                <div class="ml-3">
                  <div :data-testid="`workplace-name-row-${index}`" class="text--semibold">
                    {{ item.name }}
                  </div>
                  <div :data-testid="`workplace-id-row-${index}`" class="coro-body2">
                    {{ item.workspaceId }}
                  </div>
                </div>
              </div>
              <v-btn
                :data-testid="`${action}-button-${index}`"
                rounded
                color="primary"
                @click="onActionClick(item)"
              >
                {{ $t(`workspaces.actions.${action}`) }}
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { WorkspaceAction, WorkspaceType } from "@/constants/workplaces";
import { MfaStatus } from "@/constants/account";
import TableWrapper from "@/components/TableWrapper.vue";
import { computed, defineComponent, nextTick, type PropType } from "vue";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";
import { useDialogsStore } from "@/_store/dialogs.module";
import { confirmationDialogsConfigConstructor, isActionRestricted } from "@/_helpers/utils";
import { type WorkspaceListItem } from "@/_store/workspaces.module";
import { useAccountStore } from "@/_store";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes.ts";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<WorkspaceListItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 25,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String as PropType<WorkspaceAction>,
      required: true,
    },
  },
  emits: ["page-changed", "action-applied"],
  setup(props, { emit }) {
    const accountStore = useAccountStore();
    const myAccountStore = useMyAccountStore();
    const dialogsStore = useDialogsStore();
    const profileData = computed(() => myAccountStore.myAccount.profileData);
    const socialLogin = computed(() => accountStore.account.socialLogin);
    const i18n = useI18n();
    const router = useRouter();

    const onActionClick = (item: WorkspaceListItem) => {
      if (
        profileData.value.mfaConfig === MfaStatus.NONE &&
        item.mfaRequired &&
        profileData.value.allowMfa &&
        !socialLogin.value
      ) {
        dialogsStore.closeDialog();
        nextTick(() => {
          const dialogConfig = confirmationDialogsConfigConstructor({
            item,
            action: WorkspaceAction.LAUNCH_WITH_2FA,
            callback: async () => {
              accountStore.setWorkspace(item.workspaceId);
              await router.push({ name: RouteName.MFA_PAGE });
            },
          });
          dialogsStore.openDialog({ ...dialogConfig, disable: false, disclaimer: "" });
        });
      } else {
        emit("action-applied", item);
      }
    };

    return {
      headers: [
        {
          title: i18n.t("accessControlPage.activeSessions.table.headers.ip"),
          key: "ip",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      iconsMap: {
        [WorkspaceType.REGULAR]: "icon-regular-plan",
        [WorkspaceType.CHANNEL]: "icon-parent-plan",
        [WorkspaceType.CHILD]: "icon-child-plan",
        [WorkspaceType.CORO_AGENT]: "",
      },
      onActionClick,
      isActionRestricted,
    };
  },
});
</script>

<style scoped lang="scss">
.workspaces-table {
  max-height: calc(100vh - 250px);
}
table {
  tr {
    height: 80px !important;
  }
}
</style>
