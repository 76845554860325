export enum DataLossAction {
  ADD_PERMISSION_FOR_ALL_USERS = "addPermissionForAllUsers",
  ADD_PERMISSION_FOR_SPECIFIC_GROUPS = "addPermissionForSpecificGroups",
  ADD_PERMISSION_FOR_SPECIFIC_USERS = "addPermissionForSpecificUsers",
}

export enum DataLossPermissionChoice {
  CAN_ACCESS = "ACCESS_ONLY",
  CAN_ACCESS_AND_EXPOSE = "ACCESS_AND_EXPOSURE",
  REMOVE = "REMOVE",
}

export enum UserDataGovernanceAction {
  SET_INDUSTRY = "setDataGovernanceIndustry",
}
