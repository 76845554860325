import { defineStore } from "pinia";
import { StoreModule } from "@/constants/store.ts";
import { axiosInstance } from "@/plugins/https.ts";
import api from "@/_helpers/api.ts";

export interface TutorialStoreState {
  isNewUser: boolean;
  showDemoModeAcknowledgePopup: boolean;
  showTrialModeStartedNotice: boolean | undefined;
}

const defaultState: TutorialStoreState = {
  isNewUser: false,
  showDemoModeAcknowledgePopup: false,
  showTrialModeStartedNotice: false,
};

export const useTutorialStore = defineStore(StoreModule.TUTORIAL, {
  state: (): TutorialStoreState => ({ ...defaultState }),
  actions: {
    async resetTrialNotice() {
      const request = api.resetTrialNotice();
      try {
        await axiosInstance.request(request);
        this.showTrialModeStartedNotice = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
});
