<template>
  <div class="v-row h-100 ma-0 bg-indigo-instructional">
    <div class="v-col-6 py-0 d-flex justify-end">
      <div
        id="sign-up-page-anchor-wrapper"
        class="content-block h-100 d-flex justify-start align-center"
      >
        <div id="sign-up-page-anchor"></div>
      </div>
    </div>
    <div class="v-col-6 w-100 h-100 py-0 d-flex justify-start align-center">
      <div class="content-block my-7 d-flex justify-end">
        <div class="login-content">
          <img v-if="showBrandedIcon" alt="Logo" :src="account.appLogo" height="36" />
          <v-img
            v-else
            src="/images/logos/coro-logo-large-primary.svg"
            width="110"
            height="36"
            contain
            class="mb-4"
          ></v-img>
          <v-card class="pa-6" rounded="xl" color="white" min-height="600">
            <v-form ref="form" v-model="valid">
              <div v-if="signUpError" class="error-block d-flex mb-6 align-center w-100">
                <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                <span class="body2 text-red-dark" v-html="signUpError"></span>
              </div>
              <template v-if="step === SignUpStep.INITIAL">
                <div class="headline6 mb-5">{{ $t("signUpPage.getStartedNow") }}</div>
                <v-btn
                  class="mb-4"
                  width="170"
                  height="44px"
                  variant="outlined"
                  :loading="verificationRequestLoading"
                  rounded
                  block
                  @click="signUpWithUtmParams(service.OFFICE_365)"
                >
                  <coro-icon :icon-name="service.OFFICE_365" class="cloud-service-icon" />
                  {{ $t(`signUpPage.signUpOffice`) }}
                </v-btn>
                <v-btn
                  height="44px"
                  width="170"
                  variant="outlined"
                  :loading="verificationRequestLoading"
                  rounded
                  block
                  @click="signUpWithUtmParams(service.G_SUITE)"
                >
                  <coro-icon :icon-name="service.G_SUITE" class="cloud-service-icon" />
                  {{ $t(`signUpPage.signUpGoogle`) }}
                </v-btn>

                <div class="d-flex align-center mt-6">
                  <div class="line"></div>
                  <div class="ml-3 mr-3 text-indigo-medium">
                    {{ $t("general.or") }}
                  </div>
                  <div class="line"></div>
                </div>
                <v-text-field
                  class="mb-4 mt-6"
                  variant="outlined"
                  v-model="localValue.email"
                  :rules="[emailRule(), publicEmailRule]"
                  type="text"
                  :placeholder="$t('signUpPage.businessEmail')"
                  validate-on="lazy blur"
                  :label="$t('signUpPage.businessEmail')"
                  :hide-details="false"
                  autocomplete="new-email"
                >
                </v-text-field>
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  variant="outlined"
                  v-model="localValue.password"
                  :append-inner-icon="showPassword ? 'icon-eye' : 'icon-eye-off'"
                  @click:appendInner="showPassword = !showPassword"
                  :rules="[required(), passwordRule]"
                  :placeholder="$t('login.password')"
                  :label="$t('login.password')"
                  hide-details="auto"
                  autocomplete="new-password"
                >
                </v-text-field>
                <password-strength-meter
                  class="mb-8 mt-1"
                  :password="localValue.password"
                  :show-password-score-labels="false"
                />
                <div class="d-flex flex-column justify-center mb-2">
                  <v-btn
                    color="primary"
                    :loading="verificationRequestLoading"
                    rounded
                    block
                    size="large"
                    @click="requestVerificationCode()"
                  >
                    {{ $t("signUpPage.signUpBtn") }}
                  </v-btn>
                </div>

                <div
                  class="caption d-flex text-center text-indigo-medium w-100 mt-1 justify-center"
                  @click.stop
                >
                  <span v-html="$t('signUpPage.termsOfUseDescription')"></span>
                </div>
                <v-divider opacity="100" color="indigo-faint" class="my-4" />
                <div class="d-flex align-center justify-center w-100">
                  <span class="caption text-indigo-medium">
                    {{ $t("signUpPage.alreadyHaveAnAccount") }}
                  </span>
                  <span class="ml-1 coro-link caption" @click="goToLogin()">
                    {{ $t("login.signIn") }}
                  </span>
                </div>
              </template>
              <template v-if="step === SignUpStep.CODE_VERIFICATION">
                <div class="headline6 mb-2">
                  {{ $t("signUpPage.checkYourEmail") }}
                </div>
                <div
                  class="body1 mb-4"
                  v-html="$t('signUpPage.confirmationCodeSent', { email: localValue.email })"
                ></div>
                <v-otp-input
                  height="80"
                  :length="6"
                  :loading="signUpLoading"
                  v-model="localValue.verificationCode"
                  @update:model-value="validateOtpInput()"
                  focus-all
                  :error="!!otpError"
                >
                </v-otp-input>
                <v-messages
                  class="caption opacity-100 ml-10"
                  :active="!!otpError"
                  :messages="otpError"
                  color="red-dark"
                ></v-messages>
                <v-btn
                  class="mt-4 mb-6"
                  color="primary"
                  :loading="signUpLoading"
                  rounded
                  block
                  size="large"
                  @click="completeSignUpProcess()"
                >
                  {{ $t("verification.verifyBtn") }}
                </v-btn>

                <div
                  v-if="verificationRequestLoading"
                  class="d-flex align-center justify-center mb-2"
                >
                  <v-progress-circular indeterminate color="primary" size="16" width="2" />
                  <span class="body3 text-indigo-medium ml-2">
                    {{ $t("signUpPage.sendingNewCode") }}
                  </span>
                </div>

                <template v-else-if="showTimer">
                  <div class="body3 text-indigo-medium text-center mb-2">
                    {{ $t("signUpPage.resendAvailableAfter", countdown, { n: countdown }) }}
                  </div>
                </template>

                <div v-else class="d-flex align-center justify-center mb-2">
                  <span class="body3 text-indigo-medium">
                    {{ $t("signUpPage.notReceivedCode") }}
                  </span>
                  <span class="ml-1 coro-link body3" @click="resendVerificationCode()">
                    {{ $t("signUpPage.resend") }}
                  </span>
                </div>

                <div class="d-flex align-center justify-center mb-2">
                  <span class="caption text-indigo-medium">
                    {{ $t("signUpPage.enteredWrongEmail") }}
                  </span>
                  <span class="ml-1 coro-link caption" @click="returnToInitialStep()">
                    {{ $t("general.edit") }}
                  </span>
                </div>
              </template>
            </v-form>
          </v-card>
          <div class="d-flex align-end justify-center mt-2">
            <div class="body3 pb-1">{{ $t("signUpPage.defaultLanguage") }}: &nbsp;</div>
            <v-select
              v-model="localValue.languageCode"
              :items="Object.values(WorkspaceLocale)"
              bg-color="transparent"
              density="compact"
              persistent-hint
              variant="plain"
              hide-details
              max-width="100px"
            >
              <template #selection="{ item }">
                <span class="body3 font-weight-bold">
                  {{ $t(`general.localeDisplayNames.${item.raw}`) }}
                </span>
              </template>
              <template #item="{ item, props }">
                <v-list-item v-bind="props" :title="$t(`general.localeDisplayNames.${item.raw}`)">
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import type { VuetifyFormRef } from "@/types";
import { appLogoDefaultPath, useAccountStore } from "@/_store";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { emailRule, required } from "@/_helpers/validators";
import { Service } from "@/constants/cloud-apps";
import { RouteName } from "@/constants/routes";
import { AccountErrors } from "@/constants/account";
import { CoroMarketingLink } from "@/constants/general.ts";
import PasswordStrengthMeter from "@/components/PasswordStrengthMeter.vue";
import Patterns from "@/constants/patterns.ts";
import { WorkspaceLocale } from "@/constants/workplaces.ts";
import { useI18n } from "vue-i18n";
import pick from "lodash/pick";

enum SignUpStep {
  INITIAL = "initial",
  CODE_VERIFICATION = "CODE_VERIFICATION",
}

export default defineComponent({
  components: {
    PasswordStrengthMeter,
    CoroIcon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    const accountStore = useAccountStore();
    const { requestSignUpVerificationCode, socialSignUp, verifyPublicEmail, completeSignUp } =
      accountStore;
    const { account } = storeToRefs(accountStore);

    const localValue = ref<{
      email: string;
      password: string;
      languageCode: WorkspaceLocale;
      verificationCode?: string;
    }>({
      email: "",
      password: "",
      languageCode: WorkspaceLocale.EN_US,
    });

    const valid = ref(true);
    const form = ref<VuetifyFormRef>(null);
    const showPassword = ref(false);
    const signUpLoading = ref(false);
    const verificationRequestLoading = ref(false);
    const signUpError = ref();
    const utmUrlParams = ref({});
    const step = ref(SignUpStep.INITIAL);
    const showTimer = ref(false);
    const countdown = ref(59);
    const otpError = ref("");

    const showBrandedIcon = computed(() => {
      return account.value.brandingAlias && account.value.appLogo !== appLogoDefaultPath;
    });

    const validateOtpInput = (): boolean => {
      const verificationCode = localValue.value.verificationCode ?? "";

      // Check if the verification code is empty
      if (!verificationCode) {
        otpError.value = i18n.t("validations.verificationCodeIsRequired");
        return false;
      }

      // Check if the verification code does not match exactly 6 digits
      if (!Patterns.DIGITS_RANGE(6, 6).test(verificationCode)) {
        otpError.value = i18n.t("validations.nDigits", { n: 6 });
        return false;
      }

      // If validation passes, clear any previous error and return true
      otpError.value = "";
      return true;
    };
    const goToLogin = () => {
      router.push({ name: RouteName.LOGIN });
    };

    const handleVerificationRequest = async ({ validateForm = false, updateStep = false } = {}) => {
      // Optionally validate the form
      if (validateForm) {
        const validationResult = await form.value?.validate();
        if (!validationResult?.valid) {
          return;
        }
      }
      verificationRequestLoading.value = true;
      const { success, message } = await requestSignUpVerificationCode(localValue.value);
      verificationRequestLoading.value = false;
      if (success) {
        startCountdown();
        if (updateStep) {
          step.value = SignUpStep.CODE_VERIFICATION;
        }
        signUpError.value = "";
      } else {
        signUpError.value = message;
      }
    };

    const requestVerificationCode = async () => {
      await handleVerificationRequest({ validateForm: true, updateStep: true });
    };

    const resendVerificationCode = async () => {
      await handleVerificationRequest();
    };

    const completeSignUpProcess = async () => {
      const validationResult = await form.value?.validate();
      const otpValid = validateOtpInput();
      if (validationResult?.valid && otpValid) {
        signUpLoading.value = true;
        const response = await completeSignUp(localValue.value, utmUrlParams.value);
        if (!response.success) {
          signUpError.value = response.message;
        }
        signUpLoading.value = false;
      }
    };

    const signUpWithUtmParams = async (service: Service) => {
      await socialSignUp(service, utmUrlParams.value, localValue.value.languageCode);
    };

    const returnToInitialStep = () => {
      localValue.value.email = "";
      localValue.value.password = "";
      localValue.value.verificationCode = "";
      step.value = SignUpStep.INITIAL;
    };

    const publicEmailRule = async (value: string): Promise<string | boolean> => {
      const response = await verifyPublicEmail(value);
      return response.valid ? true : response.message;
    };

    const passwordRule = (password: string) => {
      if (!password) return i18n.t("validations.required");
      if (!Patterns.PASSWORD.test(password)) return i18n.t("validations.password");
      return true;
    };

    const startCountdown = () => {
      showTimer.value = true;
      const interval = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          showTimer.value = false;
          countdown.value = 59;
          clearInterval(interval);
        }
      }, 1000);
    };

    watch(
      localValue,
      () => {
        signUpError.value = "";
      },
      { deep: true }
    );

    onMounted(() => {
      utmUrlParams.value = pick(
        route.query,
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content"
      );
      accountStore.$reset();
      const emailParam = route.query.email as string;
      const error = route.query.errors as string;
      const adminsToContact = (route.query.admins as string[]) ?? [];
      if (error) {
        signUpError.value = i18n.t(
          `signUpPage.errors.${error}`,
          { admins: adminsToContact },
          adminsToContact.length
        );
      }
      if (emailParam) {
        localValue.value.email = emailParam;
      }
      router.replace({}).catch(() => {});
    });

    return {
      otpError,
      validateOtpInput,
      WorkspaceLocale,
      step,
      localValue,
      showPassword,
      valid,
      form,
      signUpLoading,
      verificationRequestLoading,
      requestVerificationCode,
      resendVerificationCode,
      completeSignUpProcess,
      returnToInitialStep,
      goToLogin,
      socialSignUp,
      signUpError,
      showBrandedIcon,
      publicEmailRule,
      passwordRule,
      showTimer,
      countdown,
      account,
      SignUpStep,
      CoroMarketingLink,
      service: Service,
      accountErrors: AccountErrors,
      required,
      emailRule,
      signUpWithUtmParams,
    };
  },
});
</script>

<style scoped lang="scss">
.line {
  border-bottom: 1px solid rgb(var(--v-theme-indigo-pale));
  width: 50%;
}

.cloud-service-icon {
  width: 24px;
  height: 24px;
}

.login-content {
  width: 440px;
}

.error-block {
  min-height: 32px !important;
  height: 100%;
}

.content-block {
  width: 600px;
}
</style>
