<template>
  <table-wrapper>
    <div class="coronet-table--loader">
      <v-progress-linear
        v-if="loading"
        data-testid="table-progress-bar"
        indeterminate
        height="2px"
      ></v-progress-linear>
    </div>
    <v-data-table-server
      class="coronet-table--highlightable"
      :items="items"
      :headers="headers"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-key="groupId"
      show-select
      return-object
      v-model="selectedItems"
      @update:options="
        $emit('page-changed', { pageSize: $event.itemsPerPage, page: $event.page - 1 })
      "
    >
      <!--Slot for table header-->
      <template #headers="{ columns }">
        <tr>
          <template v-if="selectedItemsQuantity > 0">
            <th>
              <!--'data-table-select' is a cell which is automatically generated by data-table with show-select enabled-->
              <bulk-selector-checkbox
                :is-indeterminate="isIndeterminate"
                :items="items"
                :selection="selection"
                :all-items-selected="allItemsSelected"
                @selection-changed="onSelectionChanged"
              />
            </th>
            <th colspan="3">
              <div v-if="selectedItemsQuantity > 0" class="d-flex align-center">
                <span class="table-selected-text" data-testid="user-groups-selected-groups-text">
                  {{
                    $t("usersSettings.userGroupsTab.groupsSelected", {
                      n: selectedItemsQuantity,
                    })
                  }}
                </span>
                <v-btn
                  color="primary"
                  variant="text"
                  class="ml-2"
                  data-testid="user-groups-table-remove-groups-btn-text"
                  @click="handleBulkAction(userGroupActions.REMOVE_GROUPS)"
                >
                  <v-icon icon="$trash" />
                  <span class="body2">
                    {{
                      $t(
                        "usersSettings.userGroupsTab.removeGroupsBtn",
                        { n: selectedItemsQuantity },
                        selectedItemsQuantity
                      )
                    }}
                  </span>
                </v-btn>
              </div>
            </th>
            <th class="text-center">
              <users-sync-btn :disabled="actionNotAllowed" />
            </th>
          </template>
          <template v-else>
            <th
              v-for="header of columns"
              :key="header.key as string"
              :class="{
                'checkbox-col': header.value === 'data-table-select',
              }"
            >
              <bulk-selector-checkbox
                v-if="header.key === 'data-table-select'"
                :is-indeterminate="isIndeterminate"
                :items="items"
                :selection="selection"
                :all-items-selected="allItemsSelected"
                @selection-changed="onSelectionChanged"
              />
              <span v-else :data-testid="`table-${header.key}-header`">
                {{ $t(`usersSettings.userGroupsTab.tableHeaders.${header.key}`) }}
              </span>
            </th>
            <th class="text-center">
              <users-sync-btn :disabled="actionNotAllowed" />
            </th>
          </template>
        </tr>
      </template>
      <!--Slot for rows-->
      <template #item="{ item, isSelected, internalItem, index }">
        <tr
          :data-testid="`table-row-${index}`"
          :class="{ 'coro-table-row--selected': isSelected([internalItem]) }"
        >
          <td class="checkbox-col" @click="$event.stopPropagation()">
            <v-checkbox-btn
              :data-testid="`table-checkbox-row-${index}`"
              :model-value="isSelected([internalItem])"
              @update:modelValue="handleOneRowSelection($event, item)"
              @click.stop
            ></v-checkbox-btn>
          </td>
          <td class="w-60" :data-testid="`user-groups-group-name-col-row-${index}`">
            <div class="d-flex align-center">
              <coro-icon class="service-icon" :icon-name="item.service"></coro-icon>
              <b class="ml-1 mr-2" :data-testid="`user-groups-table-group-name-row-${index}`">{{
                item.name
              }}</b>
            </div>
          </td>
          <td class="w-15" :data-testid="`user-groups-members-col-row-${index}`">
            {{ item.membersCount }}
          </td>
          <td class="w-15" :data-testid="`user-groups-protection-granted-col-row-${index}`">
            {{ getFormattedDateTime(item.protectionGrantedTime, "ddd, MMM D YYYY, hh:mm A") }}
          </td>
          <td
            class="w-5"
            :data-testid="`user-groups-action-col-row-${index}`"
            @click="$event.stopPropagation()"
          >
            <v-menu bottom left>
              <template #activator="{ props }">
                <v-icon
                  :data-testid="`user-groups-action-btn-row-${index}`"
                  icon="$dots"
                  v-bind="props"
                />
              </template>

              <v-list>
                <v-list-item
                  v-for="userGroupAction in itemActions"
                  :key="userGroupAction"
                  @click="handleItemAction(userGroupAction, item)"
                >
                  <v-list-item-title :data-testid="`user-groups-table-${userGroupAction}-action`">
                    {{ $t(`usersSettings.userGroupsTab.actions.${userGroupAction}`) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #no-data>
        <v-icon class="no-items-icon mt-3 mb-3" size="80" icon="$users" />
        <div class="headline5 mb-3">
          {{ $t("usersSettings.userGroupsTab.noGroups") }}
        </div>
        <a
          class="body1 coro-link"
          data-testid="users-table-add-groups-btn"
          @click="handleItemAction(userGroupActions.ADD_NEW_GROUP)"
        >
          {{ $t("usersSettings.addGroupsBtn") }}
        </a>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { getFormattedDateTime, isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import TableWrapper from "@/components/TableWrapper.vue";
import { computed, defineComponent, type PropType, toRefs } from "vue";
import UsersSyncBtn from "@/components/UsersSyncBtn.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { UserGroupAction } from "@/constants/users";
import { CoroIcons } from "@/constants/coro-icon";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import camelCase from "lodash/camelCase";
import type { Pagination } from "@/types";
import type { UserGroupSettingsItem } from "@/_store/users-settings/user-groups-tab.module";
import { useSelectableTable } from "@/composables/useSelectableTable";
import BulkSelectorCheckbox from "@/components/BulkSelectorCheckbox.vue";
import type { ISelection } from "@/_store/selector.module.ts";

export default defineComponent({
  components: {
    BulkSelectorCheckbox,
    TableWrapper,
    CoroIcon,
    UsersSyncBtn,
  },
  props: {
    items: {
      type: Array as PropType<UserGroupSettingsItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemActions: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selection: {
      type: Object as PropType<ISelection>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { totalItems, items } = toRefs(props);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    const {
      selectedItems,
      handleOneRowSelection,
      onSelectionChanged,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems,
      items,
    });

    const handleBulkAction = (action: string) => {
      emit("bulk-action-applied", action);
    };

    const handleItemAction = (action: string, item?: UserGroupSettingsItem) => {
      emit("item-action-applied", {
        action,
        item,
      });
    };

    return {
      headers: [
        {
          key: "groupName",
          sortable: false,
        },
        {
          key: "size",
          sortable: false,
        },
        {
          key: "protectionGrantedTime",
          sortable: false,
        },
      ],
      userGroupActions: UserGroupAction,
      coronetIcons: CoroIcons,
      actionNotAllowed,
      handleBulkAction,
      handleItemAction,
      selectedItems,
      camelCase,
      allItemsSelected,
      handleOneRowSelection,
      onSelectionChanged,
      getFormattedDateTime,
      isIndeterminate,
      selectedItemsQuantity,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-selection-control__wrapper {
    width: 22px !important;
  }
}

.service-icon {
  height: 30px;
  width: 30px;
}

.table-selected-text {
  font-size: 16px;
  font-weight: 500;
}

.v-btn {
  text-transform: unset !important;
}

.checkbox-col {
  min-width: 65px !important;
}
</style>
