import type { NewSensitiveDataScanItem } from "@/_store/endpoint-data-governance/sensitive-data-scans.module";
import { OsType } from "@/constants/devices";

export enum SensitiveDataScansActions {
  CREATE = "createSensitiveDataScan",
  EDIT = "editSensitiveDataScan",
  DELETE = "deleteSensitiveDataScan",
}

export enum EndpointDataGovernanceAction {
  SET_INDUSTRY = "setEndpointDataGovernanceIndustry",
}

export enum SensitiveDataScanDriveTypes {
  ALL = "ALL",
  UNENCRYPTED = "UNENCRYPTED",
}

export const defaultSensitiveDataItem: NewSensitiveDataScanItem = {
  appliedDriveType: SensitiveDataScanDriveTypes.ALL,
  daysCount: 1,
  osType: OsType.WINDOWS,
  preferredHour: 1,
  labels: [],
};
