<template>
  <div class="content-md margin-auto mt-4">
    <div>
      <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs"></v-breadcrumbs>
    </div>
    <div class="title-with-btn d-flex justify-space-between align-center mb-5 mt-4">
      <div class="headline5 d-flex align-center text-primary">
        {{ $t("securityAwarenessSettings.title") }}
      </div>
    </div>
    <template v-if="isCloudAppsConnected && isSecurityAwarenessAllowed">
      <page-tabs :tabs="tabs" :with-translation="false" class="mb-4"></page-tabs>
      <div v-if="showAllowBanner" class="info-block d-flex pa-4 mb-4 align-start position-relative">
        <v-icon size="24" class="mr-3" icon="$info" />
        <div class="banner-content">
          <div class="subtitle1 mb-1">
            {{
              $t("securityAwarenessSettings.allowBanner.title", { service: notAllowedServiceName })
            }}
          </div>
          <p
            class="mb-2 body2"
            v-html="
              $t('securityAwarenessSettings.allowBanner.description', {
                allowedService: allowedServiceName,
                notAllowedService: notAllowedServiceName,
              })
            "
          />
          <a
            href="#"
            class="d-inline-block mt-1"
            @click.prevent="handleAllowButtonClick(notAllowedService)"
          >
            <span class="subtitle2 text-decoration-underline">
              {{
                $t("securityAwarenessSettings.allowBanner.allowBtn", {
                  service: notAllowedServiceName,
                })
              }}
            </span>
          </a>
        </div>
        <v-btn
          icon
          variant="plain"
          class="banner-close-btn"
          :ripple="false"
          @click="dismissAllowBanner"
        >
          <v-icon icon="$close" />
        </v-btn>
      </div>
      <router-view></router-view>
    </template>
    <template v-else>
      <v-skeleton-loader
        class="skeleton-loader--security-settings"
        :loading="showEmptyStateSkeletonLoader"
        :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS_DENSE"
      >
        <security-awareness-empty-state :services="services" />
      </v-skeleton-loader>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes";
import { storeToRefs } from "pinia";
import { Service } from "@/constants/cloud-apps.ts";
import { useSecurityAwarenessModule } from "@/_store/security-awareness/security-awareness.module.ts";
import SecurityAwarenessEmptyState from "@/components/SecurityAwarenessEmptyState.vue";
import { useSecurityAwarenessEmptyState } from "@/composables/useSecurityAwarenessEmptyState";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module.ts";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader.ts";

export default defineComponent({
  components: { PageTabs, SecurityAwarenessEmptyState },
  setup() {
    const i18n = useI18n();
    const securityAwarenessStore = useSecurityAwarenessModule();
    const cloudAppsStore = useCloudAppsStore();
    const { services } = storeToRefs(cloudAppsStore);
    const { metadata } = storeToRefs(securityAwarenessStore);

    const {
      isCloudAppsConnected,
      isSecurityAwarenessAllowed,
      handleAllowButtonClick,
      loadSecurityAwarenessEmptyStateData,
      isGoogleConnected,
      isOfficeConnected,
      isOfficeAllowed,
      isGoogleAllowed,
      showEmptyStateSkeletonLoader,
    } = useSecurityAwarenessEmptyState();

    const tabs: CoroTab[] = [
      {
        id: 0,
        name: i18n.t("securityAwarenessSettings.tabs.phishingSimulation"),
        route: { name: RouteName.PHISHING_SIMULATION },
        testId: "phishing-simulation-tab",
      },
      {
        id: 1,
        name: i18n.t("securityAwarenessSettings.tabs.securityTraining"),
        route: { name: RouteName.SECURITY_TRAINING },
        testId: "security-training-tab",
      },
    ];

    const showAllowBanner = computed(() => {
      const allCloudAppsConnected = isGoogleConnected.value && isOfficeConnected.value;
      const allCloudAppsAllowed = isGoogleAllowed.value && isOfficeAllowed.value;
      return allCloudAppsConnected && !allCloudAppsAllowed && !metadata.value?.bannerDismissed;
    });

    const notAllowedService = computed(() => {
      if (isGoogleConnected.value && !isGoogleAllowed.value) {
        return Service.G_SUITE;
      }

      return Service.OFFICE_365;
    });

    const allowedServiceName = computed(() => {
      if (isGoogleConnected.value && isGoogleAllowed.value) {
        return i18n.t(`services.${Service.G_SUITE}`);
      }
      return i18n.t(`services.${Service.OFFICE_365}`);
    });

    const notAllowedServiceName = computed(() => {
      return i18n.t(`services.${notAllowedService.value}`);
    });

    const dismissAllowBanner = () => {
      securityAwarenessStore.dismissSaBanner();
    };

    onMounted(loadSecurityAwarenessEmptyStateData);

    return {
      tabs,
      isCloudAppsConnected,
      RouteName,
      handleAllowButtonClick,
      isSecurityAwarenessAllowed,
      Service,
      showAllowBanner,
      dismissAllowBanner,
      notAllowedService,
      allowedServiceName,
      notAllowedServiceName,
      services,
      showEmptyStateSkeletonLoader,
      coronetSkeletonLoaderTypes,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
.title-with-btn {
  height: 36px;
}

.info-block {
  border-radius: 8px;
  position: relative;
}

.banner-content {
  flex: 1;
}

.banner-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
