<template>
  <v-stepper-vertical hide-actions v-model="currentStep">
    <template v-slot:default>
      <v-stepper-vertical-item value="1" :elevation="0" :rules="getRules()">
        <template #title>
          <div class="subtitle1">{{ $t("service.office365Enforcement.stepOne.title") }}</div>
        </template>
        <template #subtitle>
          <div>
            <span
              v-if="isNotOfficeAdmin"
              data-testid="office-step1-not-admin-error"
              class="caption text-red-dark"
            >
              {{ $t("service.office365Enforcement.stepOne.error") }}
            </span>
            <span
              v-if="accountAlreadyExists"
              data-testid="office-step1-account-already-exist-error"
              class="caption text-red-dark"
              v-html="
                $t('service.office365Enforcement.stepOne.accountAlreadyExist', {
                  admins: adminsToContact[0],
                })
              "
            >
            </span>
            <span
              v-if="isAccessDenied"
              data-testid="office-step1-not-admin-error"
              class="error-message"
            >
              {{ $t("service.office365Enforcement.stepOne.accessDenied") }}
            </span>
          </div>
        </template>
        <template #icon="{ hasError, step }">
          <v-icon v-if="hasError" icon="$warning" size="24"></v-icon>
          <span v-else>{{ step }}</span>
        </template>
        <v-card variant="flat">
          <div
            class="body2 mt-2"
            data-testid="office-step1-description"
            v-html="office365Step1Description"
          ></div>
          <div class="mt-5">
            <v-btn
              v-if="isNextStep && !errorCode"
              rounded
              color="primary"
              width="120"
              data-testid="office-step1-continue-btn"
              @click="currentStep++"
            >
              {{ $t("cloudApplications.buttons.continue") }}
            </v-btn>
            <v-btn
              v-else
              rounded
              color="primary"
              width="120"
              :loading="isLoading"
              data-testid="office-step1-connect-btn"
              @click="
                connect({
                  service: config.item.name as Service,
                  onboarding: !!config.item.signup,
                  languageCode: config.item.languageCode,
                })
              "
            >
              {{ $t("cloudApplications.buttons.connectService") }}
            </v-btn>
          </div>
        </v-card>
      </v-stepper-vertical-item>

      <v-stepper-vertical-item :elevation="0" value="2">
        <template #title>
          <div class="subtitle1">{{ $t("service.office365Enforcement.stepTwo.title") }}</div>
        </template>
        <v-card variant="flat">
          <div
            class="body2"
            data-testid="office-step2-description"
            v-html="$t('service.office365Enforcement.stepTwo.description')"
          />
          <div class="mt-5">
            <v-btn
              rounded
              color="primary"
              width="120"
              data-testid="office-step2-confirm-btn"
              :loading="completeServiceConnectionLoading"
              @click="onCompleteServiceConnectionClick()"
            >
              {{ $t("general.confirm") }}
            </v-btn>
            <v-btn
              variant="text"
              class="ml-2"
              color="primary"
              data-testid="office-step2-back-btn"
              @click="currentStep--"
            >
              {{ $t("general.back") }}
            </v-btn>
          </div>
        </v-card>
      </v-stepper-vertical-item>
    </template>
  </v-stepper-vertical>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref } from "vue";
import { OfficeErrorCode, Service, ServiceStatus } from "@/constants/cloud-apps";
import { useRoute, useRouter } from "vue-router";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";
import { useGtm } from "@gtm-support/vue-gtm";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  emits: ["complete-connection", "complete"],
  setup(props, { emit }) {
    const cloudAppsModule = useCloudAppsStore();
    const gtm = useGtm();
    const { completeServiceConnection } = cloudAppsModule;
    const { isLoading } = storeToRefs(cloudAppsModule);
    const route = useRoute();
    const router = useRouter();
    const currentStep = ref(1);
    const connectionCompleted = ref(false);
    const completeServiceConnectionLoading = ref(false);
    const i18n = useI18n();
    const errorCode = route.query.error;
    const isNotOfficeAdmin = computed(() => {
      return errorCode === OfficeErrorCode.IS_NOT_ADMIN;
    });
    const updateCurrentStep = () => {
      // navigating to proper step which returned from backend response url
      if (route.query.step) {
        currentStep.value = isNotOfficeAdmin.value
          ? 1
          : Number.parseInt(route.query.step as string, 10);
      }

      // if service is incomplete, navigating to proper step
      if (props.config.item.serviceStatus === ServiceStatus.INCOMPLETE) {
        currentStep.value = 2;
      }
    };

    const accountAlreadyExists = computed(() => {
      return errorCode === OfficeErrorCode.ACCOUNT_ALREADY_EXIST;
    });
    const isAccessDenied = computed(() => {
      return errorCode === OfficeErrorCode.ACCESS_DENIED;
    });
    const office365Step1Description = computed(() => {
      return isNotOfficeAdmin.value
        ? i18n.t("service.office365Enforcement.stepOne.descriptionError")
        : i18n.t("service.office365Enforcement.stepOne.description");
    });
    onMounted(() => {
      connectionCompleted.value = props.config.item.serviceStatus === ServiceStatus.CONNECTED;
      updateCurrentStep();
    });

    const adminsToContact = computed(() => {
      const admins = route.query.admins;

      return admins?.length ? admins : "";
    });

    const isNextStep = computed(() => {
      return route.query.isNextStep === "true";
    });

    const onCompleteServiceConnectionClick = async () => {
      completeServiceConnectionLoading.value = true;
      await completeServiceConnection(props.config.item.name as Service);
      completeServiceConnectionLoading.value = false;
      router.replace({ query: {} });
      emit("complete-connection");
    };
    const completeOnboardingSteps = () => {
      if (gtm?.enabled()) {
        window.dataLayer?.push({ event: "Onboarding Completion" });
      }
      emit("complete");
    };

    const getRules = () => {
      return !(isNotOfficeAdmin.value || isAccessDenied.value || accountAlreadyExists.value)
        ? []
        : [
            () => {
              return !(
                isNotOfficeAdmin.value ||
                isAccessDenied.value ||
                accountAlreadyExists.value
              );
            },
          ];
    };

    return {
      completeServiceConnectionLoading,
      completeOnboardingSteps,
      onCompleteServiceConnectionClick,
      currentStep,
      isNotOfficeAdmin,
      accountAlreadyExists,
      adminsToContact,
      isAccessDenied,
      office365Step1Description,
      isNextStep,
      errorCode,
      Service,
      isLoading,
      connect: cloudAppsModule.connect,
      getRules,
    };
  },
});
</script>
