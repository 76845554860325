import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { StoreModule } from "@/constants/store.ts";
import { addWorkspaceHeaderIfNeeded } from "@/_store/tickets/adapters.ts";

export interface MspLabelsState {
  labels: MspLabel[];
  loading: boolean;
  showSkeletonLoader: boolean;
}

export interface MspLabel {
  id: string;
  name: string;
  color: string;
  workspaceCount: number;
}

const defaultDevicesSettingsState: MspLabelsState = {
  labels: [],
  loading: false,
  showSkeletonLoader: false,
};

export const useMspLabelsStore = defineStore(StoreModule.MSP_LABELS, {
  state: (): MspLabelsState => ({ ...defaultDevicesSettingsState }),
  actions: {
    async getLabels(showSkeletonLoader = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const request = {
        ...api.mspWorkspaceLabels,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.labels = data;
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    searchLabels(searchParam: string, workspaceId?: string) {
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.searchMspWorkspaceLabels(searchParam),
        },
        workspaceId
      );
      return axiosInstance.request(request);
    },
    async createLabel({ name, color }: Pick<MspLabel, "name" | "color">) {
      this.loading = true;
      const request = {
        ...api.mspWorkspaceLabels,
        method: "POST",
        data: { name: name.trim(), color },
      };

      try {
        await axiosInstance.request(request);
        await this.getLabels();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async editLabel({ name, color, id }: Pick<MspLabel, "name" | "color" | "id">) {
      this.loading = true;
      const request = {
        ...api.mspWorkspaceLabel(id),
        method: "PUT",
        data: { name: name.trim(), color, id },
      };

      try {
        await axiosInstance.request(request);
        await this.getLabels();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async deleteLabel(payload: DialogDataConfig<MspLabel>) {
      this.loading = true;
      const request = {
        ...api.mspWorkspaceLabel(payload.item.id),
        method: "DELETE",
      };

      try {
        await axiosInstance.request(request);
        await this.getLabels();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
  },
});
