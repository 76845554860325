<template>
  <div>
    <div class="bg-indigo-pale email-header-section pa-4">
      <div class="subtitle1 mb-2">{{ config.item.emailTitle }}</div>
      <span class="subtitle2">{{ config.item.senderName }}</span>
      <span class="text-indigo-medium caption ml-2">{{ `<${config.item.senderEmail}>` }}</span>
    </div>

    <email-preview
      class="full-details-wrapper"
      :hide-title="true"
      :html-content="config.item.emailBody"
      data-testid="tickets-page-email-preview"
    ></email-preview>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import EmailPreview from "@/components/tickets/EmailPreview.vue";
import type { SecurityTrainingEmailData } from "@/_store/security-awareness/security-awareness.module.ts";

export default defineComponent({
  components: { EmailPreview },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<SecurityTrainingEmailData>>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.email-header-section {
  margin: 0 -24px 0 -24px;
}
</style>
