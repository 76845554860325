import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAccountStore } from "@/_store/account.module";
import {
  isAccessRestricted,
  isAddonDisabled,
  isModuleDisabled,
  socAddonsEnabled,
} from "@/_helpers/utils";
import {
  type GlobalRolePermissions,
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  type MspPortalPermissions,
  MspPortalScopeSection,
  MspRolePermissionScopes,
  type NestedGlobalPermissions,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
  type WorkspaceRolePermissions,
} from "@/_store/roles.module";
import { type SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions";
import { isGlobalAccessRestricted, isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { RouteName } from "@/constants/routes";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useMspStore } from "@/_store/msp/msp.module.ts";

/**
 * Navigation guard for authentication.
 *
 */
export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const myAccountStore = useMyAccountStore();
  const {
    logged,
    account: { workplace },
  } = accountStore;
  const authRequired = !to.meta.publicPage;
  const alwaysPublicPage = to.meta.alwaysPublicPage;

  // Allow navigation to pages that are always public
  if (alwaysPublicPage) {
    return next();
  }

  // if logged in on invited page, we should forward to workspaces page where user can accept invite
  if (to.name === RouteName.INVITED_PAGE) {
    if (logged) {
      accountStore.setWorkspace("");
      return next({ name: RouteName.WORKSPACES });
    }
    return next();
  }

  // mfa setup pages should be accessible only for certain admins
  if (to.name === RouteName.MFA_PAGE) {
    if (!myAccountStore.myAccount.profileData.allowMfa) {
      return next({ name: RouteName.DASHBOARD });
    }
    return next();
  }

  // Redirect to login if the page requires authentication and the user is not logged in
  if (authRequired && !logged) {
    return next({ name: RouteName.LOGIN });
  }

  // Redirect to the dashboard if accessing a public page while logged in
  if (!authRequired && logged) {
    return next({ name: RouteName.DASHBOARD });
  }
  // Redirect to workplace selection if no workplace is set and accessing any page other than workspaces
  if (!workplace && logged && to.name !== RouteName.WORKSPACES) {
    return next({ name: RouteName.WORKSPACES });
  }
};

export const moduleNavigationGuard = (moduleName: SubscriptionModule) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    return isModuleDisabled(moduleName) ? next({ name: RouteName.DASHBOARD }) : next();
  };
};

export const addonNavigationGuard = (addonName: SubscriptionAddon) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    return isAddonDisabled(addonName) ? next({ name: RouteName.DASHBOARD }) : next();
  };
};

export const socGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const { logged } = accountStore;
  return logged && socAddonsEnabled() ? next() : next({ name: RouteName.DASHBOARD });
};

export const socTicketsLogGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const socTicketsLogDisabled = isGlobalAccessRestricted(
    GlobalRoleScopes.GLOBAL_SCOPE,
    GlobalRoleScopeSection.SOC_PORTAL
  );
  return socTicketsLogDisabled ? next("/dashboard") : next();
};

export const permissionsGuard = <
  T extends RolePermissionsScope = RolePermissionsScope,
  K extends
    keyof WorkspaceRolePermissions[T] = keyof WorkspaceRolePermissions[RolePermissionsScope],
>(
  scopeName: T,
  key: K
) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const accessRestricted = isAccessRestricted(scopeName, key);
    return accessRestricted ? next({ name: RouteName.DASHBOARD }) : next();
  };
};

type AccessControlPageSection =
  | WorkspaceManagementScopeSections.ADMIN_USERS
  | WorkspaceManagementScopeSections.ROLES
  | WorkspaceManagementScopeSections.ACTIVE_SESSIONS;

type TabGuardParams<T extends string> = {
  accessRestricted: (key: T) => boolean;
  tabs: readonly T[];
  tabNameToRouteName: Record<T, string>;
};

export const mspPagePermissionGuard = <
  T extends keyof MspPortalPermissions = keyof MspPortalPermissions,
  K extends keyof MspPortalPermissions[T] = keyof MspPortalPermissions[T],
>(
  scopeName: T,
  key: K
) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const accessRestricted = isMspAccessRestricted(scopeName, key);
    return accessRestricted ? next({ name: RouteName.MSP_DASHBOARD }) : next();
  };
};

const createPageTabGuard = <T extends string>(
  { accessRestricted, tabs, tabNameToRouteName }: TabGuardParams<T>,
  fallbackRouteName: RouteName = RouteName.DASHBOARD
) => {
  return (tab: T) => {
    return (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const allTabsRestricted = tabs.every((t) => accessRestricted(t));

      if (allTabsRestricted) {
        return next({ name: fallbackRouteName });
      }

      const firstAvailableTab = tabs.find((t) => !accessRestricted(t))!;

      if (accessRestricted(tab)) {
        return next({ name: tabNameToRouteName[firstAvailableTab] });
      }

      return next();
    };
  };
};

export const mspPortalGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const mspStore = useMspStore();
  const { hasMspAccess } = mspStore;
  return hasMspAccess ? next() : next({ name: RouteName.DASHBOARD });
};

export const permissionsPageTabGuard = createPageTabGuard({
  accessRestricted: (key: AccessControlPageSection) =>
    isAccessRestricted(RolePermissionsScope.WORKSPACE_MANAGEMENT, key),
  tabs: [
    WorkspaceManagementScopeSections.ADMIN_USERS,
    WorkspaceManagementScopeSections.ROLES,
    WorkspaceManagementScopeSections.ACTIVE_SESSIONS,
  ],
  tabNameToRouteName: {
    [WorkspaceManagementScopeSections.ADMIN_USERS]: RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB,
    [WorkspaceManagementScopeSections.ROLES]: RouteName.ACCESS_CONTROL_ROLES_TAB,
    [WorkspaceManagementScopeSections.ACTIVE_SESSIONS]:
      RouteName.ACCESS_CONTROL_ACTIVE_SESSIONS_TAB,
  },
});

export const mspAccessControlPageTabGuard = createPageTabGuard(
  {
    accessRestricted: (
      key: MspPortalScopeSection.MSP_ADMIN_USERS | MspPortalScopeSection.MSP_ROLES
    ) => isMspAccessRestricted(MspRolePermissionScopes.MSP_PORTAL_SCOPE, key),
    tabs: [MspPortalScopeSection.MSP_ADMIN_USERS, MspPortalScopeSection.MSP_ROLES],
    tabNameToRouteName: {
      [MspPortalScopeSection.MSP_ADMIN_USERS]: RouteName.MSP_ADMIN_USERS_TAB,
      [MspPortalScopeSection.MSP_ROLES]: RouteName.MSP_ROLES_TAB,
    },
  },
  RouteName.MSP_DASHBOARD
);

export const globalPermissionsPageTabGuard = createPageTabGuard({
  accessRestricted: (
    key: GlobalRoleScopeSection.GLOBAL_ROLES | GlobalRoleScopeSection.GLOBAL_ADMIN_USERS
  ) => isGlobalAccessRestricted(GlobalRoleScopes.GLOBAL_SCOPE, key),
  tabs: [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS, GlobalRoleScopeSection.GLOBAL_ROLES],
  tabNameToRouteName: {
    [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS]: RouteName.GLOBAL_ADMIN_USERS,
    [GlobalRoleScopeSection.GLOBAL_ROLES]: RouteName.GLOBAL_ROLES,
  },
});

export const usersTablePageNavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const anyUsersModuleEnabled =
    !isModuleDisabled(SubscriptionModule.CLOUD_SECURITY) ||
    !isModuleDisabled(SubscriptionModule.EMAIL_SECURITY) ||
    !isModuleDisabled(SubscriptionModule.USER_DATA_GOVERNANCE);
  return anyUsersModuleEnabled && !isAccessRestricted(RolePermissionsScope.VIEWS, "usersView")
    ? next()
    : next({ name: RouteName.DASHBOARD });
};

export const deviceTablePageNavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const anyDeviceModuleEnabled = [
    SubscriptionModule.ENDPOINT_SECURITY,
    SubscriptionModule.EDR,
    SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
    SubscriptionModule.NETWORK,
  ].some((module) => !isModuleDisabled(module));
  return anyDeviceModuleEnabled ? next() : next({ name: RouteName.DASHBOARD });
};

export const backOfficeGuard = <
  T extends keyof GlobalRolePermissions,
  K extends keyof GlobalRolePermissions[T],
  V extends GlobalRolePermissions[T][K] extends NestedGlobalPermissions
    ? NonNullable<GlobalRolePermissions[T][K]["editAccessModePermission"]>
    : GlobalRolePermissions[T][K],
>(
  scopeName?: T,
  key?: K,
  nestedKey?: keyof V
) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    // Backoffice page should be accessible only for coronet workspaces
    const backOfficeDisabled = isGlobalActionRestricted(
      GlobalRoleScopes.GLOBAL_SCOPE,
      GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
      "backOffice"
    );
    if (backOfficeDisabled) return next({ name: RouteName.DASHBOARD });
    if (scopeName && key) {
      if (key === GlobalRoleScopeSection.SPECIAL_PERMISSIONS) {
        return isGlobalActionRestricted(scopeName, key as NonNullable<K>, nestedKey)
          ? next({ name: RouteName.DASHBOARD })
          : next();
      }
      return isGlobalAccessRestricted(scopeName, key)
        ? next({ name: RouteName.DASHBOARD })
        : next();
    }

    return next();
  };
};
