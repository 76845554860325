<template>
  <div>
    <v-data-table
      class="coronet-table--list"
      :headers="headers"
      :items="items"
      :items-per-page="pagination.pageSize"
      :server-items-length="totalItems"
      :loading="loading"
      show-select
      return-object
      :model-value="selectedItems"
    >
      <template #header.data-table-select>
        <slot name="all-rows-selection">
          <v-checkbox
            :model-value="allItemsSelected"
            data-testid="table-select-all-checkbox"
            :indeterminate="isIndeterminate"
            class="ml-3"
            primary
            hide-details
            @update:modelValue="emit('handle-all-rows-selection', $event as boolean)"
          ></v-checkbox>
        </slot>
      </template>
      <template #header.type>
        <div class="d-flex align-center justify-space-between">
          <list-table-header
            :pagination="pagination"
            :total-items="totalItems"
            :items="items"
            :show-pagination="showPagination"
            @pagination-change="handleListTablePaginationChange"
          >
            <template #prepend>
              <span class="table-selected-text" v-if="selectedItemsQuantity > 0">
                {{ $t("usersPage.selected", { n: selectedItemsQuantity }) }}
              </span>
            </template>
            <template #append>
              <v-menu v-if="selectedItems.length > 0" offset-y bottom right>
                <template #activator="{ props }">
                  <v-btn
                    data-testid="users-table-bulk-actions-btn"
                    class="ml-8"
                    rounded
                    color="primary"
                    v-bind="props"
                    @click="emit('bulk-menu-clicked')"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                  </v-btn>
                </template>
                <v-list>
                  <template v-if="availableBulkActions.length === 0">
                    <div v-if="actionsLoading" class="d-flex align-center justify-center">
                      <v-progress-circular
                        size="30"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-list-item v-else data-testid="users-table-no-actions-available-item">
                      <v-list-item-title>
                        {{ $t("general.noActionsAvailable") }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-for="action in availableBulkActions"
                    :key="action.name"
                    :disabled="action.disabled"
                    :data-testid="`users-table-${kebabCase(action.name)}-action`"
                    @click="emit('bulk-action-clicked', action.name)"
                  >
                    <v-list-item-title>
                      {{ action.displayName }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </list-table-header>
        </div>
      </template>
      <template #item="{ item, isSelected, index, internalItem }">
        <tr
          :key="index"
          :class="{
            'coro-table-row--selected': isSelected([internalItem]),
            'coro-table-row--active': isRowActive(
              (item as GenericListItem)[itemKey as keyof GenericListItem]
            ),
          }"
          @click="handleRowClick(item as GenericListItem)"
        >
          <td class="pl-2">
            <v-checkbox-btn
              :data-testid="`table-checkbox-row-${index}`"
              :model-value="isSelected([internalItem])"
              @update:modelValue="
                emit('handle-one-row-selection', {
                  selected: $event,
                  item: item as GenericListItem,
                })
              "
              @click.stop
            ></v-checkbox-btn>
          </td>
          <td :id="`events-table-type-col-row-${index}`" class="pl-0">
            <slot name="list-item" :item="item" :index="index"></slot>
          </td>
        </tr>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts" generic="Action extends string, GenericListItem extends object = {}">
import { usePageableTable } from "@/composables/usePageableTable.ts";
import type { Pagination } from "@/types.ts";
import { useListTable } from "@/composables/useListTable.ts";
import { toRefs } from "vue";
import kebabCase from "lodash/kebabCase";
import ListTableHeader from "@/components/ListTableHeader.vue";

const headers = [
  {
    value: "type",
  },
];

const props = withDefaults(
  defineProps<{
    items: GenericListItem[];
    itemKey: keyof GenericListItem;
    totalItems: number;
    pagination: Pagination;
    loading: boolean;
    availableBulkActions: { name: Action; disabled?: boolean; displayName: string }[];
    actionsLoading?: boolean;
    selectedItems: GenericListItem[];
    isIndeterminate: boolean;
    allItemsSelected: boolean;
    selectedItemsQuantity: number;
  }>(),
  { availableBulkActions: () => [], actionsLoading: false, totalItems: 0, items: () => [] }
);

const emit = defineEmits<{
  "page-changed": [value: Pagination];
  "update-preview": [item: GenericListItem | null];
  "bulk-menu-clicked": [];
  "bulk-action-clicked": [action: Action];
  "handle-one-row-selection": [payload: { selected: boolean; item: GenericListItem }];
  "handle-all-rows-selection": [payload: boolean];
}>();

const { totalItems, items, pagination, itemKey } = toRefs(props);

const { showPagination, handleListTablePaginationChange } = usePageableTable(
  {
    totalItems,
    items,
    pagination,
  },
  (value: Pagination) => {
    emit("page-changed", value);
  }
);

const { handleRowClick, isRowActive } = useListTable<GenericListItem>(
  { items, itemKey: props.itemKey },
  (item) => {
    emit("update-preview", item);
  }
);
</script>
