<template>
  <div class="content-md margin-auto">
    <v-card class="add-ins-tab">
      <v-card-text>
        <div v-if="!actionNotAllowed" class="ml-4 mt-3 w-95">
          <div class="subtitle1 mb-2">
            {{ $t("coroMailSettings.title") }}
          </div>
          <div class="body1 mb-6 coro-mail-description">
            {{ $t("coroMailSettings.description") }}
          </div>
        </div>
        <div v-if="!actionNotAllowed" class="d-flex ml-4">
          <a
            v-for="service in emailServices"
            :key="service.name"
            class="d-flex align-center add-in-service-btn mr-4"
            :href="service.link"
            target="_blank"
          >
            <div class="d-flex flex-column align-center justify-center w-100">
              <v-icon size="48" color="primary" icon="$secureMessagesSmall"></v-icon>
              <div class="d-flex align-center flex-column justify-center w-100 mt-2">
                <div class="subtitle1">{{ $t("subscriptions.addons.securedMessages") }}</div>
                <div class="body2 mr-1 text-indigo-medium">
                  {{ getDescription(service.name) }}
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="ml-4 mt-10 pb-8">
          <div class="subtitle1">
            {{ $t("coroMailSettings.coroMailPortal.title") }}
          </div>
          <div class="body1 mt-2 mb-4">
            {{ $t("coroMailSettings.coroMailPortal.description") }}
          </div>
          <div>
            <div class="copy-text-input">
              <div class="d-flex align-center body1">
                <v-icon icon="$trailingLink" size="24" class="mr-2"></v-icon>
                {{ secureMessagesPortalLink }}
              </div>
              <v-btn color="primary" rounded @click="copyLink()" data-testid="copy-btn">
                {{ $t("general.copy") }}
                <v-icon icon="$copy" size="15" class="ml-2"></v-icon>
              </v-btn>
            </div>
          </div>
          <div class="subtitle1 mb-3 mt-5">{{ $t("coroMailSettings.messageSettings") }}</div>
          <v-form>
            <v-checkbox
              data-testid="restrict-message-forwarding-checkbox"
              :ripple="false"
              v-model="localValue.restrictForwarding"
              :label="$t('coroMailSettings.restrictMessageForwarding')"
            />
            <v-checkbox
              data-testid="message-expiration-checkbox"
              :ripple="false"
              v-model="localValue.expirationEnabled"
              :label="$t('coroMailSettings.setMessageExpiration')"
            />
            <v-text-field
              v-model.number="localValue.expirationCountDays"
              :disabled="!localValue.expirationEnabled"
              data-testid="number-of-days-textfield"
              class="mt-2 ml-8 number-of-days-textfield"
              type="number"
              :label="$t('coroMailSettings.numberOfDays.label')"
              :placeholder="$t('coroMailSettings.numberOfDays.placeholder')"
              :rules="[minNumber()]"
            >
            </v-text-field>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import {
  copyToClipboard,
  isWorkspaceFrozenOrActionRestricted,
  useTwoWayBinding,
} from "@/_helpers/utils";
import { useI18n } from "vue-i18n";
import { useSnackbarStore } from "@/_store";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionAddon } from "@/constants/workplaces";
import { EmailAddInsProvider } from "@/constants/emails";
import { AddonMarketplaceLink, coroMailPortalLink } from "@/constants/general";
import { minNumber } from "@/_helpers/validators";
import {
  type EmailSecuritySettings,
  useEmailSettingsStore,
} from "@/_store/email-security/email-settings.module";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const snackbarStore = useSnackbarStore();
    const emailSettingsStore = useEmailSettingsStore();
    const { settings } = storeToRefs(emailSettingsStore);
    const getDescription = (provider: EmailAddInsProvider) => {
      return i18n.t("coroMailSettings.installFor", {
        provider: i18n.t(`general.${provider}`),
      });
    };
    const copyLink = () => {
      copyToClipboard(secureMessagesPortalLink.value);
      snackbarStore.addGenericSuccess(i18n.t("snackbar.messages.general.copiedToClipboard"));
    };

    const secureMessagesPortalLink = computed(() => {
      const subdomain = window.location.hostname.split(".coro.net")[0];
      const reservedSubdomains = ["secure", "secure-ca", "secure-eu"];
      return reservedSubdomains.includes(subdomain)
        ? `https://mail.${subdomain}.coro.net`
        : coroMailPortalLink;
    });

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionAddon.SECURED_MESSAGES
      );
    });

    const updateSettings = async (payload: EmailSecuritySettings) => {
      await emailSettingsStore.updateEmailsSettings(payload);
    };
    const localValue = useTwoWayBinding(settings, updateSettings);

    onMounted(async () => {
      await emailSettingsStore.getEmailsSettings();
    });

    return {
      getDescription,
      copyLink,
      emailServices: [
        { name: EmailAddInsProvider.OUTLOOK, link: AddonMarketplaceLink.SECURE_MESSAGES_OUTLOOK },
        { name: EmailAddInsProvider.GMAIL, link: AddonMarketplaceLink.SECURE_MESSAGES_GMAIL },
      ],
      secureMessagesPortalLink,
      actionNotAllowed,
      minNumber,
      localValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.coro-mail-description {
  max-width: 1000px;
}
.add-in-service-btn {
  width: 272px;
  height: 176px !important;
  border-radius: 6px;
  border: 1px solid rgb(var(--v-theme-indigo-faint));
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 2px solid rgb(var(--v-theme-primary)) !important;
  }
}

.service-icon {
  width: 30px;
  height: 30px;
}

.v-chip-group .v-chip--active {
  border: 2px solid var(--v-primary-base) !important;
}

.copy-text-input {
  height: 56px;
  width: 550px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;
  padding: 10px 8px 10px 12px;
  border-radius: 4px;
  background-color: rgb(var(--v-theme-indigo-pale));
}

.number-of-days-textfield {
  width: 200px;
}

:deep(*) {
  .v-input__append {
    margin: 0;
  }
}
</style>
