<template>
  <div class="d-flex">
    <v-menu
      v-model="showDemoModeAcknowledgePopup"
      :open-on-click="false"
      :open-on-focus="false"
      :open-on-hover="false"
      :close-on-content-click="false"
      location="bottom end"
      min-width="400"
      max-width="400"
      offset="10"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props">
          <span
            class="body2 align-self-center"
            v-html="
              $t('demoCountdownNotice.demoModeFinishes', {
                time: getDaysUntilTrialEnds(demoMode.endDate),
              })
            "
          >
          </span>
          <v-btn class="ml-4" rounded variant="flat" color="primary" @click="openStartTrialDialog">
            <div class="d-flex align-center">
              <v-icon size="24" icon="$play" color="white" class="mr-2" />
              <span>{{ $t("demoCountdownNotice.startYourTrial") }}</span>
            </div>
          </v-btn>
        </div>
      </template>
      <v-card max-width="400" class="bg-indigo-instructional pa-4">
        <div class="body2" v-html="$t('demoCountdownNotice.afterExploringDemo')"></div>
        <div class="d-flex justify-end mt-4">
          <v-btn variant="outlined" rounded color="primary" @click="closePopover()">
            {{ $t("demoCountdownNotice.understood") }}
          </v-btn>
        </div>
      </v-card>
    </v-menu>
    <div v-if="showSeparator" class="separator mx-3"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useSubscriptionStore } from "@/_store/subscription.module";
import {
  componentDialogsConfigConstructor,
  dialogsConfigConstructor,
  getDaysUntilTrialEnds,
  navigateWithReload,
} from "@/_helpers/utils";
import { storeToRefs } from "pinia";
import { GenericModalType } from "@/constants/general.ts";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { ModalWidth } from "@/constants/modals.ts";
import StartTrialModal from "@/components/modals/StartTrialModal.vue";
import { useTutorialStore } from "@/_store/tutorial.module.ts";
import { TutorialAction } from "@/constants/tutorial.ts";
import { RouteName } from "@/constants/routes.ts";

export default defineComponent({
  props: {
    showSeparator: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const subscriptionStore = useSubscriptionStore();
    const { demoMode } = storeToRefs(subscriptionStore);
    const dialogsStore = useDialogsStore();
    const tutorialStore = useTutorialStore();
    const { showDemoModeAcknowledgePopup } = storeToRefs(tutorialStore);

    const openStartTrialDialog = () => {
      const config = componentDialogsConfigConstructor({
        action: TutorialAction.START_TRIAL_FROM_DEMO_STATE,
        width: ModalWidth.MEDIUM,
        component: StartTrialModal,
        callback: async () => {
          await subscriptionStore.startTrialForCurrentWorkspace();
          navigateWithReload(RouteName.DASHBOARD);
        },
      });
      dialogsStore.openDialog(dialogsConfigConstructor({ ...config, header: { close: false } }));
    };

    const closePopover = () => {
      tutorialStore.$patch({ showDemoModeAcknowledgePopup: false });
    };

    return {
      demoMode,
      getDaysUntilTrialEnds,
      openStartTrialDialog,
      GenericModalType,
      closePopover,
      showDemoModeAcknowledgePopup,
    };
  },
});
</script>

<style lang="scss" scoped>
.separator {
  border-left: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
