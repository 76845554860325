<template>
  <v-card class="h-100" rounded="lg" elevation="0">
    <v-row no-gutters class="h-100">
      <v-col class="py-6" data-testid="tickets-breakdown-widget-per-modules">
        <div class="px-6" v-if="ticketsPerModuleData.loading">
          <v-skeleton-loader type="heading" class="mb-2 bg-transparent" />
          <v-skeleton-loader type="list-item-two-line@4" class="mb-2 bg-transparent" />
        </div>

        <div v-else-if="ticketsPerModuleData.data">
          <div class="px-6 subtitle2 mb-5">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.ticketsPerModule") }}
          </div>

          <v-list-item
            v-for="(count, module, index) in sortedTicketsPerModule"
            :key="module"
            class="px-6 py-0"
            @click="navigateToTickets({ module })"
            :data-testid="`tickets-breakdown-widget-per-modules-${module}-item`"
          >
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center pb-3 pt-4">
                <v-icon size="24" :icon="iconToModuleMap[module]"></v-icon>
                <div class="body2 ml-2">
                  {{ $t(`subscriptions.modules.${module}`) }}
                </div>
              </div>
              <div class="subtitle2" :class="{ 'text-red-dark': count > 0 }">
                {{ count }}
              </div>
            </div>
            <v-divider
              v-if="index !== Object.values(sortedTicketsPerModule).length - 1"
              class="border-opacity-100"
              color="indigo-faint"
            ></v-divider>
          </v-list-item>
        </div>

        <div
          v-else
          class="h-100 d-flex align-center justify-center flex-column px-6"
          data-testid="widget-error-state"
        >
          <v-icon icon="$alertTriangle" size="80"></v-icon>
          <span class="subtitle1 mb-4">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.error") }}
          </span>
          <div
            class="coro-link"
            @click="$emit('retry-fetch-data', TicketsBreakdownWidgetPart.PER_MODULE)"
          >
            {{ $t("general.retry") }}
          </div>
        </div>
      </v-col>
      <v-divider color="indigo-faint" opacity="100" inset vertical class="mx-4 my-6" />
      <v-col class="py-6" data-testid="tickets-breakdown-widget-per-workspace">
        <div class="px-6" v-if="ticketsPerWorkspaceData.loading">
          <v-skeleton-loader type="heading" class="mb-2 bg-transparent" />
          <v-skeleton-loader type="list-item-two-line@4" class="mb-2 bg-transparent" />
        </div>

        <div v-else-if="ticketsPerWorkspaceData.data">
          <div class="px-6 subtitle2 mb-5">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.ticketsPerWorkspace") }}
          </div>

          <v-list-item
            v-for="({ workspace, ticketsCount }, index) in ticketsPerWorkspaceData.data"
            :key="workspace.workspaceId"
            class="px-6 py-0"
            @click="navigateToTickets({ workspaceId: workspace.workspaceId })"
            :data-testid="`tickets-breakdown-widget-per-workspace-${workspace.workspaceId}-item`"
          >
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center pb-3 pt-4">
                <v-icon size="24" :icon="iconToWorkspaceMap[workspace.type]"></v-icon>
                <div class="body2 ml-2">
                  {{ workspace.name }}
                </div>
              </div>
              <div class="subtitle2" :class="{ 'text-red-dark': ticketsCount > 0 }">
                {{ ticketsCount }}
              </div>
            </div>
            <v-divider
              v-if="index !== ticketsPerWorkspaceData.data.length - 1"
              class="border-opacity-100"
              color="indigo-faint"
            ></v-divider>
          </v-list-item>
        </div>

        <div
          v-else
          class="h-100 d-flex align-center justify-center flex-column px-6"
          data-testid="widget-error-state"
        >
          <v-icon icon="$alertTriangle" size="80"></v-icon>
          <span class="subtitle1 mb-4">
            {{ $t("mspDashboard.widgets.ticketsBreakdownWidget.error") }}</span
          >
          <div
            class="coro-link"
            @click="$emit('retry-fetch-data', TicketsBreakdownWidgetPart.PER_MODULE)"
          >
            {{ $t("general.retry") }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { DataValue } from "@/types.ts";
import type { ModuleTicketsStats, WorkspaceTicket } from "@/_store/msp/msp-dashboard.module.ts";
import { RouteName } from "@/constants/routes.ts";
import { sortObjectByValue } from "@/_helpers/utils.ts";
import {
  SubscriptionModule,
  type TicketGeneratingSubscriptionModule,
  WorkspaceType,
} from "@/constants/workplaces.ts";
import { TicketsModuleFilter } from "@/constants/tickets.ts";
import { defaultFiltersState, FilterContext, useFiltersStore } from "@/_store/filters.module.ts";
import { useRouter } from "vue-router";
import { RolePermissionsScope, type TicketsScope } from "@/_store/roles.module.ts";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions.ts";

export type TicketsBreakdownWidgetData = {
  ticketsPerModuleData: DataValue<ModuleTicketsStats>;
  ticketsPerWorkspaceData: DataValue<WorkspaceTicket[]>;
};

export enum TicketsBreakdownWidgetPart {
  PER_WORKSPACE = "ticketsBreakdownPerWorkspace",
  PER_MODULE = "ticketsBreakdownPerModule",
}

const iconToModuleMap: Record<string, string> = {
  [SubscriptionModule.CLOUD_SECURITY]: `$cloudSecurityXs`,
  [SubscriptionModule.EMAIL_SECURITY]: `$mailUnreadXs`,
  [SubscriptionModule.ENDPOINT_SECURITY]: `$endpointXs`,
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: `$endpointDataGovernanceXs`,
  [SubscriptionModule.USER_DATA_GOVERNANCE]: `$userDataGovernanceXs`,
  [SubscriptionModule.EDR]: `$edrXs`,
};

const moduleToFilterMap = {
  [SubscriptionModule.CLOUD_SECURITY]: TicketsModuleFilter.CLOUD_SECURITY_WIDGET,
  [SubscriptionModule.EMAIL_SECURITY]: TicketsModuleFilter.EMAILS_WIDGET,
  [SubscriptionModule.ENDPOINT_SECURITY]: TicketsModuleFilter.DEVICE_WIDGET,
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: TicketsModuleFilter.ENDPOINT_DATA_GOVERNANCE,
  [SubscriptionModule.USER_DATA_GOVERNANCE]: TicketsModuleFilter.USER_DATA_GOVERNANCE,
  [SubscriptionModule.EDR]: TicketsModuleFilter.EDR,
} as Record<TicketGeneratingSubscriptionModule, TicketsModuleFilter>;

const iconToWorkspaceMap: Record<string, string> = {
  [WorkspaceType.REGULAR]: "icon-regular-plan",
  [WorkspaceType.CHANNEL]: "icon-parent-plan",
  [WorkspaceType.CHILD]: "icon-child-plan",
};

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<TicketsBreakdownWidgetData>,
      required: true,
    },
  },
  emits: ["retry-fetch-data", "show-restricted-access-modal"],
  setup(props, { emit }) {
    const router = useRouter();
    const filtersStore = useFiltersStore();
    const ticketsPerModuleData = computed(() => props.widgetData.ticketsPerModuleData);
    const ticketsPerWorkspaceData = computed(() => props.widgetData.ticketsPerWorkspaceData);
    const sortedTicketsPerModule = computed<Record<TicketGeneratingSubscriptionModule, number>>(
      () => {
        return ticketsPerModuleData.value.data
          ? sortObjectByValue(ticketsPerModuleData.value.data)
          : {};
      }
    );

    const mspTicketsAccessAllowed = computed(() => {
      const modules: (keyof TicketsScope)[] = [
        SubscriptionModule.CLOUD_SECURITY,
        SubscriptionModule.ENDPOINT_SECURITY,
        SubscriptionModule.EMAIL_SECURITY,
        SubscriptionModule.USER_DATA_GOVERNANCE,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
        SubscriptionModule.EDR,
      ] as const;
      return modules.some((module) => !isMspAccessRestricted(RolePermissionsScope.TICKETS, module));
    });

    const navigateToTickets = async ({
      module,
      workspaceId,
    }: {
      module?: TicketGeneratingSubscriptionModule;
      workspaceId?: string;
    }) => {
      if (!mspTicketsAccessAllowed.value) {
        emit("show-restricted-access-modal");
        return;
      }
      const filters = {
        ...defaultFiltersState[FilterContext.MSP_TICKETS],
        widget: module ? moduleToFilterMap[module] : null,
        childWorkspaceIds: workspaceId ? [workspaceId] : [],
      };
      filtersStore.setFilters(FilterContext.MSP_TICKETS, filters);
      await router.push({ name: RouteName.MSP_TICKETS_PAGE });
    };

    return {
      ticketsPerModuleData,
      ticketsPerWorkspaceData,
      RouteName,
      sortObjectByValue,
      iconToModuleMap,
      iconToWorkspaceMap,
      sortedTicketsPerModule,
      TicketsBreakdownWidgetPart,
      navigateToTickets,
    };
  },
});
</script>
<style lang="scss">
.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}
</style>
