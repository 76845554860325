<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <div class="d-flex justify-end">
        <filter-wrapper
          class="mb-5"
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters(clearFiltersCallback)"
        >
          <v-autocomplete
            v-model="localFilters.workspaceId"
            class="workspace-autocomplete mr-2"
            :class="{ 'filter-active': localFilters.workspaceId?.length }"
            :placeholder="$t('modals.addMspAdminUser.workspace.label')"
            persistent-hint
            item-title="workspaceName"
            item-value="workspaceId"
            :hide-no-data="false"
            :no-filter="true"
            auto-select-first="exact"
            :menu-props="{
              maxHeight: '250',
            }"
            :items="mspAdminUserWorkspaces"
            :loading="workspacesLoading"
            variant="outlined"
            density="compact"
            rounded
            @update:search="onWorkspaceSearchUpdate($event)"
          ></v-autocomplete>
          <v-text-field
            v-model="localFilters.search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            variant="outlined"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            clearable
            rounded
            clear-icon="icon-x"
            @click:clear="localFilters.search = ''"
          />
        </filter-wrapper>
      </div>
      <table-wrapper>
        <v-data-table-server
          class="coronet-table--highlightable"
          :items="adminUsers.items"
          :fixed-header="true"
          :page="pagination.page + 1"
          :show-select="false"
          item-key="sessionId"
          :loading="loading"
          :headers="headers"
          v-model:items-per-page="pagination.pageSize"
          :items-length="adminUsers.total"
          @update:options="
            onPageChange({
              pageSize: $event.itemsPerPage,
              page: $event.page - 1,
            })
          "
        >
          <template #item="{ item, index }">
            <tr :key="`${item.id}_${item.workspaceId}`" class="service-row">
              <td class="content-row">
                <div class="d-flex align-center">
                  <v-icon icon="$user" size="40" class="mr-4"></v-icon>
                  <span class="subtitle2">{{ `${item.firstName} ${item.lastName}` }}</span>
                  <span v-if="!isNotCurrentAdmin(item)" class="subtitle2">
                    ({{ $t("manageWorkspaces.adminUsers.table.you") }})
                  </span>
                </div>
              </td>
              <td class="content-row">
                <span class="body2">{{ item.email }}</span>
              </td>
              <td class="content-row">
                <span class="body2">{{ item.role?.roleName }}</span>
              </td>
              <td class="content-row">
                <div class="d-flex flex-column">
                  <span class="subtitle2">{{ item.workspaceDisplayName }}</span>
                  <span class="caption text-indigo-medium">{{ item.workspaceId }}</span>
                </div>
              </td>
              <td class="text-right" @click="$event.stopPropagation()">
                <v-menu v-if="getAdminActions(item).length > 0" location="bottom left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      icon="$dots"
                      :data-testid="`admin-account-table-menu-${index}`"
                      class="item-clickable"
                      v-bind="props"
                    ></v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(action, index) in getAdminActions(item)"
                      :key="action"
                      :data-testid="`admin-account-table-menu-item-${index}`"
                      @click="handleAction(item, action)"
                    >
                      <v-list-item-title>
                        <span class="body2">
                          {{ $t(`manageWorkspaces.adminUsers.table.actions.${action}`) }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </table-wrapper>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader.ts";
import TableWrapper from "@/components/TableWrapper.vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import { MspAdminUsersAction } from "@/constants/admin-accounts.ts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
} from "@/_helpers/utils.ts";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { useAccountStore } from "@/_store/account.module.ts";
import { ModalWidth } from "@/constants/modals.ts";
import AddMspAdminUserModal from "@/components/modals/AddMspAdminUserModal.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useFilters } from "@/composables/useFilters.ts";
import { FilterContext } from "@/_store/filters.module.ts";
import { type MspAdminUser, useMspAdminUsersStore } from "@/_store/msp-admin-users.module.ts";
import { MspPortalScopeSection, MspRolePermissionScopes } from "@/_store/roles.module.ts";
import { debounce } from "lodash";
import { useI18n } from "vue-i18n";
import type { Pagination } from "@/types.ts";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";
import { isMspActionRestricted } from "@/_helpers/msp-permissions.ts";

export default defineComponent({
  components: {
    FilterWrapper,
    TableWrapper,
  },
  setup() {
    const i18n = useI18n();
    const { localFilters, showClearFiltersButton, clearFilters, filtersUpdating } = useFilters(
      FilterContext.MSP_ADMIN_USERS
    );
    const mspAdminUsersStore = useMspAdminUsersStore();
    const dialogsStore = useDialogsStore();
    const accountStore = useAccountStore();
    const { getMspAdminUsers, deleteMspAdminUser, editMspAdminUser } = mspAdminUsersStore;
    const { account } = accountStore;
    const { adminUsers, showSkeletonLoader, loading, pagination } = storeToRefs(mspAdminUsersStore);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(useWorkspacesStore());
    const workspacesLoading = ref(false);
    const mspAdminUserWorkspaces = ref<{ workspaceId: string; workspaceName: string }[]>([]);

    const isNotCurrentAdmin = (item: MspAdminUser) => {
      return item.email !== account.email;
    };
    const handleAction = (item: MspAdminUser, action: MspAdminUsersAction) => {
      switch (action) {
        case MspAdminUsersAction.DELETE:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item,
              action,
              callback: deleteMspAdminUser,
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_ADMIN_USERS,
                "remove"
              ),
            })
          );
          break;
        case MspAdminUsersAction.EDIT:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item,
              action: MspAdminUsersAction.EDIT,
              component: AddMspAdminUserModal,
              width: ModalWidth.LARGE,
              callback: editMspAdminUser,
              disable: isMspActionRestricted(
                MspRolePermissionScopes.MSP_PORTAL_SCOPE,
                MspPortalScopeSection.MSP_ADMIN_USERS,
                "assignRoles"
              ),
            })
          );
          break;
      }
    };

    const clearFiltersCallback = async () => {
      mspAdminUsersStore.$patch({ pagination: { page: 0 } });
      await getMspAdminUsers();
    };

    const getAdminActions = (item: MspAdminUser): MspAdminUsersAction[] => {
      if (isNotCurrentAdmin(item)) {
        return [MspAdminUsersAction.EDIT, MspAdminUsersAction.DELETE];
      }
      return [];
    };

    watch(
      filtersUpdating,
      async (updating: boolean) => {
        if (updating) {
          mspAdminUsersStore.$patch({ pagination: { page: 0 } });
          await getMspAdminUsers();
        }
      },
      { deep: true }
    );

    const onWorkspaceSearchUpdate = debounce(function (searchString) {
      const foundWorkspace = mspAdminUserWorkspaces.value.find(
        (v) => v.workspaceId === localFilters.value.workspaceId
      );
      if (!searchString && !foundWorkspace?.workspaceName) {
        fetchMspAdminUserWorkspaces();
      }
      if (searchString !== foundWorkspace?.workspaceName) {
        fetchMspAdminUserWorkspaces(searchString);
      }
    }, 500);

    const fetchMspAdminUserWorkspaces = async (search: string = "") => {
      workspacesLoading.value = true;
      const data = await mspAdminUsersStore.searchMspAdminUserWorkspaces(0, search);
      mspAdminUserWorkspaces.value = data.items;
      workspacesLoading.value = false;
    };

    onMounted(async () => {
      await getMspAdminUsers(true);
      await fetchMspAdminUserWorkspaces();
    });

    const onPageChange = async (pageObj: Pagination) => {
      mspAdminUsersStore.$patch({ pagination: pageObj });
      await mspAdminUsersStore.getMspAdminUsers();
    };

    watch(workspaceAndPermissionsUpdatedTimestamp, async (newVal) => {
      if (newVal) {
        await getMspAdminUsers(true);
        await fetchMspAdminUserWorkspaces();
      }
    });

    return {
      breadCrumbsItems: [
        {
          title: `‹ Control Panel`,
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      headers: [
        {
          title: i18n.t("manageWorkspaces.adminUsers.table.headers.name"),
          key: "lastName",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.adminUsers.table.headers.email"),
          key: "email",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.adminUsers.table.headers.role"),
          key: "role",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      adminUsers,
      showSkeletonLoader,
      loading,
      localFilters,
      showClearFiltersButton,
      workspacesLoading,
      mspAdminUserWorkspaces,
      pagination,
      clearFilters,
      clearFiltersCallback,
      isNotCurrentAdmin,
      getAdminActions,
      coronetSkeletonLoaderTypes,
      handleAction,
      onWorkspaceSearchUpdate,
      onPageChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}
.workspace-autocomplete {
  width: 200px;
}
</style>
