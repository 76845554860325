enum Industries {
  ACCOUNTING_SERVICES = "accountingServices",
  AGRICULTURE_AND_FOOD = "agricultureAndFood",
  AUTOMOTIVE = "automotive",
  BUSINESS_AND_MARKETING = "businessAndMarketing",
  BUSINESS_SERVICES = "businessServices",
  COLLEGES_AND_UNIVERSITIES = "collegesAndUniversities",
  CONSTRUCTION = "construction",
  CONSULTING = "consulting",
  CONSUMER_SERVICES = "consumerServices",
  EDUCATION = "education",
  ENERGY_UTILITIES_AND_WASTE = "energyAndUtilitiesAndWaste",
  FINANCE = "finance",
  GOVERNMENT = "government",
  HEALTH = "health",
  HOLDING_COMPANIES_AND_CONGLOMERATES = "holdingCompaniesAndConglomerates",
  HOSPITALITY = "hospitality",
  HR = "hr",
  IT_SERVICES = "itServices",
  INSURANCE = "insurance",
  LAW_FIRMS_AND_LEGAL_SERVICES = "lawFirmsAndLegalServices",
  MANUFACTURING = "manufacturing",
  MEDIA_AND_INTERNET = "mediaAndInternet",
  MINERALS_AND_MINING = "mineralsAndMining",
  ORGANIZATIONS = "organizations",
  PHARMA = "pharma",
  REAL_ESTATE = "realEstate",
  RETAIL = "retail",
  SOFTWARE = "software",
  TELECOMMUNICATIONS = "telecommunications",
  TRANSPORTATION = "transportation",
  OTHER = "other",
}

enum Locations {
  USA = "usa",
  CANADA = "canada",
  ITALY = "italy",
  SPAIN = "spain",
}

export { Industries, Locations };
