<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
  >
    <table-wrapper>
      <v-data-table
        class="white"
        :headers="headers"
        v-model:expanded="expanded"
        :items="items"
        :show-expand="true"
        :fixed-header="true"
        :loading="loading"
        :items-per-page="10000"
        color="primary"
      >
        <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
          <tr
            :id="`${index}`"
            v-intersect="handleVirtualScroll"
            class="table-row"
            :data-testid="`table-row-${index}`"
          >
            <td class="w-5">
              <v-icon
                class="mr-2 cursor-pointer"
                icon="$dropdown"
                color="primary"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="
                  onPropertyExpand(
                    item.id,
                    () => toggleExpand(internalItem),
                    () => isExpanded(internalItem)
                  )
                "
              />
            </td>
            <td class="icon-col">
              <v-icon size="40" :icon="getIconByType(item.type)" />
            </td>
            <td class="body2 forensic-col">
              <div class="d-flex justify-center flex-column">
                <div class="body2 font-weight-bold">
                  {{ $t(`edr.telemetryTab.types.${item.type}`) }}
                </div>
                <div class="body2">
                  <v-tooltip open-delay="300" location="top">
                    <template #activator="{ props }">
                      <div
                        :data-testid="`edr-telemetry-table-name-${index}`"
                        class="d-inline-block"
                        v-bind="props"
                      >
                        <ellipsified-copy-text max-width="590px" :text="item.name" />
                      </div>
                    </template>
                    {{ item.name }}
                  </v-tooltip>
                </div>
                <div class="caption text-gray-medium">
                  {{ getFormattedDateTime(item.timestamp) }}
                </div>
              </div>
            </td>
            <td class="body2">
              <div
                v-if="hideDevicesTableLink"
                class="body2"
                :data-testid="`edr-telemetry-table-hostname-${index}`"
              >
                {{ item.deviceDetails.hostname }}
              </div>
              <div
                v-else
                class="coro-link"
                role="button"
                :data-testid="`edr-telemetry-table-hostname-${index}`"
                @click="viewDevice(item.deviceDetails.enrollmentCode)"
              >
                {{ item.deviceDetails.hostname }}
              </div>
            </td>
            <td class="body2">
              <div
                class="coro-link"
                role="button"
                :data-testid="`edr-telemetry-table-process-name-${index}`"
                @click="viewProcess(`processHash:${item.processDetails.processHash}`)"
              >
                {{ item.processDetails.name }}
              </div>
            </td>
            <td>
              <v-menu bottom left>
                <template #activator="{ props }">
                  <v-icon icon="$dots" v-bind="props" />
                </template>
                <v-list>
                  <template v-for="action in getActionsList(item.actions)" :key="action">
                    <v-list-item
                      :data-testid="`edr-telemetry-table-${kebabCase(action)}-action`"
                      @click="handleItemAction(action, item)"
                    >
                      <v-list-item-title>
                        {{ $t(`edr.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template #expanded-row="{ item, columns }">
          <td :colspan="columns.length">
            <v-progress-linear v-if="showDetailsLoading(item)" indeterminate height="2px" />
            <div v-else class="expanded-content">
              <div class="row mb-4 mr-12">
                <v-col
                  v-for="property in getDetailsProperties(details[item.id])"
                  :key="property"
                  cols="3"
                >
                  <div v-if="details[item.id][property]">
                    <div class="caption text-primary">
                      {{ $t(`edr.telemetryTab.table.${property}`) }}
                    </div>
                    <v-tooltip v-if="showTelemetryDetailsTooltip(property)" open-delay="300" top>
                      <template #activator="{ props }">
                        <div
                          :data-testid="`edr-telemetry-details-${kebabCase(property)}-header-item`"
                          class="d-inline-block"
                          v-bind="props"
                        >
                          <ellipsified-copy-text
                            max-width="220px"
                            class="body2"
                            :text="details[item.id][property]"
                          />
                        </div>
                      </template>
                      <div>{{ details[item.id][property] }}</div>
                    </v-tooltip>
                    <div
                      v-else
                      :data-testid="`edr-telemetry-details-${kebabCase(property)}-header-item`"
                      class="d-inline-block"
                    >
                      <ellipsified-copy-text
                        max-width="220px"
                        class="body2"
                        :text="details[item.id][property]"
                      />
                    </div>
                  </div>
                </v-col>
              </div>
              <v-divider />
              <v-col class="mt-4">
                <div class="body1 mb-6">
                  {{ $t("edr.telemetryTab.table.detailedProcessInfo") }}
                </div>
                <div class="row mr-12">
                  <v-col cols="3" class="expanded-content-full-details-col body2">
                    {{ $t("edr.telemetryTab.table.processName") }}
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="details[item.id]?.processDetails?.processName"
                    class="expanded-content-full-details-col"
                  >
                    {{ details[item.id]?.processDetails?.processName }}
                  </v-col>
                  <v-col cols="3" class="expanded-content-full-details-col d-flex align-center">
                  </v-col>
                  <template v-if="details[item.id]?.processDetails?.pid">
                    <v-col cols="3" class="expanded-content-full-details-col body2">
                      {{ $t("edr.telemetryTab.table.pid") }}
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="details[item.id]?.processDetails?.pid"
                      class="expanded-content-full-details-col"
                    >
                      {{ details[item.id]?.processDetails?.pid }}
                    </v-col>
                    <v-col cols="3" class="expanded-content-full-details-col d-flex align-center">
                    </v-col>
                  </template>
                  <v-col cols="3" class="expanded-content-full-details-col body2">
                    {{ $t("edr.telemetryTab.table.processHash") }}
                  </v-col>
                  <v-col
                    cols="9"
                    data-testid="edr-telemetry-details-process-hash"
                    class="expanded-content-full-details-col coro-link"
                    @click="
                      viewProcess(`processHash:${details[item.id]?.processDetails?.processHash}`)
                    "
                  >
                    {{ details[item.id]?.processDetails?.processHash }}
                  </v-col>
                  <template v-if="details[item.id]?.processDetails?.commandLine">
                    <v-col cols="3" class="expanded-content-full-details-col body2">
                      {{ $t("edr.telemetryTab.table.commandLine") }}
                    </v-col>
                    <v-col cols="9" class="expanded-content-full-details-col">
                      <v-tooltip open-delay="300" location="top">
                        <template #activator="{ props }">
                          <div class="d-inline-block" v-bind="props">
                            <ellipsified-copy-text
                              max-width="700px"
                              :text="details[item.id]?.processDetails?.commandLine"
                            />
                          </div>
                        </template>
                        <div>{{ details[item.id]?.processDetails?.commandLine }}</div>
                      </v-tooltip>
                    </v-col>
                  </template>
                </div>
                <div
                  v-if="showAdditionalProcessDetails(details[item.id]?.processDetails)"
                  class="row mt-12 mr-12"
                >
                  <template v-if="details[item.id]?.processDetails?.parentProcessName">
                    <v-col cols="3" class="expanded-content-full-details-col body2">
                      {{ $t("edr.telemetryTab.table.parentProcessName") }}
                    </v-col>
                    <v-col cols="9" class="expanded-content-full-details-col">
                      {{ details[item.id]?.processDetails?.parentProcessName }}
                    </v-col>
                  </template>
                  <template v-if="details[item.id]?.processDetails?.parentProcessHash">
                    <v-col cols="3" class="expanded-content-full-details-col body2">
                      {{ $t("edr.telemetryTab.table.processHash") }}
                    </v-col>
                    <v-col cols="9" class="expanded-content-full-details-col">
                      {{ details[item.id]?.processDetails?.parentProcessHash }}
                    </v-col>
                  </template>
                  <template v-if="details[item.id]?.processDetails?.parentCommandLine">
                    <v-col cols="3" class="expanded-content-full-details-col body2">
                      {{ $t("edr.telemetryTab.table.commandLine") }}
                    </v-col>
                    <v-col cols="9" class="expanded-content-full-details-col">
                      <v-tooltip open-delay="300" location="top">
                        <template #activator="{ props }">
                          <div class="d-inline-block" v-bind="props">
                            <ellipsified-copy-text
                              max-width="700px"
                              class="body2"
                              :text="details[item.id]?.processDetails?.parentCommandLine"
                            />
                          </div>
                        </template>
                        <div>{{ details[item.id]?.processDetails?.parentCommandLine }}</div>
                      </v-tooltip>
                    </v-col>
                  </template>
                </div>
              </v-col>
            </div>
            <v-divider />
          </td>
        </template>
        <template #bottom></template>
      </v-data-table>
      <v-progress-circular
        v-if="infiniteLoadingInProgress"
        class="ma mt-4 telemetry-infinite-loader-spinner"
        color="primary"
        indeterminate
        size="36"
      />
    </table-wrapper>
  </v-skeleton-loader>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent, ref, toRefs } from "vue";
import EllipsifiedCopyText from "@/components/EllipsifiedCopyText.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";
import {
  useTelemetryStore,
  TelemetryDetailsProperty,
} from "@/_store/endpoint-security/telemetry.module";
import type {
  TelemetryItem,
  TelemetryProcessDetails,
  TelemetryDetailsItem,
} from "@/_store/endpoint-security/telemetry.module";
import { EdrAction, TelemetryType } from "@/constants/edr";
import {
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isAccessRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useRouter } from "vue-router";
import { kebabCase, merge } from "lodash";
import { SubscriptionModule } from "@/constants/workplaces";
import { RouteName } from "@/constants/routes.ts";

export default defineComponent({
  components: {
    EllipsifiedCopyText,
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<Array<TelemetryItem>>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const dialogsStore = useDialogsStore();
    const router = useRouter();
    const telemetryStore = useTelemetryStore();
    const {
      showSkeletonLoader,
      infiniteLoadingInProgress,
      detailsLoading,
      loadingItemsList,
      details,
    } = storeToRefs(telemetryStore);

    const { hasMore, getItems, setPagination, getEdrLog, applyTelemetryAction, getDetails } =
      telemetryStore;

    const { pagination } = toRefs(props);

    const expanded = ref([]);
    const headers = [
      { title: "", key: "data-table-expand", sortable: false },
      { title: "", key: "data-table-icon", sortable: false },
      {
        title: i18n.t("edr.telemetryTab.table.forensic"),
        key: "forensic",
        sortable: false,
      },
      {
        title: i18n.t("edr.telemetryTab.table.device"),
        key: "device",
        sortable: false,
      },
      {
        title: i18n.t("edr.telemetryTab.table.process"),
        key: "process",
        sortable: false,
      },
      { title: "", key: "actions", sortable: false },
    ];

    const detailsProperties = [
      TelemetryDetailsProperty.USER_NAME,
      TelemetryDetailsProperty.CURRENT_VALUE,
      TelemetryDetailsProperty.OLD_VALUE,
      TelemetryDetailsProperty.EVENT_ID,
      TelemetryDetailsProperty.TARGET_SERVER,
      TelemetryDetailsProperty.GROUP,
      TelemetryDetailsProperty.ACTION,
      TelemetryDetailsProperty.TRIGGER,
      TelemetryDetailsProperty.ACCOUNT_DOMAIN,
      TelemetryDetailsProperty.OPERATION_TYPE,
      TelemetryDetailsProperty.SUCCESS,
      TelemetryDetailsProperty.AUTH_TYPE,
      TelemetryDetailsProperty.UID,
      TelemetryDetailsProperty.USER_TYPE,
      TelemetryDetailsProperty.DB_PATH,
      TelemetryDetailsProperty.INSTIGATOR,
      TelemetryDetailsProperty.FRIENDLY_NAME,
      TelemetryDetailsProperty.USB_DEVICE,
      TelemetryDetailsProperty.GROUP_POLICY_OBJECT_NAME,
      TelemetryDetailsProperty.PASSWORD_MINIMUM_AGE_POLICY,
      TelemetryDetailsProperty.PASSWORD_LENGTH_POLICY,
    ];

    const hideDevicesTableLink = computed(() => {
      return isAccessRestricted(RolePermissionsScope.VIEWS, "devicesView");
    });

    const handleVirtualScroll = (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === (props.items ?? []).length - 1) {
        if (!hasMore) {
          return;
        }
        setPagination({
          page: ++pagination.value.page,
          pageSize: 15,
        });
        getItems({ virtualScroll: true });
      }
    };

    const viewDevice = (search: string) => {
      router.push({ name: RouteName.DEVICES, query: { search } });
    };

    const viewProcess = (search: string) => {
      router.push({ name: RouteName.EDR_PROCESSES_TAB, query: { search } });
    };

    const getIconByType = (type: string) => {
      switch (type) {
        case TelemetryType.REGISTRY_KEY:
          return "$registryKey";
        case TelemetryType.SCHEDULED_TASK:
          return "$scheduledTask";
        case TelemetryType.ACCOUNT_EVENT:
          return "$desktop";
        case TelemetryType.USB_DEVICE_ACTIVITY:
          return "$desktop";
        case TelemetryType.GROUP_POLICY_CHANGES:
          return "$desktop";
      }
    };

    const onPropertyExpand = async (
      id: string,
      toggleCallback: Function,
      isExpandedCallback: Function
    ) => {
      toggleCallback();
      // make call only when row is being expanded
      if (isExpandedCallback()) return;
      await getDetails(id);
    };

    const getActionsList = (actions: Array<string>) => {
      return [...actions, EdrAction.VIEW_LOG];
    };

    const handleItemAction = (action: string, item: TelemetryItem) => {
      if (action === EdrAction.VIEW_LOG) {
        getEdrLog(item.id);
      } else {
        const dialogConfig = confirmationDialogsConfigConstructor({
          item: merge(item, item.processDetails),
          action,
          callback: () => applyTelemetryAction({ action, item }),
          disable: isWorkspaceFrozenOrActionRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.EDR
          ),
        });

        dialogsStore.openDialog(dialogConfig);
      }
    };

    const getDetailsProperties = (item: TelemetryDetailsItem) => {
      if (!item) return [];

      return detailsProperties.filter((prop: TelemetryDetailsProperty) => Boolean(item[prop]));
    };

    const showDetailsLoading = (item: TelemetryItem) => {
      return detailsLoading.value && loadingItemsList.value.includes(item.id);
    };

    const showTelemetryDetailsTooltip = (property: TelemetryDetailsProperty) => {
      return [
        TelemetryDetailsProperty.ACTION,
        TelemetryDetailsProperty.DB_PATH,
        TelemetryDetailsProperty.INSTIGATOR,
        TelemetryDetailsProperty.USB_DEVICE,
        TelemetryDetailsProperty.GROUP_POLICY_OBJECT_NAME,
      ].includes(property);
    };

    const showAdditionalProcessDetails = (item: TelemetryProcessDetails) => {
      if (!item) return;

      return item.parentProcessName || item.parentProcessHash || item.parentCommandLine;
    };

    return {
      coronetSkeletonLoaderTypes,
      showSkeletonLoader,
      headers,
      expanded,
      handleVirtualScroll,
      getIconByType,
      getFormattedDateTime,
      hideDevicesTableLink,
      viewDevice,
      viewProcess,
      getActionsList,
      kebabCase,
      handleItemAction,
      infiniteLoadingInProgress,
      onPropertyExpand,
      getDetailsProperties,
      showDetailsLoading,
      showTelemetryDetailsTooltip,
      showAdditionalProcessDetails,
      details,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-data-table__wrapper {
    border-radius: 6px;
  }

  .table-row {
    height: 87px !important;
  }
}

.expander-col {
  width: 30px !important;
}

.expanded-content {
  margin: 30px 0 50px 70px;
}

.expanded-content-full-details-col {
  padding: 3px 12px !important;
}

.forensic-col {
  width: 600px;
}

.icon-col {
  padding: 0 !important;
}

.telemetry-infinite-loader-spinner {
  margin-left: calc(50% - 36px);
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.v-col {
  padding: 0 !important;
}
</style>
