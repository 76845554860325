<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div>
      <v-autocomplete
        v-if="!isEditMode"
        v-model="localValue.item.workspaceId"
        class="mb-4"
        :placeholder="$t('modals.addMspRole.workspace.placeholder')"
        :label="$t('modals.addMspRole.workspace.label')"
        :hint="$t('modals.addMspRole.workspace.hint')"
        persistent-hint
        item-title="workspaceName"
        item-value="workspaceId"
        :hide-no-data="false"
        :no-filter="true"
        auto-select-first="exact"
        :rules="[required()]"
        :loading="workspaceCandidatesLoading"
        :menu-props="{
          maxHeight: '250',
        }"
        :items="workspaceCandidates"
        variant="outlined"
        @update:search="onWorkspaceSearchUpdate($event)"
      ></v-autocomplete>
      <div class="subtitle1 mb-4">{{ $t("modals.addRole.roleName") }}</div>
      <v-text-field
        v-model.trim="localValue.item.name"
        class="mb-6"
        variant="outlined"
        :rules="[required()]"
        :label="$t('modals.addRole.role')"
        :placeholder="$t('modals.addRole.role')"
        rounded="lg"
      ></v-text-field>
      <div class="headline6 mb-6">
        {{ $t("manageWorkspaces.roles.mspPermissionsSectionTitle") }}
      </div>
      <add-role-modal-section
        v-for="scope in mspSectionScopes"
        class="mb-6"
        :item="localValue.item"
        :section-title="$t(`manageWorkspaces.roles.scopes.${scope}`)"
        :scopeName="scope"
        :key="scope"
        :show-details-tooltip="true"
        :empty-permissions-object="emptyMspPermissions"
        :show-modules-disabled-by-subscription="true"
        @update:item="localValue.item = $event"
      />
      <div class="headline6 mb-6">
        {{ $t("modals.addMspRole.childWorkspacePermissions") }}
      </div>
      <add-role-modal-section
        v-for="scope in workspaceRolesSectionScopes"
        class="mb-6"
        :item="localValue.item"
        :scopeName="scope"
        :key="scope"
        :show-details-tooltip="true"
        :empty-permissions-object="emptyMspPermissions"
        :show-modules-disabled-by-subscription="true"
        @update:item="localValue.item = $event"
      />
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { required } from "@/_helpers/validators";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import {
  MspRolePermissionScopes,
  type RoleDetails,
  RolePermissionsScope,
} from "@/_store/roles.module";
import AddRoleModalSection from "@/components/modals/add-role/AddRoleModalSection.vue";
import { emptyMspPermissions, MspRolesAction } from "@/constants/roles";
import { debounce } from "lodash";
import { useMspStore } from "@/_store/msp/msp.module.ts";

export default defineComponent({
  components: { AddRoleModalSection },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<RoleDetails>>,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const mspStore = useMspStore();
    const localValue = ref({
      item: cloneDeep(props.config.item),
      action: props.config.action,
    });
    const valid = ref(true);
    const form = ref();
    const workspaceCandidates = ref<{ workspaceName: string; workspaceId: string }[]>([]);
    const workspaceCandidatesLoading = ref<boolean>(false);
    const isEditMode: boolean = props.config.action === MspRolesAction.EDIT;

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );
    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const workspaceRolesSectionScopes = [
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      RolePermissionsScope.PROTECTION,
    ];

    const mspSectionScopes = [
      MspRolePermissionScopes.MSP_PORTAL_SCOPE,
      RolePermissionsScope.VIEWS,
      RolePermissionsScope.TICKETS,
    ];

    const onWorkspaceSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.workspaceId) {
        fetchWorkspaces();
      }
      if (searchString !== localValue.value.item.workspaceId) {
        fetchWorkspaces(searchString);
      }
    }, 500);

    const fetchWorkspaces = async (search: string = "") => {
      if (props.disable) return;
      workspaceCandidatesLoading.value = true;
      workspaceCandidates.value = await mspStore.getWorkspaceCandidates(
        search,
        "ADD_CHANNEL_ROLES"
      );
      workspaceCandidatesLoading.value = false;
    };

    onMounted(async () => {
      if (!isEditMode) {
        await fetchWorkspaces();
      }
    });

    return {
      localValue,
      valid,
      form,
      required,
      validate,
      workspaceRolesSectionScopes,
      mspSectionScopes,
      RolePermissionsScope,
      MspRolePermissionScopes,
      emptyMspPermissions,
      onWorkspaceSearchUpdate,
      workspaceCandidatesLoading,
      workspaceCandidates,
      isEditMode,
    };
  },
});
</script>

<style lang="scss" scoped></style>
