<template>
  <div class="content-md margin-auto mt-4">
    <div class="mb-3">
      <v-breadcrumbs :items="breadcrumbsItems" />
    </div>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="protected-users-page-title">
          {{ title }}
        </span>
      </div>
    </div>

    <div class="d-flex justify-end mb-8">
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(getItems)"
      >
        <v-text-field
          v-model="localFilters.search"
          density="compact"
          variant="outlined"
          background-color="white"
          :placeholder="$t('general.search')"
          :class="{ 'filter-active': localFilters.search?.length }"
          class="search-field"
          prepend-inner-icon="$search"
          hide-details
          clearable
          persistent-clear
          clear-icon="$x"
        />
      </filter-wrapper>
    </div>
    <list-with-preview :show-skeleton-loader="usersLoading">
      <template #list>
        <list-table
          :items="items"
          item-key="id"
          :pagination="pagination"
          :loading="loading"
          :selected-items="selectedItems"
          :is-indeterminate="isIndeterminate"
          :all-items-selected="allItemsSelected"
          :selected-items-quantity="selectedItemsQuantity"
          :total-items="total"
          :available-bulk-actions="availableBulkActions"
          :actions-loading="actionsLoading"
          @page-changed="onPageChange"
          @update-preview="onPreviewUpdate($event)"
          @bulk-menu-clicked="getBulkActions"
          @bulk-action-clicked="onBulkActionClick($event)"
          @handle-one-row-selection="handleOneRowSelection($event.selected, $event.item)"
        >
          <template #all-rows-selection>
            <bulk-selector-checkbox
              class="ml-2"
              :is-indeterminate="isIndeterminate"
              :items="items"
              :selection="selection"
              :all-items-selected="allItemsSelected"
              @selection-changed="onSelectionChanged"
            />
          </template>
          <template #list-item="{ item, index }">
            <div class="list-item-grid py-1">
              <div class="list-item-grid__icon-block">
                <v-icon icon="$user" size="40" />
              </div>

              <div class="list-item-grid__details-block d-flex flex-column justify-center pr-2">
                <div :data-testid="`users-table-name-row-${index}`" class="subtitle2">
                  {{ item?.name }}
                </div>
                <div :data-testid="`users-table-email-row-${index}`" class="body2">
                  {{ item?.email }}
                  <template v-if="item.aliases.length"> +{{ item.aliases.length }} </template>
                </div>
              </div>

              <div class="list-item-grid__status-block">
                <v-tooltip v-if="item.hasOpenTickets" open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      icon="$warning"
                      v-bind="props"
                    />
                  </template>
                  {{ $t("general.unresolvedTickets") }}
                </v-tooltip>
                <v-tooltip v-else open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      v-bind="props"
                      icon="$circleCheck"
                    />
                  </template>
                  {{ $t("general.noTickets") }}
                </v-tooltip>
              </div>
            </div>
          </template>
        </list-table>
      </template>
      <template #preview>
        <users-preview :user="preview"></users-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import FilterWrapper from "@/components/FilterWrapper.vue";
import ListWithPreview from "@/components/ListWithPreview.vue";
import UsersPreview from "@/components/UsersPreview.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { type UserListItem, useUsersStore } from "@/_store/users.module";
import { storeToRefs } from "pinia";
import { useSelectorStore } from "@/_store/selector.module";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";
import { computed, defineComponent, onUnmounted, onMounted, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters";
import ListTable from "@/components/tables/ListTable.vue";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { useSelectableTable } from "@/composables/useSelectableTable.ts";
import { TicketAction } from "@/constants/tickets.ts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import { ModalWidth } from "@/constants/modals.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import BulkSelectorCheckbox from "@/components/BulkSelectorCheckbox.vue";
const filterContext = FilterContext.USERS;

export default defineComponent({
  components: {
    BulkSelectorCheckbox,
    ListTable,
    FilterWrapper,
    ListWithPreview,
    UsersPreview,
  },
  props: {
    hasVulnerabilities: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const usersStore = useUsersStore();
    const selectorStore = useSelectorStore();
    const filtersStore = useFiltersStore();
    const dialogStore = useDialogsStore();
    const i18n = useI18n();
    const preview = ref(null as UserListItem | null);
    const { getItems, onPageChange } = usersStore;
    const availableBulkActions = ref<{ name: string; displayName: string }[]>([]);
    const actionsLoading = ref(false);

    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters, updateFilters } =
      useFilters(filterContext);

    const { deselectAllPages } = selectorStore;

    const { loading, items, total, pagination, usersLoading, userDetailsLoading } =
      storeToRefs(usersStore);

    const { selection } = storeToRefs(selectorStore);

    const {
      selectedItems,
      handleOneRowSelection,
      onSelectionChanged,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems: total,
      items,
    });

    const title = computed(() => {
      const protectedUsersFilters = filtersStore.filters.usersFilters;
      if (protectedUsersFilters.hasVulnerabilities === false) {
        return i18n.t("usersPage.dynamicTitle", {
          n: i18n.t("usersPage.noIssues"),
        });
      } else if (protectedUsersFilters.hasVulnerabilities === true) {
        return i18n.t("usersPage.dynamicTitle", {
          n: i18n.t("usersPage.criticalIssues"),
        });
      }
      return i18n.t("usersPage.title");
    });

    const onPreviewUpdate = (item: UserListItem | null) => {
      preview.value = item ? { ...item } : null;
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await getItems();
      },
      { deep: true, immediate: true }
    );

    onMounted(async () => {
      updateFilters({
        ...{ ...localFilters.value },
        hasVulnerabilities: props.hasVulnerabilities
          ? props.hasVulnerabilities === "true"
          : undefined,
      });
      await getItems({ showSkeletonLoader: true });
    });

    onUnmounted(() => {
      usersStore.$reset();
      clearFilters();
      deselectAllPages();
    });

    const onBulkActionClick = (action: string) => {
      switch (action) {
        case TicketAction.MARK_AS_PROCESSED:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                selection: {
                  ...selection.value,
                },
                callback: usersStore.onProtectedUsersAction,
                modules: [
                  SubscriptionModule.EMAIL_SECURITY,
                  SubscriptionModule.CLOUD_SECURITY,
                  SubscriptionModule.USER_DATA_GOVERNANCE,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable: isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
            })
          );
          break;
        default:
          dialogStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              disable: isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
              callback: () => {
                usersStore.onProtectedUsersAction({
                  selection: {
                    ...selection.value,
                  },
                  action,
                });
              },
            })
          );
          break;
      }
    };

    const getBulkActions = async () => {
      availableBulkActions.value = [];
      actionsLoading.value = true;
      try {
        const { data } = await useUsersStore().getActions(selection.value);
        availableBulkActions.value = data.items.map((name) => ({
          name,
          displayName: i18n.t(`usersPage.actions.${name}`),
        }));
        actionsLoading.value = false;
      } catch {
        actionsLoading.value = false;
      }
    };

    return {
      breadcrumbsItems: [
        {
          title: `‹ ${i18n.t("general.home")}`,
          disabled: false,
          to: { path: "/portal/dashboard" },
        },
      ],
      coronetSkeletonLoaderTypes,
      preview,
      loading,
      items,
      total,
      pagination,
      usersLoading,
      userDetailsLoading,
      selection,
      title,
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      onPageChange,
      onPreviewUpdate,
      getItems,
      selectedItems,
      handleOneRowSelection,
      onSelectionChanged,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      onBulkActionClick,
      getBulkActions,
      availableBulkActions,
      actionsLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 280px;
}
</style>
