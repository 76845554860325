<template>
  <v-card class="overview-widget" :class="classObject">
    <v-card-text class="h-100 d-flex justify-space-between" @click="widgetClicked()">
      <div class="d-flex align-center">
        <v-icon
          class="overview-widget__prepend-icon"
          :size="iconSize"
          :data-testid="`${kebabCase(item.type)}-overview-widget-type-icon`"
          :icon="`$${item.icon}`"
        />

        <div
          class="text-left"
          :class="{
            'ml-2': item.size === overviewWidgetSizes.LARGE,
            'ml-4': item.size === overviewWidgetSizes.SMALL,
          }"
        >
          <div class="subtitle1 overview-widget__title">
            <slot name="title">
              {{ $t(`dashboard.widgets.overviewWidget.types.${item.type}.title`) }}
            </slot>
            <beta-chip :addon-or-module="item.type"></beta-chip>
          </div>

          <div
            v-if="item.disabled"
            class="body2 overview-widget__subtitle"
            :class="{ 'text-red-dark full-opacity': item.disabled }"
          >
            {{ $t("general.disabled") }}
          </div>

          <template v-else-if="item.appearance === overviewWidgetAppearances.NUMBER">
            <div
              v-if="item.isProtectionEnabled && item.issuesCount"
              class="d-flex issues-count align-center text-red-base"
              :data-testid="`${kebabCase(item.type)}-overview-widget-issues-count`"
            >
              {{ item.issuesCount }}
            </div>
            <div
              v-if="item.isProtectionEnabled"
              class="body2 overview-widget__subtitle text-no-wrap"
              :data-testid="`${kebabCase(item.type)}-overview-widget-issues-resolved`"
            >
              <span class="mr-1"
                ><b>{{ item.resolvedIssuesCount }} </b></span
              >
              <span
                >{{ $t("dashboard.widgets.overviewWidget.resolved") }} /
                {{ $t("dashboard.widgets.overviewWidget.ninetyDays") }}</span
              >
            </div>
            <a
              v-else-if="!item.restricted"
              class="caption coro-link cursor-pointer"
              :data-testid="`${kebabCase(item.type)}-overview-widget-add-to-protection-link`"
              @click="navigateToProtection()"
              >{{ $t("dashboard.widgets.overviewWidget.addProtection") }}</a
            >
          </template>

          <div
            v-else
            class="body2 overview-widget__subtitle"
            :data-testid="`${kebabCase(item.type)}-overview-widget-issues-description`"
          >
            {{ item.description }}
          </div>
        </div>
      </div>

      <div
        v-if="item.appearance === overviewWidgetAppearances.SETTINGS"
        class="d-flex align-center"
      >
        <v-icon color="primary" icon="$settings" size="24" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {
  AddonOverviewWidgetType,
  OverviewWidgetAppearance,
  OverviewWidgetSize,
  OverviewWidgetType,
  WidgetType,
} from "@/constants/dashboard";
import { CoroIcons } from "@/constants/coro-icon";
import BetaChip from "@/components/BetaChip.vue";
import { useRouter } from "vue-router";
import { computed, defineComponent } from "vue";
import { kebabCase } from "lodash";
import type { OverviewWidgetConfiguration } from "@/_store/dashboard/adapters";
import { RouteName } from "@/constants/routes.ts";

export default defineComponent({
  name: "OverviewWidget",
  components: {
    BetaChip,
  },
  props: {
    item: {
      type: Object as () => OverviewWidgetConfiguration,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const coronetIconsData = CoroIcons;
    const overviewWidgetAppearancesData = OverviewWidgetAppearance;
    const overviewWidgetSizesData = OverviewWidgetSize;

    const iconSize = computed(() => {
      switch (props.item.size) {
        case overviewWidgetSizesData.LARGE:
          return 72;
        case overviewWidgetSizesData.SMALL:
          return 24;
        default:
          return 72;
      }
    });

    const classObject = computed(() => ({
      [`overview-widget--${props.item.size}`]: true,
      "overview-widget--disabled": props.item.disabled,
    }));

    const navigateToProtection = () => {
      if (props.item.disabled) return;

      switch (props.item.type) {
        case OverviewWidgetType.ENDPOINT_SECURITY:
          router.push({ name: "devices-settings-agent-deployment-tab" });
          break;
        case OverviewWidgetType.USER_DATA_GOVERNANCE:
          router.push({ name: "cloud-apps-page" });
          break;
        case OverviewWidgetType.EMAIL_SECURITY:
          router.push({ name: "email_settings_tab" });
          break;
        case OverviewWidgetType.CLOUD_SECURITY:
          router.push({ name: "cloud-apps-page" });
          break;
        case OverviewWidgetType.ENDPOINT_DATA_GOVERNANCE:
          router.push({ name: "devices-settings-agent-deployment-tab" });
          break;
      }
    };

    const widgetClicked = () => {
      if (props.item.disabled) return;

      switch (props.item.type) {
        case OverviewWidgetType.CLOUD_SECURITY:
          emitWidgetClick(WidgetType.CLOUD_SECURITY);
          break;
        case OverviewWidgetType.ENDPOINT_SECURITY:
          emitWidgetClick(WidgetType.ENDPOINT_SECURITY);
          break;
        case OverviewWidgetType.EMAIL_SECURITY:
          emitWidgetClick(WidgetType.EMAIL_SECURITY);
          break;
        case OverviewWidgetType.USER_DATA_GOVERNANCE:
          emitWidgetClick(WidgetType.USER_DATA_GOVERNANCE);
          break;
        case OverviewWidgetType.ENDPOINT_DATA_GOVERNANCE:
          emitWidgetClick(WidgetType.ENDPOINT_DATA_GOVERNANCE);
          break;
        case OverviewWidgetType.MOBILE_DEVICE_MANAGEMENT:
          emitWidgetClick(OverviewWidgetType.MOBILE_DEVICE_MANAGEMENT);
          break;
        case OverviewWidgetType.EDR:
          emitWidgetClick(OverviewWidgetType.EDR);
          break;
        case OverviewWidgetType.NETWORK:
          router.push("/portal/settings/addons/network");
          break;
        case OverviewWidgetType.SECURITY_AWARENESS:
          router.push({ name: RouteName.SECURITY_AWARENESS });
          break;
        case AddonOverviewWidgetType.WIFI_PHISHING:
          router.push("/portal/settings/endpoint-security/add-ons");
          break;
        case AddonOverviewWidgetType.INBOUND_GATEWAY:
          router.push("/portal/settings/email/inbound-gateway");
          break;
        case AddonOverviewWidgetType.SECURE_WEB_GATEWAY:
          router.push("/portal/settings/addons/network/secure-web-gateway");
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          break;
        case AddonOverviewWidgetType.SECURED_MESSAGES:
          router.push("/portal/settings/email/coro-mail");
          break;
      }
    };

    const emitWidgetClick = (type: string) => {
      emit("widget-clicked", type);
    };

    return {
      coronetIconsData,
      overviewWidgetAppearancesData,
      overviewWidgetSizesData,
      iconSize,
      classObject,
      navigateToProtection,
      widgetClicked,
      kebabCase,
      overviewWidgetAppearances: OverviewWidgetAppearance,
      overviewWidgetSizes: OverviewWidgetSize,
    };
  },
});
</script>

<style scoped lang="scss">
.overview-widget {
  text-align: center;
  transition: all 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12) !important;
  }

  &__subtitle {
    color: var(--v-indigo-medium-base);
  }

  .v-card__text {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &--small {
    height: 65px;
  }

  &--disabled {
    cursor: default !important;
    .overview-widget__title {
      opacity: 0.65;
    }

    i.v-icon:not(.icon-settings):before {
      opacity: 0.3;
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}

.full-opacity {
  opacity: 1 !important;
}

.issues-count {
  font-family: Quicksand, sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: -0.6px;
}

:deep(*) {
  .mdi-check {
    font-size: 30px !important;
  }
  .mdi-check:before {
    color: var(--v-success-lighten1) !important;
  }
  .v-card__text {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
