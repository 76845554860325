import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { ModulesSettings } from "@/_store/subscription.module";
import { useSubscriptionStore } from "@/_store/subscription.module";
import {
  createAbortController,
  handleVirtualScrollData,
  setDefaultModules,
} from "@/_helpers/utils";
import type { Pagination } from "@/types";
import { useAccountStore } from "@/_store/account.module";
import { useBackOfficeStore } from "@/_store/back-office/back-office.module";
import { AxiosError } from "axios";
import type { SubscriptionBundle, WorkspaceType } from "@/constants/workplaces";
import { WorkspaceLocale } from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";
import { useTutorialStore } from "@/_store/tutorial.module.ts";

let abortController: AbortController | undefined;

interface BetaComponent {
  type: string;
  name: string;
  endDate: number;
}

export interface WorkspaceListItem {
  workspaceId: string;
  name: string;
  type: WorkspaceType;
  mfaRequired: boolean;
}

export interface WorkspaceDetails {
  workspaceId: string;
  name: string;
  domain: string;
  type: WorkspaceType;
  mfaRequired: boolean;
  psaEnabled: boolean;
  scanUnprotectedUsers: boolean;
  supportEnabled: boolean;
  branding: {
    headerIconUrl?: string;
    supportEmail?: string;
    displayName?: string;
    brandColor?: string;
  };
  isCoronetWorkspace: boolean;
  subscription: {
    modules: ModulesSettings;
    bundles?: Array<SubscriptionBundle>;
  };
  subscriptionType: string;
  trialEndDate: number;
  headerIconUrl?: string;
  showDisabledModules: boolean;
  betaComponents: Array<BetaComponent>;
  languageCode: WorkspaceLocale;
  parentWorkspaceName?: string;
  demoModeEnabled?: boolean;
  demoModeEndDate?: number | null;
  trialModeStartedNotice?: boolean;
}

interface WorkspacesState {
  workspaces: Array<WorkspaceListItem>;
  total: number;
  pagination: Pagination;
  pendingInvites: Array<WorkspaceListItem>;
  pendingInvitesTotal: number;
  pendingInvitesPagination: Pagination;
  loading: boolean;
  betaComponents: Array<BetaComponent>;
  workspaceAndPermissionsUpdatedTimestamp: number;
}

export type SetWorkspaceActionPayload = Pick<
  WorkspaceDetails,
  | "workspaceId"
  | "name"
  | "type"
  | "headerIconUrl"
  | "branding"
  | "domain"
  | "supportEnabled"
  | "isCoronetWorkspace"
  | "psaEnabled"
  | "showDisabledModules"
  | "subscription"
  | "betaComponents"
  | "subscriptionType"
  | "trialEndDate"
  | "languageCode"
  | "parentWorkspaceName"
  | "demoModeEnabled"
  | "demoModeEndDate"
  | "trialModeStartedNotice"
>;

const defaultWorkspaceState = {
  workspaces: [],
  total: 1,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  pendingInvites: [],
  pendingInvitesTotal: 0,
  pendingInvitesPagination: {
    page: 0,
    pageSize: 25,
  },
  loading: true,
  betaComponents: [],
  workspaceAndPermissionsUpdatedTimestamp: 0,
};

export const useWorkspacesStore = defineStore("workspaces", {
  state: (): WorkspacesState => cloneDeep(defaultWorkspaceState),
  persist: false,
  actions: {
    async getWorkspaces(name: string = "", virtuallyScrollable = false) {
      abortController = createAbortController(abortController);
      this.loading = true;
      const filters = {
        name: name,
        ...this.pagination,
      };

      const request = {
        ...api.workplaces(filters),
      };

      try {
        const { data } = await axiosInstance.request({
          ...request,
          signal: abortController.signal,
        });
        this.workspaces = virtuallyScrollable
          ? handleVirtualScrollData(this.workspaces, data.items, "workspaceId")
          : data.items;
        this.total = data.total;
        this.loading = false;
      } catch (err) {
        if (err && (err as AxiosError).code === AxiosError.ERR_CANCELED) {
          return;
        }
        console.error(err);
        this.loading = false;
      }
      abortController = undefined;
    },
    async getPendingInvites(name: string = "", virtuallyScrollable = false) {
      this.loading = true;
      const request = {
        ...api.pendingInvitesToWorkplace(name, this.pendingInvitesPagination),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.pendingInvites = virtuallyScrollable
          ? handleVirtualScrollData(this.pendingInvites, data.items, "workspaceId")
          : data.items;
        this.pendingInvitesTotal = data.total;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
    joinWorkplace(id: string) {
      const request = {
        ...api.joinWorkplace(id),
      };
      return axiosInstance.request(request);
    },
    async updateWorkspaceAndPermissions(workspaceId: string) {
      const accountStore = useAccountStore();
      accountStore.setWorkspace(workspaceId);
      await this.getCurrentWorkspace();
      this.workspaceAndPermissionsUpdatedTimestamp = new Date().getTime();
    },
    async getCurrentWorkspace() {
      const request = {
        ...api.getCurrentWorkspace,
      };

      try {
        // Create an array of promises because we need to execute this tho together
        const promises: Promise<any>[] = [
          axiosInstance.request(request), // Fetch workspace data
          useBackOfficeStore().getWorkspacePermissions(), // Fetch workspace permissions
        ];
        const [workspaceResponse] = await Promise.all(promises);

        const { data } = workspaceResponse;
        this.setWorkspace(data);
      } catch (e) {
        console.error(e);
      }
    },
    setWorkspace(workplace: SetWorkspaceActionPayload) {
      const accountStore = useAccountStore();
      const subscriptionStore = useSubscriptionStore();
      accountStore.setWorkspace(workplace.workspaceId);
      accountStore.setCustomerName(workplace.name);
      accountStore.setWorkspaceType(workplace.type);
      accountStore.setAppLogo(
        workplace.headerIconUrl || (workplace.branding && workplace.branding.headerIconUrl)
      );
      accountStore.setDomain(workplace.domain);
      accountStore.setBrandColor(workplace.branding.brandColor);
      accountStore.setSupportEnabled(workplace.supportEnabled);
      accountStore.setIsCoronetWorkspace(workplace.isCoronetWorkspace);
      accountStore.setPsaEnabled(workplace.psaEnabled);
      accountStore.setShowDisabledModules(workplace.showDisabledModules);
      accountStore.$patch((state) => {
        state.account.languageCode = workplace.languageCode ?? WorkspaceLocale.EN_US;
        state.account.parentWorkspaceName = workplace.parentWorkspaceName;
      });
      subscriptionStore.$patch((state) => {
        state.subscription = {
          currentPlan: {
            subscriptionType: workplace.subscriptionType,
            planEndDate: workplace.trialEndDate,
          },
          modules: setDefaultModules(cloneDeep(workplace.subscription.modules)),
          bundles: cloneDeep(workplace.subscription.bundles ?? []),
        };
        state.demoMode = {
          enabled: workplace.demoModeEnabled!,
          endDate: workplace.demoModeEndDate!,
        };
      });
      useTutorialStore().$patch({ showTrialModeStartedNotice: workplace.trialModeStartedNotice });
      this.betaComponents = workplace.betaComponents;
    },
    setWorkspaces(workspaces: WorkspaceListItem[]) {
      this.workspaces = workspaces;
    },
    setPendingInvites(workspaces: WorkspaceListItem[]) {
      this.pendingInvites = workspaces;
    },
    setPagination(paging: Pagination) {
      this.pagination = paging;
    },
    setPendingInvitesPagination(paging: Pagination) {
      this.pendingInvitesPagination = paging;
    },
    resetState() {
      this.$reset();
    },
  },
});
