<template>
  <v-list-item v-if="scope" class="pa-0">
    <template #default>
      <div class="d-flex mt-0">
        <v-col class="py-1" cols="6">
          <span class="permission-section__title subtitle2">
            {{ title }}
          </span>
        </v-col>
        <v-col class="py-1" cols="6">
          <span class="permission-section__title subtitle2">
            {{ $t(`accessControlPage.roles.details.permissions`) }}
          </span>
        </v-col>
      </div>
      <div
        v-for="(permissionValue, permissionName) in scope"
        :key="permissionName"
        class="d-flex"
        :class="{ 'mb-1': showPermission(permissionName) }"
      >
        <template v-if="showModulesDisabledBySubscription || showPermission(permissionName)">
          <v-col class="py-0" cols="6">
            <span class="body2 mb-1">
              {{
                isNestedPermission(permissionValue)
                  ? $t(`accessControlPage.roles.scopes.${scopeName}.${permissionName}.title`)
                  : $t(`accessControlPage.roles.scopes.${scopeName}.${permissionName}`)
              }}
            </span>
          </v-col>
          <v-col class="py-0" cols="6">
            <template v-if="isNestedPermission(permissionValue)">
              <span class="body2 mb-1">
                {{
                  $t(
                    `accessControlPage.roles.details.permissionModes.${
                      (permissionValue as NestedPermissions).accessMode
                    }`
                  )
                }}
              </span>
              <ul
                class="d-flex flex-column ml-4"
                v-if="
                  (permissionValue as NestedPermissions).accessMode ===
                    RolePermissionAccessMode.EDIT &&
                  (permissionValue as NestedPermissions).editAccessModePermission
                "
              >
                <template
                  v-for="(nestedPermissionValue, nestedPermissionName) in (
                    permissionValue as NestedPermissions
                  ).editAccessModePermission"
                >
                  <li
                    v-if="nestedPermissionValue"
                    :key="nestedPermissionName"
                    class="body2 mb-1 text-wrap"
                  >
                    {{
                      $t(
                        `accessControlPage.roles.scopes.${scopeName}.${permissionName}.editAccessModePermission.${nestedPermissionName}`
                      )
                    }}
                  </li>
                </template>
              </ul>
            </template>
            <span v-else class="body2 mb-1">
              {{ $t(`accessControlPage.roles.details.permissionModes.${permissionValue}`) }}
            </span>
          </v-col>
        </template>
      </div>
    </template>
  </v-list-item>
</template>

<script setup lang="ts" generic="T">
import type {
  MspRolePermissionScopes,
  NestedPermissions,
  RolePermissions,
  RolePermissionsScope,
} from "@/_store/roles.module";
import { useRolesPermissions } from "@/composables/useRolesPermissions";
import { RolePermissionAccessMode } from "@/constants/roles";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const i18n = useI18n();

const props = defineProps<{
  scope: RolePermissions[RolePermissionsScope | MspRolePermissionScopes];
  scopeName: RolePermissionsScope | MspRolePermissionScopes;
  showModulesDisabledBySubscription?: boolean;
  scopeTitle?: string;
}>();

const title = computed(() => {
  return props.scopeTitle || i18n.t(`accessControlPage.roles.scopes.${props.scopeName}.title`);
});

const { isNestedPermission, showPermission } = useRolesPermissions(props.scopeName);
</script>

<style scoped></style>
