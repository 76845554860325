<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <v-autocomplete
      v-model="localValue.item.workspaceId"
      :disabled="isEditMode"
      class="mt-6"
      :placeholder="$t('modals.addMspAdminUser.workspace.placeholder')"
      :label="$t('modals.addMspAdminUser.workspace.label')"
      persistent-hint
      :hint="$t('modals.addMspAdminUser.workspace.hint')"
      item-title="workspaceName"
      item-value="workspaceId"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="[required()]"
      :loading="workspaceCandidatesLoading"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="workspaceCandidates"
      variant="outlined"
      @update:search="onWorkspaceSearchUpdate($event)"
    ></v-autocomplete>
    <v-autocomplete
      v-model="localValue.item.email"
      :disabled="isEditMode || !localValue.item.workspaceId"
      class="mt-6"
      :placeholder="$t('modals.addMspAdminUser.email.placeholder')"
      :label="$t('modals.addMspAdminUser.email.label')"
      item-title="email"
      item-value="email"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="[required()]"
      :loading="adminUsersSearchLoading"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="mspAdminUserCandidates"
      variant="outlined"
      @update:search="onAdminUsersSearchUpdate($event)"
    ></v-autocomplete>
    <v-autocomplete
      v-model="localValue.item.role"
      class="mt-6"
      :disabled="!localValue.item.workspaceId"
      :placeholder="$t('modals.addMspAdminUser.role.placeholder')"
      :label="$t('modals.addMspAdminUser.role.label')"
      item-title="roleName"
      item-value="roleId"
      :return-object="true"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="[roleRequiredRule]"
      :loading="rolesSearchLoading"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="roles"
      variant="outlined"
      @update:search="onRoleSearchUpdate($event)"
    ></v-autocomplete>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, type Ref, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { emailRule, required } from "@/_helpers/validators";
import { useRolesStore } from "@/_store/roles.module";
import { debounce } from "lodash";
import isEmpty from "lodash/isEmpty";
import { MspAdminUsersAction } from "@/constants/admin-accounts";
import { RoleType } from "@/constants/roles";
import { type MspAdminUser, useMspAdminUsersStore } from "@/_store/msp-admin-users.module";
import { useMspStore } from "@/_store/msp/msp.module.ts";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<MspAdminUser>>,
      required: true,
    },
    disable: {
      type: Boolean,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const mspStore = useMspStore();
    const rolesStore = useRolesStore(RoleType.MSP);
    const { searchRoles } = rolesStore;
    const { searchMspAdminUserCandidates } = useMspAdminUsersStore();
    const localValue: Ref<DialogDataConfig<Partial<MspAdminUser>>> = ref({
      item: cloneDeep(props.config.item ?? { email: null }),
      action: props.config.action,
    });
    const roles = ref<{ roleName: string; roleId: string }[]>([]);
    const mspAdminUserCandidates = ref<{ email: string }[]>([]);
    const workspaceCandidates = ref<{ workspaceName: string; workspaceId: string }[]>([]);
    const rolesSearchLoading = ref<boolean>(false);
    const adminUsersSearchLoading = ref<boolean>(false);
    const workspaceCandidatesLoading = ref<boolean>(false);
    const valid = ref(true);
    const form = ref();
    const isEditMode = computed(() => props.config.action === MspAdminUsersAction.EDIT);
    const roleRequiredRule = (value?: { roleName: string; roleId: string }) => {
      if (isEmpty(value)) {
        return i18n.t("validations.required");
      }
      return true;
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    const onRoleSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.role?.roleName) {
        fetchRoles();
      }
      if (searchString !== localValue.value.item.role?.roleName) {
        fetchRoles(searchString);
      }
    }, 500);

    const onAdminUsersSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.email) {
        fetchAdminUsers();
      }
      if (searchString !== localValue.value.item.email) {
        fetchAdminUsers(searchString);
      }
    }, 500);

    const onWorkspaceSearchUpdate = debounce(function (searchString) {
      const foundWorkspace = workspaceCandidates.value.find(
        (v) => v.workspaceId === localValue.value.item.workspaceId
      );
      if (!searchString && !foundWorkspace?.workspaceName) {
        fetchWorkspaces();
      }
      if (searchString !== foundWorkspace?.workspaceName) {
        fetchWorkspaces(searchString);
      }
    }, 500);

    watch(
      valid,
      (newVal: boolean | null) => {
        emit("update:valid", newVal ?? true);
      },
      { immediate: false }
    );

    watch(
      () => localValue.value.item.workspaceId,
      async () => {
        // once workspaceId changes, reset role and email
        localValue.value.item.role = undefined;
        localValue.value.item.email = undefined;
        await fetchAdminUsers();
        await fetchRoles();
      }
    );

    onMounted(async () => {
      await fetchWorkspaces();
      if (props.config.item?.workspaceId) {
        await fetchRoles();
      }
    });

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const fetchRoles = async (search: string = "") => {
      if (!localValue.value.item.workspaceId || props.disable) return;
      rolesSearchLoading.value = true;
      const data = await searchRoles(
        { page: 0, pageSize: 50, search },
        localValue.value.item.workspaceId
      );
      roles.value = data.items;
      rolesSearchLoading.value = false;
    };

    const fetchAdminUsers = async (search: string = "") => {
      if (!localValue.value.item.workspaceId || props.disable) return;
      adminUsersSearchLoading.value = true;
      mspAdminUserCandidates.value = await searchMspAdminUserCandidates(
        localValue.value.item.workspaceId!,
        search
      );
      adminUsersSearchLoading.value = false;
    };

    const fetchWorkspaces = async (search: string = "") => {
      if (props.disable) return;
      workspaceCandidatesLoading.value = true;
      workspaceCandidates.value = await mspStore.getWorkspaceCandidates(
        search,
        "ADD_CHANNEL_ADMINS"
      );
      workspaceCandidatesLoading.value = false;
    };

    return {
      localValue,
      valid,
      form,
      roles,
      rolesSearchLoading,
      mspAdminUserCandidates,
      workspaceCandidates,
      adminUsersSearchLoading,
      workspaceCandidatesLoading,
      isEditMode,
      roleRequiredRule,
      required,
      emailRule,
      validate,
      onRoleSearchUpdate,
      onAdminUsersSearchUpdate,
      onWorkspaceSearchUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-field--error:not(.v-field--disabled) .v-field__outline {
    --v-field-border-width: 2px !important;
  }
}
</style>
