<template>
  <div class="pb-6 px-12">
    <v-skeleton-loader
      v-if="user"
      :loading="userDetailsLoading"
      type="list-item, divider, list-item-avatar, list-item-three-line@4, list-item-avatar-two-line@4"
      class="skeleton-loader--table"
    >
      <div
        class="d-flex align-center justify-space-between pb-4 pt-4 details-preview-header coronet-white-base"
      >
        <div class="d-flex flex-column">
          <div>
            <span class="body2 text-indigo-medium">
              {{ $t("usersPage.protectedSince") }}
              {{ getFormattedDateTime(userDetails.protectionGrantedTime, "ll") }}
            </span>
            <span v-if="userDetails.suspended" class="text-red-dark body2">
              <v-icon size="24" icon="$warning" />
              {{ $t("mspUsersPage.suspended") }}
            </span>
          </div>
          <div v-if="errorsList.length > 0" class="body2 d-flex align-center text-red-dark mt-1">
            <v-icon icon="$warning" class="mr-1" />
            <div v-for="(error, index) in errorsList" :key="error">
              <span>{{ $t(`mspUsersPage.statuses.${error}`) }}</span>
              <span v-if="index < errorsList.length - 1">,&nbsp;</span>
            </div>
          </div>
        </div>
        <v-menu v-if="actionsList.length" offset-y location="bottom right">
          <template #activator="{ props }">
            <v-btn
              class="ml-8"
              rounded
              color="primary"
              data-testid="user-preview-action-button"
              v-bind="props"
            >
              {{ $t("general.actions") }}
              <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="action in actionsList"
              :key="action"
              :data-testid="`users-preview-${kebabCase(action)}-action`"
              @click="onActionClick(action)"
            >
              <v-list-item-title>
                {{ $t(`usersPage.actions.${action}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center mt-5 mb-7">
        <v-icon class="user-icon mr-3" icon="$user" />
        <span data-testid="users-preview-name" class="headline5">
          {{ userDetails.name || user.email }}
        </span>
      </div>
      <v-row data-testid="user-preview-protected-cloud-apps">
        <v-col cols="6">
          <div class="mb-4">
            <div class="subtitle1">{{ $t("general.workspace") }}</div>
            <div class="body1">
              {{ user.workspaceId }}
            </div>
            <div class="body1 coro-link mb-4" @click="onWorkspaceNameClick()">
              {{ user.workspaceName }}
            </div>
          </div>
          <div class="subtitle1">{{ $t("general.emails") }}</div>
          <div class="body2">{{ user.email }}</div>
          <template v-if="displayShowMoreButton">
            <a v-if="!showAliases" class="coro-link body2" @click="showAliases = true">
              {{
                `${$t("general.seeNMore", {
                  n: userDetails.aliases.length,
                })} ${$t("general.aliases").toLowerCase()}`
              }}
            </a>
            <template v-if="showAliases">
              <span
                v-for="(alias, index) in userDetails.aliases"
                :key="alias"
                class="body2 text-indigo-medium"
              >
                <span v-if="index > 0">; </span>
                <span>{{ alias }}</span>
              </span>
              <br />
              <a class="coro-link body2 mt-1" @click="showAliases = false">
                {{ $t("general.seeLess") }}
              </a>
            </template>
          </template>
        </v-col>
        <v-col cols="6" data-testid="user-preview-protected-cloud-apps">
          <div class="subtitle1">
            {{ $t("usersPage.protectedCloudApps") }}
          </div>
          <template v-if="userDetails.cloudServices.length">
            <template v-for="service in userDetails.cloudServices" :key="service">
              <div class="d-flex align-center mb-4">
                <coro-icon class="service-icon mr-2" :icon-name="service"></coro-icon>
                <div class="subtitle1">
                  {{ $t(`services.${service}`) }}
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <span class="subtitle1 text-indigo-medium">
              {{ $t("usersPage.noProtectedCloudApps") }}
            </span>
          </template>
        </v-col>
      </v-row>
      <entity-tickets-list
        class="mb-5 mt-3"
        :show-info-tooltip="restrictedTicketModules.length > 0"
        :access-restricted="ticketsAccessRestricted"
        :info-tooltip-text="modulesTooltipText"
        :tickets="userDetails.tickets"
        :all-open-tickets-link="allOpenTicketsLink"
        :show-resolve="false"
        @view-vulnerability="viewVulnerability($event)"
      ></entity-tickets-list>
      <entity-activity-logs
        :hide-all-activity-link="true"
        :access-restricted="false"
        :activity-logs="userDetails.activityLogs"
        :entity-id="user.email"
        search-prefix="user"
        :loading="false"
        :show-load-more="true"
        @undo-clicked="undoActivityLog($event)"
        @load-more-items-clicked="loadMoreActivityLogs()"
      ></entity-activity-logs>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { storeToRefs } from "pinia";
import { type MspUserListItem } from "@/_store/msp/msp-users.module.ts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
} from "@/_helpers/utils";
import { TicketAction, TicketStatus } from "@/constants/tickets";
import { SubscriptionModule } from "@/constants/workplaces";
import { useRouter } from "vue-router";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";

import kebabCase from "lodash/kebabCase";
import EntityTicketsList from "@/components/EntityTicketsList.vue";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import EntityActivityLogs from "@/components/EntityActivityLogs.vue";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes.ts";
import { useMspUsersStore } from "@/_store/msp/msp-users.module.ts";
import { isMspAccessRestricted, isMspActionRestricted } from "@/_helpers/msp-permissions.ts";

export default defineComponent({
  components: {
    EntityTicketsList,
    CoroIcon,
    EntityActivityLogs,
  },
  props: {
    user: {
      type: Object as PropType<MspUserListItem | null>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const mspUsersStore = useMspUsersStore();
    const dialogStore = useDialogsStore();
    const router = useRouter();
    const { userDetails, userDetailsLoading } = storeToRefs(mspUsersStore);
    const { getUserDetails, undoActivityLog, getActions } = mspUsersStore;
    const showAliases = ref(false);

    const actionsList = ref<string[]>([]);

    const errorsList = computed(() => {
      const suspended = userDetails.value.suspended;
      const unresolvedTickets = userDetails.value.tickets.length > 0;
      const errors: { [key: string]: boolean } = {
        suspended,
        unresolvedTickets,
      };
      return Object.keys(errors).filter((key: string) => errors[key]);
    });

    const allOpenTicketsLink = computed(() => {
      return {
        name: RouteName.MSP_TICKETS_PAGE,
        query: {
          search: `violator:${props.user?.email}`,
          eventTriggers: userDetails.value.tickets.map((v) => v.name).join(","),
          status: TicketStatus.OPEN,
          protectedUsersOnly: "true",
        },
      };
    });

    const displayShowMoreButton = computed(() => {
      return userDetails.value.aliases.length;
    });

    const activityLogsAccessRestricted = isMspAccessRestricted(
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      WorkspaceManagementScopeSections.ACTIVITY_LOGS
    );

    const ticketsAccessRestricted = computed(() => {
      return (
        [
          SubscriptionModule.CLOUD_SECURITY,
          SubscriptionModule.USER_DATA_GOVERNANCE,
          SubscriptionModule.EMAIL_SECURITY,
        ] as const
      ).every((module) => isMspAccessRestricted(RolePermissionsScope.TICKETS, module));
    });

    const restrictedTicketModules = computed(() => {
      return (
        [
          SubscriptionModule.CLOUD_SECURITY,
          SubscriptionModule.USER_DATA_GOVERNANCE,
          SubscriptionModule.EMAIL_SECURITY,
        ] as const
      ).filter((module) => isMspAccessRestricted(RolePermissionsScope.TICKETS, module));
    });

    const modulesTooltipText = computed(() => {
      return i18n.t(
        "entityTicketsList.modulesTooltip",
        {
          modules: restrictedTicketModules.value
            .map((module) => i18n.t(`subscriptions.modules.${module}`))
            .join(","),
        },
        restrictedTicketModules.value.length
      );
    });

    const viewVulnerability = (vulnerability: string) => {
      router.push({
        name: "tickets",
        query: {
          eventTriggers: vulnerability,
          search: `violator:${props.user?.email}`,
          protectedUsersOnly: "true",
        },
      });
    };

    const onActionClick = (action: string) => {
      switch (action) {
        case TicketAction.MARK_AS_PROCESSED:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                selection: [props.user],
                callback: mspUsersStore.onProtectedUsersAction,
                modules: [
                  SubscriptionModule.EMAIL_SECURITY,
                  SubscriptionModule.CLOUD_SECURITY,
                  SubscriptionModule.USER_DATA_GOVERNANCE,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable: isMspActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
            })
          );
          break;
        default:
          dialogStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              disable: isMspActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
              callback: () => {
                mspUsersStore.onProtectedUsersAction({
                  selection: [props.user!],
                  action,
                });
              },
            })
          );
          break;
      }
    };

    const getUserActions = async (user: MspUserListItem) => {
      try {
        const { data } = await getActions([user.id]);
        actionsList.value = data.items;
      } catch {
        actionsList.value = [];
      }
    };

    watch(
      () => props.user,
      async (user: MspUserListItem | null) => {
        if (user) {
          await getUserDetails(user);
          await getUserActions(user);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const loadMoreActivityLogs = () => {
      mspUsersStore.loadMoreMspUserActivityLogs();
    };

    const onWorkspaceNameClick = async () => {
      await router.push({
        name: RouteName.MSP,
        query: { workspaceId: props.user?.workspaceId },
      });
    };

    return {
      showAliases,
      RolePermissionsScope,
      userDetails,
      userDetailsLoading,
      actionsList,
      allOpenTicketsLink,
      displayShowMoreButton,
      onActionClick,
      viewVulnerability,
      undoActivityLog,
      kebabCase,
      getFormattedDateTime,
      ticketsAccessRestricted,
      restrictedTicketModules,
      modulesTooltipText,
      loadMoreActivityLogs,
      activityLogsAccessRestricted,
      onWorkspaceNameClick,
      errorsList,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .service-icon {
    width: 40px;
    height: 40px;
  }

  .user-icon {
    font-size: 40px;
  }

  .table-container {
    border: 1px solid rgb(var(--v-theme-indigo-faint));
  }
}
</style>
