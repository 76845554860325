import { defineStore } from "pinia";
import { useAccountStore } from "@/_store/account.module";
import type { App } from "vue";
import { i18n } from "@/plugins/i18n";
import { WebsocketMessageCode, WebsocketRawMessage } from "@/constants/websocket";
import { useTerminalStore } from "@/_store/terminal.module";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { isAccessRestricted, isJson } from "@/_helpers/utils";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";
import { useUsersSettingsStore } from "@/_store/users-settings/users-settings.module";
import { useGroupsSettingsTabStore } from "@/_store/users-settings/user-groups-tab.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import router from "@/_helpers/router";
import { useExportsStore } from "@/_store/exports.module";
import { RouteName } from "@/constants/routes";
import { useTicketsStore } from "@/_store/tickets/tickets.module.ts";
import { useMspTerminalStore } from "@/_store/msp/msp-terminal.module.ts";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";

export type SocketStore = {
  isConnected: boolean;
  message: string;
  reconnectError: boolean;
  heartBeatInterval: number;
  heartBeatTimer: number;
  reconnecting: boolean;
};

export interface WebsocketMessageWithPayload {
  code: WebsocketMessageCode;
  status: string;
  payload: any;
}

export const useSocketStore = (app: App<Element>) => {
  return defineStore("socket", {
    state: (): SocketStore => ({
      isConnected: false,
      message: "",
      reconnectError: false,
      heartBeatInterval: 50000,
      heartBeatTimer: 0,
      reconnecting: false,
    }),
    actions: {
      SOCKET_ONOPEN(event: Event) {
        app.config.globalProperties.$socket = event.currentTarget;
        this.isConnected = true;
        useTerminalStore().setWebsocketConnected(true);
        this.reconnecting = false;
        console.log("WebSocket connected");
        const { account } = useAccountStore();
        registerSession(app, account.token, account.workplace);
      },
      SOCKET_ONCLOSE() {
        setTimeout(() => {
          if (app.config.globalProperties.$socket.readyState !== 1) {
            this.isConnected = false;
            useTerminalStore().setWebsocketConnected(false);
            window.clearInterval(this.heartBeatTimer);
            this.heartBeatTimer = 0;
          }
        }, 3000);
      },
      SOCKET_ONERROR(event: Event) {
        console.error("WebSocket error", event);
      },
      SOCKET_ONMESSAGE(msg: MessageEvent) {
        this.message = msg.data;
        handleWebsocketMessage(msg);
      },
      SOCKET_RECONNECT(count: number) {
        this.reconnecting = true;
        const { account } = useAccountStore();
        registerSession(app, account.token, account.workplace);
        console.info("Reconnecting WebSocket...", count);
      },
      SOCKET_RECONNECT_ERROR() {
        this.reconnectError = true;
      },
    },
  })();
};

function registerSession(app: App<Element>, token?: string, workplaceId?: string) {
  // if there is no token or workplace, not registering session
  if (!token || !workplaceId) {
    return;
  }
  const message = {
    event: "sessionRegistration",
    data: {
      token,
      workplaceId,
    },
  };
  if (app.config.globalProperties.$socket && app.config.globalProperties.$socket.readyState === 1) {
    app.config.globalProperties.$socket.send(JSON.stringify(message));
    console.log("session registered");
  }
}

function handleWebsocketMessage(msg: MessageEvent) {
  if (isJson(msg.data)) {
    return handleMessageWithPayload(JSON.parse(msg.data));
  }
  return handleStringMessage(msg.data);
}

function handleStringMessage(message: string) {
  switch (message as WebsocketRawMessage) {
    case WebsocketRawMessage.USERS_UPDATED:
      if (router.currentRoute.value.name === RouteName.USERS_SETTINGS_USERS_TAB) {
        useUsersSettingsTabStore().getUsers();
        return;
      }
      if (router.currentRoute.value.name === RouteName.USERS_SETTINGS_GROUPS_TAB) {
        useGroupsSettingsTabStore().init();
        return;
      }
      if (
        !isAccessRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.USERS
        )
      ) {
        useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.user.usersUpdated"));
        useUsersSettingsTabStore().getUsers();
      }
      break;
    case WebsocketRawMessage.TICKETS_UPDATED:
      // TODO: uncomment after tickets migration
      // if (router.currentRoute.value.name === "tickets") {
      //   useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.event.eventsUpdated"))
      //   useTicketsStore().init();
      // }
      break;
    case WebsocketRawMessage.SUBSCRIPTION_UPDATED:
      useWorkspacesStore().getCurrentWorkspace();
      break;
  }
}

function handleMessageWithPayload(msg: WebsocketMessageWithPayload) {
  const isMspDevicesPage = router.currentRoute.value.name === RouteName.MSP_DEVICES_TAB;
  const terminalStore = isMspDevicesPage ? useMspTerminalStore() : useTerminalStore();
  const sessionId = terminalStore.sessionId;
  const snackbarMessage = i18n.global.t(`snackbar.messages.socket.${msg.code}.${msg.status}`, {
    ...msg.payload,
    service: i18n.global.t(`services.${msg.payload?.service}`),
  });
  switch (msg.code) {
    case WebsocketMessageCode.SHELL_SESSION_OPENING_FAILED:
    case WebsocketMessageCode.SHELL_SESSION_CLOSED:
    case WebsocketMessageCode.TOO_MANY_SHELL_SESSIONS_OPENED:
      if (sessionId === msg.payload.sessionId) {
        terminalStore.setFailReason(msg.code);
      }
      break;
    case WebsocketMessageCode.SHELL_SESSION_OPENED:
      if (sessionId === msg.payload.sessionId) {
        terminalStore.setSessionOpened(true);
      }
      break;
    case WebsocketMessageCode.SHELL_COMMAND_RESPONSE:
    case WebsocketMessageCode.SHELL_COMMAND_CONFIRMATION_REQUEST:
      if (sessionId === msg.payload.sessionId) {
        const getLastCommandReceivedTime = () => {
          if (msg.code === WebsocketMessageCode.SHELL_COMMAND_RESPONSE) return null;
          // Check if the last command (by checking the last received time of command) and command that we get
          // are the same, then we skip showing this command
          // if not - then there is a possibility that something is wrong with WS, and we already got the output for that command via API call
          return terminalStore.lastCommandReceivedTime === msg.payload.receivedAt
            ? terminalStore.lastCommandReceivedTime
            : msg.payload.receivedAt;
        };
        terminalStore.setCommandInProgress(false);
        terminalStore.setLastCommandResponseType(msg.code);
        terminalStore.setLastCommandReceivedTime(getLastCommandReceivedTime());
        terminalStore.emitEvent({
          type: "response",
          payload: msg.payload.commandResult,
        });
      }
      break;
    case WebsocketMessageCode.IMPORT_ALLOW_BLOCK_LIST:
      // if (router.currentRoute.value.name === "allow_block_list_tab") {
      //   dispatch("emailsSettings/getAllowBlockList", null, { root: true });
      //   dispatch("snackbar/add", snackbarMessage, { root: true });
      // }
      break;
    case WebsocketMessageCode.OPERATOR_EXPORT_ADDED:
      if (router.currentRoute.value.name === RouteName.REPORTS_EXPORTS_TAB) {
        useExportsStore().getExportsList();
      }
      break;

    case WebsocketMessageCode.IMPORT_USERS_FROM_CSV:
      if (router.currentRoute.value.name === RouteName.USERS_SETTINGS_USERS_TAB) {
        useUsersSettingsStore().init();
        useUsersSettingsTabStore().getUsers();
      }
      break;
    default:
      useSnackbarStore().addGenericSuccess(snackbarMessage);
      if (router.currentRoute.value.name === RouteName.TICKETS) {
        useTicketsStore().getTickets(false);
      }
      break;
  }
}
