<template>
  <div id="comments">
    <div class="d-flex align-center">
      <div class="headline6">{{ $t("comments.title") }}</div>
      <v-chip class="ml-2 chip--counter">
        {{ totalComments }}
      </v-chip>
    </div>

    <div class="comments-section mt-3">
      <div v-if="commentNotAllowed" class="warning-block d-flex align-center mt-9 ml-7 mr-7 mb-9">
        <v-icon class="mr-3 ml-4" icon="$info"></v-icon>
        <span class="body2">{{ $t("comments.noPermission") }}</span>
      </div>

      <coro-comment-input
        v-else
        class="mt-9 ml-7 mr-7 mb-9"
        :ticket="ticket"
        @add-comment="addComment"
      />
      <div v-if="comments.length > 0">
        <div
          v-for="item in comments"
          :key="item.id"
          class="comment-item"
          :class="{ 'comment-item--confidential': item.confidential }"
        >
          <div class="d-flex align-center">
            <div class="mr-2">
              <v-icon size="35" icon="$user"></v-icon>
            </div>
            <div class="d-flex align-center justify-space-between w-100">
              <div class="d-flex align-center">
                <div class="subtitle1 text-no-wrap comment-title mr-2">
                  {{ item.createdBy }}
                </div>
                <div class="caption text-no-wrap">
                  {{ formatDistanceToNow(new Date(item.createdAt)) }}
                </div>
                <v-tooltip v-if="getCommentRecipients(item).length" open-delay="300" location="top">
                  <template #activator="{ props }">
                    <div class="comment-recipients text-no-wrap" v-bind="props">
                      <span class="comment-recipients--separator mr-1 ml-2">|</span>
                      <v-icon size="16" icon="$forward"></v-icon>
                      <span class="caption">{{ getCommentRecipients(item).length }}</span>
                    </div>
                  </template>
                  <div class="px-3 py-1">
                    <div class="text--white text--semibold">
                      {{ $t("comments.sentTo") }}
                    </div>
                    <ul>
                      <li
                        v-for="recipient in getCommentRecipients(item)"
                        :key="recipient"
                        class="text--white"
                      >
                        {{ recipient }}
                      </li>
                    </ul>
                  </div>
                </v-tooltip>
              </div>
              <div class="text-no-wrap">
                <v-chip
                  v-if="item.confidential"
                  label
                  size="x-small"
                  color="indigo-medium"
                  variant="flat"
                >
                  <v-icon size="12" icon="$confidential"></v-icon>
                  <span class="font-weight-bold text-white">{{ $t("comments.confidential") }}</span>
                </v-chip>
              </div>
            </div>
          </div>
          <coro-comment-expander class="pl-11 pt-3" :text="item.message" :max-length="240" />
        </div>
        <div class="d-flex justify-center mt-3 mb-3">
          <div
            v-if="commentsToLoad > 0"
            class="coro-link cursor-pointer"
            @click="loadMoreComments()"
          >
            {{ $t("comments.plusNComments", { n: commentsToLoad }) }}
          </div>
        </div>
      </div>
      <div v-else class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$comments"></v-icon>
        <span class="subtitle1">{{ $t("comments.noComments") }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import CoroCommentInput from "@/components/CoroCommentInput.vue";
import CoroCommentExpander from "@/components/CoroCommentExpander.vue";
import { type Comment, type CommentSettings } from "@/_store/ticket-comments.module";
import { CommentAction } from "@/constants/tickets";
import { storeToRefs } from "pinia";
import { RolePermissionsScope, type TicketsScope } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import moment, { type MomentInput } from "moment/moment";
import { useMspTicketCommentsStore } from "@/_store/msp/tickets/msp-ticket-comments.module.ts";
import type { MspTicketDetails } from "@/_store/msp/tickets/msp-tickets.module.ts";
import { isMspActionRestricted } from "@/_helpers/msp-permissions.ts";

export default defineComponent({
  components: {
    CoroCommentInput,
    CoroCommentExpander,
  },
  props: {
    ticket: {
      type: Object as PropType<MspTicketDetails>,
      required: true,
    },
    comments: {
      type: Array as PropType<Comment[]>,
      default() {
        return [];
      },
    },
  },
  setup(props) {
    const i18n = useI18n();
    const ticketCommentsStore = useMspTicketCommentsStore();
    const { loading, totalComments } = storeToRefs(ticketCommentsStore);
    const commentsToLoad = computed(() => totalComments.value - (props.comments ?? []).length);
    const commentNotAllowed = computed(() =>
      isMspActionRestricted(
        RolePermissionsScope.TICKETS,
        props.ticket.moduleName as keyof TicketsScope,
        "commentTickets"
      )
    );

    async function loadMoreComments() {
      await ticketCommentsStore.getNextPage(props.ticket);
    }

    const getSelectedRecipientCheckboxes = (item: Comment & CommentSettings) => {
      const keysToCheck = [
        "notifyAffectedUsers",
        "notifyAllWorkspaceAdmins",
        "notifySocContacts",
      ] as const;
      return keysToCheck.filter((key) => item[key] === true);
    };

    const getCommentRecipients = (item: Comment) => {
      const notifyCheckboxList = getSelectedRecipientCheckboxes(item as Comment & CommentSettings);
      return [
        ...notifyCheckboxList.map((v) => i18n.t(`comments.${v}`)),
        ...(item.customRecipients ?? []),
      ];
    };

    const formatDistanceToNow = (date: MomentInput) => {
      return moment(date).fromNow();
    };

    const addComment = async (
      payload: CommentSettings & {
        eventId: string;
        workspaceId?: string;
      }
    ) => {
      await ticketCommentsStore.addComment(payload);
    };

    return {
      CommentAction,
      loading,
      totalComments,
      commentsToLoad,
      commentNotAllowed,
      loadMoreComments,
      getCommentRecipients,
      formatDistanceToNow,
      addComment,
    };
  },
});
</script>
<style scoped lang="scss">
.comment-item {
  padding: 16px 24px;

  &--confidential {
    background-color: rgb(var(--v-theme-indigo-faint));
    border-bottom: 1px solid rgb(var(--v-theme-white));
  }
}

.comment-recipients {
  height: 24px;
  white-space: nowrap;

  &--separator {
    color: rgb(var(--v-theme-indigo-faint)) !important;
  }
}

.comment-border {
  border-bottom: 1px solid rgb(var(--v-theme-indigo-faint));
}

.comments-section {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}

.v-chip--label {
  padding-left: 3px;
  padding-right: 3px;
}

.icon-chevron:before {
  color: white !important;
  font-size: 16px;
}
</style>
