<template>
  <!--TODO: uncomment when PDF export on BE will be fixed-->
  <!--  <v-menu offset-y location="bottom right">-->
  <!--    <template #activator="{ props }">-->
  <!--      <v-btn rounded color="primary" v-bind="props" size="large" prepend-icon="$add">-->
  <!--        {{ $t(`reports.scheduled.schedule`) }}-->
  <!--      </v-btn>-->
  <!--    </template>-->
  <!--    <v-list>-->
  <!--      <v-list-item-->
  <!--        v-for="action in exportActions"-->
  <!--        :key="action"-->
  <!--        @click="handleExportAction(action)"-->
  <!--      >-->
  <!--        <v-list-item-title>-->
  <!--          {{ $t(`reports.scheduled.actions.${action}`) }}-->
  <!--        </v-list-item-title>-->
  <!--      </v-list-item>-->
  <!--    </v-list>-->
  <!--  </v-menu>-->
  <v-btn
    size="large"
    color="primary"
    rounded
    prepend-icon="$add"
    @click="handleExportAction(ScheduledReportsAction.ADD_EXPORT)"
  >
    {{ $t(`reports.scheduled.schedule`) }}
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { ModalWidth } from "@/constants/modals";
import { ScheduledReportsAction } from "@/constants/scheduled-reports";
import { useScheduledReportsStore } from "@/_store/scheduled-reports/scheduled-reports.module.ts";
import ScheduledExportModal from "@/components/modals/scheduled-reports/ScheduledExportModal.vue";
import ScheduledReportModal from "@/components/modals/scheduled-reports/ScheduledReportModal.vue";

export default defineComponent({
  setup() {
    const dialogsStore = useDialogsStore();
    const scheduledReportsStore = useScheduledReportsStore();

    const exportActions = [ScheduledReportsAction.ADD_EXPORT, ScheduledReportsAction.ADD_REPORT];

    const handleExportAction = (action: ScheduledReportsAction) => {
      const disable = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.REPORTS
      );
      switch (action) {
        case ScheduledReportsAction.ADD_EXPORT:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: scheduledReportsStore.addScheduledReport,
              component: ScheduledExportModal,
              width: ModalWidth.LARGE,
              disable,
            }),
            scrollable: true,
            disablePersistentAnimation: true,
          });
          break;
        case ScheduledReportsAction.ADD_REPORT:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: scheduledReportsStore.addScheduledReport,
              component: ScheduledReportModal,
              width: ModalWidth.LARGE,
              disable,
            }),
            scrollable: true,
            disablePersistentAnimation: true,
          });
          break;
      }
    };

    return {
      exportActions,
      handleExportAction,
      ScheduledReportsAction,
    };
  },
});
</script>
