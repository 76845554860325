import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { StoreModule } from "@/constants/store.ts";
import type { Service } from "@/constants/cloud-apps.ts";
import { ComplianceTraining } from "@/constants/security-awareness.ts";

export interface SecurityTrainingEmailData {
  emailTitle: string;
  senderName: string;
  senderEmail: string;
  emailBody: string;
}

export interface SecurityTrainingData {
  startDate: number;
  endDate: number;
  emailPreviewName: string;
  emailPreviewId: string;
}

export interface PhishingSimulationSettings {
  phishingSimulationEnabled: boolean;
  phishingAutoEnrolment: boolean;
  scheduledSimulations: SecurityTrainingData[];
  simulationsHistory: SecurityTrainingData[];
}

export interface SecurityTrainingSettings {
  trainingEnabled: boolean;
  trainingAutoEnrolment: boolean;
  trainingCourses: ComplianceTraining[];
}

export interface SecurityAwarenessMetadata {
  daysToAllowList: number;
  bannerDismissed: boolean;
}

export interface SecurityAwarenessState {
  phishingSettings: PhishingSimulationSettings;
  securityTrainingSettings: SecurityTrainingSettings;
  showSkeletonLoader: boolean;
  loading: boolean;
  metadata: SecurityAwarenessMetadata;
  metadataLoading: boolean;
}

const defaultSecurityAwarenessState = {
  phishingSettings: {
    phishingSimulationEnabled: false,
    phishingAutoEnrolment: false,
    scheduledSimulations: [],
    simulationsHistory: [],
  },
  securityTrainingSettings: {
    trainingEnabled: false,
    trainingAutoEnrolment: false,
    trainingCourses: [],
  },
  loading: false,
  showSkeletonLoader: false,
  metadataLoading: false,
  metadata: {
    daysToAllowList: 28,
    bannerDismissed: false,
  },
};

export const useSecurityAwarenessModule = defineStore(StoreModule.SECURITY_AWARENESS, {
  state: (): SecurityAwarenessState => ({ ...defaultSecurityAwarenessState }),
  actions: {
    async getPhishingSimulations(): Promise<void> {
      this.showSkeletonLoader = true;
      try {
        const response = await axiosInstance.request(api.phishingSimulations());
        const {
          data: { scheduledSimulations, simulationsHistory },
        } = response;

        this.phishingSettings = {
          ...this.phishingSettings,
          scheduledSimulations,
          simulationsHistory,
        };
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async getSecurityTrainingSettings(): Promise<void> {
      try {
        const response = await axiosInstance.request(api.securityTrainingSettings());

        const {
          trainingEnabled,
          trainingAutoEnrolment,
          trainingCourses,
          phishingSimulationEnabled,
          phishingAutoEnrolment,
        } = response.data;

        this.securityTrainingSettings = {
          trainingEnabled,
          trainingAutoEnrolment,
          trainingCourses: Object.entries(trainingCourses || {})
            .filter(([, value]) => value)
            .map(([key]) => key) as ComplianceTraining[],
        };

        this.phishingSettings = {
          ...this.phishingSettings,
          phishingSimulationEnabled,
          phishingAutoEnrolment,
        };
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async updatePhishingSettings(newSettings: PhishingSimulationSettings): Promise<void> {
      const request = {
        ...api.securityTrainingSettings(),
        method: "put",
        data: newSettings,
      };

      try {
        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
      }
    },
    async getEmailTemplate(emailId: string): Promise<{ data: SecurityTrainingEmailData } | null> {
      const request = {
        ...api.securityAwarenessEmailTemplate(),
        params: {
          emailId,
        },
      };

      try {
        return axiosInstance.request(request);
      } catch (error) {
        console.error(error);

        return Promise.reject();
      }
    },
    async updateTrainingSettings(newSettings: SecurityTrainingSettings): Promise<void> {
      const { trainingCourses, ...rest } = newSettings;
      this.loading = true;
      const request = {
        ...api.securityTrainingSettings(),
        method: "put",
        data: {
          ...rest,
          trainingCourses: (Object.keys(ComplianceTraining) as ComplianceTraining[]).reduce(
            (acc, curr) => {
              acc[curr] = trainingCourses.includes(curr);

              return acc;
            },
            {} as Record<ComplianceTraining, boolean>
          ),
        },
      };

      try {
        const response = await axiosInstance.request(request);
        const { trainingEnabled, trainingAutoEnrolment, trainingCourses } = response.data;

        this.securityTrainingSettings = {
          trainingEnabled,
          trainingAutoEnrolment,
          trainingCourses,
        };
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async sendTestEmails(service: Service) {
      const request = {
        ...api.sendSecurityAwarenessTestEmails(),
        data: {
          service,
        },
      };
      try {
        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
      }
    },
    async confirmEmails(service: Service) {
      const request = {
        ...api.confirmSecurityAwarenessEmails(),
        data: {
          service,
        },
      };
      try {
        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
      }
    },
    async getMetadata() {
      const request = {
        ...api.securityAwarenessMetadata(),
      };
      this.metadataLoading = true;
      try {
        const response = await axiosInstance.request(request);
        this.metadata = response.data;
      } catch (error) {
        console.error("Error fetching security awareness metadata:", error);
      } finally {
        this.metadataLoading = false;
      }
    },
    async dismissSaBanner() {
      try {
        const request = await axiosInstance.request({
          ...api.dismissSaBanner(),
        });
        await axiosInstance.request(request);
        await this.getMetadata();
      } catch (error) {
        console.error("Error updating banner dismissed state:", error);
      }
    },
  },
});
